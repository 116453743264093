// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar_wrapper__oKnqD {
  pointer-events: none;

  display: flex;
  align-items: center;
  margin-bottom: auto;
  background: linear-gradient(var(--overlayBackground), transparent); /* backwards compatible */
  color: var(--overlayForeground);
  transition: opacity 0.2s;

  justify-content: flex-start;
  padding: 10px 20px;
  gap: 20px;
}

@supports (color: color-mix(in lch, red, blue)) {
.top-bar_wrapper__oKnqD {
  background: linear-gradient(color-mix(in srgb, var(--overlayBackground) 90%, transparent), transparent);
}
}

.top-bar_wrapper__oKnqD.top-bar_hidden__x5oQk {
    opacity: 0;
  }

.top-bar_wrapper__oKnqD > * {
    pointer-events: auto;
  }

.top-bar_wrapper__oKnqD .top-bar_logo__9-JLN {
    width: 34px;
    height: 34px;
    object-fit: contain;
    object-position: center;
  }

.top-bar_wrapper__oKnqD .top-bar_title__B4bHd {
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;

    pointer-events: all;
    overflow: hidden;
  }

.top-bar_wrapper__oKnqD .top-bar_downloadSourcesButton__FEK2- {
    margin-left: auto;
  }

.smallScreen .top-bar_logo__9-JLN {
    width: 24px;
    height: 24px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/top-bar/top-bar.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;;EAEpB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kEAAkE,EAAE,yBAAyB;EAE7F,+BAA+B;EAC/B,wBAAwB;;EAExB,2BAA2B;EAC3B,kBAAkB;EAClB,SAAS;AA8BX;;AA3CA;AAAA;EAOE,uGAAuG;AAoCzG;AAAA;;AA5BE;IACE,UAAU;EACZ;;AAEA;IACE,oBAAoB;EACtB;;AAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;EACzB;;AAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;;IAEvB,mBAAmB;IACnB,gBAAgB;EAClB;;AAEA;IACE,iBAAiB;EACnB;;AAIA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".wrapper {\n  pointer-events: none;\n\n  display: flex;\n  align-items: center;\n  margin-bottom: auto;\n  background: linear-gradient(var(--overlayBackground), transparent); /* backwards compatible */\n  background: linear-gradient(color-mix(in srgb, var(--overlayBackground) 90%, transparent), transparent);\n  color: var(--overlayForeground);\n  transition: opacity 0.2s;\n\n  justify-content: flex-start;\n  padding: 10px 20px;\n  gap: 20px;\n\n  &.hidden {\n    opacity: 0;\n  }\n\n  > * {\n    pointer-events: auto;\n  }\n\n  .logo {\n    width: 34px;\n    height: 34px;\n    object-fit: contain;\n    object-position: center;\n  }\n\n  .title {\n    font-size: 12px;\n    line-height: 15px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    pointer-events: all;\n    overflow: hidden;\n  }\n\n  .downloadSourcesButton {\n    margin-left: auto;\n  }\n}\n\n:global(.smallScreen) {\n  & .logo {\n    width: 24px;\n    height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `top-bar_wrapper__oKnqD`,
	"hidden": `top-bar_hidden__x5oQk`,
	"logo": `top-bar_logo__9-JLN`,
	"title": `top-bar_title__B4bHd`,
	"downloadSourcesButton": `top-bar_downloadSourcesButton__FEK2-`
};
export default ___CSS_LOADER_EXPORT___;
