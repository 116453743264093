import type { RestrictionType } from './sso-whitelist-type-modal';

import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ChipsInput,
  FileOutlineIcon,
  FileUploader,
  IconButton,
  InfoCircleIcon,
  InputChip,
  InputDescription,
  Modal,
  PrimaryButton,
  Radio,
  SecondaryButton,
  SelectionInputLayout,
  Spinner,
  Tooltip,
  WarningIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { t } from 'i18next';
import { useState } from 'react';

import { validateEmailInput } from '../validate-email-input';

import styles from './whitelist-modal.module.css';

type WhitelistModalProps = {
  type: RestrictionType;
  isOpen: boolean;
  maxItems?: number;
  onGoBack?: () => void;
  onConfirm: (items: string[]) => void;
  onClose: () => void;
};

type Item = {
  value: string;
  validationMessage?: string;
};

type AddMethod = 'manual' | 'csv';

export function WhitelistModal({ isOpen, type, maxItems = 5000, onGoBack, onConfirm, onClose }: WhitelistModalProps) {
  const [_items, setItems] = useState<Item[]>([]);
  const [addMethod, setAddMethod] = useState<AddMethod>('manual');
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const validateItems = (items: Item[]) => {
    return type === 'emails' ? validateEmails(items) : items;
  };

  const validateEmails = (emails: Item[]) => {
    const validatedEmails: Item[] = [];
    const validatedValues = new Set<string>();

    for (const email of emails) {
      const validationMessage = validateEmailInput(email.value, Array.from(validatedValues));
      const isValid = !validationMessage;

      validatedEmails.push({ value: email.value, validationMessage });

      if (isValid) validatedValues.add(email.value);
    }

    return validatedEmails;
  };

  const onChipsAdded = (values: Item['value'][]) => {
    setItems((state) => validateItems([...state, ...values.map((value) => ({ value, validationMessage: '' }))]));
  };

  const onChipDeleted = (index: number) => {
    setItems((state) => validateItems(state.filter((_, existingIndex) => existingIndex !== index)));
  };

  const onChipEdit = (value: string, index: number) => {
    if (type === 'emails') {
      const validationMessage = validateEmailInput(
        value,
        _items.map(({ value }) => value)
      );

      setItems((state) =>
        state.map((item, existingIndex) => (existingIndex === index ? { value, validationMessage } : item))
      );
    }
  };

  const resetState = () => {
    setItems([]);
    setErrorMessage(undefined);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleConfirm = () => {
    if (submitDisabled) return;

    onConfirm(_items.map(({ value }) => value));
    handleClose();
  };

  const numberOfItems = _items.length;
  const invalidItems = _items.filter(({ validationMessage }) => Boolean(validationMessage));
  const numberOfInvalidItems = invalidItems.length;
  const numberOfValidItems = _items.length - numberOfInvalidItems;
  const submitDisabled = numberOfItems === 0 || numberOfValidItems === 0 || numberOfInvalidItems > 0;
  const radioButtonsAvailable = addMethod !== 'csv' || _items.length === 0;
  const fileUploaderAvailable = addMethod === 'csv' && _items.length === 0;

  let validationMessage;
  if (numberOfInvalidItems === 1) validationMessage = invalidItems[0].validationMessage;
  if (numberOfInvalidItems > 1)
    validationMessage = t('manager.webcastSetup.viewerAccess.whitelistModal.errorMessages.generic.multipleErrors');

  const tooltipLabel =
    validationMessage ||
    (submitDisabled && t('manager.webcastSetup.viewerAccess.whitelistModal.errorMessages.generic.emptyList')) ||
    undefined;

  const handleUploadFile = (files: File | File[]) => {
    const file = Array.isArray(files) ? files[0] : files;
    const fileReader = new FileReader();

    setErrorMessage(undefined);
    setIsCsvLoading(true);

    fileReader.onload = (event) => {
      parseAndSaveCsvItems(event?.target?.result as string);
      setIsCsvLoading(false);
    };

    fileReader.readAsText(file);
  };

  const parseAndSaveCsvItems = (value?: string) => {
    if (!value) return setErrorMessage(t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.hint.empty'));

    let items = value.split('\n').map((item) => item.trim().toLowerCase());

    if (type === 'emails') {
      const emailsWithoutHeader = !items[0].includes('@') ? items.slice(1) : items;
      items = Array.from(new Set(emailsWithoutHeader)).filter(Boolean);
    }

    if (items.length > maxItems) {
      return setErrorMessage(t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.hint.size'));
    }

    setItems((state) => validateItems([...state, ...items.map((value) => ({ value, validationMessage: '' }))]));
  };

  const sortedItemsByValidation = _items.sort((a, b) => {
    if (a.validationMessage && !b.validationMessage) return -1;
    if (!a.validationMessage && b.validationMessage) return 1;
    return 0;
  });

  const ItemsChipsInput = (
    <ChipsInput
      separators={['Enter', ',', ' ']}
      onChipsAdded={onChipsAdded}
      className={{ chipsInput: styles.chipsInput }}
      data-testid="whitelist-chips-input"
    >
      {sortedItemsByValidation.map((item, index) => (
        <InputChip
          key={`${item.value}-${index}`}
          value={item.value}
          invalid={Boolean(item.validationMessage)}
          onDelete={() => onChipDeleted(index)}
          onEdit={(value) => onChipEdit(value, index)}
        />
      ))}
    </ChipsInput>
  );

  return (
    <Modal
      data-testid={`${type}-whitelist-modal`}
      title={
        <div className={styles.itemWhitelistTitle}>
          {onGoBack && (
            <IconButton rounded onClick={onGoBack} aria-label="">
              <ChevronLeftIcon />
            </IconButton>
          )}
          {t(`manager.webcastSetup.viewerAccess.whitelistModal.title.${type}`)}
        </div>
      }
      isOpen={isOpen}
      onClose={handleClose}
      footer={
        !fileUploaderAvailable && (
          <div className={styles.itemWhitelistFooter}>
            <SecondaryButton data-testid={`${type}-whitelist-modal-cancel-button`} onClick={handleClose}>
              {t('manager.webcastSetup.viewerAccess.whitelistModal.footer.cancel')}
            </SecondaryButton>

            <Tooltip label={tooltipLabel} hidden={!submitDisabled}>
              <PrimaryButton
                data-testid={`${type}-whitelist-modal-submit-button`}
                disabled={submitDisabled}
                onClick={handleConfirm}
              >
                {t('manager.webcastSetup.viewerAccess.whitelistModal.footer.confirm')}
              </PrimaryButton>
            </Tooltip>
          </div>
        )
      }
    >
      {radioButtonsAvailable && (
        <div className={styles.addMethodWrapper}>
          <SelectionInputLayout
            checked={addMethod === 'manual'}
            inputElement={
              <Radio
                data-testid="add-value-manual"
                name="add-values-method"
                value="manual"
                checked={addMethod === 'manual'}
                onChange={() => setAddMethod('manual')}
              >
                {t('manager.webcastSetup.viewerAccess.whitelistModal.manualInput.radio')}
              </Radio>
            }
          />

          <SelectionInputLayout
            checked={addMethod === 'csv'}
            inputElement={
              <Radio
                data-testid="add-value-csv"
                name="add-values-method"
                value="csv"
                checked={addMethod === 'csv'}
                onChange={() => setAddMethod('csv')}
              >
                {t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.radio')}
              </Radio>
            }
          />
        </div>
      )}

      {addMethod === 'manual' && (
        <>
          <InputDescription
            hint={t(`manager.webcastSetup.viewerAccess.whitelistModal.manualInput.hint.${type}`)}
            hintDelay={0}
            label={t(`manager.webcastSetup.viewerAccess.whitelistModal.manualInput.label.${type}`)}
            style={{ marginTop: 50 }}
          >
            {ItemsChipsInput}
          </InputDescription>

          <div
            data-testid={`${type}-whitelist-modal-manual-info`}
            className={classNames(styles.inputInfo, validationMessage && styles.invalid)}
          >
            {validationMessage || t('manager.webcastSetup.viewerAccess.whitelistModal.manualInput.helpMessage')}
          </div>
        </>
      )}

      {addMethod === 'csv' && numberOfItems === 0 && (
        <div className={styles.fileUploaderWrapper}>
          {isCsvLoading && <Spinner className={styles.fileUploaderSpinner} />}

          <FileUploader
            // Remount the file uploader when an error occurs in order not to show the progress bar
            // when the file got loaded but there are still zero items because there was an error.
            key={errorMessage}
            acceptedFormats=".csv"
            icon={<FileOutlineIcon />}
            onChange={handleUploadFile}
            testIdPrefix={`${type}-whitelist-csv-`}
            additionalUploadInformation={[
              t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.hint.size'),
              t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.hint.extension'),
              t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.hint.delimiter'),
              t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.hint.duplication'),
            ]}
          >
            {errorMessage && (
              <div data-testid="file-upload-error-message" className={classNames(styles.resultInfo, styles.invalid)}>
                <WarningIcon />
                {errorMessage}
              </div>
            )}
          </FileUploader>
        </div>
      )}

      {addMethod === 'csv' && numberOfItems > 0 && (
        <>
          <div className={styles.resultWrapper}>
            <span data-testid="valid-values-info">
              {t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.validValues', {
                count: numberOfValidItems,
              })}
            </span>

            <div className={classNames(styles.resultInfo, numberOfInvalidItems > 0 && styles.invalid)}>
              {numberOfInvalidItems > 0 ? (
                <>
                  <InfoCircleIcon />

                  <span data-testid="invalid-values-info">
                    {t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.invalidValues', {
                      count: numberOfInvalidItems,
                    })}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon />

                  <span data-testid="all-valid-values-info">
                    {t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.allValidValues')}
                  </span>
                </>
              )}
            </div>
          </div>

          <InputDescription label={t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.label')}>
            {ItemsChipsInput}
          </InputDescription>

          {Boolean(numberOfInvalidItems) && (
            <div
              data-testid={`${type}-whitelist-modal-csv-info`}
              className={classNames(styles.inputInfo, styles.invalid)}
            >
              <InfoCircleIcon />
              {t('manager.webcastSetup.viewerAccess.whitelistModal.csvUpload.invalidValuesInfo')}
            </div>
          )}
        </>
      )}
    </Modal>
  );
}
