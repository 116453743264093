import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { PlayIcon } from '../../icons/play';
import { PauseIcon } from '../../icons/pause';
import styles from '../../styles/common.module.css';

type Props = {
  className?: string;
  isPlaying?: boolean;
  onClick?: () => void;
};

export function PlayButton({ isPlaying = false, onClick, className }: Props) {
  const { t } = useTranslation();

  return isPlaying ? (
    <button type="button" name="pause-button" className={classNames(className, styles.button)} onClickCapture={onClick}>
      <Tooltip>{t('ui.play.button.label.active')}</Tooltip>
      <PauseIcon width="12" height="14" />
    </button>
  ) : (
    <button type="button" name="play-button" className={classNames(className, styles.button)} onClickCapture={onClick}>
      <Tooltip>{t('ui.play.button.label.inactive')}</Tooltip>
      <PlayIcon />
    </button>
  );
}
