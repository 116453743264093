import type { Descendant } from 'slate';

import { InfoCircleIcon, Paragraph, Tooltip } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { RichTextEditor } from '../../../../components/rich-text-editor';

import styles from './compliance-link-editor.module.css';

type Props = {
  content?: Descendant[];
  disabled?: boolean;
  refreshKey?: number; // Change this to force a refresh of the editor
  onChange: (content: Descendant[]) => void;
};

export function ComplianceLinkEditor({ content, disabled, refreshKey = 0, onChange }: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <Paragraph className={styles.editorParagraph}>
        {t('manager.channelSettings.compliance.editor.paragraph')}
      </Paragraph>

      <div className={styles.richTextEditorWrapper}>
        <RichTextEditor
          key={refreshKey}
          value={content}
          data-testid="compilance-rich-text-editor"
          disabled={disabled}
          autoFocus={true}
          onChange={onChange}
          className={styles.richTextEditor}
          customButtons={
            <Tooltip label={t('manager.channelSettings.compliance.editor.tooltip')} delay={0}>
              <InfoCircleIcon className={styles.infoIcon} style={{ marginLeft: 'auto' }} />
            </Tooltip>
          }
          displayBoldMarkButton={false}
          displayItalicMarkButton={false}
          displayUnderlineMarkButton={false}
          displayOrderedListMarkButton={false}
          displayUnorderedListMarkButton={false}
          placeholder="Placeholder"
          renderPlaceholder={({ attributes }) => (
            <span {...attributes} className={styles.placeholder}>
              {t('manager.channelSettings.compliance.editor.renderPlaceholder1')}
              <br />
              <br />
              <a href="/">{t('manager.channelSettings.compliance.editor.renderPlaceholder2')}</a>
              <br />
              <br />
              <a href="/">{t('manager.channelSettings.compliance.editor.renderPlaceholder3')}</a>
              <br />
              <br />
              <a href="/">{t('manager.channelSettings.compliance.editor.renderPlaceholder4')}</a>
            </span>
          )}
        />
      </div>
    </div>
  );
}
