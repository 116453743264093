import { CSSProperties, useMemo } from 'react';

type PlayerColors = {
  theme?: 'dark' | 'light';
  customPrimaryColor?: string;
  controlBarColor?: string;
  iconColor?: string;
};

const defaultColors = {
  '--primaryColor': '#009339',
  '--backgroundColor': '#202020',
  '--videoBackground': '#000000',
  '--textColor': '#FFFFFF',
  '--sliderBackgroundColor': 'rgba(255, 255, 255, 0.2)',
  '--labelOverlayColor': 'rgba(32, 32, 32, 0.5)',
  '--overlayBackground': '#202020',
  '--overlayForeground': '#FFFFFF',
};

export function usePlayerColorsStyles({ theme, customPrimaryColor, controlBarColor, iconColor }: PlayerColors) {
  const styles = useMemo(
    () =>
      ({
        '--primaryColor': customPrimaryColor || defaultColors['--primaryColor'],
        '--backgroundColor': theme === 'light' ? '#FFFFFF' : defaultColors['--backgroundColor'],
        '--videoBackground': theme === 'light' ? '#FFFFFF' : defaultColors['--videoBackground'],
        '--textColor': theme === 'light' ? '#000000' : defaultColors['--textColor'],
        '--sliderBackgroundColor': defaultColors['--sliderBackgroundColor'],
        '--labelOverlayColor': defaultColors['--labelOverlayColor'],
        '--overlayBackground':
          controlBarColor || (theme === 'light' ? '#FFFFFF' : defaultColors['--overlayBackground']),
        '--overlayForeground': iconColor || (theme === 'light' ? '#333333' : defaultColors['--overlayForeground']),
      }) as CSSProperties,
    [controlBarColor, customPrimaryColor, iconColor, theme]
  );

  return styles;
}
