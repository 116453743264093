import { useState, useEffect, useCallback } from 'react';
import type { MutableRefObject } from 'react';

import { clamp } from '../utils/clamp';

/**
 * Returns the X and Y coordinates of the cursor being moved over a DOM element.
 * @param {MutableRefObject<HTMLElement | null>} ref A React ref to the DOM element.
 * @param {boolean} [inPercentage=true] Returned value will be in percentage if true.
 * @param {boolean} [clearOnMouseLeave=true] Sets values to zero on mousemeave if true.
 * @returns {[{x: number, y: number}, boolean]} Numeric X and Y coordinates of the cursor,
 * and a boolean indicating that the cursor is moving.
 */
export function useCursorPosition(
  ref: MutableRefObject<HTMLElement | null>,
  inPercentage = false,
  clearOnMouseLeave = true
): [{ x: number; y: number }, boolean] {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [active, setActive] = useState(false);

  const calculateValues = useCallback(
    (clientX: number, clientY: number, node: HTMLElement) => {
      const { left, top, width, height } = node.getBoundingClientRect();

      let x = clamp(0, clientX - left, width);
      let y = clamp(0, clientY - top, width);

      if (inPercentage) {
        x = (x / width) * 100;
        y = (y / height) * 100;
      }

      setPosition({ x, y });
      setActive(true);
    },
    [inPercentage]
  );

  const clearValues = useCallback(() => {
    if (clearOnMouseLeave) setPosition({ x: 0, y: 0 });
    setActive(false);
  }, [clearOnMouseLeave]);

  useEffect(() => {
    const node = ref.current;

    if (!node) return;

    const handleMouseMove = (event: MouseEvent) => {
      calculateValues(event.clientX, event.clientY, node);
    };

    const handleTouchMove = (event: TouchEvent) => {
      calculateValues(event.touches[0].clientX, event.touches[0].clientY, node);
    };

    node.addEventListener('mousemove', handleMouseMove, { passive: true });
    node.addEventListener('mouseleave', clearValues, { passive: true });
    node.addEventListener('touchmove', handleTouchMove);
    node.addEventListener('touchend', clearValues);

    return () => {
      node.removeEventListener('mousemove', handleMouseMove);
      node.removeEventListener('mouseleave', clearValues);
      node.removeEventListener('touchmove', handleTouchMove);
      node.removeEventListener('touchend', clearValues);
    };
  }, [ref, calculateValues, clearValues]);

  return [position, active];
}
