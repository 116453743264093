import { useEffect, useRef } from 'react';

/**
 * Returns previous value of the provided one.
 * @param {any} value The value to keep track of.
 * @returns {any} Returns previous value.
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
