import type { Locale } from 'date-fns';
import type { TFunction } from 'i18next';

import { differenceInMinutes, formatDistanceStrict } from 'date-fns';

import { State } from '../../../generated/graphql-viewer';

export function getCounterText(
  plannedStartAt: Date,
  comparisonDate: Date,
  state: State,
  locale: Locale,
  translator: TFunction<'translations', undefined>
) {
  const minutesToStart = differenceInMinutes(plannedStartAt, comparisonDate);
  const distanceToStart = formatDistanceStrict(plannedStartAt, comparisonDate, {
    addSuffix: true,
    locale: locale,
  });

  let counterText = '';

  if (state === State.PRELIVE) {
    if (minutesToStart >= 1) {
      counterText = `${translator('viewer.views.stream.start')} ${distanceToStart}`;
    }
    if (minutesToStart < 1 && minutesToStart >= 0) {
      counterText = translator('viewer.views.stream.startsSoon');
    }
    if (minutesToStart < 0) {
      counterText = translator('viewer.views.stream.notStarted');
    }
  }

  if (state === State.POSTLIVE) {
    counterText = `${translator('viewer.views.stream.streamed')} ${distanceToStart}`;
  }

  return counterText;
}
