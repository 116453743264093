/**
 * Gets a random `number` between `min` and `max` bounds.
 * @param {number} min The minimum expected value.
 * @param {number} max The maximum expected value.
 * @returns {number} Returns a random number no lower than (and may possibly equal) min, and less than (and not equal) max.
 * @example
 * getRandomNumber(0.3, 0.5) // 0.4
 * getRandomNumber() // 0.9
 */
export function getRandomNumber(min: number = 0.5, max: number = 1): number {
  return Math.random() * (max - min) + min;
}
