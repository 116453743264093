import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubtitleOption } from '../settings-menu';
import { AudioTrack } from './language-menu.types';
import { useLanguageMenuItems } from './hooks/use-language-menu-items';
import { MenuOptions, MenuWrapper } from '../menu/menu-wrapper';

type Props = {
  isOpen?: boolean;
  chosenSubtitleId?: string;
  chosenAudioTrackId?: string;
  subtitles: SubtitleOption[];
  audioTracks: AudioTrack[];
  subtitlesEnabled: boolean;
  onSetSubtitle?: (subtitleId: string) => void;
  onSetAudioTrack?: (audioTrackId: string) => void;
  onClose: () => void;
};

const AUDIO_MENU_ID = 'audio-menu';
const SUBTITLES_MENU_ID = 'subtitles-menu';
const LANGUAGE_MENU_ID = 'language-menu';

export function LanguageMenu({
  isOpen,
  chosenSubtitleId,
  chosenAudioTrackId,
  subtitles = [],
  audioTracks = [],
  subtitlesEnabled,
  onSetSubtitle,
  onSetAudioTrack,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const { menuItems } = useLanguageMenuItems(audioTracks, subtitles, chosenSubtitleId);

  const menuOptions: MenuOptions = useMemo(() => {
    const audioMenuOptions = menuItems.filter((item) => item.type === 'audio');
    const subtitlesMenuOptions = menuItems.filter((item) => item.type === 'subtitle');

    const showAudioSubmenu = !!audioTracks?.length;
    const showSubtitlesSubmenu = !!subtitles?.length;

    const audioSubmenu = showAudioSubmenu
      ? {
          id: AUDIO_MENU_ID,
          label: t('ui.menu.item.audio'),
          value: chosenAudioTrackId || '',
          defaultValue: t('ui.menu.item.quality.auto'),
          showHeader: true,
          options: audioMenuOptions,
        }
      : undefined;
    const subtitlesSubmenu = showSubtitlesSubmenu
      ? {
          id: SUBTITLES_MENU_ID,
          label: t('ui.menu.item.subtitles'),
          value: subtitlesEnabled ? chosenSubtitleId : 'off',
          showHeader: true,
          options: subtitlesMenuOptions,
        }
      : undefined;
    const submenus = [audioSubmenu, subtitlesSubmenu].filter((item) => !!item);

    const menu =
      submenus.length === 1
        ? submenus[0]
        : { id: LANGUAGE_MENU_ID, label: t('ui.menu.item.language'), options: submenus };

    return menu;
  }, [audioTracks?.length, chosenAudioTrackId, chosenSubtitleId, menuItems, subtitles?.length, subtitlesEnabled, t]);

  const onChooseItem = useCallback(
    (currentScreen: string, itemId: string) => {
      if (currentScreen === AUDIO_MENU_ID) {
        onSetAudioTrack?.(itemId);
      } else if (currentScreen === SUBTITLES_MENU_ID) {
        onSetSubtitle?.(itemId);
      }
    },
    [onSetAudioTrack, onSetSubtitle]
  );

  return (
    <MenuWrapper
      isOpen={isOpen}
      onClose={onClose}
      menuOptions={menuOptions}
      onChooseItem={onChooseItem}
      buttonName="language-menu-button"
    />
  );
}
