import type { Video } from '../../../generated/graphql-manager';

import { Note, Spinner, classNames } from '@movingimage-evp/mi-ui-component-library';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getLocale } from '../../../i18n';
import { secondsToHHMMSS } from '../../../utils';

import styles from './videos-items.module.css';

type Props = {
  videos?: Video[];
  loading?: boolean;
  isSearchTerm?: boolean;
  onVideoClick: (videoId: Video['id']) => void;
};

export function VideosItems({ videos, loading, isSearchTerm, onVideoClick }: Props) {
  const locale = getLocale();
  const { t } = useTranslation();
  const noVideosAvailable = videos?.length === 0 && !loading;
  const noSearchVideosAvailable = noVideosAvailable && isSearchTerm;

  return (
    <div data-testid="video-manager-browser-videos-items" className={classNames(styles.wrapper, 'lspro-scrollbars')}>
      {noVideosAvailable && (
        <Note
          data-testid="video-manager-browser-video-not-available"
          className={styles.noVideosNote}
          title={
            noSearchVideosAvailable
              ? t('components.videoManager.videoBrowser.noSearchVideosTitle')
              : t('components.videoManager.videoBrowser.noVideosTitle')
          }
        >
          {noSearchVideosAvailable
            ? t('components.videoManager.videoBrowser.noSearchVideosDescription')
            : t('components.videoManager.videoBrowser.noVideosDescription')}
        </Note>
      )}

      {loading && <Spinner size={40} className={styles.spinner} />}

      {videos?.map((video) => (
        <button
          data-testid={`video-manager-browser-video-button-${video.id}`}
          key={video.id}
          className={styles.videoButton}
          onClick={() => onVideoClick(video.id)}
        >
          <span className={styles.thumbnail} style={{ backgroundImage: `url('${video.thumbnail}')` }}>
            <span className={styles.time}>{secondsToHHMMSS(video.duration / 1000)}</span>
          </span>

          <span className={styles.details}>
            <span>{video.title}</span>
            <span>{format(new Date(video.createdDate), 'dd.MM.yyyy', { locale })}</span>
          </span>
        </button>
      ))}
    </div>
  );
}
