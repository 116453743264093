import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { FullscreenEnterIcon } from '../../icons/fullscreen-enter';
import { FullscreenExitIcon } from '../../icons/fullscreen-exit';
import styles from '../../styles/common.module.css';

type Props = {
  isFullscreen?: boolean;
  className?: string;
  onClick?: () => void;
};

export function FullscreenButton({ isFullscreen = false, onClick, className }: Props) {
  const { t } = useTranslation();

  return isFullscreen ? (
    <FullscreenExitButton className={className} label={t('ui.fullscreen.button.label.active')} onClick={onClick} />
  ) : (
    <FullscreenEnterButton className={className} label={t(`ui.fullscreen.button.label.inactive`)} onClick={onClick} />
  );
}

type ButtonProps = {
  label: string;
  className?: string;
  onClick?: () => void;
};

function FullscreenEnterButton({ label, onClick, className }: ButtonProps) {
  return (
    <button type="button" name="fullscreen-button" className={classNames(className, styles.button)} onClick={onClick}>
      <Tooltip>{label}</Tooltip>
      <FullscreenEnterIcon />
    </button>
  );
}

function FullscreenExitButton({ label, onClick, className }: ButtonProps) {
  return (
    <button
      type="button"
      name="fullscreen-exit-button"
      className={classNames(className, styles.button)}
      onClick={onClick}
    >
      <Tooltip>{label}</Tooltip>
      <FullscreenExitIcon />
    </button>
  );
}
