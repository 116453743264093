import type { InputHTMLAttributes } from 'react';

import { classNames } from '@movingimage-evp/mi-ui-component-library';

import { isValidHexValue } from '../../../../utils';

import styles from './color-picker.module.css';

type Props = {
  'data-testid'?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function ColorPicker({ id, className, style, 'data-testid': dataTestid, ...props }: Props) {
  const color = String(props.value);
  const isValid = isValidHexValue(color);

  return (
    <div className={classNames(styles.wrapper, className)} style={style}>
      <div
        className={classNames(styles.colorPreview, !isValid && styles.invalid)}
        style={isValid ? { backgroundColor: color } : undefined}
      >
        <input type="color" {...props} />
      </div>

      <input id={id} type="text" data-testid={dataTestid} {...props} />
    </div>
  );
}
