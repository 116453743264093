import { Modal, SecondaryButton } from '@movingimage-evp/mi-ui-component-library';
import { t } from 'i18next';

import styles from './turn-off-recording-confirmation-modal.module.css';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export function TurnOffRecordingConfirmationModal({ isOpen, onCancel, onConfirm }: Props) {
  return (
    <Modal
      data-testid="turn-off-recording-confirmation-modal"
      title={t('manager.webcastSetup.eventRecording.options.recordLivestream.confirmationModal.title')}
      isOpen={isOpen}
      onClose={onCancel}
      contentProps={{ className: styles.modal }}
      footer={
        <>
          <SecondaryButton data-testid="cancel-turn-off-recording-button" type="button" onClick={onCancel}>
            {t('common.cancel')}
          </SecondaryButton>

          <SecondaryButton data-testid="confirm-turn-off-recording-button" type="button" onClick={onConfirm}>
            {t('common.confirm')}
          </SecondaryButton>
        </>
      }
    >
      {t('manager.webcastSetup.eventRecording.options.recordLivestream.confirmationModal.text')}
    </Modal>
  );
}
