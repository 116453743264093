// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner_wrapper__fVE7M {
  display: none;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.vjs-waiting .spinner_wrapper__fVE7M {
  display: flex;
}

.spinner_spinner__a7DuJ {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
}

.spinner_spinner__a7DuJ div {
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;

  position: absolute;

  border: 8px solid #fff;
  border-radius: 50%;
  border-color: #fff transparent transparent transparent;
  animation: spinner_spin__4bT3x 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner_spinner__a7DuJ div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner_spinner__a7DuJ div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner_spinner__a7DuJ div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner_spin__4bT3x {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/spinner/spinner.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;;EAEZ,8BAA8B;;EAE9B,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,WAAW;;EAEX,kBAAkB;;EAElB,sBAAsB;EACtB,kBAAkB;EAClB,sDAAsD;EACtD,yEAA0D;AAC5D;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".wrapper {\n  display: none;\n\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n\n  background: rgba(0, 0, 0, 0.5);\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 10;\n}\n\n:global(.vjs-waiting) .wrapper {\n  display: flex;\n}\n\n.spinner {\n  display: inline-block;\n  width: 80px;\n  height: 80px;\n  position: relative;\n}\n\n.spinner div {\n  display: block;\n  width: 64px;\n  height: 64px;\n  margin: 8px;\n\n  position: absolute;\n\n  border: 8px solid #fff;\n  border-radius: 50%;\n  border-color: #fff transparent transparent transparent;\n  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n}\n\n.spinner div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n\n.spinner div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n\n.spinner div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `spinner_wrapper__fVE7M`,
	"spinner": `spinner_spinner__a7DuJ`,
	"spin": `spinner_spin__4bT3x`
};
export default ___CSS_LOADER_EXPORT___;
