import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { StepBackIcon } from '../../icons/step-back';
import { StepForwardIcon } from '../../icons/step-forward';
import styles from '../../styles/common.module.css';

type Props = {
  className?: string;
  onClick?: () => void;
};

export function StepBackButton({ onClick, className }: Props) {
  const { t } = useTranslation();

  const label = t('ui.step-back.button.label');

  return (
    <button
      type="button"
      name="step-back-button"
      data-testid="step-back-button"
      className={classNames(className, styles.button)}
      onClick={onClick}
    >
      <Tooltip>{label}</Tooltip>
      <StepBackIcon />
    </button>
  );
}

export function StepForwardButton({ onClick }: Props) {
  const { t } = useTranslation();

  const label = t('ui.step-forward.button.label');

  return (
    <button
      type="button"
      name="step-forward-button"
      data-testid="step-forward-button"
      className={styles.button}
      onClick={onClick}
    >
      <Tooltip>{label}</Tooltip>
      <StepForwardIcon />
    </button>
  );
}
