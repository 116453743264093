import type { VideoJsPlayer } from 'video.js';
import { PlayerSources } from '../player.config';
import { Plugins } from '../player.interface';

import { getHiveSources } from './hive';
import { getRampSources } from './ramp';
import { getVideoJSSources } from './get-videojs-sources';

import videojs from 'video.js';

export async function getSources(
  player: VideoJsPlayer,
  sources: PlayerSources,
  plugins: Plugins,
  abortSignal: AbortSignal,
  preferredQuality?: string
): Promise<videojs.Tech.SourceObject[]> {
  const videoJsSources = getVideoJSSources(sources, preferredQuality);
  const fallbackUrl = videoJsSources[0]?.src;

  const hiveSources = plugins.hive && (await getHiveSources(player, plugins.hive, abortSignal));
  const rampSources = plugins.ramp && (await getRampSources(player, plugins.ramp, fallbackUrl, abortSignal));

  if (hiveSources) {
    return hiveSources;
  }
  if (rampSources) {
    return rampSources;
  }

  return videoJsSources;
}
