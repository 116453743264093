// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-animation-overlay_wrapper__ITIR5 {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  background-color: black;
}

.audio-animation-overlay_image__Ac-1P {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;

  background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/audio-animation-overlay/audio-animation-overlay.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,WAAW;EACX,YAAY;;EAEZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;;EAEX,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,iBAAiB;;EAEjB,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 100%;\n  height: 100%;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: -1;\n\n  background-color: black;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: -1;\n  object-fit: cover;\n\n  background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `audio-animation-overlay_wrapper__ITIR5`,
	"image": `audio-animation-overlay_image__Ac-1P`
};
export default ___CSS_LOADER_EXPORT___;
