import { Paragraph, Tooltip } from '@movingimage-evp/mi-ui-component-library';

import styles from './video-manager.module.css';

export function ChannelPath({ path }: { path: string }) {
  function shortenPath(path: string) {
    const segments = path.split('/');

    if (segments.length <= 3) {
      return path.replace(/\//g, ' / ');
    } else {
      return `${segments[0]} / ... / ${segments[segments.length - 2]} / ${segments[segments.length - 1]}`;
    }
  }

  return (
    <Tooltip label={path}>
      <Paragraph className={styles.label}>{shortenPath(path)}</Paragraph>
    </Tooltip>
  );
}
