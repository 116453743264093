import type { WebcastWithTitle } from '../types';

import { Modal, Paragraph, SecondaryButton } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import style from './duplicate-webcast.module.css';

type Props = {
  isOpen: boolean;
  webcastTitle: WebcastWithTitle['title'];
  onClose: () => void;
  onDuplicate: () => void;
};

export function DuplicateWebcastModal({ isOpen, webcastTitle, onClose, onDuplicate }: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      data-testid="duplicate-webcast-modal"
      title={t('manager.webcasts.duplicateWebcast.modal.title')}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          <SecondaryButton data-testid="cancel-duplicate-webcast-button" type="button" onClick={onClose}>
            {t('common.cancel')}
          </SecondaryButton>

          <SecondaryButton data-testid="confirm-duplicate-webcast-button" type="button" onClick={onDuplicate}>
            {t('manager.webcasts.duplicateWebcast.modal.createButton')}
          </SecondaryButton>
        </>
      }
    >
      <Paragraph className={style.info}>{t('manager.webcasts.duplicateWebcast.modal.info')}</Paragraph>

      {webcastTitle && (
        <Paragraph className={style.title}>{`${webcastTitle} ${t(
          'manager.webcasts.duplicateWebcast.copy'
        )}`}</Paragraph>
      )}
    </Modal>
  );
}
