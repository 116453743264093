// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video_wrapper__Ul88O {
  display: contents;
}

.video_wrapper__Ul88O video-js {
  display: block;
  position: relative;
  background: var(--videoBackground);
}

.video_wrapper__Ul88O video-js.vjs-fill {
  width: 100%;
  height: 100%;
}

.video_wrapper__Ul88O video-js.vjs-fluid {
  width: 100%;
  height: auto;
}

.video_wrapper__Ul88O video {
  display: block;
  width: 100%;
  height: 100%;
}

.video_wrapper__Ul88O video-js.vjs-fluid video {
  position: absolute;
  top: 0;
  left: 0;
}

/* Show the poster image when the video has not started playing */
.video_wrapper__Ul88O video-js .vjs-poster {
  display: block;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.video_wrapper__Ul88O video-js .vjs-poster img {
  object-fit: contain;
  height: 100%;
  width: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* 
  Hide the poster image when
    - the poster is not set
    - or the video has started playing
*/
.video_wrapper__Ul88O video-js .vjs-poster.vjs-hidden,
.video_wrapper__Ul88O video-js.vjs-has-started .vjs-poster {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/player/video.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA,iEAAiE;AACjE;EACE,cAAc;;EAEd,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,WAAW;;EAEX,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;;;;CAIC;AACD;;EAEE,aAAa;AACf","sourcesContent":[".wrapper {\n  display: contents;\n}\n\n.wrapper video-js {\n  display: block;\n  position: relative;\n  background: var(--videoBackground);\n}\n\n.wrapper video-js:global(.vjs-fill) {\n  width: 100%;\n  height: 100%;\n}\n\n.wrapper video-js:global(.vjs-fluid) {\n  width: 100%;\n  height: auto;\n}\n\n.wrapper video {\n  display: block;\n  width: 100%;\n  height: 100%;\n}\n\n.wrapper video-js:global(.vjs-fluid) video {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n/* Show the poster image when the video has not started playing */\n.wrapper video-js :global(.vjs-poster) {\n  display: block;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n}\n\n.wrapper video-js :global(.vjs-poster) img {\n  object-fit: contain;\n  height: 100%;\n  width: 100%;\n\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n/* \n  Hide the poster image when\n    - the poster is not set\n    - or the video has started playing\n*/\n.wrapper video-js :global(.vjs-poster.vjs-hidden),\n.wrapper video-js:global(.vjs-has-started .vjs-poster) {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `video_wrapper__Ul88O`
};
export default ___CSS_LOADER_EXPORT___;
