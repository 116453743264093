export const downloadFile = (url: string) => {
  const link = document.createElement('a');

  if (link.download === undefined) {
    // Fallback to opening a new tab if download attribute is not supported
    window.open(url, '_blank', 'noopener noreferrer');
    return;
  }

  const clickEvent = new MouseEvent('click', { bubbles: true, cancelable: true });

  link.href = url;
  link.referrerPolicy = 'no-referrer';
  link.download = url;
  link.target = '_blank'; // Open in a new tab when not same domain
  link.dispatchEvent(clickEvent);
};
