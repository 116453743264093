import type { ChangeEvent } from 'react';

import { Checkbox, SelectionInputLayout } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { Role } from '../../../../../generated/graphql-manager';

import styles from './role-select.module.css';

export type RoleSelectorProps = {
  selectedRoles?: Role[];
  onSelectedRolesChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function RoleSelector({ selectedRoles, onSelectedRolesChange }: RoleSelectorProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.checkboxList}>
      <SelectionInputLayout
        hintMessage={t('users.rolesHint.ADMIN')}
        checked={selectedRoles?.includes(Role.ADMIN)}
        inputElement={
          <Checkbox
            data-testid="admin-option"
            name={Role.ADMIN}
            checked={selectedRoles?.includes(Role.ADMIN)}
            onChange={onSelectedRolesChange}
          >
            {t('users.roles.ADMIN')}
          </Checkbox>
        }
      />

      <SelectionInputLayout
        hintMessage={t('users.rolesHint.PRODUCER')}
        checked={selectedRoles?.includes(Role.PRODUCER)}
        inputElement={
          <Checkbox
            data-testid="producer-option"
            name={Role.PRODUCER}
            checked={selectedRoles?.includes(Role.PRODUCER)}
            onChange={onSelectedRolesChange}
          >
            {t('users.roles.PRODUCER')}
          </Checkbox>
        }
      />

      <SelectionInputLayout
        hintMessage={t('users.rolesHint.EDITOR')}
        checked={selectedRoles?.includes(Role.EDITOR)}
        inputElement={
          <Checkbox
            data-testid="editor-option"
            name={Role.EDITOR}
            checked={selectedRoles?.includes(Role.EDITOR)}
            onChange={onSelectedRolesChange}
          >
            {t('users.roles.EDITOR')}
          </Checkbox>
        }
      />

      <SelectionInputLayout
        hintMessage={t('users.rolesHint.MODERATOR')}
        checked={selectedRoles?.includes(Role.MODERATOR)}
        inputElement={
          <Checkbox
            data-testid="moderator-option"
            name={Role.MODERATOR}
            checked={selectedRoles?.includes(Role.MODERATOR)}
            onChange={onSelectedRolesChange}
          >
            {t('users.roles.MODERATOR')}
          </Checkbox>
        }
      />
    </div>
  );
}
