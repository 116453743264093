import type {
  SelectOption,
  SelectProps,
} from '@movingimage-evp/mi-ui-component-library/types/components/select/select';

import { LanguageIcon, Select } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { getLanguageKey } from '../../../utils/languages';
import { useSelectedLanguage } from '../../providers/selected-language-provider';

import styles from './viewer-language-switch.module.css';

export function ViewerLanguageSwitch(props: SelectProps) {
  const { t } = useTranslation();
  const { availableLanguages, selectedLanguage, setSelectedLanguage } = useSelectedLanguage();

  if (!selectedLanguage || !availableLanguages || (availableLanguages && availableLanguages?.length < 2)) return null;

  const options = availableLanguages.map((language) => ({
    key: language,
    label: t(`common.languageSwitch.options.${getLanguageKey(language)}.optionLabel`),
    displayLabel: t(`common.languageSwitch.options.${getLanguageKey(language)}.selectedLabel`),
  }));

  const value = options.find((option) => option.key === selectedLanguage);

  return (
    <Select
      data-testid="language-switch"
      className={styles.wrapper}
      icon={<LanguageIcon />}
      optionsIdProperty="key"
      value={value}
      options={options}
      onChange={(option: SelectOption) => setSelectedLanguage(option.key)}
      getDisplayLabel={(option: SelectOption) => option.displayLabel}
      {...props}
    />
  );
}
