import { useEffect } from 'react';
import { VideoJsPlayer } from 'video.js';
import { supportsNativeHls } from '../utils/supports-native-hls';
import { VideoJsNativeEvent } from '../videojs-event';
import { SourceType } from '../player.config';

/**
 * This hook is used to monitor the health of HLS playback in the player.
 * It sets up an interval that checks the readiness state of the video element in the player.
 * If the video was ready and then becomes not ready for a certain time (determined by MAX_NOT_READY),
 * it dispatches a MediaError.
 *
 * This is only needed in Safari, since the MediaError is not automatically dispatched.
 *
 * @param {VideoJsPlayer | null} player - The Video.js player instance.
 */
export function useVideoHealthCheckForNativeHls(player: VideoJsPlayer | null): void {
  useEffect(() => {
    // If it cannot play HLS natively, Video.js takes care of it and it properly dispatches the error.
    if (!player || !supportsNativeHls()) {
      return;
    }

    let intervalId: number | undefined;

    function healthCheck() {
      const maxNotReadyCount = 4;
      let videoWasReady = false;
      let notReadyCount = 0;

      intervalId = window.setInterval(() => {
        if (!player || player.isDisposed()) {
          clearInterval(intervalId);
          return;
        }

        const videoElement = player.el().querySelector('video');

        if (!videoElement) {
          return;
        }

        if (videoElement.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA) {
          videoWasReady = true;
          notReadyCount = 0;
        }

        if (videoWasReady && videoElement.readyState < HTMLMediaElement.HAVE_ENOUGH_DATA) {
          notReadyCount++;

          if (notReadyCount >= maxNotReadyCount) {
            videoWasReady = false;
            notReadyCount = 0;

            // dispatch MediaError
            player.error(MediaError.MEDIA_ERR_DECODE);
          }
        }
      }, 4000);
    }

    // We need the type to distinguish if its a HLS source.
    // This information is accessible after "loadedmetadata" event.
    // we are listening here for "canplay" and not "loadedmetadata" on iOS,
    // because "canplay" is triggered in the "onPlay" phase instead of "onLoad",
    // which saves some battery power.
    // Source: https://medium.com/@nathan5x/event-lifecycle-of-html-video-element-part-1-f63373c981d3
    function onCanPlay() {
      if (!player) return;
      clearInterval(intervalId);

      if (player.currentType() === SourceType.APPLE_HLS || player.currentType() === SourceType.HLS) {
        healthCheck();
      }
    }

    player.on(VideoJsNativeEvent.CAN_PLAY, onCanPlay);

    return () => {
      player.off(VideoJsNativeEvent.CAN_PLAY, onCanPlay);
      clearInterval(intervalId);
    };
  }, [player]);
}
