import type { User } from '../../../../generated/graphql-manager';

import { Modal, Paragraph, SecondaryButton, Spinner, UserCircleIcon } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRemoveUserMutation } from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';

import styles from './remove-user-modal.module.css';

type RemoveUserModalProps = {
  user: User | undefined;
  isOpen: boolean;
  className: string;
  onClose: () => void;
};

export function RemoveUserModal({ user, isOpen, className, onClose }: RemoveUserModalProps) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const [loading, setLoading] = useState(false);

  const [removeUserMutation] = useRemoveUserMutation();

  const handleConfirm = () => {
    setLoading(true);

    if (user) {
      removeUserMutation({
        variables: {
          input: {
            id: user.id,
            lsproId,
          },
        },
        optimisticResponse: {
          removeUser: {
            __typename: 'RemoveUserSuccess',
            id: user.id,
          },
        },
        update: (cache, { data }) => {
          if (data?.removeUser.__typename !== 'RemoveUserSuccess') return;

          cache.evict({ id: cache.identify({ id: user.id, __typename: 'User' }) });
          cache.gc();
        },
        onCompleted: () => {
          onClose();
          setLoading(false);
        },
      });
    }
  };

  return (
    <Modal
      data-testid="remove-user-modal"
      title={t('users.removeUserModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      className={className}
      footer={
        <>
          <SecondaryButton data-testid="cancel-remove-user-button" type="button" onClick={onClose}>
            {t('common.cancel')}
          </SecondaryButton>

          <SecondaryButton
            data-testid="confirm-remove-user-button"
            type="button"
            disabled={loading}
            onClick={handleConfirm}
          >
            {loading ? <Spinner /> : t('users.removeUserModal.confirmButton')}
          </SecondaryButton>
        </>
      }
    >
      <p className={styles.subTitle}>{t('users.removeUserModal.subTitle')}</p>

      <div className={styles.user}>
        <UserCircleIcon />

        <div style={{ paddingLeft: 16 }}>
          <Paragraph data-testid="remove-user-email" className={styles.mail}>
            {user?.email}
          </Paragraph>
        </div>
      </div>
    </Modal>
  );
}
