/**
 * @param {number} [endNumber] The value of the last element
 * @default undefined
 * @param {number} [startNumber] The value of the first element
 * @default 0
 * @param {boolean} [reverse] Whether the array has a reverse order
 * @default false
 * @returns {number[]} Array of given numbers
 */
export function generateArrayOfNumbers(endNumber?: number, startNumber = 0, reverse = false) {
  if (endNumber === undefined) return [];

  const numbers: number[] = [];

  for (let i = startNumber; i <= endNumber; i++) {
    numbers.push(i);
  }

  if (reverse) {
    numbers.reverse();
  }

  return numbers;
}
