import { FileUploader } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { State, useGetWebcastPresentationsQuery } from '../../../../../generated/graphql-manager';
import { useUserPermissions } from '../../../../hooks/user-permissions';
import { usePresentationsProcessor } from '../../../../providers/presentations-processor';
import { Presentation } from '../presentation';

import styles from './presentation-uploader.module.css';

type Props = {
  language: string;
  onLoadStart?: () => void;
  onLoadEnd?: () => void;
};

export function PresentationUploader({ language, onLoadStart, onLoadEnd }: Props) {
  const { webcastId = '' } = useParams();
  const { uploadPresentation } = usePresentationsProcessor();
  const { isEventEditingAllowed } = useUserPermissions();
  const { t } = useTranslation();

  const handleFileChange = (files: File | File[]) => {
    const file = Array.isArray(files) ? files[0] : files;

    uploadPresentation({ webcastId, language, file, onSuccess: getPresentationsResponse.refetch });
  };

  const getPresentationsResponse = useGetWebcastPresentationsQuery({ variables: { webcastId } });

  const currentLanguagePresentations = (getPresentationsResponse.data?.webcast?.presentations || []).filter(
    (presentation) => presentation.language === language
  );

  const formDisabled = getPresentationsResponse.data?.webcast.state !== State.PRELIVE || !isEventEditingAllowed;

  const presentationsList = (
    <div className={styles.presentationsList}>
      {currentLanguagePresentations.map((presentation) => (
        <Presentation
          key={presentation.id}
          presentation={presentation}
          disablePresentationDeletion={formDisabled}
          onLoadStart={onLoadStart}
          onLoadEnd={onLoadEnd}
        />
      ))}
    </div>
  );

  const fileUploader = (
    <FileUploader
      testIdPrefix="presentation-"
      acceptedFormats=".pdf"
      maximumFileSize={104857600} // 100MB
      disabled={formDisabled}
      icon={null}
      onChange={handleFileChange}
      onLoadStart={onLoadStart}
      onLoadEnd={onLoadEnd}
      additionalUploadInformation={[
        t('manager.webcastSetup.media.languageContent.fileUploader.additionalFileRestrictions'),
      ]}
    />
  );

  return currentLanguagePresentations.length > 0 ? presentationsList : fileUploader;
}
