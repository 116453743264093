import { CueData } from '../player.config';

interface WebkitDataCue extends TextTrackCue {
  type: string;
  value: {
    data: string;
  };
}

interface MSEDataCue extends TextTrackCue {
  value: {
    data: Uint8Array | string;
  };
}

const isWebkitDataCueType = (obj: any): obj is WebkitDataCue => {
  return obj?.type === 'org.id3';
};

const isMSEDataCueType = (obj: any): obj is MSEDataCue => {
  return !!obj?.value?.data;
};

/**
 * This is to handle the case where the cue.endTime is Infinity.
 * In this case, video.js sets cue.endTime = cue.startTime, and the cue never gets pushed to the activeCues.
 * To fix this, we set the cue.endTime to Number.MAX_VALUE, so the cue gets activated and onCueChange gets called.
 *
 * This only happens in browsers that don't support native HLS, with 'metadata' type TextTracks containing idp3 cues.
 * An issue has been created on https://github.com/videojs/http-streaming/issues/1431 to address this. In the meantime, we need this fix.
 *
 * @param track - The TextTrack to fix.
 */
export const fixTextTrackDataCueEndTimes = (track: TextTrack): void => {
  if (track.cues?.length) {
    for (let i = 0; i < (track.cues?.length ?? 0); i++) {
      const cue = track.cues[i];
      if (cue.endTime === cue.startTime) {
        cue.endTime = Number.MAX_VALUE;
      }
    }
  }
};

/**
 * Sets the minimum display time for all cues in a TextTrack.
 * If the cue's duration is less than the minimumDisplayTime, the cue's endTime is set to startTime + minimumDisplayTime.
 *
 * @param track - The TextTrack to set the minimum display time for.
 * @param minimumDisplayTime - The minimum display time in seconds.
 *
 * default value for minimumDisplayTime is 2 seconds
 */
export const setMinimumDisplayTime = (track: TextTrack, minimumDisplayTime = 2): void => {
  const SMALL_DIFF = 0.01; // A small difference to avoid overlapping cues. This will make sure we always check the next cue's startTime.

  if (!track.cues?.length) {
    return;
  }

  for (let i = 0; i < track.cues.length; i++) {
    const cue = track.cues[i];

    if (cue.endTime - cue.startTime < minimumDisplayTime) {
      // Cue is too short. Extend it to minimumDisplayTime (or the beginning of the next cue).
      const adjustedEndTime = cue.startTime + minimumDisplayTime - SMALL_DIFF;
      cue.endTime = Math.min(adjustedEndTime, track.cues[i + 1]?.startTime ?? adjustedEndTime);
    }
  }
};

export const parseDataCues = (cues: TextTrackCueList | null): CueData[] => {
  const cueDatas: CueData[] = [];
  let cuesArray: TextTrackCue[] = [];

  if (cues?.length) {
    cuesArray = Array.from(cues);
  }

  cuesArray.forEach((cue) => {
    if (isMSEDataCueType(cue)) {
      const value = getDecodedValue(cue.value.data);

      cueDatas.push({
        value,
        start: cue.startTime,
        end: cue.endTime,
      });
    }
  });

  return cueDatas;
};

export const parseWebkitDataCues = (cues: TextTrackCueList): CueData[] => {
  const cueDatas: CueData[] = [];

  Array.from(cues).forEach((cue) => {
    if (isWebkitDataCueType(cue)) {
      cueDatas.push({
        value: cue.value.data,
        start: cue.startTime,
        end: cue.endTime,
      });
    }
  });

  return cueDatas;
};

/**
 * Decodes a given data into a string. If the data is already a string, it returns the data as is.
 * If the data is a Uint8Array, it decodes the data using TextDecoder and removes any null and end of text characters.
 * If an error occurs during the process, it returns an empty string.
 *
 * @param {string | Uint8Array} data - The data to be decoded.
 * @returns {string} The decoded string or an empty string if an error occurs.
 */
function getDecodedValue(data: string | Uint8Array): string {
  try {
    if (typeof data === 'string') {
      return data;
    } else {
      const decoder = new TextDecoder();
      // eslint-disable-next-line no-control-regex
      return decoder.decode(data).replace(/\x03|\x00/g, '');
    }
  } catch (e) {
    return '';
  }
}
