import type { BaseEditor } from 'slate';
import type { HistoryEditor } from 'slate-history';
import type { ReactEditor } from 'slate-react';

export type Block = 'paragraph' | 'numbered-list' | 'bulleted-list' | 'list-item';
export type Mark = 'bold' | 'italic' | 'underline' | 'link';
export type Format = Block | Mark;
export type CustomText = { text: string; bold?: boolean; italic?: boolean; underline?: boolean };
export type CustomElement = { type: Format; href?: string; children: CustomText[] };
export type SlateLink = { type: 'link'; href: string; children: CustomText[] };
export type Hotkey = { key: string; mark: Mark };

export function isSlateLink(node: object): node is SlateLink {
  return 'type' in node && node.type === 'link' && 'href' in node;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
