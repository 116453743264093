import { RefObject, useEffect, useState } from 'react';

import { PauseIcon } from '../../icons/pause';
import { BigPlayButton } from '../big-play-button';

import { KeyboardAction } from '../user-interfaces/hooks/use-keyboard-shortcuts';
import { KeyboardIndicators } from './keyboard-indicators';

import styles from './overlay.module.css';

type Props = {
  isPlaying?: boolean;
  isStopped?: boolean;
  isTrimmable?: boolean;
  volume: number;
  isMuted?: boolean;
  wrapperRef: RefObject<HTMLElement>;
  onClick?: () => void;
  lastKeyboardAction?: KeyboardAction;
  enableBigPlayButton?: boolean;
};

export function Overlay({
  isPlaying = false,
  isStopped = false,
  isTrimmable = false,
  volume,
  isMuted,
  wrapperRef,
  onClick,
  lastKeyboardAction,
  enableBigPlayButton = true,
}: Props) {
  const [bigPlayButtonVisible, setBigPlayButtonVisible] = useState(!isPlaying);
  const [pauseIconVisible, setPauseIconVisible] = useState(false);

  useEffect(() => {
    isPlaying && setBigPlayButtonVisible(false);
    isStopped && setBigPlayButtonVisible(true);
    isTrimmable && setBigPlayButtonVisible(false);
    !enableBigPlayButton && setBigPlayButtonVisible(false);
  }, [enableBigPlayButton, isPlaying, isStopped, isTrimmable]);

  const handlePauseIconAnimationEnd = () => {
    setPauseIconVisible(false);
  };

  useEffect(() => {
    if (!isPlaying && !bigPlayButtonVisible && enableBigPlayButton) {
      setPauseIconVisible(true);
    }
  }, [bigPlayButtonVisible, enableBigPlayButton, isPlaying, isStopped, setPauseIconVisible]);

  return (
    <>
      <div className={styles.wrapper} onClickCapture={onClick} data-testid="overlay">
        {pauseIconVisible && <PauseIcon width="108" height="126" onAnimationEnd={handlePauseIconAnimationEnd} />}
        {!bigPlayButtonVisible && (
          <KeyboardIndicators volume={volume} isMuted={isMuted} lastKeyboardAction={lastKeyboardAction} />
        )}
      </div>

      {bigPlayButtonVisible && (
        <BigPlayButton
          onClick={() => {
            onClick?.();
            // the player will lose the focus when the big play button is clicked
            // so we need to focus the wrapper again to allow keyboard navigation
            wrapperRef.current?.focus();
          }}
        />
      )}
    </>
  );
}
