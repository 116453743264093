import type { HTMLAttributes } from 'react';
import type { Descendant } from 'slate';

import { Accordion, PlusIcon, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { RichTextEditor } from '../../../../../components/rich-text-editor';
import { isRichTextEditorContentEmpty } from '../../../../../utils';
import { useUserPermissions } from '../../../../hooks/user-permissions';

import styles from './language-editors.module.css';

type Props = {
  language: string;
  preLiveDescription: Descendant[] | undefined;
  liveDescription: Descendant[] | undefined;
  postLiveDescription: Descendant[] | undefined;
  editorRefreshKeys: number[];
  onDescriptionChange: (value: Descendant[], language: string, type: 'preLive' | 'live' | 'postLive') => void;
} & HTMLAttributes<HTMLDivElement>;

export function LanguageEditors({
  language,
  preLiveDescription,
  liveDescription,
  postLiveDescription,
  editorRefreshKeys,
  onDescriptionChange,
  className,
}: Props) {
  const { t } = useTranslation();
  const { isEventEditingAllowed } = useUserPermissions();

  return (
    <div className={classNames(styles.editorsWrapper, className)}>
      <Accordion
        key={editorRefreshKeys[0]}
        data-testid={`prelive-event-description-accordion-${language}`}
        buttonId={`prelive-event-description-accordion-button-${language}`}
        compact
        isInitiallyOpen={!isRichTextEditorContentEmpty(JSON.stringify(preLiveDescription))}
        buttonLabel={t('manager.webcastSetup.eventDescription.labels.PRELIVE')}
        buttonContent={
          <>
            <PlusIcon /> {t('manager.webcastSetup.eventDescription.labels.PRELIVE')}
          </>
        }
      >
        <RichTextEditor
          key={editorRefreshKeys[0]}
          data-testid={`prelive-event-description-rich-text-editor-${language}`}
          value={preLiveDescription}
          autoFocus
          disabled={!isEventEditingAllowed}
          className={styles.richTextEditor}
          onChange={(value) => onDescriptionChange(value, language, 'preLive')}
        />
      </Accordion>

      <Accordion
        key={editorRefreshKeys[1]}
        data-testid={`live-event-description-accordion-${language}`}
        buttonId={`live-event-description-accordion-button-${language}`}
        compact
        isInitiallyOpen={!isRichTextEditorContentEmpty(JSON.stringify(liveDescription))}
        buttonLabel={t('manager.webcastSetup.eventDescription.labels.LIVE')}
        buttonContent={
          <>
            <PlusIcon /> {t('manager.webcastSetup.eventDescription.labels.LIVE')}
          </>
        }
      >
        <RichTextEditor
          key={editorRefreshKeys[1]}
          data-testid={`live-event-description-rich-text-editor-${language}`}
          value={liveDescription}
          disabled={!isEventEditingAllowed}
          className={styles.richTextEditor}
          onChange={(value) => onDescriptionChange(value, language, 'live')}
        />
      </Accordion>

      <Accordion
        key={editorRefreshKeys[2]}
        data-testid={`postlive-event-description-accordion-${language}`}
        buttonId={`postlive-event-description-accordion-button-${language}`}
        compact
        isInitiallyOpen={!isRichTextEditorContentEmpty(JSON.stringify(postLiveDescription))}
        buttonLabel={t('manager.webcastSetup.eventDescription.labels.POSTLIVE')}
        buttonContent={
          <>
            <PlusIcon /> {t('manager.webcastSetup.eventDescription.labels.POSTLIVE')}
          </>
        }
      >
        <RichTextEditor
          key={editorRefreshKeys[2]}
          data-testid={`postlive-event-description-rich-text-editor-${language}`}
          value={postLiveDescription}
          disabled={!isEventEditingAllowed}
          className={styles.richTextEditor}
          onChange={(value) => onDescriptionChange(value, language, 'postLive')}
        />
      </Accordion>
    </div>
  );
}
