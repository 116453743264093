/// <reference path="../../../../typings/youboralib.d.ts"/>
import * as youbora from 'youboralib';
import 'youbora-adapter-videojs';
import { VideoJsPlayer } from 'video.js';
import { useEffect } from 'react';

import { doNotTrack, getPackageVersion } from '../../../../utils';
import { VideoJsNativeEvent } from '../../../player/videojs-event';
import { AnalyticsMode } from '../../../player/player.interface';

const MiAdapter = youbora.adapters.Videojs.extend({
  getPlayerVersion: getPackageVersion,
});

const getReferrerInfo = (): string => {
  return document.referrer ? document.referrer : 'Direct access without referrer / Referrer information not available';
};

const getYouboraPlugin = (config: youbora.YouboraPluginConfig, analyticsMode: AnalyticsMode) => {
  const initialOptions: youbora.YouboraPluginConfig = { accountCode: config.accountCode };

  if (config.host) {
    initialOptions.host = config.host;
  }

  if (analyticsMode === 'minimal') {
    initialOptions.disableStorage = true;
  }

  const pluginInstance = new youbora.Plugin(initialOptions);
  youbora.Util.logAllEvents(pluginInstance);

  return pluginInstance;
};

export function useYouboraAnalytics(
  player: VideoJsPlayer,
  analyticsMode: AnalyticsMode,
  config?: youbora.YouboraPluginConfig
): void {
  useEffect(() => {
    if (!doNotTrack && config) {
      const onLoadedMetadata = () => {
        plugin.setOptions({
          'content.isLive': `${player.duration() === Infinity}`,
        });
      };

      const options: youbora.YouboraPluginConfig = {
        'content.customDimension.4': document.location.href,
        'content.customDimension.5': getReferrerInfo(),
        ...config,
      };

      if (analyticsMode === 'minimal') {
        options['user.name'] = 'Unknown';
        options['device.id'] = 'Unknown';
        options['device.isAnonymous'] = true;
        options['network.ip'] = '0.0.0.0';
      }

      const plugin = getYouboraPlugin(config, analyticsMode);
      plugin.setOptions(options);
      plugin.setAdapter(new MiAdapter(player));

      player.on(VideoJsNativeEvent.LOADED_METADATA, onLoadedMetadata);

      return () => {
        player.off(VideoJsNativeEvent.LOADED_METADATA, onLoadedMetadata);
        plugin.removeAdapter();
      };
    }
  }, [player, analyticsMode, config]);
}
