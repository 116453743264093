import { filterOlderEntries } from './filter-older-entries';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage';

type UserInformation = {
  nickname?: string;
  userId?: string;
};

export function getUserInformation(eventId: string) {
  try {
    const viewerInformation = getLocalStorageItem('userEventInformation');

    if (viewerInformation) {
      const parsedViewerInformation = JSON.parse(viewerInformation);
      return parsedViewerInformation[eventId];
    }
  } catch {
    return undefined;
  }

  return undefined;
}

export function setUserInformation(eventId: string, viewerInformation: UserInformation) {
  try {
    const plainViewerInformation = getLocalStorageItem('userEventInformation') || '';
    const parsedViewerInformation: Record<
      string,
      UserInformation & {
        timeStamp: number;
      }
    > = plainViewerInformation ? JSON.parse(plainViewerInformation) : {};

    const currentTime = Date.now();

    parsedViewerInformation[eventId] = {
      nickname: viewerInformation.nickname || parsedViewerInformation[eventId]?.nickname,
      userId: viewerInformation.userId || parsedViewerInformation[eventId]?.userId,
      timeStamp: currentTime,
    };

    const filteredViewerInformation = filterOlderEntries(parsedViewerInformation, currentTime, 48);
    setLocalStorageItem('userEventInformation', JSON.stringify(filteredViewerInformation));
  } catch {
    return;
  }
}
