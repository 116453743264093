import type { Dispatch, SetStateAction } from 'react';
import type { VideoJsPlayer } from 'video.js';
import type {
  CueData,
  PlayerApi,
  PlayerCallback,
  PlayerDurationChangeCallback,
  PlayerErrorCallback,
  PlayerTimedMetadataCallback,
} from './player.config';
import type { PlayerTextTrack } from './player.config';

import { PlayerEvent } from './player.config';
import { isValidSubtitle } from '../../utils/is-valid-subtitle';
import { isInBetween } from '../../utils/is-in-between';
import { ErrorCode, getErrorCodeFromMediaError, PlayerError } from './player-errors';
import { VideoJsCustomEvent, VideoJsNativeEvent } from './videojs-event';

type PlayerEventListeners = {
  play: PlayerCallback[];
  pause: PlayerCallback[];
  timedmetadata: PlayerTimedMetadataCallback[];
  timeupdate: PlayerCallback[];
  seeked: PlayerCallback[];
  ended: PlayerCallback[];
  loadedmetadata: PlayerCallback[];
  volumechange: PlayerCallback[];
  error: PlayerErrorCallback[];
  durationchange: PlayerDurationChangeCallback[];
};

type PlayerEventCallback =
  | PlayerCallback
  | PlayerTimedMetadataCallback
  | PlayerDurationChangeCallback
  | PlayerErrorCallback;

type Props = {
  setStartTime: Dispatch<SetStateAction<number>>;
  setEndTime: Dispatch<SetStateAction<number>>;
  getTrimStartTime: () => number;
  getTrimEndTime: () => number;
  handleSubtitleSelection: (subtitleId: string) => void;
  options: { defaultVolume: number };
};

export function createPlayerApi(player: VideoJsPlayer, props: Props): PlayerApi {
  const eventListeners: PlayerEventListeners = {
    play: [],
    pause: [],
    timedmetadata: [],
    timeupdate: [],
    seeked: [],
    ended: [],
    loadedmetadata: [],
    error: [],
    volumechange: [],
    durationchange: [],
  };

  const playHandler = () => {
    eventListeners[PlayerEvent.PLAY].forEach((callback) => callback());
  };
  const pauseHandler = () => {
    eventListeners[PlayerEvent.PAUSE].forEach((callback) => callback());
  };
  const timeUpdateHandler = () => {
    eventListeners[PlayerEvent.TIME_UPDATE].forEach((callback) => callback());
  };
  const seekedHandler = () => {
    eventListeners[PlayerEvent.SEEKED].forEach((callback) => callback());
  };
  const endedHandler = () => {
    eventListeners[PlayerEvent.ENDED].forEach((callback) => callback());
  };
  const timedMetadataHandler = (_: Event, metadata: CueData[]) => {
    eventListeners[PlayerEvent.TIMED_METADATA].forEach((callback) => callback({ metadata }));
  };
  const loadedMetadataHandler = () => {
    eventListeners[PlayerEvent.LOADED_METADATA].forEach((callback) => callback());
  };
  const volumeChangeHandler = () => {
    eventListeners[PlayerEvent.VOLUME_CHANGE].forEach((callback) => callback());
  };
  const durationChangeHandler = () => {
    eventListeners[PlayerEvent.DURATION_CHANGE].forEach((callback) => callback({ duration: player.duration() }));
  };
  const errorHandler = () => {
    const error = player.error();
    const playerError = new PlayerError(getErrorCodeFromMediaError(error), error?.message || '');
    eventListeners[PlayerEvent.ERROR].forEach((callback) => callback(playerError));
  };

  function play(): Promise<void> {
    const playPromise = player.play();

    return new Promise((resolve, reject) => {
      if (playPromise) {
        playPromise.then(resolve).catch((errorMessage) => {
          const error = player.error();

          if (error) {
            reject(new PlayerError(getErrorCodeFromMediaError(error), error.message || ''));
          } else {
            reject(new PlayerError(ErrorCode.MEDIA_ERR_CUSTOM, errorMessage));
          }
        });
      } else {
        reject(new PlayerError(ErrorCode.PLAYER_ERR_NOT_SUPPORTED, 'Play method did not return a promise'));
      }
    });
  }

  function pause(): void {
    player.pause();
  }

  function getCurrentTime(): number {
    return player.currentTime();
  }

  function setCurrentTime(time: number) {
    player.currentTime(time);
  }

  function getTrimStartTime() {
    return props.getTrimStartTime();
  }

  function setTrimStartTime(time: number) {
    props.setStartTime(time);
  }

  function getTrimEndTime() {
    return props.getTrimEndTime();
  }

  function setTrimEndTime(time: number) {
    props.setEndTime(time);
  }

  function removeSubtitles() {
    const playerTracks = Array.from(player.remoteTextTracks());
    // 'removeRemoteTextTrack' expects HTMLTrackElement|TextTracks
    playerTracks.forEach((track) => player.removeRemoteTextTrack(track as unknown as HTMLTrackElement));
  }

  function addSubtitle(subtitle: PlayerTextTrack): Promise<void> {
    return new Promise((resolve, reject) => {
      const playerTracks = Array.from(player.remoteTextTracks());

      if (isValidSubtitle(playerTracks, subtitle)) {
        player.addRemoteTextTrack(subtitle, true);
        resolve();
      } else {
        reject(new PlayerError(ErrorCode.PLAYER_ERR_NOT_VALID, 'Subtitle object is not valid'));
      }
    });
  }

  function setSelectedSubtitle(id: string): void {
    props.handleSubtitleSelection(id);
  }

  function setMuted(mute: boolean): void {
    player.muted(mute);

    if (!mute && player.volume() <= 0) {
      player.volume(props.options.defaultVolume);
    }
  }

  function getVolume(): number {
    return player.volume();
  }

  function setVolume(volume: number): void {
    if (isInBetween(volume, 0, 1)) {
      player.volume(volume);
      player.muted(volume <= 0);
    }
  }

  function getMuted(): boolean {
    return player.muted();
  }

  function getPaused(): boolean {
    return player.paused();
  }

  function hasEnded(): boolean {
    return player.ended();
  }

  function getDuration(): number {
    return player.duration();
  }

  function addEventListener(eventName: PlayerEvent, callback: PlayerEventCallback): void {
    if (!eventListeners.hasOwnProperty(eventName)) {
      return;
    }
    if (!callback || typeof callback !== 'function') {
      return;
    }

    if (eventListeners[eventName].length === 0) {
      addPlayerEventListener(eventName);
    }

    eventListeners[eventName].push(callback as any);
  }

  function removeEventListener(eventName: PlayerEvent, callback: PlayerEventCallback): void {
    if (!eventListeners.hasOwnProperty(eventName)) {
      return;
    }

    if (!callback || typeof callback !== 'function') {
      return;
    }

    const index = eventListeners[eventName].indexOf(callback as any);
    if (index !== -1) {
      eventListeners[eventName].splice(index, 1);
    }

    if (eventListeners[eventName].length === 0) {
      removePlayerEventListener(eventName);
    }
  }

  function addPlayerEventListener(eventName: PlayerEvent) {
    switch (eventName) {
      case PlayerEvent.PLAY:
        player.on(VideoJsNativeEvent.PLAY, playHandler);
        break;
      case PlayerEvent.PAUSE:
        player.on(VideoJsNativeEvent.PAUSE, pauseHandler);
        break;
      case PlayerEvent.TIME_UPDATE:
        player.on(VideoJsNativeEvent.TIME_UPDATE, timeUpdateHandler);
        break;
      case PlayerEvent.SEEKED:
        player.on(VideoJsNativeEvent.SEEKED, seekedHandler);
        break;
      case PlayerEvent.ENDED:
        player.on(VideoJsNativeEvent.ENDED, endedHandler);
        break;
      case PlayerEvent.TIMED_METADATA:
        player.on(VideoJsCustomEvent.TIMED_METADATA, timedMetadataHandler);
        break;
      case PlayerEvent.LOADED_METADATA:
        player.on(VideoJsNativeEvent.LOADED_METADATA, loadedMetadataHandler);
        break;
      case PlayerEvent.VOLUME_CHANGE:
        player.on(VideoJsNativeEvent.VOLUME_CHANGE, volumeChangeHandler);
        break;
      case PlayerEvent.DURATION_CHANGE:
        player.on(VideoJsNativeEvent.DURATION_CHANGE, durationChangeHandler);
        break;
      case PlayerEvent.ERROR:
        player.on(VideoJsNativeEvent.ERROR, errorHandler);
        break;
    }
  }

  function removePlayerEventListener(eventName: PlayerEvent) {
    switch (eventName) {
      case PlayerEvent.PLAY:
        player.off(VideoJsNativeEvent.PLAY, playHandler);
        break;
      case PlayerEvent.PAUSE:
        player.off(VideoJsNativeEvent.PAUSE, pauseHandler);
        break;
      case PlayerEvent.TIME_UPDATE:
        player.off(VideoJsNativeEvent.TIME_UPDATE, timeUpdateHandler);
        break;
      case PlayerEvent.SEEKED:
        player.off(VideoJsNativeEvent.SEEKED, seekedHandler);
        break;
      case PlayerEvent.ENDED:
        player.off(VideoJsNativeEvent.ENDED, endedHandler);
        break;
      case PlayerEvent.TIMED_METADATA:
        player.off(VideoJsCustomEvent.TIMED_METADATA, timedMetadataHandler);
        break;
      case PlayerEvent.LOADED_METADATA:
        player.off(VideoJsNativeEvent.LOADED_METADATA, loadedMetadataHandler);
        break;
      case PlayerEvent.VOLUME_CHANGE:
        player.off(VideoJsNativeEvent.VOLUME_CHANGE, volumeChangeHandler);
        break;
      case PlayerEvent.ERROR:
        player.off(VideoJsNativeEvent.ERROR, errorHandler);
        break;
    }
  }

  return {
    play,
    pause,
    getCurrentTime,
    setCurrentTime,
    getTrimStartTime,
    setTrimStartTime,
    getTrimEndTime,
    setTrimEndTime,
    addSubtitle,
    removeSubtitles,
    setSelectedSubtitle,
    getPaused,
    setMuted,
    getMuted,
    getVolume,
    setVolume,
    hasEnded,
    getDuration,
    addEventListener,
    removeEventListener,
  };
}
