export enum VideoJsCustomEvent {
  TIMED_METADATA = 'custom::timedmetadata',
}

export enum VideoJsNativeEvent {
  ABORT = 'abort',
  CAN_PLAY = 'canplay',
  CAN_PLAY_THROUGH = 'canplaythrough',
  CONTROLS_DISABLED = 'controlsdisabled',
  CONTROLS_ENABLED = 'controlsenabled',
  DISPOSE = 'dispose',
  DURATION_CHANGE = 'durationchange',
  EMPTIED = 'emptied',
  ENDED = 'ended',
  ENTER_FULL_WINDOW = 'enterFullWindow',
  ENTER_PICTURE_IN_PICTURE = 'enterpictureinpicture',
  ERROR = 'error',
  EXIT_FULL_WINDOW = 'exitFullWindow',
  FULLSCREEN_CHANGE = 'fullscreenchange',
  FULLSCREEN_ERROR = 'fullscreenerror',
  LANGUAGE_CHANGE = 'languagechange',
  LEAVE_PICTURE_IN_PICTURE = 'leavepictureinpicture',
  LOAD_START = 'loadstart',
  LOADED_DATA = 'loadeddata',
  LOADED_METADATA = 'loadedmetadata',
  PAUSE = 'pause',
  PLAY = 'play',
  PLAYBACK_RATES_CHANGE = 'playbackrateschange',
  PLAYING = 'playing',
  POSTER_CHANGE = 'posterchange',
  PROGRESS = 'progress',
  RATE_CHANGE = 'ratechange',
  RESIZE = 'resize',
  SEEKED = 'seeked',
  SEEKING = 'seeking',
  SOURCE_SET = 'sourceset',
  STALLED = 'stalled',
  SUSPEND = 'suspend',
  TEXT_DATA = 'textdata',
  TEXT_TRACK_CHANGE = 'texttrackchange',
  TIME_UPDATE = 'timeupdate',
  USER_ACTIVE = 'useractive',
  USER_INACTIVE = 'userinactive',
  USING_CUSTOM_CONTROLS = 'usingcustomcontrols',
  USING_NATIVE_CONTROLS = 'usingnativecontrols',
  VOLUME_CHANGE = 'volumechange',
  WAITING = 'waiting',
  ADD_TRACK = 'addtrack',
  REMOVE_TRACK = 'removetrack',
  CUE_CHANGE = 'cuechange',
  READY = 'ready',
}
