interface DocumentElementWithFullscreen extends HTMLElement {
  webkitRequestFullscreen?: () => void;
  webkitExitFullscreen?: () => void;
  exitFullscreen?: () => void;
}

export function toggleFullscreen(isFullscreen: boolean, elementId: string) {
  const mainElement = document.getElementById(elementId) as DocumentElementWithFullscreen;
  if (!mainElement) return;

  if (!isFullscreen) {
    if (mainElement.requestFullscreen) {
      mainElement.requestFullscreen();
    } else if (mainElement.webkitRequestFullscreen) {
      mainElement.webkitRequestFullscreen();
    }
  }

  if (isFullscreen) {
    document.exitFullscreen();
  }
}
