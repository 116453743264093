import { KeyboardAction } from '../user-interfaces/hooks/use-keyboard-shortcuts';

import { VolumeIndicator } from './volume-indicator';
import { StepBackIndicator } from './step-back-indicator';
import { StepForwardIndicator } from './step-forward-indicator';

type Props = {
  volume: number;
  isMuted?: boolean;
  lastKeyboardAction?: KeyboardAction;
};

export function KeyboardIndicators({ isMuted, volume, lastKeyboardAction }: Props) {
  switch (lastKeyboardAction) {
    case 'VolumeIncrease':
    case 'VolumeDecrease':
      return <VolumeIndicator isMuted={isMuted} volume={volume} />;
    case 'ShortTimeDecrease':
    case 'LongTimeDecrease':
      return <StepBackIndicator lastKeyboardAction={lastKeyboardAction} />;
    case 'ShortTimeIncrease':
    case 'LongTimeIncrease':
      return <StepForwardIndicator lastKeyboardAction={lastKeyboardAction} />;
    default:
      return null;
  }
}
