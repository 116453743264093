import { useTranslation } from 'react-i18next';

import { EventSummary } from './event-summary';
import accessDeniedStudioImage from '../../../../assets/access-denied-studio.svg';
import { ErrorPage } from '../../../../components/error-page/error-page';
import { useUserPermissions } from '../../../hooks/user-permissions';

export function EventSummaryGuard() {
  const { isAccessToEventSetupAllowed } = useUserPermissions();
  const { t } = useTranslation();

  if (!isAccessToEventSetupAllowed) {
    return (
      <ErrorPage
        data-testid="access-denied"
        title={t('common.accessDenied.title')}
        subTitle={t('common.accessDenied.subTitle')}
        image={accessDeniedStudioImage}
        style={{ gridArea: 'section', height: 'unset', background: 'none' }}
      />
    );
  }

  return <EventSummary />;
}
