import type { GraphqlError } from './error-handling/error-component';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { type ReactNode, useMemo, useState } from 'react';

import { ErrorComponent } from './error-handling/error-component';
import { problemsHandler } from './error-handling/problems-handler';
import { keycloak } from './service/authentication/keycloak-provider';
import { getSessionStorageItem, removeSessionStorageItem } from './utils/session-storage';

const MIN_VALIDITY_SECONDS = 300;

const adminHeaders = setContext(async (_, { headers }) => {
  // Refresh token if expired
  await keycloak.updateToken(MIN_VALIDITY_SECONDS).catch(keycloak.login);

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${keycloak?.token}`,
      'content-type': 'application/json',
    },
  };
});

export function AdminApiProvider({ children }: { children: ReactNode }) {
  const [error, setError] = useState<GraphqlError>();

  const adminClient = useMemo(() => {
    return new ApolloClient({
      uri: process.env.REACT_APP_ADMIN_APOLLO_CLIENT_URI,
      cache: new InMemoryCache({
        typePolicies: {
          Webcast: {
            fields: {
              presentations: {
                merge: (existing: { id: string }[] = [], incoming: { id: string }[] = []) =>
                  incoming.map((incomingItem) => {
                    const existingItem = existing.find(({ id }) => id === incomingItem.id);
                    return existingItem ? { ...existingItem, ...incomingItem } : incomingItem;
                  }),
              },
              content: { merge: true },
            },
          },
          CurrentUser: { merge: true },
          LiveStreamingSettings: { merge: true },
          Query: {
            fields: {
              webcasts: relayStylePagination(),
            },
          },
        },
      }),
      link: from([
        adminHeaders,
        problemsHandler(setError),
        new HttpLink({ uri: process.env.REACT_APP_ADMIN_APOLLO_CLIENT_URI }),
      ]),
    });
  }, []);

  return (
    <ApolloProvider client={adminClient}>
      <ErrorComponent error={error} onClose={() => setError(undefined)}>
        {children}
      </ErrorComponent>
    </ApolloProvider>
  );
}

const viewerHeaders = setContext((_, { headers }) => {
  const token = getSessionStorageItem('viewerAccessToken');

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'X-Referrer': window.document.referrer ? new URL(window.document.referrer).hostname : undefined,
      'content-type': 'application/json',
    },
  };
});

removeSessionStorageItem('viewerAccessToken');

export function ViewerApiProvider({ children }: { children: ReactNode }) {
  const [error, setError] = useState<GraphqlError>();

  const viewerClient = useMemo(() => {
    return new ApolloClient({
      uri: process.env.REACT_APP_VIEWER_APOLLO_CLIENT_URI,
      cache: new InMemoryCache(),
      link: from([
        viewerHeaders,
        problemsHandler(setError),
        new HttpLink({ uri: process.env.REACT_APP_VIEWER_APOLLO_CLIENT_URI }),
      ]),
    });
  }, []);

  return (
    <ApolloProvider client={viewerClient}>
      <ErrorComponent error={error} onClose={() => setError(undefined)}>
        {children}
      </ErrorComponent>
    </ApolloProvider>
  );
}
