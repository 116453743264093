import { ImgHTMLAttributes } from 'react';

type IconProps = {} & ImgHTMLAttributes<SVGSVGElement>;

export const PlayCircleIcon = (props: IconProps) => (
  <svg
    id="play-circle-button"
    viewBox="0 0 129 129"
    fill="none"
    aria-hidden="true"
    role="img"
    data-testid="play-circle-button"
    {...props}
  >
    <circle cx="64.5" cy="64.5" r="64.5" />
    <circle cx="64.5" cy="64.5" r="63.5" stroke="var(--primaryColor)" strokeWidth="2" />
    <path d="M47.3335 36.4167V94.7501L93.1668 65.5834L47.3335 36.4167Z" />
  </svg>
);
