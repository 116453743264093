import { useRef } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '../../icons/check';
import { ChevronIcon } from '../../icons/chevron';
import styles from './menu.module.css';
import { Menu } from './menu';
import MenuItem from './menu-item';
import { useMenuNavigation } from './use-menu-navigation';

export type MenuOptions = {
  id: string;
  label?: string;
  value?: string | number;
  defaultValue?: string;
  showHeader?: boolean;
  options?: MenuOptions[] | undefined;
};

type MenuWrapperProps = {
  isOpen: boolean | undefined;
  onClose: () => void;
  menuOptions: MenuOptions;
  onChooseItem?: (currentScreen: string, itemId: string) => void;
  buttonName: string;
};

export const MenuWrapper: React.FC<MenuWrapperProps> = ({ isOpen, onClose, menuOptions, onChooseItem, buttonName }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { currentScreen, setCurrentScreen, currentScreenData, parentScreen, onGoBack } = useMenuNavigation({
    menuOptions,
    isOpen: !!isOpen,
    onClose,
    buttonName,
    wrapperRef,
  });

  return (
    <div ref={wrapperRef} className={classNames(styles.wrapper, !isOpen && styles.closed)} tabIndex={-1}>
      <Menu
        label={currentScreenData?.label}
        onGoBack={onGoBack}
        onClose={onClose}
        dataTestId={currentScreenData?.id}
        hasParent={!!parentScreen}
        showHeader={currentScreenData?.showHeader}
      >
        {currentScreenData?.options?.map((option) => (
          <MenuItem
            key={option.id}
            data-testid={option.id || option.label}
            className={classNames({ [styles.active]: option.id === currentScreenData.value })}
            onClick={() => {
              if (!option.options?.length) {
                onChooseItem?.(currentScreen, option.id);
                onGoBack();
              } else {
                setCurrentScreen(option.id);
              }
            }}
            label={option.label}
            currentValue={option.options?.find((o) => o.id === option.value)?.label || option.defaultValue}
            leftIcon={
              !option.options?.length ? option.id === currentScreenData.value ? <CheckIcon /> : <></> : undefined
            }
            rightIcon={option.options?.length ? <ChevronIcon /> : undefined}
            data-goto={option.id}
          />
        ))}
      </Menu>
    </div>
  );
};
