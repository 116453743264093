import type { PresentationStatus } from './types';

import { useEffect } from 'react';

import { getPresentationStatus } from './get-presentation-status';
import { useGetWebcastPresentationsQuery } from '../../../generated/graphql-manager';
import { useProgressContext } from '../progress-context/progress-context';

type Props = {
  id: PresentationStatus['id'];
  webcastId: PresentationStatus['webcastId'];
  onFinish: (id: PresentationStatus['id']) => void;
  onFail: (id: PresentationStatus['id']) => void;
};

export function PresentationStatusPoller({ id, webcastId, onFinish, onFail }: Props) {
  const { updateStatus, updateProgress } = useProgressContext();

  const { data, startPolling, stopPolling } = useGetWebcastPresentationsQuery({ variables: { webcastId } });

  const presentation = data?.webcast.presentations.find((presentation) => presentation.id === id);

  const { processedSlides, totalSlides, processingPassed, processingFailed } = getPresentationStatus(presentation);

  useEffect(() => {
    if (processingFailed) {
      updateStatus(id, 'FAILED');
      stopPolling();
      onFail(id);
      return;
    }

    if (processingPassed) {
      updateStatus(id, 'PASSED');
      stopPolling();
      onFinish(id);
      return;
    }

    startPolling(2000);
  }, [id, processingPassed, processingFailed, updateStatus, startPolling, stopPolling, onFinish, onFail]);

  useEffect(() => {
    if (!processedSlides || !totalSlides) return;

    const uploadProgress = 50;
    const processingProgress = Math.round((processedSlides / totalSlides) * 100);
    const totalProgress = uploadProgress + processingProgress / 2;

    updateStatus(id, 'PROCESSING');
    updateProgress(id, totalProgress);
  }, [id, processedSlides, totalSlides, updateStatus, updateProgress]);

  useEffect(() => () => stopPolling(), [stopPolling]);

  return null;
}
