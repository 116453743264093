import { t } from 'i18next';

const domainRegex = /^(?:\*\.)?[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,6}$/i;
const isValidDomain = (domain: string) => domainRegex.test(domain);

export function validateInput(value: string, values: string[]) {
  const excludedStartStrings = ['http', 'https'];

  for (const excludedStartString of excludedStartStrings) {
    if (value.startsWith(excludedStartString)) {
      return t('manager.webcastSetup.embedding.restrictEmbedding.errorMessages.protocol');
    }
  }

  if (value.indexOf('*') >= 0 && !value.startsWith('*.')) {
    return t('manager.webcastSetup.embedding.restrictEmbedding.errorMessages.wildcardNotInTheBeginning');
  }

  if (!isValidDomain(value)) {
    return t('manager.webcastSetup.embedding.restrictEmbedding.errorMessages.noValidDomain');
  }

  if (values.indexOf(value) >= 0) {
    return t('manager.webcastSetup.embedding.restrictEmbedding.errorMessages.duplicateDomain');
  }
}
