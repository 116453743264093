import type { MouseEvent } from 'react';

import styles from './theme-toggle.module.css';

export function ThemeToggle() {
  const toggleTheme = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    document.documentElement.setAttribute(
      'data-theme',
      document.documentElement.getAttribute('data-theme') === 'dark' ? 'light' : 'dark'
    );
  };

  return <button className={styles.themeToggle} onMouseDown={toggleTheme} />;
}
