import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDynamicSize } from '../../hooks/use-dynamic-size';
import { TimeBadge } from '../time-badge';
import { RelatedVideo } from './related-videos.types';

import styles from './related-videos.module.css';
import { ChevronIcon } from '../../icons/chevron';
import { PlayCircleIcon } from '../../icons/play-circle';

interface RelatedVideosProps {
  videos: RelatedVideo[];
  onClick?: (video: RelatedVideo) => void;
  smallScreen: boolean;
}

export function RelatedVideos({ videos, onClick, smallScreen }: RelatedVideosProps) {
  const gridRef = useRef<HTMLDivElement>(null);
  const { width, height } = useDynamicSize(gridRef);
  const [currentPage, setCurrentPage] = useState(1);

  const { cardWidth, cardHeight, gap, padding } = useMemo(
    () =>
      !smallScreen
        ? { cardWidth: 434, cardHeight: 145, gap: 20, padding: 20 }
        : { cardWidth: 320, cardHeight: 107, gap: 10, padding: 10 },
    [smallScreen]
  );

  const colCount = Math.floor((width - padding * 2 + gap) / (cardWidth + gap));
  const rowCount = Math.floor((height - padding + gap) / (cardHeight + gap));
  const countPerPage = Math.max(1, colCount * rowCount);
  const pageCount = Math.ceil(videos.length / countPerPage);

  const cssVariales = {
    '--related-videos-card-width': `${cardWidth}px`,
    '--related-videos-card-height': `${cardHeight}px`,
    '--related-videos-gap': `${gap}px`,
    '--related-videos-padding': `${padding}px`,
  } as React.CSSProperties;

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(pageCount, prev + 1));
  }, [pageCount]);

  // Reset current page when countPerPage or videos change
  useEffect(() => {
    if (countPerPage && videos) {
      setCurrentPage(1);
    }
  }, [countPerPage, videos]);

  const pagedVideos = videos.slice((currentPage - 1) * countPerPage, currentPage * countPerPage);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, video: RelatedVideo) => {
      if (onClick) {
        e.preventDefault();
        onClick(video);
      }
    },
    [onClick]
  );

  return (
    <>
      <div className={styles.wrapper} style={cssVariales} data-testid="related-videos">
        <div className={styles.grid} ref={gridRef}>
          {pagedVideos.map((video, index) => (
            <a
              key={video.relatedVideoId || `${currentPage}_${index}`}
              href={video.videoUrl || '#'}
              className={styles.card}
              onClick={(e) => handleClick(e, video)}
            >
              <div className={styles.thumbnail}>
                <img src={video.thumbnailUrl} alt={video.title} />

                <PlayCircleIcon className={styles.playCircleIcon} />
              </div>
              <div className={styles.cardContent}>
                <div className={styles.title}>{video.title}</div>
                <TimeBadge time={video.duration} />
              </div>
            </a>
          ))}
        </div>
        {pageCount > 1 && (
          <div className={styles.pagination}>
            <button className={styles.prevPageButton} onClick={handlePrevPage} disabled={currentPage <= 1}>
              <ChevronIcon />
            </button>
            <button className={styles.nextPageButton} onClick={handleNextPage} disabled={currentPage >= pageCount}>
              <ChevronIcon />
            </button>
          </div>
        )}
      </div>
      <div className={styles.overlay} />
    </>
  );
}
