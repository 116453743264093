import styles from './language.module.css';

export type LanguageProps = {
  language: string;
};

export function Language({ language, ...props }: LanguageProps) {
  return (
    <span className={styles.language} {...props}>
      {language}
    </span>
  );
}
