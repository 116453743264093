import type { GetWebcastSummaryManagerQuery } from '../../../../../../generated/graphql-manager';

import { title } from 'process';

import {
  DuplicateIcon,
  Heading,
  IconButton,
  InfoCircleIcon,
  PlayerIcon,
  Tooltip,
} from '@movingimage-evp/mi-ui-component-library';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useAbsoluteRoutes } from '../../../../../../routes';
import { addParamToPath, formatEventStartDate } from '../../../../../../utils';
import { Language } from '../../../../../components/language';
import { useUserPermissions } from '../../../../../hooks/user-permissions';
import { DuplicateWebcastModal } from '../../../../webcasts/duplicate-webcast/duplicate-webcast-modal';
import { DuplicationProgressIndicator } from '../../../../webcasts/duplicate-webcast/duplication-progress-indicator';

import styles from './event-summary-header.module.css';

type EventSummaryHeaderProps = {
  webcast: GetWebcastSummaryManagerQuery['webcast'];
  webcastTitle: string;
  session?: GetWebcastSummaryManagerQuery['session'];
};

const PROGRESS_INDICATOR_TIMEOUT = 5000;

export function EventSummaryHeader({ webcast, webcastTitle, session }: EventSummaryHeaderProps) {
  const navigate = useNavigate();
  const routes = useAbsoluteRoutes();

  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
  const [isProgressIndicatorVisible, setIsProgressIndicatorVisible] = useState(false);

  const timeoutId = useRef<number>();
  const errorTimeoutId = useRef<number>();
  const { isEventDuplicationAllowed } = useUserPermissions();

  const duplicateTooltipText = isEventDuplicationAllowed
    ? t('manager.eventSummary.duplicateButton')
    : t('manager.webcastSetup.notEditableMessage.role');

  const handleWebcastDuplicatedError = () => {
    errorTimeoutId.current = window.setTimeout(() => {
      setIsProgressIndicatorVisible(false);
    }, PROGRESS_INDICATOR_TIMEOUT);
  };

  const handleWebcastDuplicated = () => {
    timeoutId.current = window.setTimeout(() => {
      setIsProgressIndicatorVisible(false);
    }, PROGRESS_INDICATOR_TIMEOUT);
  };

  const startDate = session?.__typename === 'GetSessionSuccess' ? session.startDate : undefined;
  const languages = [webcast.primaryLanguage, ...(webcast?.additionalLanguages || [])];

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
      clearTimeout(errorTimeoutId.current);
    };
  }, []);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.eventInfo}>
          {title && <Heading data-testid="event-title">{webcastTitle}</Heading>}

          <table className={styles.table}>
            <tbody>
              <tr>
                <td>{t('manager.eventSummary.time')}:</td>
                <td>{startDate && formatEventStartDate(startDate)}</td>
              </tr>

              <tr>
                <td>{t('manager.eventSummary.languages')}:</td>
                <td>{languages?.map((language) => <Language key={language} language={language} />)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.actionButtons}>
          <Tooltip label={duplicateTooltipText}>
            <IconButton
              disabled={!isEventDuplicationAllowed}
              aria-label={t('manager.eventSummary.duplicateButton')}
              onClick={() => setIsDuplicateModalVisible(true)}
              data-testid="duplicate-button"
            >
              <DuplicateIcon />
            </IconButton>
          </Tooltip>

          <Tooltip label={t('manager.eventSummary.eventSummaryButton')}>
            <IconButton
              aria-label={t('manager.eventSummary.eventSummaryButton')}
              onClick={() => navigate(addParamToPath(routes.webcastSetup_setupSummary, webcast.id))}
              data-testid="setup-summary-button"
            >
              <InfoCircleIcon />
            </IconButton>
          </Tooltip>

          <Tooltip label={t('manager.eventSummary.viewerButton')}>
            <IconButton
              aria-label={t('manager.eventSummary.viewerButton')}
              component={NavLink}
              to={addParamToPath(routes.viewer, webcast.id)}
              rel="noreferrer"
              target="_blank"
              data-testid="viewer-button"
            >
              <PlayerIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <DuplicateWebcastModal
        isOpen={isDuplicateModalVisible}
        webcastTitle={webcastTitle}
        onDuplicate={() => {
          setIsProgressIndicatorVisible(true);
          setIsDuplicateModalVisible(false);
        }}
        onClose={() => setIsDuplicateModalVisible(false)}
      />

      {isProgressIndicatorVisible && (
        <DuplicationProgressIndicator
          duplicatedWebcastId={webcast.id}
          duplicatedWebcastTitle={webcastTitle}
          onWebcastDuplicated={handleWebcastDuplicated}
          onWebcastDuplicatedError={handleWebcastDuplicatedError}
        />
      )}
    </>
  );
}
