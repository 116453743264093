import {
  Heading,
  Paragraph,
  PrimaryButton,
  Radio,
  SelectionInputLayout,
} from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  EventPermissionType,
  useGetWebcastPermissionsQuery,
  useUpdateEventPermissionMutation,
} from 'src/generated/graphql-manager';
import { SetupPageFooter } from 'src/manager/components/setup-page-footer';
import { useCurrentUser } from 'src/manager/hooks/current-user';
import { useUserPermissions } from 'src/manager/hooks/user-permissions';
import { useAbsoluteRoutes } from 'src/routes';

import { ContributorsList } from './contributors-list/contributors-list';
import { PermissionsManager } from './permissions-manager/permissions-manager';
import managerStyles from '../../../manager.module.css';

import styles from './event-permissions.module.css';

export type Contributor = {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  type: UserPermissionType;
};

export enum UserPermissionType {
  COLLABORATORS = 'collaborators',
  OWNERS = 'owners',
}

export function EventPermissionsPage() {
  const { t } = useTranslation();
  const { currentAccountName, userId, isEditorUser } = useCurrentUser();
  const { isEventEditingAllowed } = useUserPermissions();
  const routes = useAbsoluteRoutes();
  const { webcastId = '' } = useParams();

  const [isPermissionManagerOpen, setIsPermissionManagerOpen] = useState(false);
  const togglePermissionManager = () => {
    setIsPermissionManagerOpen((state) => !state);
  };

  const getEventPermissionQueryResponse = useGetWebcastPermissionsQuery({ variables: { webcastId } });
  const webcast = getEventPermissionQueryResponse.data?.webcast;
  const eventPermissionType = webcast?.eventPermission?.type;
  const owners = webcast?.eventPermission?.owners || [];
  const collaborators = webcast?.eventPermission?.collaborators || [];

  const _collaborators: Contributor[] = collaborators.map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    type: UserPermissionType.COLLABORATORS,
  }));

  const _owners: Contributor[] = owners.map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    type: UserPermissionType.OWNERS,
  }));

  const eventContributors = [..._owners, ..._collaborators];

  const [updateEventPermission, { loading: updateEventPermissionLoading }] = useUpdateEventPermissionMutation();

  const handleChangeEventPermissionType = (type: EventPermissionType) => {
    updateEventPermission({
      variables: { input: { webcastId, type } },
      optimisticResponse: {
        updateEventPermission: { __typename: 'UpdateEventPermissionSuccess', eventPermission: { type } },
      },
      update: (cache, { data }) => {
        if (data?.updateEventPermission?.__typename !== 'UpdateEventPermissionSuccess') return;

        cache.modify({
          id: cache.identify({ __typename: 'Webcast', id: webcastId }),
          fields: {
            eventPermission: (eventPermission) => ({ ...eventPermission, type }),
          },
        });
      },
      onCompleted: () => getEventPermissionQueryResponse.refetch(),
    });
  };

  const formDisabled = !isEventEditingAllowed || (isEditorUser && !owners.some((owner) => owner.id === userId));

  return (
    <>
      <main className={managerStyles.main} data-testid="event-permissions-page">
        <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.eventPermissions.title')}</Heading>

        <Paragraph className={managerStyles.grayText}>
          {t('manager.webcastSetup.eventPermissions.description')}
        </Paragraph>

        <div className={styles.options}>
          <SelectionInputLayout
            checked={eventPermissionType === EventPermissionType.PUBLIC}
            hintMessage={t('manager.webcastSetup.eventPermissions.options.public.description')}
            disabled={formDisabled}
            inputElement={
              <Radio
                data-testid="public-event-permission"
                name="event-permission"
                value={EventPermissionType.PUBLIC}
                checked={eventPermissionType === EventPermissionType.PUBLIC}
                disabled={formDisabled}
                onChange={() => handleChangeEventPermissionType(EventPermissionType.PUBLIC)}
              >
                {t('manager.webcastSetup.eventPermissions.options.public.label', { organization: currentAccountName })}
              </Radio>
            }
          />

          <SelectionInputLayout
            checked={eventPermissionType === EventPermissionType.PRIVATE}
            hintMessage={t('manager.webcastSetup.eventPermissions.options.private.description')}
            disabled={formDisabled}
            inputElement={
              <Radio
                data-testid="private-event-permission"
                name="event-permission"
                value={EventPermissionType.PRIVATE}
                checked={eventPermissionType === EventPermissionType.PRIVATE}
                disabled={formDisabled}
                onChange={() => handleChangeEventPermissionType(EventPermissionType.PRIVATE)}
              >
                {t('manager.webcastSetup.eventPermissions.options.private.label')}
              </Radio>
            }
          >
            <PrimaryButton
              small
              onClick={togglePermissionManager}
              data-testid="invite-contributors-button"
              disabled={formDisabled}
            >
              {t('manager.webcastSetup.eventPermissions.options.private.inviteButton')}
            </PrimaryButton>

            <div className={styles.contributorsList}>
              <Paragraph>{t('manager.webcastSetup.eventPermissions.options.private.teamLabel')}</Paragraph>

              <ContributorsList contributors={eventContributors} />
            </div>
          </SelectionInputLayout>
        </div>

        <SetupPageFooter
          nextLabel={t('manager.webcastSetup.eventPermissions.nextStep.label')}
          route={routes.webcastSetup_language}
          saving={updateEventPermissionLoading}
        />
      </main>

      {isPermissionManagerOpen && (
        <PermissionsManager
          owners={owners}
          collaborators={collaborators}
          isOpen={isPermissionManagerOpen}
          onClose={togglePermissionManager}
        />
      )}
    </>
  );
}
