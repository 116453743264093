import type { HTMLAttributes } from 'react';

import { Spinner } from '@movingimage-evp/mi-ui-component-library';

import styles from './loading-screen.module.css';

export function LoadingScreen(props: HTMLAttributes<HTMLElement>) {
  return (
    <div className={styles.wrapper} {...props}>
      <Spinner size={40} />
    </div>
  );
}
