type IconProps = {
  width?: string;
  height?: string;
};

export const VolumeIcon = ({ width = '18', height = '18' }: IconProps) => (
  <svg
    data-testid="volume-icon"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="#fff"
    aria-hidden="true"
    role="img"
  >
    <path d="M11 .23v2.06a7 7 0 010 13.41v2.07A9 9 0 0011 .23zM13.5 9c0-1.77-1-3.29-2.5-4.03V13a4.4 4.4 0 002.5-4zM0 6v6h4l5 5V1L4 6H0z" />
  </svg>
);
