import type { Locale } from 'date-fns';

import { resources as componentLibraryResources } from '@movingimage-evp/mi-ui-component-library';
import { de, enUS, fr, it } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { resources } from './translations';
import { getLocalStorageItem } from './utils/local-storage';

export const LANGUAGE_LOCAL_STORAGE_KEY = 'language';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en-US',
    ns: ['translations', 'formats'],
    defaultNS: 'translations',
    debug: false,
    keySeparator: '.', // we use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react does it already for us
    },
    cleanCode: true,
    detection: {
      order: ['navigator', 'htmlTag'],
      caches: [],
    },
  });

for (const key in componentLibraryResources) {
  i18n.addResourceBundle(key, 'translations', componentLibraryResources[key].translations);
}

const language = getLocalStorageItem(LANGUAGE_LOCAL_STORAGE_KEY);

if (language) i18n.changeLanguage(language);

const locales: Record<string, Locale> = { en: enUS, de, fr, it };

/**
 * Returns the locale based on the language
 * @param language Optional language to take. If no language is given, it will take the language from the local storage or the browser
 * @returns The locale based on the language
 */
export const getLocale = (language?: string): Locale => {
  const fullLanguage = language || getLocalStorageItem(LANGUAGE_LOCAL_STORAGE_KEY) || window.navigator.language;
  const mainLanguage = fullLanguage.split('-')[0];
  const availableTranslations = Object.keys(resources);

  if (availableTranslations.includes(mainLanguage) && locales[mainLanguage]) return locales[mainLanguage];

  return locales.en;
};

export default i18n;
