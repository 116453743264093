export const getActiveElementName = () => document.activeElement?.getAttribute('name') || '';

export const volumeSliderIsFocused = (activeElementName: string) => activeElementName === 'volume-slider';

export const uiButtonIsFocused = (activeElementName: string) =>
  activeElementName === 'play-button' ||
  activeElementName === 'pause-button' ||
  activeElementName === 'mute-button' ||
  activeElementName === 'step-back-button' ||
  activeElementName === 'step-forward-button' ||
  activeElementName === 'loop-button' ||
  activeElementName === 'settings-menu-button' ||
  activeElementName === 'language-menu-button' ||
  activeElementName === 'fullscreen-button';
