import { Modal, Paragraph, PrimaryButton, SecondaryButton } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { useVmproConnection } from './vmpro-connector';

export function VmproConnectionModal() {
  const { t } = useTranslation();
  const { isVmproLinked, connectionModalVisible, closeConnectionModal, callKeycloak } = useVmproConnection();
  const isOpen = !isVmproLinked && connectionModalVisible;

  const onConfirm = () => {
    callKeycloak();
    closeConnectionModal();
  };

  return (
    <Modal
      data-testid="vmpro-connection-modal"
      title={t('manager.vmproConnectionModal.title')}
      isOpen={isOpen}
      onClose={closeConnectionModal}
      footer={
        <>
          <SecondaryButton data-testid="cancel-vmpro-connection-button" type="button" onClick={closeConnectionModal}>
            {t('common.cancel')}
          </SecondaryButton>

          <PrimaryButton data-testid="confirm-vmpro-connection-button" type="button" onClick={onConfirm}>
            {t('manager.vmproConnectionModal.button')}
          </PrimaryButton>
        </>
      }
    >
      <Paragraph>{t('manager.vmproConnectionModal.paragraph')}</Paragraph>
    </Modal>
  );
}
