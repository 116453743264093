/**
 * Checks if an array contains only numbers.
 * @param {number[]} array The array to check.
 * @returns {boolean} True if the array is a number array, otherwise false.
 * @example
 * isArrayOfNumbers([1, 2, 3]) // true
 * isArrayOfNumbers([1, 2, '3']) // false
 */
export function isArrayOfNumbers(array: number[]): boolean {
  return array.length > 0 && array.every((item) => typeof item === 'number');
}
