type IconProps = {
  width?: string;
  height?: string;
};

export const MutedIcon = ({ width = '18', height = '18' }: IconProps) => (
  <svg
    data-testid="mute-icon"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    style={{ fill: 'var(--primaryColor)' }}
    aria-hidden="true"
    role="img"
  >
    <path d="M1.6.6l15.8 15.8-1.5 1.4-3.9-4V17l-5-5H3V6h1.2l-4-4L1.6.6zM12 1v7.2L8.4 4.6 12 1z" />
  </svg>
);
