import type { SelectOption } from '@movingimage-evp/mi-ui-component-library';

import { Select } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import styles from './video-manager-select.module.css';

type VideoManagerSelectProps = {
  currentVideoManagerId: string;
  videoManagers: { id: string; name: string }[];
  info: string;
  disabled?: boolean;
  handleVideoManagerIdChange: (option: SelectOption) => void;
};
export function VideoManagerSelect({
  currentVideoManagerId,
  videoManagers,
  info,
  disabled,
  handleVideoManagerIdChange,
}: VideoManagerSelectProps) {
  const { t } = useTranslation();

  const options = videoManagers.map((videoManager) => ({
    key: videoManager.id,
    label: videoManager.name,
  }));

  const value = options.find((option) => option.key === currentVideoManagerId);

  return (
    <div className={styles.accountPicker}>
      <span>{info}</span>

      <Select
        data-testid="video-manager-browser-account-picker"
        value={value}
        placeholder={t('components.videoManager.videoManagerSelectPlaceholder')}
        disabled={disabled}
        options={options}
        onChange={handleVideoManagerIdChange}
        optionsIdProperty="key"
      />
    </div>
  );
}
