import ReactDOMServer from 'react-dom/server';

type EmbedCodeParams = {
  url: string;
  title: string;
  dataTestId?: string;
};

export function generateEmbedCode(params: EmbedCodeParams) {
  return ReactDOMServer.renderToStaticMarkup(generateReactEmbedCode(params));
}

export function generateReactEmbedCode({ url, title, dataTestId }: EmbedCodeParams) {
  return (
    <iframe
      width="800"
      height="450"
      style={{ border: 0 }}
      src={url}
      title={title}
      allowFullScreen
      data-cy={dataTestId}
    ></iframe>
  );
}
