import type { SelectOption } from '@movingimage-evp/mi-ui-component-library';

import { InputDescription, SaveButton, Select } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { useGetLsproBrandingSettingsQuery } from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';

import styles from './font-and-typography.module.css';

type Props = {
  headingFont: string;
  textFont: string;
  saving: boolean;
  onHeadingFontChange: (font: string) => void;
  onTextFontChange: (font: string) => void;
  onSave: () => void;
};

export function FontAndTypography({
  headingFont,
  textFont,
  saving,
  onHeadingFontChange,
  onTextFontChange,
  onSave,
}: Props) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();

  const { data } = useGetLsproBrandingSettingsQuery({ variables: { lsproId } });

  const supportedFonts = data?.configuration.supportedFonts || [];
  const options = supportedFonts.map((font) => ({ key: font }));

  if (!options.length) return null;

  return (
    <div data-testid="font-and-typography" className={styles.wrapper}>
      <InputDescription label={t('manager.branding.fontAndTypography.headingFont')}>
        <Select
          data-testid="heading-font-select"
          placeholder={t('common.select')}
          optionsIdProperty="key"
          value={{ key: headingFont }}
          options={options}
          onChange={(option: SelectOption) => onHeadingFontChange(option.key)}
        />
      </InputDescription>

      <InputDescription label={t('manager.branding.fontAndTypography.textFont')}>
        <Select
          data-testid="text-font-select"
          placeholder={t('common.select')}
          optionsIdProperty="key"
          value={{ key: textFont }}
          options={options}
          onChange={(option: SelectOption) => onTextFontChange(option.key)}
        />
      </InputDescription>

      <SaveButton data-testid="font-and-typography-save-button" small saving={saving} onClick={onSave} />
    </div>
  );
}
