import classNames from 'classnames';
import { getFormattedTime } from '../../utils/time';

import styles from './time-badge.module.css';

type TimeBadgeProps = {
  time: number;
  isActive?: boolean;
};

export function TimeBadge({ time, isActive }: TimeBadgeProps) {
  return <div className={classNames(styles.wrapper, { [styles.active]: isActive })}>{getFormattedTime(time)}</div>;
}
