const supportedLanguages = ['en', 'de', 'fr', 'it'] as const;

type SupportedLanguages = (typeof supportedLanguages)[number];

const isSupportedLanguage = (value: string): value is SupportedLanguages =>
  supportedLanguages.includes(value as SupportedLanguages);

export const getLanguageKey = (language: string): SupportedLanguages =>
  isSupportedLanguage(language) ? language : 'en';

const supportedAiTranslations = [
  ...supportedLanguages,
  'ar',
  'bg',
  'cs',
  'da',
  'el',
  'en-gb',
  'en-us',
  'es',
  'et',
  'fi',
  'hu',
  'ja',
  'ko',
  'lt',
  'lv',
  'nb',
  'nl',
  'pl',
  'pt-br',
  'pt-pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'tr',
  'uk',
  'zh',
] as const;

type SupportedAiTranslations = (typeof supportedAiTranslations)[number];

const isSupportedAiTranslation = (value: string): value is SupportedAiTranslations =>
  supportedAiTranslations.includes(value as SupportedAiTranslations);

export const getAiTranslationKey = (language: string): SupportedAiTranslations =>
  (isSupportedAiTranslation(language) ? language : 'en') as SupportedAiTranslations;
