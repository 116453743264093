export interface LanguageMap {
  [key: string]: string;
}

const data: LanguageMap = {
  aar: 'Afaraf',
  aa: 'Afaraf',
  abk: 'Abkhazian',
  ab: 'Abkhazian',
  ace: 'Achinese',
  ach: 'Acoli',
  ada: 'Adangme',
  ady: 'Adyghe',
  afa: 'Afro-Asiatic Languages',
  afh: 'Afrihili',
  afr: 'Afrikaans',
  af: 'Afrikaans',
  ain: 'Ainu',
  aka: 'Akan',
  ak: 'Akan',
  akk: 'Akkadian',
  alb: 'Shqip',
  sqi: 'Shqip',
  sq: 'Shqip',
  ale: 'Aleut',
  alg: 'Algonquian Languages',
  alt: 'Southern Altai',
  amh: 'አማርኛ',
  am: 'አማርኛ',
  ang: 'English Old (ca.450-1100)',
  anp: 'Angika',
  apa: 'Apache Languages',
  ara: 'العربية',
  ar: 'العربية',
  arc: 'Official Aramaic (700-300 BCE)',
  arg: 'Aragonés',
  an: 'Aragonés',
  arm: 'Հայերեն',
  hye: 'Հայերեն',
  hy: 'Հայերեն',
  arn: 'Mapudungun',
  arp: 'Arapaho',
  art: 'Artificial Languages',
  arw: 'Arawak',
  asm: 'অসমীয়া',
  as: 'অসমীয়া',
  ast: 'Leonese',
  ath: 'Athapascan Languages',
  aus: 'Australian Languages',
  ava: 'магӀарул мацӀ',
  av: 'магӀарул мацӀ',
  ave: 'Avesta',
  ae: 'Avesta',
  awa: 'Awadhi',
  aym: 'Aymar Aru',
  ay: 'Aymar Aru',
  aze: 'Azərbaycan Dili',
  az: 'Azərbaycan Dili',
  bad: 'Banda Languages',
  bai: 'Bamileke Languages',
  bak: 'башҡорт теле',
  ba: 'башҡорт теле',
  bal: 'Baluchi',
  bam: 'Bamanankan',
  bm: 'Bamanankan',
  ban: 'Balinese',
  baq: 'Euskera',
  eus: 'Euskera',
  eu: 'Euskera',
  bas: 'Basa',
  bat: 'Baltic Languages',
  bej: 'Beja',
  bel: 'Беларуская',
  be: 'Беларуская',
  bem: 'Bemba',
  ben: 'বাংলা',
  bn: 'বাংলা',
  ber: 'Berber Languages',
  bho: 'Bhojpuri',
  bih: 'Bihari Languages',
  bh: 'Bihari Languages',
  bik: 'Bikol',
  bin: 'Edo',
  bis: 'Bislama',
  bi: 'Bislama',
  bla: 'Siksika',
  bnt: 'Bantu (Other)',
  bos: 'Bosanski Jezik',
  bs: 'Bosanski Jezik',
  bra: 'Braj',
  bre: 'Brezhoneg',
  br: 'Brezhoneg',
  btk: 'Batak Languages',
  bua: 'Buriat',
  bug: 'Buginese',
  bul: 'български език',
  bg: 'български език',
  bur: 'ဗမာစာ',
  mya: 'ဗမာစာ',
  my: 'ဗမာစာ',
  byn: 'Blin',
  cad: 'Caddo',
  cai: 'Central American Indian Languages',
  car: 'Galibi Carib',
  cat: 'Català',
  ca: 'Català',
  cau: 'Caucasian Languages',
  ceb: 'Cebuano',
  cel: 'Celtic Languages',
  cha: 'Chamoru',
  ch: 'Chamoru',
  chb: 'Chibcha',
  che: 'нохчийн мотт',
  ce: 'нохчийн мотт',
  chg: 'Chagatai',
  chi: '漢語',
  zho: '漢語',
  zh: '漢語',
  chk: 'Chuukese',
  chm: 'Mari',
  chn: 'Chinook Jargon',
  cho: 'Choctaw',
  chp: 'Dene Suline',
  chr: 'Cherokee',
  chu: 'Old Slavonic',
  cu: 'Old Slavonic',
  chv: 'чӑваш чӗлхи',
  cv: 'чӑваш чӗлхи',
  chy: 'Cheyenne',
  cmc: 'Chamic Languages',
  cop: 'Coptic',
  cor: 'Kernewek',
  kw: 'Kernewek',
  cos: 'Lingua Corsa',
  co: 'Lingua Corsa',
  cpe: 'English Based',
  cpf: 'French-based',
  cpp: 'Portuguese-based',
  cre: 'ᓀᐦᐃᔭᐍᐏᐣ',
  cr: 'ᓀᐦᐃᔭᐍᐏᐣ',
  crh: 'Crimean Turkish',
  crp: 'Creoles And Pidgins',
  csb: 'Kashubian',
  cus: 'Cushitic Languages',
  cze: 'čeština',
  ces: 'čeština',
  cs: 'čeština',
  dak: 'Dakota',
  dan: 'Dansk',
  da: 'Dansk',
  dar: 'Dargwa',
  day: 'Land Dayak Languages',
  del: 'Delaware',
  den: 'Slave (Athapascan)',
  dgr: 'Dogrib',
  din: 'Dinka',
  div: 'Maldivian',
  dv: 'Maldivian',
  doi: 'Dogri',
  dra: 'Dravidian Languages',
  dsb: 'Lower Sorbian',
  dua: 'Duala',
  dum: 'Dutch Middle (ca.1050-1350)',
  dut: 'Flemish',
  nld: 'Flemish',
  nl: 'Flemish',
  dyu: 'Dyula',
  dzo: 'Dzongkha',
  dz: 'Dzongkha',
  efi: 'Efik',
  egy: 'Egyptian (Ancient)',
  eka: 'Ekajuk',
  elx: 'Elamite',
  eng: 'English',
  en: 'English',
  enm: 'English Middle (1100-1500)',
  epo: 'Esperanto',
  eo: 'Esperanto',
  est: 'Eesti Keel',
  et: 'Eesti Keel',
  ewe: 'Eʋegbe',
  ee: 'Eʋegbe',
  ewo: 'Ewondo',
  fan: 'Fang',
  fao: 'Føroyskt',
  fo: 'Føroyskt',
  fat: 'Fanti',
  fij: 'Vosa Vakaviti',
  fj: 'Vosa Vakaviti',
  fil: 'Pilipino',
  fin: 'Suomi',
  fi: 'Suomi',
  fiu: 'Finno-Ugrian Languages',
  fon: 'Fon',
  fre: 'Langue Française',
  fra: 'Langue Française',
  fr: 'Langue Française',
  frm: 'French Middle (ca.1400-1600)',
  fro: 'French Old (842-ca.1400)',
  frr: 'Northern Frisian',
  frs: 'Eastern Frisian',
  fry: 'Frysk',
  fy: 'Frysk',
  ful: 'Fulah',
  ff: 'Fulah',
  fur: 'Friulian',
  gaa: 'Ga',
  gay: 'Gayo',
  gba: 'Gbaya',
  gem: 'Germanic Languages',
  geo: 'ქართული',
  kat: 'ქართული',
  ka: 'ქართული',
  ger: 'Deutsch',
  deu: 'Deutsch',
  de: 'Deutsch',
  gez: 'Geez',
  gil: 'Gilbertese',
  gla: 'Scottish Gaelic',
  gd: 'Scottish Gaelic',
  gle: 'Gaeilge',
  ga: 'Gaeilge',
  glg: 'Galego',
  gl: 'Galego',
  glv: 'Gailck',
  gv: 'Gailck',
  gmh: 'German Middle High (ca.1050-1500)',
  goh: 'German Old High (ca.750-1050)',
  gon: 'Gondi',
  gor: 'Gorontalo',
  got: 'Gothic',
  grb: 'Grebo',
  grc: 'Greek Ancient (to 1453)',
  gre: 'Greek Modern (1453-)',
  ell: 'Greek Modern (1453-)',
  el: 'Greek Modern (1453-)',
  grn: 'Guarani',
  gn: 'Guarani',
  gsw: 'Swiss German',
  guj: 'ગુજરાતી',
  gu: 'ગુજરાતી',
  gwi: "Gwich'in",
  hai: 'Haida',
  hat: 'Kreyòl Ayisyen',
  ht: 'Kreyòl Ayisyen',
  hau: 'هَوُسَ',
  ha: 'هَوُسَ',
  haw: 'Hawaiian',
  heb: 'Hebrew',
  he: 'Hebrew',
  her: 'Otjiherero',
  hz: 'Otjiherero',
  hil: 'Hiligaynon',
  him: 'Western Pahari Languages',
  hin: 'हिन्दी',
  hi: 'हिन्दी',
  hit: 'Hittite',
  hmn: 'Mong',
  hmo: 'Hiri Motu',
  ho: 'Hiri Motu',
  hrv: 'Hrvatski',
  hr: 'Hrvatski',
  hsb: 'Upper Sorbian',
  hun: 'Magyar',
  hu: 'Magyar',
  hup: 'Hupa',
  iba: 'Iban',
  ibo: 'Asụsụ Igbo',
  ig: 'Asụsụ Igbo',
  ice: 'Íslenska',
  isl: 'Íslenska',
  is: 'Íslenska',
  ido: 'Ido',
  io: 'Ido',
  iii: 'Sichuan Yi',
  ii: 'Sichuan Yi',
  ijo: 'Ijo Languages',
  iku: 'ᐃᓄᒃᑎᑐᑦ',
  iu: 'ᐃᓄᒃᑎᑐᑦ',
  ile: 'Occidental',
  ie: 'Occidental',
  ilo: 'Iloko',
  ina: 'Interlingua (International Auxiliary Language Association)',
  ia: 'Interlingua (International Auxiliary Language Association)',
  inc: 'Indic Languages',
  ind: 'Bahasa Indonesia',
  id: 'Bahasa Indonesia',
  ine: 'Indo-European Languages',
  inh: 'Ingush',
  ipk: 'Iñupiatun',
  ik: 'Iñupiatun',
  ira: 'Iranian Languages',
  iro: 'Iroquoian Languages',
  ita: 'Italiano',
  it: 'Italiano',
  jav: 'Basa Jawa',
  jv: 'Basa Jawa',
  jbo: 'Lojban',
  jpn: '日本語 (にほんご／にっぽんご)',
  ja: '日本語 (にほんご／にっぽんご)',
  jpr: 'Judeo-Persian',
  jrb: 'Judeo-Arabic',
  kaa: 'Kara-Kalpak',
  kab: 'Kabyle',
  kac: 'Kachin',
  kal: 'Kalaallisut',
  kl: 'Kalaallisut',
  kam: 'Kamba',
  kan: 'ಕನ್ನಡ',
  kn: 'ಕನ್ನಡ',
  kar: 'Karen Languages',
  kas: 'कश्मीरी',
  ks: 'कश्मीरी',
  kau: 'Kanuri',
  kr: 'Kanuri',
  kaw: 'Kawi',
  kaz: 'Қазақ тілі',
  kk: 'Қазақ тілі',
  kbd: 'Kabardian',
  kha: 'Khasi',
  khi: 'Khoisan Languages',
  khm: 'Central Khmer',
  km: 'Central Khmer',
  kho: 'Sakan',
  kik: 'Kikuyu',
  ki: 'Kikuyu',
  kin: 'Ikinyarwanda',
  rw: 'Ikinyarwanda',
  kir: 'Kyrgyz',
  ky: 'Kyrgyz',
  kmb: 'Kimbundu',
  kok: 'Konkani',
  kom: 'коми кыв',
  kv: 'коми кыв',
  kon: 'KiKongo',
  kg: 'KiKongo',
  kor: '한국어 (韓國語)',
  ko: '한국어 (韓國語)',
  kos: 'Kosraean',
  kpe: 'Kpelle',
  krc: 'Karachay-Balkar',
  krl: 'Karelian',
  kro: 'Kru Languages',
  kru: 'Kurukh',
  kua: 'Kwanyama',
  kj: 'Kwanyama',
  kum: 'Kumyk',
  kur: 'كوردی‎',
  ku: 'كوردی‎',
  kut: 'Kutenai',
  lad: 'Ladino',
  lah: 'Lahnda',
  lam: 'Lamba',
  lao: 'ພາສາລາວ',
  lo: 'ພາສາລາວ',
  lat: 'Lingua Latina',
  la: 'Lingua Latina',
  lav: 'Latviešu Valoda',
  lv: 'Latviešu Valoda',
  lez: 'Lezghian',
  lim: 'Limburgish',
  li: 'Limburgish',
  lin: 'Lingála',
  ln: 'Lingála',
  lit: 'Lietuvių Kalba',
  lt: 'Lietuvių Kalba',
  lol: 'Mongo',
  loz: 'Lozi',
  ltz: 'Luxembourgish',
  lb: 'Luxembourgish',
  lua: 'Luba-Lulua',
  lub: '',
  lu: '',
  lug: 'Ganda',
  lg: 'Ganda',
  lui: 'Luiseno',
  lun: 'Lunda',
  luo: 'Luo (Kenya And Tanzania)',
  lus: 'Lushai',
  mac: 'македонски јазик',
  mkd: 'македонски јазик',
  mk: 'македонски јазик',
  mad: 'Madurese',
  mag: 'Magahi',
  mah: 'Kajin M̧ajeļ',
  mh: 'Kajin M̧ajeļ',
  mai: 'Maithili',
  mak: 'Makasar',
  mal: 'മലയാളം',
  ml: 'മലയാളം',
  man: 'Mandingo',
  mao: 'Maori',
  mri: 'Maori',
  mi: 'Maori',
  map: 'Austronesian Languages',
  mar: 'Marathi',
  mr: 'Marathi',
  mas: 'Masai',
  may: 'بهاس ملايو‎',
  msa: 'بهاس ملايو‎',
  ms: 'بهاس ملايو‎',
  mdf: 'Moksha',
  mdr: 'Mandar',
  men: 'Mende',
  mga: 'Irish Middle (900-1200)',
  mic: 'Micmac',
  min: 'Minangkabau',
  mis: 'Uncoded Languages',
  mkh: 'Mon-Khmer Languages',
  mlg: 'Malagasy Fiteny',
  mg: 'Malagasy Fiteny',
  mlt: 'Malti',
  mt: 'Malti',
  mnc: 'Manchu',
  mni: 'Manipuri',
  mno: 'Manobo Languages',
  moh: 'Mohawk',
  mon: 'монгол',
  mn: 'монгол',
  mos: 'Mossi',
  mul: 'Multiple Languages',
  mun: 'Munda Languages',
  mus: 'Creek',
  mwl: 'Mirandese',
  mwr: 'Marwari',
  myn: 'Mayan Languages',
  myv: 'Erzya',
  nah: 'Nahuatl Languages',
  nai: 'North American Indian Languages',
  nap: 'Neapolitan',
  nau: 'Ekakairũ Naoero',
  na: 'Ekakairũ Naoero',
  nav: 'Navajo',
  nv: 'Navajo',
  nbl: 'South',
  nr: 'South',
  nde: 'North',
  nd: 'North',
  ndo: 'Owambo',
  ng: 'Owambo',
  nds: 'Saxon',
  nep: 'नेपाली',
  ne: 'नेपाली',
  new: 'Newari',
  nia: 'Nias',
  nic: 'Niger-Kordofanian Languages',
  niu: 'Niuean',
  nno: 'Nynorsk',
  nn: 'Nynorsk',
  nob: 'Norwegian',
  nb: 'Norwegian',
  nog: 'Nogai',
  non: 'Old',
  nor: 'Norsk',
  no: 'Norsk',
  nqo: "N'Ko",
  nso: 'Sepedi',
  nub: 'Nubian Languages',
  nwc: 'Old Newari',
  nya: 'Chinyanja',
  ny: 'Chinyanja',
  nym: 'Nyamwezi',
  nyn: 'Nyankole',
  nyo: 'Nyoro',
  nzi: 'Nzima',
  oci: 'Provençal',
  oc: 'Provençal',
  oji: 'Ojibwa',
  oj: 'Ojibwa',
  ori: 'ଓଡ଼ିଆ',
  or: 'ଓଡ଼ିଆ',
  orm: 'Afaan Oromoo',
  om: 'Afaan Oromoo',
  osa: 'Osage',
  oss: 'Ossetic',
  os: 'Ossetic',
  ota: 'Turkish Ottoman (1500-1928)',
  oto: 'Otomian Languages',
  paa: 'Papuan Languages',
  pag: 'Pangasinan',
  pal: 'Pahlavi',
  pam: 'Pampanga',
  pan: 'Punjabi',
  pa: 'Punjabi',
  pap: 'Papiamento',
  pau: 'Palauan',
  peo: 'Persian Old (ca.600-400 B.C.)',
  per: 'فارسی',
  fas: 'فارسی',
  fa: 'فارسی',
  phi: 'Philippine Languages',
  phn: 'Phoenician',
  pli: 'Pali',
  pi: 'Pali',
  pol: 'Polski',
  pl: 'Polski',
  pon: 'Pohnpeian',
  por: 'Português',
  pt: 'Português',
  pra: 'Prakrit Languages',
  pro: 'Provençal Old (to 1500)',
  pus: 'Pushto',
  ps: 'Pushto',
  'qaa-qtz': 'Reserved For Local Use',
  que: 'Runa Simi',
  qu: 'Runa Simi',
  raj: 'Rajasthani',
  rap: 'Rapanui',
  rar: 'Rarotongan',
  roa: 'Romance Languages',
  roh: 'Rumantsch Grischun',
  rm: 'Rumantsch Grischun',
  rom: 'Romany',
  rum: 'Romanian',
  ron: 'Romanian',
  ro: 'Romanian',
  run: 'Rundi',
  rn: 'Rundi',
  rup: 'Macedo-Romanian',
  rus: 'русский язык',
  ru: 'русский язык',
  sad: 'Sandawe',
  sag: 'Yângâ Tî Sängö',
  sg: 'Yângâ Tî Sängö',
  sah: 'Yakut',
  sai: 'South American Indian (Other)',
  sal: 'Salishan Languages',
  sam: 'Samaritan Aramaic',
  san: 'Sanskrit',
  sa: 'Sanskrit',
  sas: 'Sasak',
  sat: 'Santali',
  scn: 'Sicilian',
  sco: 'Scots',
  sel: 'Selkup',
  sem: 'Semitic Languages',
  sga: 'Irish Old (to 900)',
  sgn: 'Sign Languages',
  shn: 'Shan',
  sid: 'Sidamo',
  sin: 'Sinhalese',
  si: 'Sinhalese',
  sio: 'Siouan Languages',
  sit: 'Sino-Tibetan Languages',
  sla: 'Slavic Languages',
  slo: 'Slovenčina',
  slk: 'Slovenčina',
  sk: 'Slovenčina',
  slv: 'Slovenian',
  sl: 'Slovenian',
  sma: 'Southern Sami',
  sme: 'Davvisámegiella',
  se: 'Davvisámegiella',
  smi: 'Sami Languages',
  smj: 'Lule Sami',
  smn: 'Inari Sami',
  smo: 'Gagana Faa Samoa',
  sm: 'Gagana Faa Samoa',
  sms: 'Skolt Sami',
  sna: 'ChiShona',
  sn: 'ChiShona',
  snd: 'सिन्धी',
  sd: 'सिन्धी',
  snk: 'Soninke',
  sog: 'Sogdian',
  som: 'Soomaaliga',
  so: 'Soomaaliga',
  son: 'Songhai Languages',
  sot: 'Southern',
  st: 'Southern',
  spa: 'Español',
  es: 'Español',
  srd: 'Sardu',
  sc: 'Sardu',
  srn: 'Sranan Tongo',
  srp: 'српски језик',
  sr: 'српски језик',
  srr: 'Serer',
  ssa: 'Nilo-Saharan Languages',
  ssw: 'SiSwati',
  ss: 'SiSwati',
  suk: 'Sukuma',
  sun: 'Basa Sunda',
  su: 'Basa Sunda',
  sus: 'Susu',
  sux: 'Sumerian',
  swa: 'Kiswahili',
  sw: 'Kiswahili',
  swe: 'Svenska',
  sv: 'Svenska',
  syc: 'Classical Syriac',
  syr: 'Syriac',
  tah: 'Reo Tahiti',
  ty: 'Reo Tahiti',
  tai: 'Tai Languages',
  tam: 'தமிழ்',
  ta: 'தமிழ்',
  tat: 'تاتارچا‎',
  tt: 'تاتارچا‎',
  tel: 'తెలుగు',
  te: 'తెలుగు',
  tem: 'Timne',
  ter: 'Tereno',
  tet: 'Tetum',
  tgk: 'تاجیکی‎',
  tg: 'تاجیکی‎',
  tgl: 'ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  tl: 'ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  tha: 'ไทย',
  th: 'ไทย',
  tib: 'Tibetan',
  bod: 'Tibetan',
  bo: 'Tibetan',
  tig: 'Tigre',
  tir: 'ትግርኛ',
  ti: 'ትግርኛ',
  tiv: 'Tiv',
  tkl: 'Tokelau',
  tlh: 'TlhIngan-Hol',
  tli: 'Tlingit',
  tmh: 'Tamashek',
  tog: 'Tonga (Nyasa)',
  ton: 'Faka Tonga',
  to: 'Faka Tonga',
  tpi: 'Tok Pisin',
  tsi: 'Tsimshian',
  tsn: 'Setswana',
  tn: 'Setswana',
  tso: 'Xitsonga',
  ts: 'Xitsonga',
  tuk: 'Түркмен',
  tk: 'Түркмен',
  tum: 'Tumbuka',
  tup: 'Tupi Languages',
  tur: 'Türkçe',
  tr: 'Türkçe',
  tut: 'Altaic Languages',
  tvl: 'Tuvalu',
  twi: 'Twi',
  tw: 'Twi',
  tyv: 'Tuvinian',
  udm: 'Udmurt',
  uga: 'Ugaritic',
  uig: 'Uyghur',
  ug: 'Uyghur',
  ukr: 'українська',
  uk: 'українська',
  umb: 'Umbundu',
  und: 'Undetermined',
  urd: 'اردو',
  ur: 'اردو',
  uzb: 'أۇزبېك‎',
  uz: 'أۇزبېك‎',
  vai: 'Vai',
  ven: 'Tshivenḓa',
  ve: 'Tshivenḓa',
  vie: 'Tiếng Việt',
  vi: 'Tiếng Việt',
  vol: 'Volapük',
  vo: 'Volapük',
  vot: 'Votic',
  wak: 'Wakashan Languages',
  wal: 'Walamo',
  war: 'Waray',
  was: 'Washo',
  wel: 'Cymraeg',
  cym: 'Cymraeg',
  cy: 'Cymraeg',
  wen: 'Sorbian Languages',
  wln: 'Walon',
  wa: 'Walon',
  wol: 'Wollof',
  wo: 'Wollof',
  xal: 'Oirat',
  xho: 'IsiXhosa',
  xh: 'IsiXhosa',
  yao: 'Yao',
  yap: 'Yapese',
  yid: 'ייִדיש',
  yi: 'ייִדיש',
  yor: 'Yorùbá',
  yo: 'Yorùbá',
  ypk: 'Yupik Languages',
  zap: 'Zapotec',
  zbl: 'Blissymbols',
  zen: 'Zenaga',
  zgh: 'Standard Moroccan Tamazight',
  zha: 'Zhuang',
  za: 'Zhuang',
  znd: 'Zande Languages',
  zul: 'Zulu',
  zu: 'Zulu',
  zun: 'Zuni',
  zxx: 'Not Applicable',
  zza: 'Zazaki',
};

export { data };
