import type { HTMLAttributes } from 'react';

import { CalendarSmallIcon, FileIcon, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { State } from '../../generated/graphql-manager';
import { ONDEMAND_STATE } from '../../manager/pages/webcasts/webcasts';

import styles from './state-badge.module.css';

type StateBadgeProps = {
  state?: State | null;
} & HTMLAttributes<HTMLSpanElement>;

export function StateBadge({ state, ...props }: StateBadgeProps) {
  const { t } = useTranslation();

  if (!state) return null;

  const icon = {
    [State.INITIALIZING]: null,
    [State.LIVE]: null,
    [State.LIVE_TEST]: null,
    [State.PRELIVE]: <CalendarSmallIcon />,
    [State.POSTLIVE]: <FileIcon />,
    [ONDEMAND_STATE]: <FileIcon />,
  }[state];

  return (
    <span className={classNames(styles.stateBadge, styles[state])} data-testid="state-badge" {...props}>
      {t(`manager.webcasts.state.${state}`)} {icon}
    </span>
  );
}
