import type { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';

/**
 * An extension of the useState hook, to also provide a ref to the value
 * @param {T} initialValue The initial value to save in the state.
 * @returns Returns an array with the ref to the state value, the state value and the setter function.
 */
export function useRefState<T>(initialValue: T): [MutableRefObject<T>, T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [stateRef, state, setState];
}
