import type { Video } from '../../../generated/graphql-manager';
import type { PlayerConfig } from '@movingimage-evp/player-library';

import {
  CloseIcon,
  IconButton,
  ModalPortal,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { Player } from '@movingimage-evp/player-library';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchVideoDetails } from '../../../common';
import { getLocale } from '../../../i18n';

import styles from './video-details-modal.module.css';

type Props = {
  isOpen: boolean;
  video?: Video;
  onConfirm: (video: Video) => void;
  onClose: () => void;
};

export function VideoDetailsModal({ isOpen, video, onConfirm, onClose }: Props) {
  const locale = getLocale();
  const { t } = useTranslation();
  const [playerConfig, setPlayerConfig] = useState<PlayerConfig>();
  const [error, setError] = useState<string>();

  const handleClose = () => {
    setPlayerConfig(undefined);
    setError(undefined);
    onClose();
  };

  const handleConfirm = () => {
    if (!video) return;

    onConfirm(video);
    handleClose();
  };

  useEffect(() => {
    if (!video) return;

    // Clear video data so that the Player is properly re-rendered with a new one
    setPlayerConfig(undefined);

    let ignoreResult = false;
    fetchVideoDetails(video.id, video.channelId)
      .then((videoData) => {
        if (ignoreResult) return;
        setPlayerConfig(videoData.playerData);
      })
      .catch((error) => {
        if (error.status === 401) return setError(t('errors.coverVideo.hasSecurityPolicy'));
        if (error.status === 404) return setError(t('errors.coverVideo.notFound'));
        setError(t('errors.coverVideo.unknown'));
      });

    return () => {
      ignoreResult = true;
    };
  }, [video, t]);

  return (
    <ModalPortal isOpen={isOpen} onClose={handleClose}>
      <div className={classNames(styles.modal, 'lspro-scrollbars')} data-testid="video-details-modal">
        <div className={styles.player}>
          {playerConfig && <Player {...playerConfig} />}
          {error}
        </div>

        <div className={styles.content}>
          <div className={styles.header}>
            <h3>{t('components.videoManager.videoBrowser.videoDetailsModal.details')}</h3>

            <Tooltip label={t('components.videoManager.videoBrowser.videoDetailsModal.close')}>
              <IconButton
                rounded
                onClick={handleClose}
                aria-label={t('components.videoManager.videoBrowser.videoDetailsModal.close')}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div className={styles.info}>
            <div className={styles.item}>
              <span>{t('components.videoManager.videoBrowser.videoDetailsModal.fileName')}</span>
              <span data-testid="video-details-modal-file-name">{video?.title}</span>
            </div>

            <div className={styles.item}>
              <span>{t('components.videoManager.videoBrowser.videoDetailsModal.date')}</span>

              <span data-testid="video-details-modal-date">
                {format(new Date(video?.createdDate || 0), 'PP', { locale })}
              </span>
            </div>
          </div>

          <div className={styles.actions}>
            <PrimaryButton
              data-testid="video-details-modal-confirm-button"
              disabled={!video || error !== undefined}
              onClick={handleConfirm}
            >
              {t('components.videoManager.videoBrowser.videoDetailsModal.buttons.confirm')}
            </PrimaryButton>

            <SecondaryButton data-testid="video-details-modal-cancel-button" onClick={handleClose}>
              {t('components.videoManager.videoBrowser.videoDetailsModal.buttons.cancel')}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}
