// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message_wrapper__NNJYo {
  display: flex;
  gap: clamp(8px, 5%, 30px);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: var(--textColor);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  overflow: hidden;
  text-align: center;

  background: var(--backgroundColor); /* backwards compatible */
}

@supports (color: color-mix(in lch, red, blue)) {
.error-message_wrapper__NNJYo {
  background: color-mix(in srgb, var(--backgroundColor) 75%, transparent);
}
}

.error-message_wrapper__NNJYo .error-message_link__4H4kJ {
  color: var(--primaryColor);
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.error-message_wrapper__NNJYo .error-message_link__4H4kJ:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/error-message/error-message.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,WAAW;EACX,YAAY;;EAEZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;;EAEV,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,mCAAmC;EACnC,kCAAkC;;EAElC,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;;EAElB,kCAAkC,EAAE,yBAAyB;AAE/D;;AA5BA;AAAA;EA2BE,uEAAuE;AACzE;AAAA;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,6BAAqB;EAArB,qBAAqB;AACvB;;AAEA;EACE,kCAA0B;EAA1B,0BAA0B;AAC5B","sourcesContent":[".wrapper {\n  display: flex;\n  gap: clamp(8px, 5%, 30px);\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  width: 100%;\n  height: 100%;\n\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n\n  font-family: Montserrat, sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  color: var(--textColor);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n  box-sizing: border-box;\n  overflow: hidden;\n  text-align: center;\n\n  background: var(--backgroundColor); /* backwards compatible */\n  background: color-mix(in srgb, var(--backgroundColor) 75%, transparent);\n}\n\n.wrapper .link {\n  color: var(--primaryColor);\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.wrapper .link:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `error-message_wrapper__NNJYo`,
	"link": `error-message_link__4H4kJ`
};
export default ___CSS_LOADER_EXPORT___;
