import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ChaptersIcon } from '../../icons/chapters';
import { Tooltip } from '../tooltip';

import commonStyles from '../../styles/common.module.css';

export const ChaptersMenuButtonName = 'chapters-menu-button';

type Props = {
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
};

export function ChaptersMenuButton({ isActive, className, onClick }: Props) {
  const { t } = useTranslation();

  const label = isActive ? t('ui.chapters.menu.button.label.active') : t('ui.chapters.menu.button.label.inactive');

  return (
    <button
      data-testid={ChaptersMenuButtonName}
      type="button"
      name={ChaptersMenuButtonName}
      aria-expanded={isActive}
      aria-haspopup="true"
      className={classNames(commonStyles.button, className)}
      onClick={onClick}
    >
      <Tooltip>{label}</Tooltip>
      <ChaptersIcon />
    </button>
  );
}
