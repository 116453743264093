export type DownloadFileOptions = {
  url: string;
  fileName: string;
  onSuccess?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
  onError?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
  onProgress?: (percentComplete: number, remainingMinutes: number, remainingSeconds: number) => void;
  onStart?: (request: XMLHttpRequest) => void;
  token?: string;
};

/**
 * @param {string} url URL of the file to download
 * @param {string} fileName Name of the file to download
 * @param {(event: ProgressEvent<XMLHttpRequestEventTarget>) => void} [onSuccess] A callback to be called when the download finishes
 * @param {(event: ProgressEvent<XMLHttpRequestEventTarget>) => void} [onError] A callback to be called when the download fails
 * @param {(percentComplete: number, remainingMinutes: number, remainingSeconds: number) => void} [onProgress] A callback to be called when the download progresses
 * @param {(request: XMLHttpRequest) => void} [onStart] A callback to be called when the download starts
 * @param {string} [token] Authorization token
 * @returns {XMLHttpRequest} XHR object
 */
export function downloadFile({ url, fileName, onSuccess, onError, onProgress, onStart, token }: DownloadFileOptions) {
  const xhr = new XMLHttpRequest();

  xhr.responseType = 'blob';
  xhr.open('GET', url);

  if (token) xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  const startTime = new Date().getTime();

  const createAndClickDownloadLink = () => {
    const blob = new Blob([xhr.response], { type: 'application/octetstream' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.setAttribute('download', fileName);
    link.setAttribute('href', href);
    document.body.appendChild(link);

    try {
      link.click();
    } finally {
      document.body.removeChild(link);
    }
  };

  xhr.addEventListener('load', (event) => {
    if (xhr.status === 200) {
      createAndClickDownloadLink();
      onSuccess?.(event);
    } else {
      onError?.(event);
    }
  });

  xhr.addEventListener('error', (event) => onError?.(event));

  xhr.addEventListener('progress', (event) => {
    const percent_complete = Math.floor((event.loaded / event.total) * 100);
    const duration = (new Date().getTime() - startTime) / 1000;
    const bytesPerSecond = event.loaded / duration;

    const time = (event.total - event.loaded) / bytesPerSecond;
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor(time / 60);
    onProgress?.(percent_complete, minutes, seconds);
  });

  onStart?.(xhr);
  xhr.send();

  return xhr;
}
