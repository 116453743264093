import type { Channel } from '../../../../generated/graphql-manager';

export function getPathForChannel(
  channel: Channel,
  channels: Channel[],
  fileName = 'Recording.mp4',
  path = ''
): string {
  const parentChannel = channels.find(({ id }) => id === channel.parentId);

  return parentChannel
    ? getPathForChannel(parentChannel, channels, fileName, '/' + channel.name + path)
    : channel.name + path + '/' + fileName;
}
