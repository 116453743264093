import type { CustomElement, Format, Hotkey, Mark } from './rich-text-editor-types';
import type { BaseEditor } from 'slate';
import type { HistoryEditor } from 'slate-history';
import type { ReactEditor } from 'slate-react';

import { Editor, Node, Range, Element as SlateElement, Transforms } from 'slate';

import { isUrl } from '../../utils';

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const HOTKEYS: Hotkey[] = [
  { key: 'mod+b', mark: 'bold' },
  { key: 'mod+i', mark: 'italic' },
  { key: 'mod+u', mark: 'underline' },
];

export const withInlines = (editor: Editor) => {
  const { insertData, isInline, normalizeNode } = editor;

  // Treat links as inline elements
  editor.isInline = (element) => element.type === 'link' || isInline(element);

  // Convert URLs into links when pasting text
  editor.insertData = (data) => {
    const href = data.getData('text/plain');

    if (isUrl(href)) createLink(editor, href, '');
    else insertData(data);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (SlateElement.isElement(node) && node.type === 'paragraph') {
      const children = Array.from(Editor.nodes(editor, { at: path }));

      for (const [child, childPath] of children) {
        // Remove link nodes whose text value is an empty string.
        // Empty text links happen when you move from the link to next line or delete the link line.
        if (SlateElement.isElement(child) && child.type === 'link' && child.children[0].text === '') {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] });
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }

          return;
        }
      }
    }

    normalizeNode(entry);
  };

  return editor;
};

export const isMarkActive = (editor: Editor, mark: Mark) => {
  const marks = Editor.marks(editor);

  if (!marks) return false;

  return Object.prototype.hasOwnProperty.call(marks, mark);
};

export const isBlockActive = (editor: Editor, format: Format) => {
  const { selection } = editor;

  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => !Editor.isEditor(node) && SlateElement.isElement(node) && node.type === format,
    })
  );

  return match !== undefined;
};

export const isLinkActive = (editor: Editor) => {
  const [match] = Array.from(
    Editor.nodes(editor, {
      match: (node) => SlateElement.isElement(node) && node.type === 'link',
    })
  );

  return match !== undefined;
};

export const toggleMark = (editor: Editor, mark: Mark) => {
  const isActive = isMarkActive(editor, mark);

  if (isActive) Editor.removeMark(editor, mark);
  else Editor.addMark(editor, mark, true);
};

export const toggleBlock = (editor: Editor, format: Format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (node) => !Editor.isEditor(node) && SlateElement.isElement(node) && LIST_TYPES.includes(node.type),
    split: true,
  });

  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) Transforms.wrapNodes(editor, { type: format, children: [] });
};

export const createLink = (editor: Editor, href: string, text?: string) => {
  const currentSelection = editor.selection;
  const isCollapsed = currentSelection && Range.isCollapsed(currentSelection);
  const link: CustomElement = {
    type: 'link',
    href,
    children: isCollapsed ? [{ text: text || href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const editLink = (editor: Editor, href: string, text?: string) => {
  if (!editor.selection || !text) return;

  Transforms.removeNodes(editor, { match: (node) => !Editor.isEditor(node) });
  createLink(editor, href, text);
};

export const removeLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) => SlateElement.isElement(node) && node.type === 'link',
  });
};

/**
 * Function to get the current selected Element.
 * @param editor
 * @returns The selected current Element
 */
export const getSelectedSlateElement = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
  // Path to the selected leaf node
  const currentSelectedPath = editor.selection?.focus.path;

  if (!currentSelectedPath) {
    return;
  }

  /**
   * The current selected path is the path to the final text node
   * We instead want the previous Node thas has the type information therefore we need
   * to get the path of its parent.
   */
  const pathToSelectedSlateElement = currentSelectedPath.slice(0, currentSelectedPath.length - 1);

  return Node.get(editor, pathToSelectedSlateElement);
};

export const multipleNodesSelected = (editor: BaseEditor & ReactEditor & HistoryEditor) => {
  if (editor.selection === null) {
    return false;
  }

  const focusedNodePath = editor.selection.focus.path;
  const anchoredNodePath = editor.selection.anchor.path;

  const focusedNode = Node.get(editor, focusedNodePath);
  const anchoredNode = Node.get(editor, anchoredNodePath);

  return focusedNode !== anchoredNode;
};
