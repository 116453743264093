export const CheckIcon = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    aria-hidden="true"
    fill="var(--primaryColor)"
    data-testid="check-icon"
    role="img"
  >
    <path d="M18 2 6 14 .5 8.5l1.41-1.41L6 11.17 16.59.59 18 2Z" />
  </svg>
);
