let supportsNativeHlsCached: boolean;

/**
 * Check if the browser supports native HLS playback.
 *
 * @remarks Code based on https://github.com/videojs/http-streaming/blob/ddc9374d7715fbbb9c0584de2a2c8fba0b7f29c0/src/videojs-http-streaming.js#L491
 * @returns {boolean}
 */
export function supportsNativeHls() {
  if (supportsNativeHlsCached !== undefined) {
    return supportsNativeHlsCached;
  }

  if (!document || !document.createElement) {
    return false;
  }

  const video = document.createElement('video');

  // HLS manifests can go by many mime-types
  const canPlay = [
    // Apple sanctioned
    'application/vnd.apple.mpegurl',
    // Apple sanctioned for backwards compatibility
    'audio/mpegurl',
    // Very common
    'audio/x-mpegurl',
    // Very common
    'application/x-mpegurl',
    // Included for completeness
    'video/x-mpegurl',
    'video/mpegurl',
    'application/mpegurl',
  ];

  return (supportsNativeHlsCached = canPlay.some(function (canItPlay) {
    return /maybe|probably/i.test(video.canPlayType(canItPlay));
  }));
}
