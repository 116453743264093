export const DownloadIcon = () => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="currentColor"
    data-testid="download-icon"
    aria-hidden="true"
    role="img"
  >
    <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" />
  </svg>
);
