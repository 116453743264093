/**
 * @param {string | undefined} url The url link to check
 * @default undefined
 * @returns {string} The correct url for link
 */
export function addDoubleSlashToUrl(url: string | undefined) {
  if (!url) return url;

  // Check if the URL starts with http:// or https://
  if (!/^https?:\/\//i.test(url)) {
    url = '//' + url;
  }
  return url;
}
