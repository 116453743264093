import type { VideoJsPlayer } from 'video.js';
import type { RampPluginOptions } from '../player.interface';

import { SourceType } from '../player.config';
import { ErrorCode, PlayerError } from '../player-errors';

import videojs from 'video.js';

let rampInstance: any;

export async function getRampSources(
  player: VideoJsPlayer,
  rampOptions: RampPluginOptions,
  fallbackUrl: string,
  abortSignal: AbortSignal
): Promise<videojs.Tech.SourceObject[]> {
  return new Promise(
    (resolve: (source: videojs.Tech.SourceObject[]) => void, reject: (reason: PlayerError) => void) => {
      abortSignal.addEventListener('abort', () => {
        reject(
          new PlayerError(ErrorCode.PLAYER_ERR_GET_SOURCE_ABORTED, 'The request for sources from ramp was aborted.')
        );
      });

      const onReady = (src: string) => {
        if (src) {
          resolve([{ src, type: SourceType.HLS }]);
        } else {
          reject(new PlayerError(ErrorCode.PLAYER_ERR_NO_SOURCE_DETECTED, 'Ramp is ready but no source was detected.'));
        }
      };

      if (!rampInstance) {
        const rcvrParams = createRcvrParams(player, rampOptions, fallbackUrl, onReady);
        rampInstance = new RcvrInterface(rcvrParams.i_param, rcvrParams.r_param, rcvrParams.o_param);
      } else {
        updateRamp(rampInstance, fallbackUrl, onReady);
      }
    }
  );
}

function updateRamp(rampInstance: any, fallbackUrl: string, onReady: (src: string) => void) {
  rampInstance.fallbackUrl = fallbackUrl;
  rampInstance.rcvrOnReady = (_: unknown, src: string, __: unknown) => onReady(src);
  rampInstance.fallbackProtocol(true);
}

function createRcvrParams(
  player: VideoJsPlayer,
  rampOptions: RampPluginOptions,
  fallbackUrl: string,
  onReady: (src: string) => void
) {
  const {
    p2pEnabled,
    vbrickHostName,
    vbrickJwtKeyName,
    vbrickApiKey,
    vbrickApiSecret,
    omnicacheUris,
    debug,
    id,
    title,
  } = rampOptions;

  return RcvrInterface.GetDefaults(
    true,
    {
      id: id,
      title: title,
      player,
      playerRootName: 'videojs',
      playerRoot: videojs,
      allowXOC: p2pEnabled && !!omnicacheUris, // Will enable both OmniCache + P2P when both are provided
      verbose: debug,
      allowFallback: true,
      fallbackUrl,
      analyticsUrl: '',
      allowExternStatic: false,
      debug,
      extern: {
        vendor: 'vbrick',
        magic: {
          hostName: vbrickHostName,
          jwtKeyName: vbrickJwtKeyName,
          apiKey: vbrickApiKey,
          apiSecret: vbrickApiSecret,
        },
      },
    },
    {
      maddr: '-1',
      onReady: (_: unknown, src: string, __: unknown) => onReady(src),
    },
    {
      verbose: debug,
      uri: omnicacheUris,
    }
  );
}

export function initializeRamp(): void {
  // TODO: Remove VideoJS from the global scope after ramp changes the API initialization (PLAY-286)
  if (!window.videojs) {
    window.videojs = videojs;
  }
  require('./../../../../libs/ramp/ramp-20240906.min');
}
