export type CssVariables = Record<string, string>;

/**
 * Appends a <style data-mi-custom-styles> with the passed in CSS variables to the document head.
 * @param {CssVariables} cssVariables - An object containing CSS variable names as keys and their values as values.
 * @param {string} theme - The theme to apply.
 * @example
 * addCssVariables({ '--custom-variable': '#ff00ff' });
 */
export function addCssVariables(cssVariables: CssVariables, theme?: string) {
  let styleElement: HTMLStyleElement | null = document.querySelector('style[data-mi-custom-styles]');

  if (!styleElement) {
    styleElement = document.createElement('style');
    styleElement.setAttribute('data-mi-custom-styles', '');
    document.head.appendChild(styleElement);
  }

  const cssContent = Object.entries(cssVariables)
    .map(([name, value]) => `${name}: ${value};`)
    .join(' ');

  if (theme) {
    styleElement.appendChild(document.createTextNode(`[data-theme="${theme.toLowerCase()}"] { ${cssContent} }`));
  } else {
    styleElement.appendChild(document.createTextNode(`:root { ${cssContent} }`));
  }
}
