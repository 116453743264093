import type { MutableRefObject } from 'react';
import { useState, useCallback } from 'react';
import { useOnResize } from './use-on-resize';

/**
 * Returns numeric DOMRect values of a DOM element. Values are recalculated on resize.
 * @param {MutableRefObject<HTMLElement | null>} elementRefToObserve A React ref to the DOM element that will have ResizeObserver attached to it.
 * @param {MutableRefObject<HTMLElement | null>} elementRefToMeasure A React ref to the DOM element that will be measured (defaults to the element that will be observed).
 * @returns {DOMRectReadOnly} Size and position of the element.
 */
export function useDynamicSize(
  elementRefToObserve: MutableRefObject<HTMLElement | null>,
  elementRefToMeasure = elementRefToObserve
) {
  const [size, setSize] = useState<DOMRectReadOnly>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    toJSON: () => null,
  });

  const handleResize = useCallback(() => {
    const elementToObserve = elementRefToObserve?.current;
    const elementToMeasure = elementRefToMeasure?.current;

    if (!elementToObserve || !elementToMeasure) return;

    setSize(elementToMeasure.getBoundingClientRect());
  }, [elementRefToMeasure, elementRefToObserve]);

  useOnResize(elementRefToObserve, handleResize);

  return size;
}
