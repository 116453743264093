import type { HTMLAttributes, KeyboardEvent, ReactNode } from 'react';
import type { Descendant } from 'slate';
import type { RenderElementProps, RenderLeafProps, RenderPlaceholderProps } from 'slate-react';

import {
  BoldIcon,
  ItalicIcon,
  LinkIcon,
  OrderedListIcon,
  UnderlineIcon,
  UnorderedListIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import i18next from 'i18next';
import isHotkey from 'is-hotkey';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { AddLinkButton, BlockButton, MarkButton } from './rich-text-editor-buttons';
import { HOTKEYS, toggleMark, withInlines } from './rich-text-editor-utils';
import { addDoubleSlashToUrl } from '../../utils';

import styles from './rich-text-editor.module.css';

type Props = {
  value?: Descendant[];
  readOnly?: boolean;
  autoFocus?: boolean;
  customButtons?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
  placeholder?: string;
  displayBoldMarkButton?: boolean;
  displayItalicMarkButton?: boolean;
  displayUnderlineMarkButton?: boolean;
  displayOrderedListMarkButton?: boolean;
  displayUnorderedListMarkButton?: boolean;
  displayLinkButton?: boolean;
  renderPlaceholder?: ((props: RenderPlaceholderProps) => JSX.Element) | undefined;
  onChange?: (value: Descendant[]) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>;

export function RichTextEditor({
  value,
  readOnly,
  autoFocus,
  customButtons,
  disabled,
  children,
  placeholder,
  displayBoldMarkButton = true,
  displayItalicMarkButton = true,
  displayUnderlineMarkButton = true,
  displayOrderedListMarkButton = true,
  displayUnorderedListMarkButton = true,
  displayLinkButton = true,
  renderPlaceholder,
  onChange,
  ...props
}: Props) {
  const { t } = useTranslation();

  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);
  const valueAvailable = value && value.length > 0;
  const toolbarVisible = valueAvailable && !readOnly;

  const detectHotkeys = (event: KeyboardEvent<HTMLDivElement>) => {
    const { nativeEvent } = event;

    HOTKEYS.forEach(({ key, mark }) => {
      if (!isHotkey(key, nativeEvent)) return;
      event.preventDefault();
      toggleMark(editor, mark);
    });
  };

  if (valueAvailable)
    return (
      <div {...props} className={classNames(styles.wrapper, readOnly && styles.readOnly, props.className)}>
        <Slate data-testid="slate" editor={editor} initialValue={value} onChange={onChange}>
          {toolbarVisible && (
            <div className={styles.toolbar} data-testid="toolbar">
              {displayBoldMarkButton && (
                <MarkButton
                  mark="bold"
                  data-testid="bold-button"
                  disabled={disabled}
                  aria-label={t('components.richTextEditor.bold')}
                  title={t('components.richTextEditor.bold')}
                >
                  <BoldIcon />
                </MarkButton>
              )}

              {displayItalicMarkButton && (
                <MarkButton
                  mark="italic"
                  data-testid="italic-button"
                  disabled={disabled}
                  title={t('components.richTextEditor.italic')}
                >
                  <ItalicIcon />
                </MarkButton>
              )}

              {displayUnderlineMarkButton && (
                <MarkButton
                  mark="underline"
                  data-testid="underline-button"
                  disabled={disabled}
                  title={t('components.richTextEditor.underline')}
                >
                  <UnderlineIcon />
                </MarkButton>
              )}

              {(displayBoldMarkButton || displayItalicMarkButton || displayUnderlineMarkButton) && (
                <span className={styles.separator} />
              )}

              {displayOrderedListMarkButton && (
                <BlockButton
                  format="numbered-list"
                  data-testid="numbered-list-button"
                  disabled={disabled}
                  aria-label={t('components.richTextEditor.numbered')}
                  title={t('components.richTextEditor.numbered')}
                >
                  <OrderedListIcon />
                </BlockButton>
              )}

              {displayUnorderedListMarkButton && (
                <BlockButton
                  format="bulleted-list"
                  data-testid="bulleted-list-button"
                  disabled={disabled}
                  aria-label={t('components.richTextEditor.bulletList')}
                  title={t('components.richTextEditor.bulletList')}
                >
                  <UnorderedListIcon />
                </BlockButton>
              )}

              {(displayOrderedListMarkButton || displayUnorderedListMarkButton) && (
                <span className={styles.separator} />
              )}

              {displayLinkButton && (
                <AddLinkButton
                  data-testid="add-link-button"
                  disabled={disabled}
                  aria-label={t('components.richTextEditor.addLink')}
                  title={t('components.richTextEditor.addLink')}
                >
                  <LinkIcon />
                </AddLinkButton>
              )}

              {customButtons}
            </div>
          )}

          {children}

          <Editable
            autoFocus={autoFocus}
            readOnly={readOnly || disabled}
            placeholder={placeholder}
            renderPlaceholder={renderPlaceholder}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={detectHotkeys}
            data-testid="editable"
          />
        </Slate>
      </div>
    );

  return (
    <div className={classNames(styles.wrapper, props.className)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

function Element(props: RenderElementProps) {
  const { element } = props;

  switch (element.type) {
    case 'bulleted-list':
      return <ul {...props} />;
    case 'list-item':
      return <li {...props} />;
    case 'numbered-list':
      return <ol {...props} />;
    case 'link':
      return (
        <a href={addDoubleSlashToUrl(element.href)} target="_blank" rel="noreferrer" className={styles.link} {...props}>
          {/* This explicit passing of children here is to silence ESLint's rule that says:
          "Anchors must have content and the content must be accessible by a screen reader" */}
          {props.children}
        </a>
      );
    default:
      return <p {...props} />;
  }
}

function Leaf({ attributes, children, leaf }: RenderLeafProps) {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.italic) children = <em>{children}</em>;
  if (leaf.underline) children = <u>{children}</u>;

  return (
    <span
      {...attributes}
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      style={leaf.text === '' ? { paddingLeft: '0.1px' } : undefined}
    >
      {children}
    </span>
  );
}

export function createInitialContent(value?: string | null): Descendant[] {
  return [{ type: 'paragraph', children: [{ text: value || '' }] }];
}

export function parseContent(value?: string | null, defaultValue = createInitialContent()): Descendant[] {
  if (!value) return defaultValue;

  try {
    const content = JSON.parse(value);
    return content.length > 0 ? content : defaultValue;
  } catch {
    return createInitialContent(i18next.t('components.richTextEditor.parsingError'));
  }
}
