import { createRoot } from 'react-dom/client';

import './index.css';
import '@movingimage-evp/mi-ui-component-library/src/styles/variables.css';
import '@movingimage-evp/mi-ui-component-library/src/styles/base.css';
import '@movingimage-evp/mi-ui-component-library/src/styles/fonts.css';

import { App } from './app';
import { ThemeToggle } from './components/theme-toggle';
import { FeatureToggle } from './service/feature-toggle/feature-toggle';

const container = document.getElementById('root');

if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

root.render(
  <>
    <App />

    <FeatureToggle featureName="themeToggle">
      <ThemeToggle />
    </FeatureToggle>
  </>
);
