import type { SelectOption } from '@movingimage-evp/mi-ui-component-library';
import type { FormEvent } from 'react';

import { InputDescription, Modal, SecondaryButton, Select, Spinner } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStreamingUsageReportLazyQuery } from '../../../../../generated/graphql-manager';
import { keycloak } from '../../../../../service/authentication/keycloak-provider';
import { downloadFile, generateArrayOfNumbers, getNumberOfDays } from '../../../../../utils';
import { useCurrentUser } from '../../../../hooks/current-user';
import { getTranslatedMonths } from '../plan-details-and-usage.helpers';

import styles from './create-report.module.css';

const today = new Date();
const currentYear = today.getFullYear();

type EditUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FormErrors = {
  month?: string;
  year?: string;
};

export function CreateReportModal({ isOpen, onClose }: EditUserModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { lsproId } = useCurrentUser();
  const [reportDate, setReportDate] = useState({ month: '', year: '' });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [getDownloadUrl] = useStreamingUsageReportLazyQuery({
    onCompleted: (data) => {
      if (data.streamingUsageReport.__typename === 'GetStreamingUsageReportSuccess') {
        downloadFile({
          url: data.streamingUsageReport.downloadUrl,
          fileName: `report-${lsproId}-${reportDate.year}-${reportDate.month.padStart(2, '0')}.csv`,
          token: keycloak.token,
        });

        handleModalClose();
      }
    },
  });

  const handleConfirm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (reportDate.year && reportDate.month) {
      const date = `${reportDate.year}-${reportDate.month.padStart(2, '0')}`;

      getDownloadUrl({
        variables: {
          lsproId,
          startDate: `${date}-01T00:00:00Z`,
          endDate: `${date}-${getNumberOfDays(Number(reportDate.year), Number(reportDate.month))}T00:00:00Z`,
        },
      });
    }

    if (!reportDate.month) {
      setFormErrors((state) => ({
        ...state,
        month: t('manager.channelSettings.planDetailsAndUsage.month.required'),
      }));
    }

    if (!reportDate.year) {
      setFormErrors((state) => ({
        ...state,
        year: t('manager.channelSettings.planDetailsAndUsage.year.required'),
      }));
    }

    setLoading(false);
  };

  const onCreateReportChange = (value: string, name: string) => {
    setReportDate({ ...reportDate, [name]: value });
    setFormErrors({ ...formErrors, [name]: undefined });
  };

  const handleModalClose = () => {
    onClose();
    setReportDate({ month: '', year: '' });
    setFormErrors({});
  };

  const monthOptions = generateArrayOfNumbers(12, 1).map((month) => ({
    key: String(month),
    label: getTranslatedMonths(t)[month - 1],
  }));

  const yearOptions = generateArrayOfNumbers(currentYear, 2015, true).map((year) => ({
    key: String(year),
    label: String(year),
  }));

  return (
    <Modal
      data-testid="create-report-modal"
      title={t('manager.channelSettings.planDetailsAndUsage.modalTitle')}
      isOpen={isOpen}
      onClose={handleModalClose}
      className={styles.container}
      contentProps={{ style: { overflow: 'visible' } }}
      footer={
        <>
          <SecondaryButton data-testid="cancel-create-report-button" type="button" onClick={handleModalClose}>
            {t('common.cancel')}
          </SecondaryButton>

          <SecondaryButton
            data-testid="confirm-create-report-button"
            type="submit"
            form="create-report-form"
            disabled={loading}
          >
            {loading ? <Spinner /> : t('common.create')}
          </SecondaryButton>
        </>
      }
    >
      <form id="create-report-form" className={styles.modalContent} onSubmit={handleConfirm}>
        <InputDescription
          inputId="month-select"
          errorMessage={formErrors.month}
          label={t('manager.channelSettings.planDetailsAndUsage.month.label')}
        >
          <Select
            data-testid="create-report-month"
            placeholder={t('manager.channelSettings.planDetailsAndUsage.month.placeholder')}
            optionsIdProperty="key"
            value={monthOptions.find((option) => option.key === reportDate.month)}
            options={monthOptions}
            onChange={(option: SelectOption) => onCreateReportChange(option.key, 'month')}
          />
        </InputDescription>

        <InputDescription
          inputId="year-select"
          errorMessage={formErrors.year}
          label={t('manager.channelSettings.planDetailsAndUsage.year.label')}
        >
          <Select
            data-testid="create-report-year"
            placeholder={t('manager.channelSettings.planDetailsAndUsage.year.placeholder')}
            optionsIdProperty="key"
            value={yearOptions.find((option) => option.key === reportDate.year)}
            options={yearOptions}
            onChange={(option: SelectOption) => onCreateReportChange(option.key, 'year')}
          />
        </InputDescription>
      </form>
    </Modal>
  );
}
