import type { WebcastOrder } from '../../../../../generated/graphql-manager';
import type { WebcastWithTitle } from '../../types';
import type { Props } from '../grid/grid';
import type { SortOrder } from '@movingimage-evp/mi-ui-component-library';
import type { KeyboardEvent, MouseEvent } from 'react';

import {
  PresentationIcon,
  Table,
  classNames,
  getNextSorting,
  usePrevious,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StateBadge } from '../../../../../components/state-badge';
import { State } from '../../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../../routes';
import { addParamToPath, convertStringOrderObject, formatEventStartDate } from '../../../../../utils';
import { Language } from '../../../../components/language';
import { useUserPermissions } from '../../../../hooks/user-permissions';
import { useUiState } from '../../../../providers/ui-state-provider/ui-state-provider';
import { ONDEMAND_STATE } from '../../webcasts';
import { ActionButtons } from '../action-buttons/action-buttons';

import styles from './list.module.css';

type WebcastOrderId = 'PLANNED_AT' | 'STATE';

export function ListView({ webcasts, orderBy, isRefetching, onDeleteWebcast, onDuplicateWebcast, onSort }: Props) {
  const { t } = useTranslation();
  const { isAccessToEventSetupAllowed } = useUserPermissions();
  const navigate = useNavigate();
  const routes = useAbsoluteRoutes();
  const { getEventsScrollPosition, saveUiState } = useUiState();
  const listRef = useRef<HTMLDivElement>(null);
  const wasRefetching = usePrevious(isRefetching);

  const handleNavigation = (webcastId: WebcastWithTitle['id'], state: WebcastWithTitle['state']) => {
    if (!isAccessToEventSetupAllowed) {
      navigate(addParamToPath(routes.studio_messaging, webcastId));
      return;
    }

    if (state === State.POSTLIVE || state === ONDEMAND_STATE) {
      navigate(addParamToPath(routes.eventSummary, webcastId));
      return;
    }

    navigate(addParamToPath(routes.webcastSetup_setupSummary, webcastId));
  };

  const handleRowClick = (event: MouseEvent, webcastId: WebcastWithTitle['id'], state: WebcastWithTitle['state']) => {
    if (event.target instanceof HTMLButtonElement) return;

    handleNavigation(webcastId, state);
  };

  const handleKeyDown = (event: KeyboardEvent, webcastId: WebcastWithTitle['id'], state: WebcastWithTitle['state']) => {
    if (event.key !== 'Enter') return;

    handleNavigation(webcastId, state);
  };

  const [sorting, setSorting] = useState<{ id: WebcastOrderId; order?: SortOrder } | undefined>(
    convertStringOrderObject(orderBy)
  );

  useEffect(() => {
    if (!sorting) return;

    const searchKey = sorting.order ? (sorting.id + '_' + sorting.order).toLocaleUpperCase() : undefined;

    onSort?.(searchKey as WebcastOrder);
  }, [onSort, sorting]);

  const handleSortChange = (columnId: WebcastOrderId) => {
    if (columnId !== sorting?.id) {
      setSorting({ id: columnId, order: 'asc' });
      return;
    }

    setSorting({ ...sorting, order: getNextSorting(sorting.order) });
  };

  const handleScroll = () => {
    saveUiState({ key: 'eventsScrollPosition', value: Math.round(listRef.current?.scrollTop || 0) });
  };

  useEffect(() => {
    listRef.current?.scrollTo({ top: getEventsScrollPosition(), behavior: 'smooth' });
  }, [getEventsScrollPosition]);

  useEffect(() => {
    if (wasRefetching && !isRefetching) {
      listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [isRefetching, wasRefetching]);

  return (
    <div className={classNames(styles.scroll, 'lspro-scrollbars')} ref={listRef} onScroll={handleScroll}>
      <Table data-testid="webcasts-table" className={styles.table}>
        <Table.Header sticky>
          <Table.Row>
            <Table.HeaderCell>{t('manager.webcasts.list.title')}</Table.HeaderCell>
            <Table.HeaderCell
              sorting={{
                onSortingChange: () => handleSortChange('PLANNED_AT'),
                order: sorting?.id === 'PLANNED_AT' ? sorting?.order : undefined,
              }}
            >
              {t('manager.webcasts.list.date')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorting={{
                onSortingChange: () => handleSortChange('STATE'),
                order: sorting?.id === 'STATE' ? sorting?.order : undefined,
              }}
            >
              {t('manager.webcasts.list.status')}
            </Table.HeaderCell>
            <Table.HeaderCell>{t('manager.webcasts.list.languages')}</Table.HeaderCell>
            <Table.HeaderCell>{t('manager.webcasts.list.presentations')}</Table.HeaderCell>
            <Table.HeaderCell>{t('manager.webcasts.list.actions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {webcasts?.map((webcast, index, webcastsArray) => {
            const {
              id,
              title,
              plannedStartAt,
              state,
              primaryLanguage,
              additionalLanguages,
              presentations,
              coverImageUrl,
            } = webcast;
            const isInitializing = state === State.INITIALIZING;
            const menuPosition = index === webcastsArray.length - 1 ? 'top-right' : 'right';
            const languages = [primaryLanguage, ...additionalLanguages];

            return (
              <Table.Row
                key={id}
                role="button"
                data-testid="webcast-row-information"
                highlightOnHover
                focusable={true}
                className={classNames(isInitializing && styles.rowDisabled)}
                onClick={(event) => handleRowClick(event, id, state)}
                onKeyDown={(event) => handleKeyDown(event, id, state)}
              >
                <Table.Cell>
                  <div className={styles.event} data-testid="webcast-title">
                    <div
                      className={styles.thumbnail}
                      data-testid="webcast-row-thumbnail"
                      style={coverImageUrl ? { backgroundImage: `url(${coverImageUrl})` } : undefined}
                    />

                    {title}
                  </div>
                </Table.Cell>

                <Table.Cell data-testid="webcast-planned-start">{formatEventStartDate(plannedStartAt)}</Table.Cell>

                <Table.Cell data-testid="webcast-state">
                  <StateBadge state={state} />
                </Table.Cell>

                <Table.Cell data-testid="webcast-languages">
                  {languages.map((language) => (
                    <Language key={language} language={language} />
                  ))}
                </Table.Cell>

                <Table.Cell data-testid="webcast-presentations">
                  {presentations.length > 0
                    ? presentations.map((presentation) => (
                        <PresentationIcon key={presentation.language} style={{ fill: 'var(--mi-neutral-85)' }} />
                      ))
                    : '-'}
                </Table.Cell>

                <Table.ActionCell>
                  <ActionButtons
                    webcast={webcast}
                    menuPosition={menuPosition}
                    onDeleteWebcast={onDeleteWebcast}
                    onDuplicateWebcast={onDuplicateWebcast}
                  />
                </Table.ActionCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
