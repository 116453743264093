import { AnimationEventHandler } from 'react';

type IconProps = { onAnimationEnd?: AnimationEventHandler | undefined };

export const StepBackIcon = ({ onAnimationEnd }: IconProps) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="#fff"
    aria-hidden="true"
    role="img"
    onAnimationEnd={onAnimationEnd}
    data-testid="step-back-icon"
  >
    <path d="M9.5 0c4.65 0 8.58 3.03 9.97 7.22L17.1 8A7.95 7.95 0 004.38 4.38L7 7H0V0l2.6 2.6C4.45 1 6.85 0 9.5 0zM7 9v10H5v-8H3V9h4zm8 2v6a2 2 0 01-2 2h-2a2 2 0 01-2-2v-6a2 2 0 012-2h2a2 2 0 012 2zm-4 0v6h2v-6h-2z" />
  </svg>
);
