const protocolAndDomain = /^(?:\w+:)?\/\/(\S+)$/;
const localhostDomain = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
const nonLocalhostDomain = /^[^\s.]+\.\S{2,}$/;

/**
 * Validate a URL string
 * @param {string} value
 * @return {boolean}
 */
export function isUrl(value: string) {
  if (typeof value !== 'string') return false;

  const match = value.match(protocolAndDomain);

  if (!match) return false;

  const everythingAfterProtocol = match[1];

  return localhostDomain.test(everythingAfterProtocol) || nonLocalhostDomain.test(everythingAfterProtocol);
}
