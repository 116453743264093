import en from './en.json';
import en_format from './en_format.json';

import de from './de.json';
import de_format from './de_format.json';

export const resources = {
  en: {
    translations: en,
    formats: en_format,
  },
  de: {
    translations: de,
    formats: de_format,
  },
  'de-DE': {
    translations: de,
    formats: de_format,
  },
};
