import type { GraphqlError } from './error-component';

import i18next from 'i18next';

export const getErrorMessage = (error: string): GraphqlError | undefined => {
  switch (error) {
    case 'UpdateWebcast':
    case 'CreateWebcast':
    case 'Validation':
    case 'UpdateAccessCodePolicy':
    case 'UpdateSingleSignOnPolicy':
    case 'UpdateEmailRegistrationPolicy':
    case 'UpdateEmailWhitelistPolicy':
    case 'UpdateReferrerProtectionPolicy':
    case 'CreateChat':
    case 'ChangeChatType':
      return {
        message: i18next.t('manager.webcastSetup.notification.error'),
      };
    case 'UpdateWebcastDescription':
      return {
        message: i18next.t('manager.webcastSetup.eventDescription.errors.descriptionSaveFailed'),
      };
    default:
      return undefined;
  }
};
