import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './live-badge.module.css';

type Props = {
  className?: string;
};

export function LiveBadge({ className }: Props) {
  const { t } = useTranslation();

  return (
    <div data-testid="live-badge" className={classNames(className, styles.liveBadge)}>
      {t('ui.live-badge.text')}
    </div>
  );
}
