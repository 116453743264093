/*
 * Our list of error codes. Divided into two groups:
 * - Media errors
 * - Player errors
 * Media errors are standard errors coming from the video element.
 * Player errors are custom errors coming from the player.
 */
export enum ErrorCode {
  // Player errors
  PLAYER_ERR_NOT_VALID = 'PLAYER_ERR_NOT_VALID',
  PLAYER_ERR_NOT_SUPPORTED = 'PLAYER_ERR_NOT_SUPPORTED',
  PLAYER_ERR_EVENT_NOT_SUPPORTED = 'PLAYER_ERR_EVENT_NOT_SUPPORTED',
  PLAYER_ERR_GET_SOURCE_ABORTED = 'PLAYER_ERR_GET_SOURCE_ABORTED',
  PLAYER_ERR_NO_SOURCE_DETECTED = 'PLAYER_ERR_NO_SOURCE_DETECTED',
  PLAYER_ERR_INIT_HIVE_P2P_FAILED = 'PLAYER_ERR_INIT_P2P_FAILED',
  PLAYER_ERR_AUTOPLAY_NOT_ALLOWED = 'PLAYER_ERR_AUTOPLAY_NOT_ALLOWED',
  PLAYER_ERR_AUTOPLAY_MUTED_NOT_ALLOWED = 'PLAYER_ERR_AUTOPLAY_MUTED_NOT_ALLOWED',
  PLAYER_ERR_AUTOPLAY_NOT_POSSIBLE = 'PLAYER_ERR_AUTOPLAY_NOT_POSSIBLE',
  PLAYER_ERR_AUTOPLAY_MUTED_NOT_POSSIBLE = 'PLAYER_ERR_AUTOPLAY_MUTED_NOT_POSSIBLE',
  UNKNOWN = 'UNKNOWN',
  // Media errors
  MEDIA_ERR_CUSTOM = 'MEDIA_ERR_CUSTOM',
  MEDIA_ERR_ABORTED = 'MEDIA_ERR_ABORTED',
  MEDIA_ERR_NETWORK = 'MEDIA_ERR_NETWORK',
  MEDIA_ERR_DECODE = 'MEDIA_ERR_DECODE',
  MEDIA_ERR_SRC_NOT_SUPPORTED = 'MEDIA_ERR_SRC_NOT_SUPPORTED',
  MEDIA_ERR_ENCRYPTED = 'MEDIA_ERR_ENCRYPTED',
  MEDIA_ERR_UNKNOWN = 'MEDIA_ERR_UNKNOWN',
}

export function getErrorLabel(errorCode: ErrorCode): string {
  switch (errorCode) {
    case ErrorCode.MEDIA_ERR_ABORTED:
      return 'ui.error.aborted.label';
    case ErrorCode.MEDIA_ERR_NETWORK:
      return 'ui.error.network.label';
    case ErrorCode.MEDIA_ERR_DECODE:
      return 'ui.error.decode.label';
    case ErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED:
      return 'ui.error.not.supported.label';
    case ErrorCode.MEDIA_ERR_ENCRYPTED:
      return 'ui.error.encrypted.label';
    default:
      return 'ui.error.unexpected.label';
  }
}

export function getErrorCodeFromMediaError(error: MediaError | null): ErrorCode {
  switch (error?.code) {
    case 0:
      return ErrorCode.MEDIA_ERR_CUSTOM;
    case 1:
      return ErrorCode.MEDIA_ERR_ABORTED;
    case 2:
      return ErrorCode.MEDIA_ERR_NETWORK;
    case 3:
      return ErrorCode.MEDIA_ERR_DECODE;
    case 4:
      return ErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED;
    case 5:
      return ErrorCode.MEDIA_ERR_ENCRYPTED;
    default:
      return ErrorCode.MEDIA_ERR_UNKNOWN;
  }
}

export function getCanReloadSources(errorCode: ErrorCode): boolean {
  return (
    errorCode !== ErrorCode.MEDIA_ERR_SRC_NOT_SUPPORTED &&
    errorCode !== ErrorCode.MEDIA_ERR_ENCRYPTED &&
    errorCode !== ErrorCode.MEDIA_ERR_DECODE
  );
}

export class PlayerError extends Error {
  constructor(
    public code: ErrorCode,
    message?: string
  ) {
    super(message);
    this.name = 'PlayerError';

    // As long the compiler doesn't support ES6, we need to set the prototype explicitly.
    Object.setPrototypeOf(this, PlayerError.prototype);
  }
}
