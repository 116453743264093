// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-badge_liveBadge__zSIz- {
  display: flex;
  align-items: center;

  padding: 5px 14px 5px 10px;

  background: var(--backgroundColor);
  color: var(--textColor);

  border-radius: 2px;

  font-size: 12px;
  line-height: 15px;
}

.live-badge_liveBadge__zSIz-::before {
  content: '';

  display: block;
  width: 4px;
  height: 4px;
  margin-right: 10px;

  background: red;
  border-radius: 50%;

  animation: live-badge_blink__1jB\\+Z 1s ease-in-out infinite alternate;
}

@media (prefers-reduced-motion) {
  .live-badge_liveBadge__zSIz-::before {
    animation: none;
  }
}

@keyframes live-badge_blink__1jB\\+Z {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/live-badge/live-badge.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;;EAEnB,0BAA0B;;EAE1B,kCAAkC;EAClC,uBAAuB;;EAEvB,kBAAkB;;EAElB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;;EAEX,cAAc;EACd,UAAU;EACV,WAAW;EACX,kBAAkB;;EAElB,eAAe;EACf,kBAAkB;;EAElB,qEAAkD;AACpD;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".liveBadge {\n  display: flex;\n  align-items: center;\n\n  padding: 5px 14px 5px 10px;\n\n  background: var(--backgroundColor);\n  color: var(--textColor);\n\n  border-radius: 2px;\n\n  font-size: 12px;\n  line-height: 15px;\n}\n\n.liveBadge::before {\n  content: '';\n\n  display: block;\n  width: 4px;\n  height: 4px;\n  margin-right: 10px;\n\n  background: red;\n  border-radius: 50%;\n\n  animation: blink 1s ease-in-out infinite alternate;\n}\n\n@media (prefers-reduced-motion) {\n  .liveBadge::before {\n    animation: none;\n  }\n}\n\n@keyframes blink {\n  from {\n    opacity: 1;\n  }\n\n  to {\n    opacity: 0.1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"liveBadge": `live-badge_liveBadge__zSIz-`,
	"blink": `live-badge_blink__1jB+Z`
};
export default ___CSS_LOADER_EXPORT___;
