import { CheckIcon, SubHeading, Tab, Tabs } from '@movingimage-evp/mi-ui-component-library';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AssetUploadStatusCode, EventStage, useGetWebcastMediaQuery } from '../../../../../generated/graphql-manager';
import { getLanguageKey } from '../../../../../utils';
import { useUserPermissions } from '../../../../hooks/user-permissions';
import { CoverMediaUploader } from '../cover-media-uploader';
import { MediaAccordion } from '../media-accordion';
import styles from '../media.module.css';

const COVER_IMAGE_POLL_INTERVAL = 2000;

type Props = {
  eventStage: EventStage;
  onLoadStart: () => void;
  onLoadEnd: () => void;
};

export function MediaContent({ eventStage, onLoadStart, onLoadEnd }: Props) {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const { isEventEditingAllowed } = useUserPermissions();

  const { data, loading, refetch, startPolling, stopPolling } = useGetWebcastMediaQuery({
    variables: { webcastId },
    skip: !webcastId,
    fetchPolicy: 'no-cache',
  });

  const primaryLanguage = data?.webcast.primaryLanguage || '';
  const additionalLanguages = data?.webcast.additionalLanguages || [];
  const languages = [primaryLanguage, ...additionalLanguages];

  const key = eventStage === EventStage.PRELIVE ? 'preLive' : 'postLive';

  const contents = data?.webcast.contents;
  const coverImagePending = contents?.some(
    (content) => content[key]?.coverImage?.status.code === AssetUploadStatusCode.PENDING
  );

  useEffect(() => {
    if (!coverImagePending) stopPolling();
  }, [coverImagePending, stopPolling]);

  useEffect(() => () => stopPolling(), [stopPolling]);

  return (
    <>
      {!loading && contents && (
        <div className={styles.wrapper}>
          <SubHeading>{t(`manager.webcastSetup.media.subHeading.${key}`)}</SubHeading>

          {languages.length === 1 && (
            <CoverMediaUploader
              testId={key}
              language={primaryLanguage}
              eventStage={eventStage}
              coverImage={contents[0][key]?.coverImage}
              coverVideo={contents[0][key]?.video}
              disabled={!isEventEditingAllowed}
              onLoadStart={onLoadStart}
              onLoadEnd={onLoadEnd}
              refetch={(options) => {
                if (options?.poll) {
                  startPolling(COVER_IMAGE_POLL_INTERVAL);
                } else {
                  refetch();
                }
              }}
            />
          )}

          {languages.length > 1 && (
            <Tabs compact={languages.length <= 4} data-testid={`${key}-media-tabs`}>
              {languages.map((language) => {
                const translatedLanguage = t(
                  `manager.webcastSetup.language.supportedLanguages.${getLanguageKey(language)}`
                );

                const label =
                  language === primaryLanguage
                    ? t('manager.webcastSetup.media.primaryLanguage', { primaryLanguage: translatedLanguage })
                    : translatedLanguage;

                const content = contents.find((content) => content.language === language);
                const data = content?.[key];
                const coverImagePending = data?.coverImage?.status.code === AssetUploadStatusCode.PENDING;
                const contentAvailable = Boolean(data?.coverImage?.assetId || data?.video?.videoId);
                const hideAccordion = contentAvailable && !coverImagePending;

                return (
                  <Tab
                    key={language}
                    data-testid={`${key}-tab-${language}`}
                    label={
                      <>
                        {label}
                        {contentAvailable && <CheckIcon className={styles.presentationExistsIcon} />}
                      </>
                    }
                  >
                    <MediaAccordion testId={`${key}-${language}`} visible={!hideAccordion} key={language}>
                      <CoverMediaUploader
                        key={language}
                        testId={`${key}-${language}`}
                        language={language}
                        eventStage={eventStage}
                        coverImage={data?.coverImage}
                        coverVideo={data?.video}
                        disabled={!isEventEditingAllowed}
                        onLoadStart={onLoadStart}
                        onLoadEnd={onLoadEnd}
                        refetch={(options) => {
                          if (options?.poll) {
                            startPolling(COVER_IMAGE_POLL_INTERVAL);
                          } else {
                            refetch();
                          }
                        }}
                      />
                    </MediaAccordion>
                  </Tab>
                );
              })}
            </Tabs>
          )}
        </div>
      )}
    </>
  );
}
