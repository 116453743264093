import { SourceType } from '../components/player/player.config';

/**
 * Checks if the source type is HLS.
 * @param {string} sourceType The source type.
 * @returns {boolean} True if the source type is one of the HLS source types.
 * @example
 * isHLSSource('application/vnd.apple.mpegurl') // true
 * isHLSSource('video/mp4') // false
 */
export function isHLSSource(sourceType: string): boolean {
  return sourceType === SourceType.APPLE_HLS || sourceType === SourceType.HLS;
}
