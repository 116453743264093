import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { GearIcon } from '../../icons/gear';
import commonStyles from '../../styles/common.module.css';
import styles from './settings-menu-button.module.css';

type Props = {
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
};

export function SettingsMenuButton({ isActive, className, onClick }: Props) {
  const { t } = useTranslation();

  const label = isActive ? t('ui.menu.button.label.active') : t('ui.menu.button.label.inactive');

  return (
    <button
      data-testid="settings-menu-button"
      type="button"
      name="settings-menu-button"
      aria-expanded={isActive}
      aria-haspopup="true"
      className={classNames(commonStyles.button, styles.menuButton, { [styles.active]: isActive }, className)}
      onClick={onClick}
    >
      <Tooltip>{label}</Tooltip>
      <GearIcon />
    </button>
  );
}
