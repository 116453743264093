import type { ReactNode } from 'react';

import styles from './tooltip.module.css';
import classNames from 'classnames';

type Props = {
  children?: ReactNode;
  direction?: 'top' | 'bottom';
};

export function Tooltip({ children, direction = 'top' }: Props) {
  return (
    <span className={classNames(styles.tooltip, { [styles.alignBottom]: direction === 'bottom' })}>{children}</span>
  );
}
