import type { Channel } from '../../../generated/graphql-manager';

import {
  Checkbox,
  ChevronIcon,
  FolderIcon,
  IconButton,
  SitemapIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getIdPathForChannel } from '../../pages/webcast-setup/event-summary/event-summary-utils';

import styles from './channels-list.module.css';

type ChannelsListProps = {
  currentChannelId: Channel['id'];
  initialChannelPath?: string;
  channels?: Channel[];
  rootChannelLabel?: string;
  className?: string;
  onChannelClick: (channelId: Channel['id']) => void;
  onChannelSelect?: (channel: Channel) => void;
};

export function ChannelsList({
  currentChannelId,
  initialChannelPath,
  channels,
  rootChannelLabel,
  className,
  onChannelClick,
  onChannelSelect,
}: ChannelsListProps) {
  if (!channels) return null;

  const rootChannel = channels[0];

  return (
    <div data-testid="video-manager-browser-channels-list" className={classNames(styles.list, className)}>
      <div className={classNames(styles.scroller, 'lspro-scrollbars')}>
        <Item
          isRootChannel
          key={rootChannel?.id}
          data-testid={`video-manager-browser-channel-${rootChannel?.id}`}
          channel={rootChannel}
          allChannels={channels}
          subchannelIds={rootChannel?.children}
          onClick={onChannelClick}
          onSelect={onChannelSelect}
          label={rootChannelLabel || ''}
          currentChannelId={currentChannelId}
          subchannelNumber={0}
          initialChannelPath={initialChannelPath}
        />
      </div>
    </div>
  );
}

type ItemProps = {
  channel?: Channel;
  allChannels?: Channel[];
  initialChannelPath?: string;
  selected?: boolean;
  label?: string;
  isRootChannel?: boolean;
  subchannelNumber?: number;
  currentChannelId?: Channel['id'];
  subchannelIds?: Channel['children'];
  onClick?: (channelId: Channel['id']) => void;
  onSelect?: (channel: Channel) => void;
};

export function Item({
  channel,
  allChannels,
  initialChannelPath,
  selected,
  label,
  subchannelIds,
  isRootChannel,
  currentChannelId,
  subchannelNumber = 0,
  onClick,
  onSelect,
}: ItemProps) {
  const { t } = useTranslation();

  const currentChannelPath = channel && allChannels ? getIdPathForChannel(channel, allChannels) : undefined;
  const subchannelsInitiallyOpen = currentChannelPath ? initialChannelPath?.startsWith(currentChannelPath) : false;

  const [subchannelsOpen, setSubchannelsOpen] = useState(subchannelsInitiallyOpen || isRootChannel);

  if (!channel) return null;

  const toggleSubchannelsList = () => {
    setSubchannelsOpen((state) => !state);
  };

  const handleChannelClick = () => {
    onClick?.(channel.id);
  };

  const handleOnSelect = () => {
    onSelect?.(channel);
  };

  const hasChildren = channel?.children !== undefined && channel.children.length > 0;

  const subchannelChevronButton = hasChildren && !isRootChannel && (
    <IconButton
      rounded
      aria-label={t('components.videoManager.videoBrowser.arialLabelSubchannels')}
      onClick={toggleSubchannelsList}
      data-testid={`video-manager-browser-channel-chevron-button-${channel.id}`}
    >
      <ChevronIcon className={classNames(subchannelsOpen && styles.rotated)} />
    </IconButton>
  );

  return (
    <>
      <div
        className={classNames(styles.item, currentChannelId === channel.id && !onSelect && styles.selected)}
        style={{ paddingLeft: `${28 * subchannelNumber}px` }}
        data-testid={`video-manager-browser-channel-${channel.id}-wrapper`}
      >
        {onSelect && (
          <div className={styles.itemSelected}>
            {subchannelChevronButton}

            {isRootChannel && <SitemapIcon />}

            <Checkbox
              data-testid={`video-manager-browser-channel-${channel.id}-checkbox`}
              checked={selected}
              onChange={handleOnSelect}
              style={{ paddingLeft: `${!hasChildren && subchannelNumber > 1 ? 14 : 0}px` }}
            />

            <FolderIcon />

            <span className={styles.label}>{label || channel.name}</span>
          </div>
        )}

        {!onSelect && (
          <>
            {subchannelChevronButton}

            {isRootChannel && (
              <IconButton
                rounded
                aria-label={t('components.videoManager.videoBrowser.arialLabelChannels')}
                onClick={toggleSubchannelsList}
                style={{ paddingLeft: 28 }}
              >
                <SitemapIcon />
              </IconButton>
            )}

            <button
              data-testid={`video-manager-browser-channel-${channel.id}`}
              id={`video-manager-browser-channel-${channel.id}`}
              className={styles.itemButton}
              disabled={!onClick}
              onClick={handleChannelClick}
            >
              {label || channel.name}
            </button>
          </>
        )}
      </div>

      {subchannelsOpen && (
        <>
          {subchannelIds?.map((id) => {
            const channel = allChannels?.find((channel) => channel.id === id);

            return (
              <Item
                key={id}
                channel={channel}
                allChannels={allChannels}
                subchannelNumber={subchannelNumber + 1}
                subchannelIds={channel?.children}
                selected={selected}
                label={channel?.name}
                currentChannelId={currentChannelId}
                onClick={onClick}
                onSelect={onSelect}
                initialChannelPath={initialChannelPath}
              />
            );
          })}
        </>
      )}
    </>
  );
}
