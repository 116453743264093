import React from 'react';

import styles from './menu.module.css';
import classNames from 'classnames';

interface MenuItemProps extends React.ComponentProps<'button'> {
  label?: React.ReactNode;
  currentValue?: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = ({ label, currentValue, onClick, leftIcon, rightIcon, ...props }) => {
  return (
    <button
      {...props}
      type="button"
      role="menuitem"
      className={classNames(styles.menuItem, props.className)}
      onClick={onClick}
    >
      {leftIcon && <span className={classNames(styles.icon, styles.leftIcon)}>{leftIcon}</span>}

      <div className={styles.menuItemLabel}>
        <span className={styles.menuItemLabelText}>{label}</span>
      </div>

      {currentValue}

      {rightIcon && <span className={classNames(styles.icon, styles.rightIcon)}>{rightIcon}</span>}
    </button>
  );
};

export default MenuItem;
