/**
 * Adds or updates a meta property in the HTML head.
 *
 * @param {('name' | 'property')} attribute - The attribute name of the meta tag.
 * @param {string} value - The value of the attribute.
 * @param {string} content - The content of the meta tag.
 * @return {void} This function does not return anything.
 */
export function addMetaProperty(attribute: 'name' | 'property', value: string, content: string) {
  const metaTag = document.querySelector(`meta[${attribute}="${value}"]`);

  if (metaTag) {
    metaTag.setAttribute('content', content);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute(attribute, value);
    meta.setAttribute('content', content);
    document.head.appendChild(meta);
  }
}
