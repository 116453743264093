import type { ButtonHTMLAttributes, FormEvent, MouseEvent } from 'react';

import {
  LinkIcon,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
  TypographyIcon,
  UnlinkIcon,
  classNames,
  useOnClickOutside,
} from '@movingimage-evp/mi-ui-component-library';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { type Format, type Mark, isSlateLink } from './rich-text-editor-types';
import {
  createLink,
  editLink,
  getSelectedSlateElement,
  isBlockActive,
  isMarkActive,
  multipleNodesSelected,
  removeLink,
  toggleBlock,
  toggleMark,
} from './rich-text-editor-utils';

import styles from './rich-text-editor.module.css';

export function EditorButton({
  isActive,
  onMouseDown,
  title,
  tooltipHidden,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean;
  tooltipHidden?: boolean;
  title?: string;
  onMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <Tooltip label={title} hidden={tooltipHidden}>
      <button
        type="button"
        className={classNames(styles.button, isActive && styles.active)}
        onMouseDown={(event) => {
          // Prevent editor's focus loss
          event.preventDefault();
          onMouseDown?.(event);
        }}
        {...props}
      />
    </Tooltip>
  );
}

export function BlockButton({ format, ...props }: ButtonHTMLAttributes<HTMLButtonElement> & { format: Format }) {
  const editor = useSlate();

  return (
    <EditorButton isActive={isBlockActive(editor, format)} onMouseDown={() => toggleBlock(editor, format)} {...props} />
  );
}

export function MarkButton({ mark, ...props }: ButtonHTMLAttributes<HTMLButtonElement> & { mark: Mark }) {
  const editor = useSlate();

  return <EditorButton isActive={isMarkActive(editor, mark)} onMouseDown={() => toggleMark(editor, mark)} {...props} />;
}

export function AddLinkButton({ children, disabled, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation();
  const editor = useSlate();
  const [formMode, setFormMode] = useState<'create' | 'edit'>();
  const [linkText, setLinkText] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const menuVisible = formMode !== undefined;

  const menuRef = useRef<HTMLFormElement>(null);

  const openMenu = () => {
    setLinkText(Editor.string(editor, editor.selection || []));
    setFormMode('create');
  };

  const closeMenu = useCallback(() => {
    setLinkUrl('');
    setLinkText('');
    setFormMode(undefined);
  }, []);

  const handleCancel = () => {
    closeMenu();
    ReactEditor.deselect(editor);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formMode === 'create') createLink(editor, linkUrl, linkText);
    else editLink(editor, linkUrl, linkText);

    ReactEditor.blur(editor);
    ReactEditor.deselect(editor);
    closeMenu();
  };

  useEffect(() => {
    if (multipleNodesSelected(editor)) {
      closeMenu();
      return;
    }

    const selectedNode = getSelectedSlateElement(editor);
    if (!selectedNode || !isSlateLink(selectedNode)) {
      if (formMode === 'edit') {
        closeMenu();
      }
      return;
    }

    setLinkUrl(selectedNode.href || '');
    setLinkText(selectedNode.children[0].text || '');
    setFormMode('edit');
  }, [closeMenu, editor, editor.selection, formMode]);

  useOnClickOutside(menuRef, handleCancel);

  return (
    <div className={styles.addLinkButtonWrapper}>
      <EditorButton
        isActive={menuVisible}
        tooltipHidden={menuVisible}
        onClick={menuVisible ? handleCancel : openMenu}
        disabled={disabled || multipleNodesSelected(editor)}
        {...props}
      >
        {children}
      </EditorButton>

      {menuVisible && (
        <form className={styles.linkDropdown} onSubmit={handleSubmit} ref={menuRef} data-testid="link-form">
          <div className={styles.row}>
            <LinkIcon />
            <span className={styles.separator} />

            <input
              data-testid="add-url"
              placeholder={t('components.richTextEditor.addLinkUrlPlaceholder')}
              value={linkUrl}
              autoFocus
              onChange={(event) => setLinkUrl(event.target.value)}
            />

            {formMode === 'edit' && (
              <EditorButton
                data-testid="remove-link-button"
                aria-label={t('components.richTextEditor.removeLink')}
                title={t('components.richTextEditor.removeLink')}
                onClick={closeMenu}
                onMouseDown={() => removeLink(editor)}
              >
                <UnlinkIcon />
              </EditorButton>
            )}
          </div>

          <div className={styles.row}>
            <TypographyIcon />
            <span className={styles.separator} />

            <input
              data-testid="add-link-text"
              placeholder={t('components.richTextEditor.addLinkTextPlaceholder')}
              value={linkText}
              onChange={(event) => setLinkText(event.target.value)}
            />
          </div>

          <div className={styles.row}>
            <SecondaryButton type="button" small onClick={handleCancel} data-testid="cancel-button">
              {t('common.cancel')}
            </SecondaryButton>

            <PrimaryButton type="submit" small disabled={!linkUrl} data-testid="submit-button">
              {formMode === 'create' ? t('common.insert') : t('common.set')}
            </PrimaryButton>
          </div>
        </form>
      )}
    </div>
  );
}
