import { Heading, Paragraph, SecondaryButton, Spinner } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateReportModal } from './create-report-modal/create-report';
import { getTranslatedMonths } from './plan-details-and-usage.helpers';
import { useStreamingUsageQuery } from '../../../../generated/graphql-manager';
import { PlanUsage } from '../../../components/plan-usage/plan-usage';
import { useCurrentUser } from '../../../hooks/current-user';
import managerStyles from '../../../manager.module.css';

import styles from './plan-details-and-usage.module.css';

export function PlanDetailsAndUsagePage() {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const [isCreateReportModalOpened, setIsCreateReportModalOpened] = useState(false);

  const { data, loading } = useStreamingUsageQuery({ variables: { lsproId } });
  const streamingUsage = data?.streamingUsage?.__typename === 'GetMonthlyStreamingUsageResult' && data?.streamingUsage;

  return (
    <>
      <main className={managerStyles.main} data-testid="plan-details-and-usage-page">
        <div className={styles.block}>
          <Paragraph data-testid="plan-details-and-usage-page-title">
            {t('manager.channelSettings.planDetailsAndUsage.title')}
          </Paragraph>

          <Heading data-testid="plan-details-and-usage-page-heading">
            {t('manager.channelSettings.planDetailsAndUsage.heading')}
          </Heading>

          <Paragraph data-testid="plan-details-and-usage-page-paragraph">
            {t('manager.channelSettings.planDetailsAndUsage.paragraph')}
          </Paragraph>
        </div>

        {loading ? (
          <Spinner style={{ margin: 'auto' }} />
        ) : (
          streamingUsage && (
            <div className={styles.block}>
              <Paragraph data-testid="plan-details-and-usage-date">
                {t('manager.channelSettings.planDetailsAndUsage.dateUsage', {
                  month: getTranslatedMonths(t)[streamingUsage.month - 1],
                  year: streamingUsage.year,
                })}
              </Paragraph>
              <PlanUsage
                usedMinutes={streamingUsage.usedMinutes}
                quota={streamingUsage.quota.value}
                style={{ marginTop: 60 }}
              />
            </div>
          )
        )}

        <div className={managerStyles.buttons}>
          <SecondaryButton
            data-testid="plan-details-and-usage-create-report"
            onClick={() => setIsCreateReportModalOpened(true)}
          >
            {t('manager.channelSettings.planDetailsAndUsage.button')}
          </SecondaryButton>
        </div>
      </main>

      <CreateReportModal isOpen={isCreateReportModalOpened} onClose={() => setIsCreateReportModalOpened(false)} />
    </>
  );
}
