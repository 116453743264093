import type { GetWebcastSummaryManagerQuery } from '../../../../../../generated/graphql-manager';

import { InfoCircleIcon, Paragraph, Tooltip } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import styles from './event-summary-upload.module.css';

type EventSummaryRecordingProps = {
  webcast: GetWebcastSummaryManagerQuery['webcast'];
  isMultilanguages?: boolean;
};

export function EventSummaryUpload({ webcast, isMultilanguages }: EventSummaryRecordingProps) {
  const { t } = useTranslation();

  const uploadChannels = webcast?.recordingSettings?.videoManager?.channels;
  const uploadToVmProEnabled = uploadChannels && uploadChannels.length > 0;

  return (
    <div data-testid="upload-container" className={styles.container}>
      {uploadToVmProEnabled && (
        <>
          <div className={styles.headerWrapper}>
            <Paragraph data-testid="upload-header">{t('manager.eventSummary.recording.recordingPathTitle')}</Paragraph>

            {isMultilanguages && (
              <Tooltip label={t('manager.eventSummary.recording.recordingPathHint')}>
                <InfoCircleIcon className={styles.hint} />
              </Tooltip>
            )}
          </div>

          <div className={styles.uploadPath}>
            {uploadChannels?.map((channel) => (
              <Tooltip label={channel.name} key={channel.id}>
                <Paragraph
                  key={channel.name}
                  data-testid={`recording-channel-${channel.name}`}
                  className={styles.pathName}
                >
                  {channel.name}
                </Paragraph>
              </Tooltip>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
