// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-badge_wrapper__Wp0D- {
  flex-shrink: 0;
  padding: 2px 10px 2px 10px;
  border-width: 0;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
}
.time-badge_wrapper__Wp0D-.time-badge_active__sLI8V {
    background: var(--primaryColor);
  }
`, "",{"version":3,"sources":["webpack://./src/components/time-badge/time-badge.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,8BAA8B;EAC9B,eAAe;EACf,gBAAgB;EAChB,cAAc;AAKhB;AAHE;IACE,+BAA+B;EACjC","sourcesContent":[".wrapper {\n  flex-shrink: 0;\n  padding: 2px 10px 2px 10px;\n  border-width: 0;\n  border-radius: 3px;\n  background: rgba(0, 0, 0, 0.5);\n  font-size: 12px;\n  font-weight: 700;\n  color: #ffffff;\n\n  &.active {\n    background: var(--primaryColor);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `time-badge_wrapper__Wp0D-`,
	"active": `time-badge_active__sLI8V`
};
export default ___CSS_LOADER_EXPORT___;
