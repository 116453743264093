import i18next from 'i18next';

import { validEmail } from '../../../../utils';

export function inviteUserEmailValidation(value: string) {
  if (!value) {
    return i18next.t('translations:users.inviteUserModal.validation.required');
  } else if (!validEmail(value)) {
    return i18next.t('translations:users.inviteUserModal.validation.email');
  } else {
    return '';
  }
}
