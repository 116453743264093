import type { Role } from '../../../../generated/graphql-manager';
import type { ChangeEvent } from 'react';

import {
  InputDescription,
  Modal,
  PlusIcon,
  PrimaryButton,
  SecondaryButton,
  Spinner,
  TextInput,
} from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { inviteUserEmailValidation } from './invite-user-modal.validation';
import { GetUsersDocument as GET_USERS, useInviteUserMutation } from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';
import { RoleSelector } from '../components/role-select/role-select';

import styles from './invite-user-modal.module.css';

type InviteUserModalProps = {
  isOpen: boolean;
  className: string;
  onClose: () => void;
};

export function InviteUserModal({ isOpen, className, onClose }: InviteUserModalProps) {
  const { t } = useTranslation();
  const { lsproId, currentAccountName } = useCurrentUser();
  const [roles, setRoles] = useState<Role[]>([]);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const isConfirmationAvailable = email && roles.length > 0;

  const [inviteUserMutation] = useInviteUserMutation();

  const handleConfirm = () => {
    setLoading(true);

    const validationError = inviteUserEmailValidation(email);
    setError(validationError);

    if (validationError) {
      setLoading(false);
      return;
    }

    inviteUserMutation({
      variables: {
        input: {
          lsproId,
          email,
          roles,
        },
      },
      refetchQueries: [{ query: GET_USERS, variables: { lsproId } }],
      onCompleted: (data) => {
        if (data.inviteUser.__typename === 'InviteUserProblem') {
          if (data.inviteUser.code === 'USER_ALREADY_PRESENT') {
            setError(t('users.inviteUserModal.validation.alreadyPresent'));
            setLoading(false);
          }

          return;
        }
        handleOnClose();
      },
    });
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setEmail(event.currentTarget.value);
    setError('');
  };

  const handleOnClose = () => {
    setEmail('');
    setError('');
    setRoles([]);
    setLoading(false);
    onClose();
  };

  const handleRolesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name as Role;
    const addedRole = event.currentTarget.checked && name;

    setRoles((state) => {
      if (addedRole) return [...state, addedRole];
      return state.filter((role) => role !== name);
    });
  };

  return (
    <Modal
      data-testid="invite-user-modal"
      title={t('users.inviteUserModal.title', { organization: currentAccountName })}
      isOpen={isOpen}
      onClose={handleOnClose}
      className={className}
      footer={
        <>
          <PrimaryButton
            data-testid="confirm-invite-user-button"
            type="button"
            disabled={loading || !isConfirmationAvailable}
            onClick={handleConfirm}
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                {t('users.inviteUserModal.confirmButton')} <PlusIcon />
              </>
            )}
          </PrimaryButton>

          <SecondaryButton data-testid="cancel-invite-user-button" type="button" onClick={handleOnClose}>
            {t('common.cancel')}
          </SecondaryButton>
        </>
      }
    >
      <div className={styles.modalContent}>
        <InputDescription
          inputId="invite-user-email"
          label={t('users.inviteUserModal.emailLabel')}
          errorMessage={error}
        >
          <TextInput data-testid="invite-user-email" type="email" autoFocus value={email} onChange={onEmailChange} />
        </InputDescription>

        <p>{t('users.inviteUserModal.paragraph')}</p>

        <RoleSelector selectedRoles={roles} onSelectedRolesChange={handleRolesChange} />
      </div>
    </Modal>
  );
}
