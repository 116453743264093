import { useEffect, useState } from 'react';

import videojs, { VideoJsPlayer } from 'video.js';
import { VideoJsNativeEvent } from '../videojs-event';
import { AudioTrack } from '../../language-menu';

export function useAudioTracks(player: VideoJsPlayer): AudioTrack[] {
  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);

  useEffect(() => {
    let audioTrackList: videojs.AudioTrackList;

    function onAddTrack(event: any) {
      // Default audio tracks have empty language
      if (event?.track?.language === '') {
        return;
      }

      if (event?.track) {
        const track: AudioTrack = {
          id: event.track.id,
          label: event.track.label,
          kind: event.track.kind,
          enabled: event.track.enabled,
          language: event.track.language,
        };
        setAudioTracks((prevState) => [...prevState, track]);
      }
    }

    const onLoadSource = () => {
      audioTrackList?.removeEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);

      audioTrackList = player.audioTracks();
      audioTrackList?.addEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
    };
    player.on(VideoJsNativeEvent.LOAD_START, onLoadSource);

    return () => {
      audioTrackList?.removeEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
      player.off(VideoJsNativeEvent.LOAD_START, onLoadSource);
    };
  }, [player]);

  return audioTracks;
}
