import { useEffect, useState } from 'react';
import { VideoJsPlayer } from 'video.js';
import { VideoJsNativeEvent } from '../videojs-event';

export function useCanPlay(player: VideoJsPlayer): boolean {
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    const onCanPlay = () => {
      setCanPlay(true);
    };

    const onCanPlayThrough = () => {
      setCanPlay(true);
    };

    const onWaiting = () => {
      setCanPlay(false);
    };

    player.on(VideoJsNativeEvent.CAN_PLAY, onCanPlay);
    player.on(VideoJsNativeEvent.CAN_PLAY_THROUGH, onCanPlayThrough);
    player.on(VideoJsNativeEvent.WAITING, onWaiting);

    return () => {
      player.off(VideoJsNativeEvent.CAN_PLAY, onCanPlay);
      player.off(VideoJsNativeEvent.CAN_PLAY_THROUGH, onCanPlayThrough);
      player.off(VideoJsNativeEvent.WAITING, onWaiting);
    };
  }, [player, setCanPlay]);

  return canPlay;
}
