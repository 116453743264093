/**
 * @param {string} path The navigation path
 * @param {string} param The param which is added to the path
 * @returns {string} Full path to navigate
 */
export function addParamToPath(path: string, param?: string) {
  const basePath = path.charAt(path.length - 1) === '/' ? path.replace(/.$/, '') : path;

  return param ? `${basePath}/${param}` : basePath;
}
