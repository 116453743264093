import type { SortOrder } from '@movingimage-evp/mi-ui-component-library';

type WebcastOrderId = 'PLANNED_AT' | 'STATE';

/**
 * Converts a string order into an object containing the id and order.
 *
 * @param {string} [order] - The string order to convert
 * @return {Object} An object with id and order properties
 * @example
 * convertStringOrderObject('PLANNED_AT_ASC') // { id: 'PLANNED_AT', order: 'asc' }
 */
export function convertStringOrderObject(order?: string) {
  if (!order) return undefined;

  const parts = order.split('_');

  return {
    id: parts.slice(0, -1).join('_') as WebcastOrderId,
    order: parts[parts.length - 1].toLowerCase() as SortOrder,
  };
}
