import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getFormattedTime } from '../../utils/time';
import styles from './timer.module.css';

type Props = {
  className?: string;
  currentTime?: number;
  totalTime?: number;
};

const hourInMilliseconds = 3600000;

export function Timer({ currentTime = 0, totalTime = 0, className }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classNames(className, styles.wrapper)}>
      <span role="timer" aria-label={t('ui.timer.current-time.label')} data-testid="current-time">
        {getFormattedTime(currentTime, totalTime < hourInMilliseconds)}
      </span>

      {totalTime > 0 && (
        <span role="timer" aria-label={t('ui.timer.total-time.label')} data-testid="total-time">
          {getFormattedTime(totalTime, totalTime < hourInMilliseconds)}
        </span>
      )}
    </div>
  );
}
