import { CryingFaceIcon } from '../../icons/crying-face';
import { useTranslation } from 'react-i18next';
import { getErrorLabel, getErrorCodeFromMediaError, getCanReloadSources } from '../player/player-errors';
import styles from './error-message.module.css';

type Props = {
  onReloadSources: () => void;
  mediaError: MediaError;
};

export function ErrorMessage({ onReloadSources, mediaError }: Props) {
  const { t } = useTranslation();
  const errorCode = getErrorCodeFromMediaError(mediaError);
  const canReloadSources = getCanReloadSources(errorCode);
  const label = getErrorLabel(errorCode);

  return (
    <div className={styles.wrapper} data-testid="error-wrapper">
      <CryingFaceIcon />
      {label && <span role="alert">{t(label)}</span>}

      {canReloadSources && (
        <span role="link" className={styles.link} onClick={() => onReloadSources()}>
          {t('ui.retry.button.label')}
        </span>
      )}
    </div>
  );
}
