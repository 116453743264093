import type { HTMLAttributes } from 'react';

import { Note, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import styles from './unmute-popup.module.css';

type UnmutePopupProps = {
  title: string;
  onClose: () => void;
  onUnMute: () => void;
} & HTMLAttributes<HTMLDivElement>;

export function UnmutePopup({ title, className, onClose, onUnMute, ...props }: UnmutePopupProps) {
  const { t } = useTranslation();

  return (
    <Note
      testId="unmute-popup"
      className={classNames(styles.unmutePopup, className)}
      title={title}
      dismissLabel={t('viewer.views.unmutePopup.buttonLabel')}
      onClose={onClose}
      onDismiss={onUnMute}
      {...props}
    >
      {t('viewer.views.unmutePopup.content')}
    </Note>
  );
}
