import { useState, useEffect, useMemo, useCallback } from 'react';
import { Key } from '../../utils';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { usePrevious } from '../../hooks/use-previous';
import { MenuOptions } from './menu-wrapper';

const supportedKeys: string[] = [Key.ArrowUp, Key.ArrowDown, Key.ArrowLeft, Key.ArrowRight, Key.Escape];

type MenuNavigationProps = {
  menuOptions: MenuOptions;
  wrapperRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  buttonName: string;
  isOpen: boolean;
};

/**
 * Implements the navigation logic for the player menus, including accessibility and keyboard navigation.
 *
 * @param menuOptions - The menu options to navigate through.
 * @param isOpen - Whether the menu is open or not.
 * @param onClose - The function to close the menu.
 * @param buttonName - The name of the button that opens the menu.
 * @param wrapperRef - The reference to the menu wrapper element.
 *
 * @returns
 */
export const useMenuNavigation = ({ menuOptions, isOpen, onClose, buttonName, wrapperRef }: MenuNavigationProps) => {
  const [currentScreen, setCurrentScreen] = useState(menuOptions.id);
  const previousScreen = usePrevious(currentScreen);
  const parentScreen = useMemo(() => getParentScreen(currentScreen, menuOptions), [currentScreen, menuOptions]);
  const currentScreenData = useMemo(() => getCurrentScreen(currentScreen, menuOptions), [currentScreen, menuOptions]);

  // Reset the current screen when the menu is opened
  useEffect(() => {
    if (isOpen) {
      setCurrentScreen(menuOptions.id);
    }
  }, [isOpen, menuOptions.id]);

  useOnClickOutside(wrapperRef, onClose, buttonName);

  const focusOnFirstButton = useCallback(() => {
    wrapperRef.current?.querySelectorAll('button')?.[0].focus();
  }, [wrapperRef]);

  const focusOnSpecificButton = useCallback(
    (submenu: string) => {
      (wrapperRef.current?.querySelector(`[data-goto="${submenu}"]`) as HTMLButtonElement).focus();
    },
    [wrapperRef]
  );

  const onGoBack = useCallback(() => {
    if (parentScreen) {
      setCurrentScreen(parentScreen);
    } else {
      onClose();
    }
  }, [parentScreen, onClose]);

  useEffect(() => {
    if (!previousScreen) return;

    if (previousScreen !== menuOptions.id && currentScreen === menuOptions.id) {
      focusOnSpecificButton(previousScreen);
    }

    if (previousScreen === menuOptions.id && currentScreen !== menuOptions.id) {
      focusOnFirstButton();
    }
  }, [currentScreen, focusOnFirstButton, focusOnSpecificButton, menuOptions.id, previousScreen]);

  // Set focus on the menu when it's open so that
  // the keydown listener can start capturing key events
  useEffect(() => {
    isOpen && wrapperRef?.current?.focus();
  }, [isOpen, wrapperRef]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;

    if (!wrapperElement) return;

    const onKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement === wrapperRef.current) {
        focusOnFirstButton();
        return;
      }

      switch (event.key) {
        case Key.ArrowUp:
          (document.activeElement?.previousElementSibling as HTMLElement)?.focus();
          break;

        case Key.ArrowDown:
          (document.activeElement?.nextElementSibling as HTMLElement)?.focus();
          break;

        case Key.ArrowLeft:
          onGoBack();
          break;

        case Key.ArrowRight:
          const goToAttribute = document.activeElement?.getAttribute('data-goto');
          if (typeof goToAttribute === 'string') {
            setCurrentScreen(goToAttribute);
          }
          break;

        case Key.Escape:
          onClose();
          break;
      }

      if (supportedKeys.includes(event.key)) event.preventDefault();
    };

    wrapperElement.addEventListener('keydown', onKeyDown);

    return () => {
      wrapperElement.removeEventListener('keydown', onKeyDown);
    };
  }, [currentScreen, focusOnFirstButton, onClose, onGoBack, wrapperRef]);

  return {
    currentScreen,
    setCurrentScreen,
    currentScreenData,
    parentScreen,
    onGoBack,
  };
};

function getCurrentScreen(currentScreen: string, menuOptions: MenuOptions): MenuOptions | undefined {
  if (currentScreen === menuOptions.id) return menuOptions;

  for (const option of menuOptions.options || []) {
    if (option.id === currentScreen) return option;

    const options = getCurrentScreen(currentScreen, option);
    if (options) return options;
  }

  return undefined;
}

function getParentScreen(currentScreen: string, menuOptions: MenuOptions): string | undefined {
  if (currentScreen === menuOptions.id) return undefined;

  for (const option of menuOptions.options || []) {
    if (option.id === currentScreen) return menuOptions.id;

    if (option.options) {
      const parentScreen = getParentScreen(currentScreen, option);
      if (parentScreen) return parentScreen;
    }
  }

  return undefined;
}
