// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-menu-button_menuButton__bEvMU > svg {
  transition: transform 0.2s ease-out;
}

.settings-menu-button_menuButton__bEvMU.settings-menu-button_active__m7aBs > svg {
  transform: rotate(90deg);
}

@media (prefers-reduced-motion) {
  .settings-menu-button_menuButton__bEvMU.settings-menu-button_active__m7aBs > svg {
    transform: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/settings-menu/settings-menu-button.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".menuButton > svg {\n  transition: transform 0.2s ease-out;\n}\n\n.menuButton.active > svg {\n  transform: rotate(90deg);\n}\n\n@media (prefers-reduced-motion) {\n  .menuButton.active > svg {\n    transform: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": `settings-menu-button_menuButton__bEvMU`,
	"active": `settings-menu-button_active__m7aBs`
};
export default ___CSS_LOADER_EXPORT___;
