import type { HTMLAttributes } from 'react';

import { InfoCircleIcon, classNames } from '@movingimage-evp/mi-ui-component-library';

import styles from './infobox.module.css';

export type InfoboxProps = HTMLAttributes<HTMLDivElement>;

/**
 * @param {ReactNode} [children] Text to be displayed next to the element.
 * @default undefined
 */
export function Infobox({ className, children, ...props }: InfoboxProps) {
  return (
    <div className={classNames(styles.wrapper, className)} data-testid="infobox" {...props}>
      <InfoCircleIcon />
      {children}
    </div>
  );
}
