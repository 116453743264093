import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

/**
 * Returns a boolean indicating whether keyboard navigation happened over an element.
 * @param ref A React ref to the DOM element.
 * @param tabStopDelay The number of milliseconds to wait before setting the return value to `false` again.
 * @returns A boolean of whether Tab or Arrow Keys were pressed over the DOM element.
 */
export function useKeyboardNavigating(ref: MutableRefObject<HTMLElement | null>, tabStopDelay = 3000): boolean {
  const timeoutRef: { current: number | undefined } = useRef();
  const [isNavigating, setIsNavigating] = useState(false);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isNavigationKey(event.key)) return;

      clearTimeout(timeoutRef.current);
      setIsNavigating(true);

      timeoutRef.current = window.setTimeout(() => {
        setIsNavigating(false);
      }, tabStopDelay);
    },
    [tabStopDelay]
  );

  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeoutRef.current);
    setIsNavigating(false);
  }, []);

  useEffect(() => {
    const node = ref?.current;

    if (!node) return;

    node.addEventListener('keydown', handleKeyDown);
    // Mouse leave is used to reset the timeout when the focus moves out of the player.
    node.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      clearTimeout(timeoutRef.current);
      node.removeEventListener('keydown', handleKeyDown);
      node.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [handleKeyDown, handleMouseLeave, ref, tabStopDelay]);

  return isNavigating;
}

function isNavigationKey(key: string) {
  return key === 'Tab' || key === 'ArrowUp' || key === 'ArrowDown' || key === 'ArrowLeft' || key === 'ArrowRight';
}
