import { RefObject, useEffect, useRef, useState } from 'react';
import { volumeSliderIsFocused, uiButtonIsFocused, getActiveElementName } from './use-keyboard-shortcuts.utils';
import { Key } from '../../../utils/key';

export type KeyboardAction =
  | 'VolumeIncrease'
  | 'VolumeDecrease'
  | 'ShortTimeIncrease'
  | 'ShortTimeDecrease'
  | 'FullscreenToggle'
  | 'LongTimeIncrease'
  | 'LongTimeDecrease';

const VOLUME_CHANGE_STEP = 0.1;
const LONG_TIME_CHANGE_STEP = 10000;
const SHORT_TIME_CHANGE_STEP = 5000;

export function useKeyboardShortcuts(
  wrapperRef: RefObject<HTMLElement>,
  onVolumeDeltaChange?: (value: number) => void,
  onTimeDeltaChange?: (value: number) => void,
  onMuteChange?: (value: boolean) => void,
  isMuted?: boolean,
  menuIsOpen?: boolean,
  onPlaybackToggle?: () => void,
  onFullscreenToggle?: () => void
) {
  const [lastKeyboardAction, setLastKeyboardAction] = useState<KeyboardAction | undefined>(undefined);
  const timeoutRef: { current: number | undefined } = useRef();

  useEffect(() => {
    const wrapperElement = wrapperRef?.current;

    if (!wrapperElement) return;

    const onKeyDown = (event: KeyboardEvent) => {
      const activeElementName = getActiveElementName();
      switch (event.key) {
        case Key.ArrowUp:
          if (!menuIsOpen && !volumeSliderIsFocused(activeElementName)) {
            onVolumeDeltaChange?.(VOLUME_CHANGE_STEP);
            setLastKeyboardAction('VolumeIncrease');
            event.preventDefault();
          }
          break;
        case Key.ArrowDown:
          if (!menuIsOpen && !volumeSliderIsFocused(activeElementName)) {
            onVolumeDeltaChange?.(-VOLUME_CHANGE_STEP);
            setLastKeyboardAction('VolumeDecrease');
            event.preventDefault();
          }
          break;
        case Key.ArrowRight:
          if (!menuIsOpen && !volumeSliderIsFocused(activeElementName)) {
            onTimeDeltaChange?.(SHORT_TIME_CHANGE_STEP);
            setLastKeyboardAction('ShortTimeIncrease');
            event.preventDefault();
          }
          break;
        case Key.ArrowLeft:
          if (!menuIsOpen && !volumeSliderIsFocused(activeElementName)) {
            onTimeDeltaChange?.(-SHORT_TIME_CHANGE_STEP);
            setLastKeyboardAction('ShortTimeDecrease');
            event.preventDefault();
          }
          break;
        case Key.F:
          onFullscreenToggle?.();
          break;
        case Key.L:
          onTimeDeltaChange?.(LONG_TIME_CHANGE_STEP);
          setLastKeyboardAction('LongTimeIncrease');
          break;
        case Key.J:
          onTimeDeltaChange?.(-LONG_TIME_CHANGE_STEP);
          setLastKeyboardAction('LongTimeDecrease');
          break;
        case Key.M:
          onMuteChange?.(!isMuted);
          break;
        case Key.Space:
          if (!uiButtonIsFocused(activeElementName)) {
            onPlaybackToggle?.();
            event.preventDefault();
          }
          break;
      }
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => setLastKeyboardAction(undefined), 500);
    };

    const onClick = () => {
      setLastKeyboardAction(undefined);
    };

    wrapperElement.addEventListener('keydown', onKeyDown);
    wrapperElement.addEventListener('click', onClick);

    return () => {
      wrapperElement.removeEventListener('keydown', onKeyDown);
      wrapperElement.removeEventListener('click', onClick);
    };
  }, [
    wrapperRef,
    isMuted,
    menuIsOpen,
    onFullscreenToggle,
    onTimeDeltaChange,
    onMuteChange,
    onVolumeDeltaChange,
    onPlaybackToggle,
  ]);

  return lastKeyboardAction;
}
