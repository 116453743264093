import type { TransferProgress } from './transfer-progress';
import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useReducer } from 'react';

import { ProgressReducer } from './progress-reducer';

type ProgressContextValue = {
  indicatorsList: TransferProgress[];
  addProgressIndicator: (indicator: TransferProgress) => void;
  updateStatus: (id: string, status: TransferProgress['status']) => void;
  updateProgress: (
    id: string,
    progressPercentage: number,
    remainingTime?: { minutes: number; seconds: number }
  ) => void;
  cancelProgressIndicator: (id: string) => void;
};

const initialProgress: TransferProgress[] = [];

const initialContext: ProgressContextValue = {
  indicatorsList: initialProgress,
  addProgressIndicator: () => {},
  updateStatus: () => {},
  updateProgress: () => {},
  cancelProgressIndicator: () => {},
};

export const ProgressContext = createContext<ProgressContextValue>(initialContext);

export function ProgressContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(ProgressReducer, initialProgress);

  const addProgressIndicator = useCallback((indicator: TransferProgress) => {
    dispatch({ type: 'ADD_INDICATOR', payload: { indicator } });
  }, []);

  const updateStatus = useCallback((id: string, status: TransferProgress['status']) => {
    dispatch({ type: 'UPDATE_STATUS', payload: { id, status } });
  }, []);

  const updateProgress = useCallback(
    (id: string, progressPercentage: number, remainingTime?: { minutes: number; seconds: number }) => {
      dispatch({ type: 'UPDATE_PROGRESS', payload: { id, progressPercentage, remainingTime } });
    },
    []
  );

  const cancelProgressIndicator = useCallback((id: string) => {
    dispatch({ type: 'CANCEL', payload: { id } });
  }, []);

  return (
    <ProgressContext.Provider
      value={{
        indicatorsList: state,
        addProgressIndicator,
        updateStatus,
        updateProgress,
        cancelProgressIndicator,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
}

export const useProgressContext = () => useContext(ProgressContext);
