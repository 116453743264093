export type Screen = 'main' | Submenu;
export type Submenu = 'subtitle' | 'quality' | 'speed';

export const AutoQualityId = 'auto';

export type MenuOption = {
  id: string;
  label: string;
  languageCode?: string;
};

export type QualityLevelOption = {
  id: string;
  height: number;
};

export interface SubtitleOption {
  id: string;
  englishLabel: string;
  languageCode: string;
}
