import type { Channel, Video, VideoManager } from '../../../generated/graphql-manager';
import type { ChangeEvent } from 'react';

import {
  CloseIcon,
  Heading,
  IconButton,
  InfoCircleIcon,
  ModalPortal,
  SearchIcon,
  Spinner,
  SubHeading,
  TextInput,
  Tooltip,
  useDebounce,
  usePrevious,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChannelsList } from './channels-list';
import { VideoDetailsModal } from './video-details-modal';
import { VideoManagerSelect } from './video-manager-select';
import { VideosItems } from './videos-items';
import {
  useGetVideoManagerQuery,
  useGetVideoManagersQuery,
  useGetVideosQuery,
} from '../../../generated/graphql-manager';
import { getIdPathForChannel } from '../../pages/webcast-setup/event-summary/event-summary-utils';
import { useVmproConnection } from '../../providers/vmpro-connector';

import styles from './video-browser.module.css';

type Props = {
  title: string;
  isOpen: boolean;
  onChooseVideo: (video: Video) => void;
  onClose: () => void;
  initialChannelId?: string;
  initialVideoManagerId?: string | null;
};

export function VideoBrowser({
  title,
  isOpen,
  initialChannelId,
  initialVideoManagerId,
  onChooseVideo,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const { isVmproLinked, showConnectionModal, connectionModalVisible } = useVmproConnection();
  const modalVisible = isOpen && isVmproLinked;
  const vmproNotLinked = isOpen && !isVmproLinked;

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const handleSearchTermChange = ({ target }: ChangeEvent<HTMLInputElement>) => setSearchTerm(target.value);

  // --- Video managers ---
  const [videoManagerId, setVideoManagerId] = useState<VideoManager['id']>(initialVideoManagerId || '');
  const getVideoManagersResponse = useGetVideoManagersQuery({
    skip: !modalVisible,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (videoManagerId) return;

      if (!initialVideoManagerId) {
        setVideoManagerId(
          data.videoManagers.__typename === 'GetVideoManagersSuccess' ? data.videoManagers.videoManagers[0].id : ''
        );
      }
    },
  });

  const videoManagers =
    getVideoManagersResponse.data?.videoManagers.__typename === 'GetVideoManagersSuccess'
      ? getVideoManagersResponse.data.videoManagers.videoManagers
      : [];

  const videoManagersRequestFinished = getVideoManagersResponse.called && !getVideoManagersResponse.loading;
  const noVideoManagers = videoManagersRequestFinished && videoManagers.length === 0;
  const uiEnabled = videoManagersRequestFinished && videoManagers.length > 0;

  // --- Channels ---
  const [channelId, setChannelId] = useState<Channel['id']>('');
  const getVideoManagerResponse = useGetVideoManagerQuery({
    variables: { videoManagerId },
    skip: !videoManagerId || !modalVisible || !videoManagersRequestFinished,
    onCompleted: (data) => {
      const channelId =
        (data.videoManager.__typename === 'VideoManager' && data?.videoManager?.channels?.[0]?.id) || '';
      setChannelId(initialChannelId || channelId);
    },
    onError: () => {
      setVideoManagerId(videoManagers[0]?.id || '');
    },
  });

  const channels =
    getVideoManagerResponse.data?.videoManager.__typename === 'VideoManager'
      ? getVideoManagerResponse.data.videoManager.channels || []
      : [];

  const initialChannel = channels.find((channel) => channel.id === initialChannelId);
  const initialChannelPath = initialChannel ? getIdPathForChannel(initialChannel, channels) : undefined;

  // --- Videos ---
  const [videoId, setVideoId] = useState<Video['id']>('');
  const getVideosResponse = useGetVideosQuery({
    variables: { videoManagerId, channelId, searchTerm: debouncedSearchTerm },
    skip: !channelId || !modalVisible,
  });

  const videos =
    getVideosResponse.data?.videos.__typename === 'VideoConnection'
      ? getVideosResponse.data.videos.edges.map(({ node }) => node)
      : [];

  const video = videos.find(({ id }) => id === videoId);
  const clearVideoId = () => setVideoId('');

  useEffect(() => {
    if (!isOpen) setSearchTerm('');
  }, [isOpen]);

  useEffect(() => {
    if (vmproNotLinked) showConnectionModal();
  }, [vmproNotLinked, showConnectionModal]);

  const previousConnectionModalVisible = usePrevious(connectionModalVisible);

  useEffect(() => {
    if (vmproNotLinked && previousConnectionModalVisible && !connectionModalVisible) {
      onClose();
    }
  }, [vmproNotLinked, connectionModalVisible, previousConnectionModalVisible, onClose]);

  useEffect(() => {
    if (!initialChannelId) return;

    const scrollToElement = document.getElementById(`video-manager-browser-channel-${initialChannelId}`);

    if (!scrollToElement) return;

    scrollToElement.scrollIntoView({ behavior: 'instant' });
  });

  return (
    <ModalPortal data-testid="video-manager-browser" isOpen={modalVisible} onClose={onClose}>
      <div className={styles.videoBrowserModal}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <div className={styles.title}>
              <Heading>{title}</Heading>
              <Tooltip label={t('components.videoManager.videoBrowser.tooltip')} delay={0}>
                <InfoCircleIcon style={{ marginLeft: 10, verticalAlign: 'middle' }} />
              </Tooltip>
            </div>

            <IconButton
              rounded
              onClick={onClose}
              aria-label={t('components.videoManager.videoBrowser.videoDetailsModal.close')}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <VideoManagerSelect
            info={t('components.videoManager.videoBrowser.info')}
            videoManagers={videoManagers}
            currentVideoManagerId={videoManagerId}
            disabled={!uiEnabled}
            handleVideoManagerIdChange={({ key }) => setVideoManagerId(key)}
          />
        </div>

        <div className={styles.contentWrapper}>
          <TextInput
            data-testid="video-manager-browser-search"
            value={searchTerm}
            placeholder={t('components.videoManager.videoBrowser.searchInputPlaceholder')}
            disabled={!uiEnabled}
            onChange={handleSearchTermChange}
          >
            <SearchIcon />
          </TextInput>

          <div className={styles.videoWrapper}>
            {modalVisible && !videoManagersRequestFinished && <Spinner size={40} className={styles.spinner} />}

            {uiEnabled && (
              <>
                <ChannelsList
                  currentChannelId={channelId}
                  channels={channels}
                  onChannelClick={setChannelId}
                  rootChannelLabel={t('components.videoManager.videoBrowser.allVideos')}
                  initialChannelPath={initialChannelPath}
                />

                <VideosItems
                  videos={videos}
                  loading={!getVideosResponse.called || getVideosResponse.loading}
                  isSearchTerm={Boolean(debouncedSearchTerm)}
                  onVideoClick={setVideoId}
                />
              </>
            )}

            {noVideoManagers && (
              <SubHeading data-testid="video-manager-browser-access-denied-message" className={styles.accessDenied}>
                {t('components.videoManager.videoBrowser.accessDenied')}
              </SubHeading>
            )}

            <VideoDetailsModal
              isOpen={video !== undefined}
              video={video}
              onConfirm={onChooseVideo}
              onClose={clearVideoId}
            />
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}
