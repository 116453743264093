/**
 * @param {number} year The chosen year
 * @default undefined
 * @param {number} month The chosen month
 * @default undefined
 * @returns {number} The number of days for a given month in given year
 */
export function getNumberOfDays(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}
