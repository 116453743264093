type UploadFileOptions = {
  file: File;
  token: string;
  url: string;
  onProgress?: (percentage: number) => void;
  onSuccess?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
  onError?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
  onLoadEnd?: (event: ProgressEvent<XMLHttpRequestEventTarget>) => void;
};

/**
 * @param {File} file The file to upload
 * @param {string} token The token to send with the request
 * @param {string} url The url to send the request to
 * @param {(percentage: number) => void} [onProgress] A callback to be called when the upload progress changes
 * @param {(event: ProgressEvent<XMLHttpRequestEventTarget>) => void} [onSuccess] A callback to be called when the upload finishes
 * @param {(event: ProgressEvent<XMLHttpRequestEventTarget>) => void} [onError] A callback to be called when the upload fails
 * @param {(event: ProgressEvent<XMLHttpRequestEventTarget>) => void} [onLoadEnd] A callback to be called when the upload finishes or fails
 * @returns {XMLHttpRequest} XHR object
 */
export function uploadFile({ file, token, url, onProgress, onSuccess, onError, onLoadEnd }: UploadFileOptions) {
  const formData = new FormData();

  formData.append('file', file, file.name);

  const xhr = new XMLHttpRequest();

  if (onSuccess) xhr.addEventListener('load', onSuccess);
  if (onError) xhr.addEventListener('error', onError);
  if (onLoadEnd) xhr.addEventListener('loadend', onLoadEnd);

  if (onProgress) {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) onProgress(Math.round((event.loaded / event.total) * 100));
    });
  }

  xhr.open('POST', url);
  xhr.setRequestHeader('Authorization', `token ${token}`);
  xhr.send(formData);

  return xhr;
}
