import type { VideoJsPlayer } from 'video.js';

import { useEffect } from 'react';

import { VideoJsCustomEvent, VideoJsNativeEvent } from '../videojs-event';
import { fixTextTrackDataCueEndTimes, parseDataCues, parseWebkitDataCues } from '../utils/parse-data-cues';
import { supportsNativeHls } from '../utils/supports-native-hls';

export const useTimedMetadata = (player: VideoJsPlayer) => {
  useEffect(() => {
    if (player) {
      let currentTrack: TextTrack;
      let videoElement: HTMLVideoElement | HTMLAudioElement;
      // This interval is used to fix the endTime of the cues that have endTime = startTime. It is needed since the first cueChange event is not fired because of this.
      let interval: NodeJS.Timeout | undefined = undefined;

      const onNativeCueChange = () => {
        if (!currentTrack.activeCues) {
          return;
        }

        const cues = parseWebkitDataCues(currentTrack.activeCues);

        if (cues.length > 0) {
          player.trigger(VideoJsCustomEvent.TIMED_METADATA, cues);
        }
      };

      const onNativeAddTrack = ({ track }: TrackEvent) => {
        if (!(track?.kind === 'metadata')) {
          return;
        }

        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onNativeCueChange);

        currentTrack = track;
        currentTrack.mode = 'hidden';
        currentTrack.addEventListener(VideoJsNativeEvent.CUE_CHANGE, onNativeCueChange);
      };

      const onCueChange = () => {
        clearInterval(interval);

        fixTextTrackDataCueEndTimes(currentTrack);

        if (!currentTrack.activeCues) {
          return;
        }

        const cues = parseDataCues(currentTrack.activeCues);

        if (cues.length > 0) {
          player.trigger(VideoJsCustomEvent.TIMED_METADATA, cues);
        }
      };

      const onAddTrack = ({ track }: TrackEvent) => {
        if (!(track?.kind === 'metadata' && track.label === 'Timed Metadata')) {
          return;
        }

        // clean up
        clearInterval(interval);
        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);

        // set up
        currentTrack = track;
        currentTrack.mode = 'hidden';
        currentTrack.addEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);

        interval = setInterval(() => {
          if (track.cues?.length) {
            fixTextTrackDataCueEndTimes(currentTrack);

            clearInterval(interval);
          }
        }, 1000);
      };

      if (supportsNativeHls()) {
        // In case of Safari, videojs + http-streaming choose the native hls streaming.
        // Because of the native playback and functionality, videojs is not needed
        videoElement = player.tech({ IWillNotUseThisInPlugins: true }).el();
        videoElement.textTracks.addEventListener(VideoJsNativeEvent.ADD_TRACK, onNativeAddTrack);
      } else {
        // MSE supported browsers does not support HLS, so videojs take care about the playback
        // and the text-track handling
        player.textTracks().addEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
      }

      return () => {
        clearInterval(interval);

        videoElement?.textTracks.removeEventListener(VideoJsNativeEvent.ADD_TRACK, onNativeAddTrack);
        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onNativeCueChange);

        const textTracks = player.textTracks();
        textTracks.removeEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);
      };
    }
  }, [player]);
};
