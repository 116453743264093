import { type ReactNode, createContext, useContext, useEffect, useState } from 'react';

type MediaQueryContextValue = {
  isMobile: boolean;
  isDesktop: boolean;
};

const getValues = (): MediaQueryContextValue => ({
  isMobile: window.matchMedia('(max-width: 768px)').matches,
  isDesktop: !window.matchMedia('(max-width: 768px)').matches,
});

export const MediaQueryContext = createContext(getValues());

export function MediaQueryProvider({ children }: { children: ReactNode }) {
  const [mediaQuery, setMediaQuery] = useState(getValues());

  const handleResize = () => setMediaQuery(getValues());

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <MediaQueryContext.Provider value={mediaQuery}>{children}</MediaQueryContext.Provider>;
}

export const useMediaQuery = () => useContext(MediaQueryContext);
