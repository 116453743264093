// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waveform_waveform__q5Uxv {
  position: absolute;
  width: 75%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waveform_waveform__q5Uxv .waveform_bar__l4XTc {
  animation-name: waveform_wave__XbWrO;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  background: white;
  margin: 1%;
  height: 100%;
  width: 100%;
}

@keyframes waveform_wave__XbWrO {
  0% {
    opacity: 0.35;
    height: 15%;
  }
  100% {
    opacity: 1;
    height: 70%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/waveform/waveform.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,oCAAoB;EACpB,mCAAmC;EACnC,sCAAsC;EACtC,8BAA8B;EAC9B,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,WAAW;EACb;EACA;IACE,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".waveform {\n  position: absolute;\n  width: 75%;\n  height: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.waveform .bar {\n  animation-name: wave;\n  animation-iteration-count: infinite;\n  animation-timing-function: ease-in-out;\n  animation-direction: alternate;\n  background: white;\n  margin: 1%;\n  height: 100%;\n  width: 100%;\n}\n\n@keyframes wave {\n  0% {\n    opacity: 0.35;\n    height: 15%;\n  }\n  100% {\n    opacity: 1;\n    height: 70%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"waveform": `waveform_waveform__q5Uxv`,
	"bar": `waveform_bar__l4XTc`,
	"wave": `waveform_wave__XbWrO`
};
export default ___CSS_LOADER_EXPORT___;
