import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

/**
 * Call a callback function when element is resized.
 * @param {MutableRefObject<HTMLElement | null>} elementRef - A React ref to the DOM element that will have ResizeObserver attached to it.
 * @param {Function} callback - A callback function to be called when the element is resized.
 */
export function useOnResize(elementRef: MutableRefObject<HTMLElement | null>, callback: () => void) {
  useEffect(() => {
    const element = elementRef?.current;

    if (!element || !window.ResizeObserver) return;

    const observer = new ResizeObserver(callback);
    observer.observe(element);

    return () => observer.disconnect();
  }, [elementRef, callback]);
}
