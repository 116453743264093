import { parseContent } from '../components/rich-text-editor';

export const isRichTextEditorContentEmpty = (description?: string | null) => {
  const content = parseContent(description);

  if (content.length > 1) return false;
  if ('text' in content[0]) return content[0].text === '';
  if ('children' in content[0]) return content[0].children.length === 1 && content[0].children[0].text === '';

  return false;
};
