import type { GetWebcastsQuery } from '../../../../generated/graphql-manager';

import { Modal, Paragraph, SecondaryButton } from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GetWebcastsDocument as GET_WEBCASTS,
  useDeleteWebcastEventMutation,
} from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';

type Props = {
  webcastId: string;
  isOpen: boolean;
  onClose: () => void;
};

export function DeleteWebcastModal({ webcastId, isOpen, onClose }: Props) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const [error, setError] = useState(false);

  const [deleteWebcastMutation, { loading }] = useDeleteWebcastEventMutation();

  const handleConfirm = () => {
    if (loading || !webcastId) return;

    deleteWebcastMutation({
      variables: { id: webcastId },
      optimisticResponse: {
        deleteWebcast: {
          __typename: 'DeleteWebcastSuccess',
          webcast: {
            __typename: 'Webcast',
            id: webcastId,
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteWebcast.__typename !== 'DeleteWebcastSuccess') return;

        const cachedWebcastsQuery = cache.readQuery<GetWebcastsQuery>({
          query: GET_WEBCASTS,
          variables: { lsproId },
        });

        const updatedCachedWebcasts = cachedWebcastsQuery?.webcasts.edges.filter(
          (edge) => edge?.node?.id !== webcastId
        );

        cache.writeQuery({
          query: GET_WEBCASTS,
          variables: { lsproId },
          data: {
            webcasts: {
              ...cachedWebcastsQuery?.webcasts,
              edges: updatedCachedWebcasts,
            },
          },
        });

        cache.evict({ id: cache.identify({ id: webcastId, __typename: 'Webcast' }) });
        cache.gc();
      },
      onCompleted: (data) => {
        if (data.deleteWebcast.__typename === 'DeleteWebcastProblem') {
          setError(true);
        } else {
          onClose();
        }
      },
      onError: () => {
        setError(true);
      },
    });
  };

  useEffect(() => {
    if (webcastId) {
      setError(false);
    }
  }, [webcastId]);

  return (
    <Modal
      data-testid="delete-webcast-modal"
      title={t('manager.webcasts.deleteWebcastModal.title')}
      loading={loading}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          <SecondaryButton data-testid="cancel-delete-webcast-button" type="button" onClick={onClose}>
            {error ? t('common.close') : t('common.cancel')}
          </SecondaryButton>

          {!error && (
            <SecondaryButton
              data-testid="confirm-delete-webcast-button"
              type="button"
              onClick={handleConfirm}
              disabled={loading}
            >
              {t('common.confirm')}
            </SecondaryButton>
          )}
        </>
      }
    >
      <Paragraph>
        {error ? t('manager.webcasts.deleteWebcastModal.errorInfo') : t('manager.webcasts.deleteWebcastModal.info')}
      </Paragraph>
    </Modal>
  );
}
