import type { UserMessage } from '@sendbird/chat/lib/__definition';

export type ChatUser = {
  userId: string;
  nickname: string;
};

export enum MessageCustomType {
  FROM_MODERATOR = 'FROM_MODERATOR',
  APPROVED_BY_MODERATOR = 'APPROVED_BY_MODERATOR',
  DECLINED_BY_MODERATOR = 'DECLINED_BY_MODERATOR',
}

export type ChatMessage = UserMessage & {
  customType: MessageCustomType;
};

export type Action = {
  id: string;
  type: ReactionKey | 'vote';
  messageId: number;
  userId: string;
};

export type ChatConfig = {
  applicationId?: string;
  channelUrl?: string;
  eventId: string;
  userId: string;
  nickname?: string;
};

export type Chat = {
  user?: ChatUser;
  messages: ChatMessage[];
  userActionError?: string;
  providerError?: string;
  connected: boolean;
  connecting: boolean;
  sendingMessage: boolean;
  editingMessage: boolean;
  deletingMessage: boolean;
  actions: Action[];
  connectUser: (config: ChatConfig) => void;
  connectModerator: (config: ChatConfig) => void;
  sendMessage: (message: ChatMessage['message'], customType?: ChatMessage['customType']) => Promise<boolean>;
  sendThreadMessage: (
    parentMessageId: ChatMessage['messageId'],
    message: ChatMessage['message']
  ) => Promise<ChatMessage | undefined>;
  editMessage: (
    messageId: ChatMessage['messageId'],
    message?: ChatMessage['message'],
    customType?: ChatMessage['customType']
  ) => Promise<boolean>;
  deleteMessage: (message: ChatMessage) => Promise<boolean>;
  reactToMessage: (message: ChatMessage['messageId'], reactionName: ReactionKey) => void;
  getReactionsByMessageId: (messageId: ChatMessage['messageId']) => Reaction;
  getNumberOfReactionsByMessageId: (messageId: ChatMessage['messageId'], reactionName: ReactionKey) => number;
  hasUserReactedToMessage: (messageId: ChatMessage['messageId'], reactionName: ReactionKey) => boolean;
  voteForMessage: (message: ChatMessage['messageId']) => void;
  getVotesByMessageId: (messageId: ChatMessage['messageId']) => number;
  hasUserVotedForMessage: (messageId: ChatMessage['messageId']) => boolean;
};

export type ReactionKey = 'heart' | 'thumbsUp' | 'laugh';

export type Reaction = {
  [key in ReactionKey]: ChatUser['userId'][];
};

export type ChannelReactions = Record<string, Reaction | undefined>;

export type Votes = Record<string, ChatUser['userId'][] | undefined>;

export type ChannelMetadata = {
  reactions: ChannelReactions;
  votes: Votes;
};

export const reactionIcons = {
  heart: '❤️',
  thumbsUp: '👍',
  laugh: '😂',
};

// Using values larger than 100 will throw a Sendbird API error
export const MESSAGES_LIMIT = 100;
