// docs: https://download.hivestreaming.com/media-player/docs/html5/docs/10.0.0/index.html
export type HiveTechName = 'HiveJava' | 'HiveJS' | 'StatsJS';
export type HiveDebugLevel = 'off' | 'error' | 'warn' | 'notice' | 'info' | 'debug';

export enum HiveSeverity {
  'NORMAL',
  'HIGH',
  'CRITICAL',
}

export interface OptionsHtml5 {
  playerName: string;
  playerVersion: string;
  eventName?: string;
  hiveTechOrder?: string[];
  debugLevel: HiveDebugLevel;
  streamUrlMatcher?: RegExp[];
  HiveJs?: HiveJSOptions;
  onError: (msg: HiveErrorMessage) => void;
}

export interface HiveTechOptions {
  onActiveSession?: (session: HiveSession) => void;
  onError?: (error: HiveErrorMessage) => void;
}

export interface HiveErrorMessage {
  pluginVersion: string;
  reportingId: string;
  severity: HiveSeverity;
  message: string;
  code: number;
  p2pId: string;
}

export interface HiveJSOptions extends HiveTechOptions {
  renderStatsCallback?: (stats: unknown) => void;
  onLoadingCompleted?: (plugin: unknown) => void;
  /**
   * This allows the partner to define a pattern in the manifest/segment URL
   * which identifies the same stream/ segment when the URL has different sources.
   *
   * This setting only affects WebRTC and Stats Only.
   */
  streamUrlMatcher?: RegExp[];
}

export interface HiveSession {
  id: string;
  manifest: string;
  tech: string;
  ticketUrl: string;
}

export interface HivePlugin {
  initSession: (value: string | HiveToken) => Promise<HiveSession>;
  createXMLHttpRequest: () => unknown;
  destroyHivePlugin: () => void;
  closeHiveSession: () => void;
  getActiveSession: () => HiveSession | null;
  isSessionActive: () => boolean;
}

export interface HiveToken {
  /** The JWT string */
  jwt: string;
  /** A string representing the intended manifest to play. This manifest will be cross-referenced by Hive Services to ensure the Platform is attempting to play a manifest that is validated in the JWT. */
  manifest: string;
  /** A string representing the Event/Video Id. This will validated by Hive Services to ensure the JWT matches the provided value. */
  videoId: string;
  /** An optional string for where to authorize with Hive Services, either "test" or "prod" (default). */
  endpoint?: 'test' | 'prod';
}
