import { CSSProperties } from 'react';
import classNames from 'classnames';

import { Watermark } from './watermark-overlay.types';
import styles from './watermark-overlay.module.css';

type Props = {
  watermark?: Watermark;
};

const toCamelCase = (str: string) => str.replaceAll(/-([a-z])/g, (g) => g[1].toUpperCase());

export function WatermarkOverlay({ watermark }: Props) {
  if (!watermark || !watermark.content) {
    return null;
  }

  const style = {
    opacity: watermark.opacity || 0.5,
    color: watermark.color || 'white',
    WebkitTextStroke: watermark.textStroke || '1px black',
  } as CSSProperties;

  const position = watermark.position ? toCamelCase(watermark.position) : 'topCenter';

  return (
    <div aria-hidden="true" data-testid="watermark-overlay" className={classNames(styles.container, styles[position])}>
      <div data-testid="watermark-content" className={styles.watermark} style={style}>
        {watermark.content}
      </div>
    </div>
  );
}
