import { VideoJsPlayer } from 'video.js';
import { ErrorCode, PlayerError } from '../player-errors';

export async function tryToPlay(player: VideoJsPlayer): Promise<void> {
  const promise = player.play();

  if (promise === undefined) {
    return Promise.resolve();
  }

  return promise;
}

async function tryToPlayMuted(player: VideoJsPlayer): Promise<void> {
  const prevMutedState = player.muted();
  player.muted(true);

  return tryToPlay(player).catch(() => {
    player.muted(prevMutedState);
  });
}

export function tryToAutoplay(player: VideoJsPlayer): Promise<void> {
  // Firefox supports autoplay policy check
  const autoplayPolicy = getAutoplayPolicy();

  switch (autoplayPolicy) {
    case 'allowed':
      return tryToPlay(player).catch((err) => {
        throw new PlayerError(ErrorCode.PLAYER_ERR_AUTOPLAY_NOT_POSSIBLE, err.message);
      });
    case 'allowed-muted':
      return tryToPlayMuted(player).catch((err) => {
        throw new PlayerError(ErrorCode.PLAYER_ERR_AUTOPLAY_MUTED_NOT_POSSIBLE, err.message);
      });
    case 'disallowed':
      throw new PlayerError(ErrorCode.PLAYER_ERR_AUTOPLAY_NOT_ALLOWED, 'Autoplay is not allowed.');
    default:
      // browsers like Safari and Chrome doesn't support autoplay policy check
      return tryToPlay(player).catch((err) => {
        if (err.name === 'NotAllowedError') {
          // try to play the media muted
          return tryToPlayMuted(player).catch((err) => {
            if (err.name === 'NotAllowedError') {
              throw new PlayerError(ErrorCode.PLAYER_ERR_AUTOPLAY_MUTED_NOT_ALLOWED, err.message);
            } else {
              throw new PlayerError(ErrorCode.PLAYER_ERR_AUTOPLAY_MUTED_NOT_POSSIBLE, err.message);
            }
          });
        } else {
          throw new PlayerError(ErrorCode.PLAYER_ERR_AUTOPLAY_NOT_POSSIBLE, err.message);
        }
      });
  }
}

type AutoplayPolicy = 'allowed' | 'allowed-muted' | 'disallowed';

function getAutoplayPolicy(): AutoplayPolicy | undefined {
  // only firefox supports autoplay policy check
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/getAutoplayPolicy
  return (navigator as any).getAutoplayPolicy ? (navigator as any).getAutoplayPolicy('mediaelement') : undefined;
}
