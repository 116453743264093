import { AnimationEventHandler } from 'react';

type IconProps = {
  onAnimationEnd?: AnimationEventHandler | undefined;
  width?: string;
  height?: string;
};

export const PlayIcon = ({ onAnimationEnd, width = '11', height = '15' }: IconProps) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 11 15"
    fill="#fff"
    aria-hidden="true"
    role="img"
    onAnimationEnd={onAnimationEnd}
    data-testid="play-icon"
  >
    <path d="M0 0v14l11-7L0 0z" />
  </svg>
);
