import type { AppearanceAsset, AppearanceAssetType } from '../../../../generated/graphql-manager';

import {
  AlertIcon,
  FileUploader,
  IconButton,
  ImageOutlineIcon,
  Tooltip,
  TrashIcon,
} from '@movingimage-evp/mi-ui-component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCreateAppearanceAssetMutation,
  useDeleteAppearanceAssetMutation,
} from '../../../../generated/graphql-manager';
import { uploadFile } from '../../../../utils';
import { useCurrentUser } from '../../../hooks/current-user';

import styles from './asset.module.css';

type Props = {
  asset?: AppearanceAsset;
  assetType: AppearanceAssetType;
  acceptedFormats: string;
  maximumFileSize: number;
  additionalUploadInformation?: string[];
  heading?: string;
  onSuccess?: () => void;
};

export function Asset({
  asset,
  assetType,
  acceptedFormats,
  maximumFileSize,
  additionalUploadInformation,
  heading,
  onSuccess,
  ...props
}: Props) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const [createAssetMutation, { loading: creatingAsset }] = useCreateAppearanceAssetMutation();
  const [deleteAssetMutation, { loading: deletingAsset }] = useDeleteAppearanceAssetMutation();

  const xhrRef = useRef<XMLHttpRequest>();

  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const copyId = assetType.toLowerCase().replace(/_/g, '-');
  const assetUrl = asset?.downloadLink;

  const uploadAsset = (files: File | File[]) => {
    const file = Array.isArray(files) ? files[0] : files;

    setError('');

    createAssetMutation({
      variables: { input: { lsproId, assetType } },
      optimisticResponse: {
        __typename: 'Mutation',
        createAppearanceAsset: {
          __typename: 'CreateAppearanceAssetSuccess',
          asset: {
            __typename: 'AppearanceAsset',
            type: assetType,
            downloadLink: '',
            uploadLink: {
              __typename: 'UploadLink',
              url: '',
              token: '',
            },
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.createAppearanceAsset.__typename !== 'CreateAppearanceAssetSuccess') return;

        const newAsset = data.createAppearanceAsset.asset;
        const reader = new FileReader();

        reader.onload = () => {
          cache.modify({
            fields: {
              lsproSettings: (lsproSettings) => ({
                ...lsproSettings,
                appearance: {
                  ...lsproSettings.appearance,
                  assets: [...lsproSettings.appearance.assets, { ...newAsset, downloadLink: reader.result }],
                },
              }),
            },
          });
        };

        reader.readAsDataURL(file);
      },
      onCompleted: (data) => {
        if (data.createAppearanceAsset.__typename !== 'CreateAppearanceAssetSuccess') {
          setError(t('manager.branding.logoAndFavicon.fileUpload.errors.assetCreation'));
          return;
        }

        const {
          uploadLink: { url, token },
        } = data.createAppearanceAsset.asset;

        xhrRef.current = uploadFile({
          file,
          token,
          url,
          onProgress: setUploadProgress,
          onSuccess: () => onSuccess?.(),
          onError: () => setError(t('manager.branding.logoAndFavicon.fileUpload.errors.upload')),
          onLoadEnd: () => setUploadProgress(0),
        });
      },
    });
  };

  const deleteAsset = () => {
    setError('');

    deleteAssetMutation({
      variables: { input: { lsproId, assetType } },
      update: (cache, { data }) => {
        if (data?.deleteAppearanceAsset.__typename !== 'DeleteAppearanceAssetSuccess') return;

        cache.modify({
          fields: {
            lsproSettings: (lsproSettings) => ({
              ...lsproSettings,
              appearance: {
                ...lsproSettings.appearance,
                assets: lsproSettings.appearance.assets.filter((asset: AppearanceAsset) => asset.type !== assetType),
              },
            }),
          },
        });
      },
      onCompleted: (data) => {
        if (data.deleteAppearanceAsset.__typename !== 'DeleteAppearanceAssetSuccess') {
          setError(t('manager.branding.logoAndFavicon.fileUpload.errors.assetDeletion'));
        }
      },
    });
  };

  const cancelUpload = () => {
    xhrRef.current?.abort();
    deleteAsset();
    setUploadProgress(0);
  };

  if (assetUrl) {
    return (
      <div className={styles.preview} data-testid={`${copyId}-preview`} {...props}>
        <div className={styles.file}>
          <img
            data-testid={`${copyId}-preview-image`}
            className={styles.image}
            src={assetUrl}
            alt={t(`manager.branding.logoAndFavicon.fileUpload.${assetType}.name`)}
          />

          <div data-testid={`${copyId}-preview-name`} className={styles.info}>
            <ImageOutlineIcon />

            <span>{t(`manager.branding.logoAndFavicon.fileUpload.${assetType}.name`)}</span>

            <Tooltip label={t('manager.branding.logoAndFavicon.fileUpload.deleteAsset')}>
              <IconButton
                rounded
                data-testid={`${copyId}-delete-button`}
                type="button"
                disabled={deletingAsset}
                onClick={deleteAsset}
                aria-label={t('manager.branding.logoAndFavicon.fileUpload.deleteAsset')}
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {error && (
          <span data-testid={`${copyId}-error-message`} className={styles.errorMessage}>
            {error} <AlertIcon />
          </span>
        )}
      </div>
    );
  }

  return (
    <FileUploader
      data-testid={`${copyId}-uploader`}
      testIdPrefix={`${copyId}-`}
      acceptedFormats={acceptedFormats}
      maximumFileSize={maximumFileSize}
      progress={uploadProgress}
      errorMessage={error}
      disabled={creatingAsset}
      additionalUploadInformation={additionalUploadInformation}
      heading={heading}
      onChange={uploadAsset}
      onClose={cancelUpload}
      {...props}
    />
  );
}
