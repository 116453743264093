import type { Presentation as PresentationType } from '../../../../generated/graphql-manager';

import {
  FileCard,
  Modal,
  ModalPortal,
  Paragraph,
  PresentationPreview,
  SecondaryButton,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { downloadFile } from '../../../../utils';
import { getPresentationStatus, usePresentationsProcessor } from '../../../providers/presentations-processor';

type Props = {
  presentation: PresentationType;
  disablePresentationDeletion?: boolean;
  onLoadStart?: () => void;
  onLoadEnd?: () => void;
};

export function Presentation({ presentation, disablePresentationDeletion, onLoadStart, onLoadEnd }: Props) {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const { presentationStatuses, deletePresentation, presentationLoading } = usePresentationsProcessor();

  const uploadProgress = presentationStatuses.find((status) => status.id === presentation.id)?.uploadProgress;
  const slides = presentation.slides || [];
  const { processedSlides, totalSlides, slidesInfoAvailable, processingFinished, processingFailed, processingPassed } =
    getPresentationStatus(presentation);

  const handleDelete = () => {
    deletePresentation({
      id: presentation.id,
      webcastId,
      language: presentation.language,
      groupId: presentation.groupId,
    });
  };

  const handleDownload = (url: string, fileName: string) => {
    downloadFile({
      url,
      fileName,
      onError: (event: ProgressEvent<XMLHttpRequestEventTarget>) => console.error('Download failed', event),
    });
  };

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const openErrorModal = () => setIsErrorModalOpen(true);
  const closeErrorModal = () => setIsErrorModalOpen(false);

  useEffect(() => {
    if (processingFailed) openErrorModal();
  }, [processingFailed]);

  useEffect(() => {
    if (presentationLoading) onLoadStart?.();
    else onLoadEnd?.();
  }, [onLoadEnd, onLoadStart, presentationLoading]);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const openPreview = () => setIsPreviewOpen(true);
  const closePreview = () => setIsPreviewOpen(false);

  const getProgressMessage = () => {
    const prefix = 'manager.webcastSetup.media.presentation.progress';

    if (processingFailed) {
      return t(`${prefix}.failed`);
    }

    if (processingPassed) {
      return t(`${prefix}.finished.count`, { count: totalSlides || 0 });
    }

    if (uploadProgress && uploadProgress < 100) {
      return t(`${prefix}.uploading`, { uploadProgress });
    }

    if (slidesInfoAvailable) {
      return t(`${prefix}.processing`, { processedSlides, totalSlides });
    }

    return t(`${prefix}.unknown`);
  };

  return (
    <>
      <FileCard
        key={presentation.id}
        horizontal
        fileName={presentation.sourceFileName}
        fileUrl={presentation.downloadLink}
        thumbnailUrl={slides[0]?.downloadUrl}
        numberOfSlides={presentation.status.slidesExtractionStatus.totalSlides || 0}
        spinner={!processingFinished}
        progressMessage={getProgressMessage()}
        disableDeletion={disablePresentationDeletion}
        onDelete={handleDelete}
        onDownload={presentation.downloadLink ? handleDownload : undefined}
        onThumbnailClick={openPreview}
      />

      <Modal
        data-testid="presentation-error-modal"
        title={t('manager.webcastSetup.media.presentation.errorModal.title')}
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        footer={
          <SecondaryButton
            data-testid="presentation-error-modal-delete-button"
            type="button"
            onClick={() => {
              handleDelete();
              closeErrorModal();
            }}
          >
            {t('manager.webcastSetup.media.presentation.errorModal.button')}
          </SecondaryButton>
        }
      >
        <Paragraph>{t('manager.webcastSetup.media.presentation.errorModal.info')}</Paragraph>
      </Modal>

      <ModalPortal isOpen={isPreviewOpen} onClose={closePreview}>
        <PresentationPreview
          slides={slides}
          onClose={closePreview}
          style={{ maxWidth: 1200, height: '100%', maxHeight: 830 }}
        />
      </ModalPortal>
    </>
  );
}
