import { useCallback, useMemo } from 'react';
import type { MenuOption, QualityLevelOption } from './settings-menu.types';

import { MenuOptions, MenuWrapper } from '../menu/menu-wrapper';
import { useTranslation } from 'react-i18next';
import { getQualityMenuOptions } from './settings-menu.utils';

type Props = {
  isOpen?: boolean;
  chosenQualityId?: string;
  qualities?: QualityLevelOption[];
  autoQualityOptionAvailable?: boolean;
  chosenPlaybackSpeedId?: string;
  playbackSpeeds?: MenuOption[];
  hidePlaybackSpeed?: boolean;
  onSetQuality?: (qualityId: string) => void;
  onSetPlaybackSpeed?: (playbackSpeedId: string) => void;
  onClose: () => void;
};

export function SettingsMenu({
  isOpen,
  chosenQualityId,
  qualities,
  autoQualityOptionAvailable,
  chosenPlaybackSpeedId,
  playbackSpeeds,
  hidePlaybackSpeed,
  onSetQuality,
  onSetPlaybackSpeed,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const SETTINGS_MENU_ID = 'settings-menu';
  const QUALITY_MENU_ID = 'quality-menu';
  const SPEED_MENU_ID = 'speed-menu';

  const menuOptions: MenuOptions = useMemo(() => {
    const showQualitySubmenu = !!qualities;
    const showPlaybackSpeedSubmenu = !hidePlaybackSpeed;

    const qualitySubmenu = showQualitySubmenu
      ? {
          id: QUALITY_MENU_ID,
          label: t('ui.menu.item.quality'),
          value: chosenQualityId,
          defaultValue: t('ui.menu.item.quality.auto'),
          showHeader: true,
          options: getQualityMenuOptions(t, qualities, autoQualityOptionAvailable),
        }
      : undefined;
    const speedSubmenu = showPlaybackSpeedSubmenu
      ? {
          id: SPEED_MENU_ID,
          label: t('ui.menu.item.speed'),
          value: chosenPlaybackSpeedId,
          showHeader: true,
          options: playbackSpeeds,
        }
      : undefined;
    const submenus = [qualitySubmenu, speedSubmenu].filter((item) => !!item);

    const menu =
      submenus.length === 1
        ? submenus[0]
        : {
            id: SETTINGS_MENU_ID,
            label: t('ui.menu.item.settings'),
            options: submenus,
          };

    return menu;
  }, [
    autoQualityOptionAvailable,
    chosenPlaybackSpeedId,
    chosenQualityId,
    hidePlaybackSpeed,
    playbackSpeeds,
    qualities,
    t,
  ]);

  const onChooseItem = useCallback(
    (currentScreen: string, itemId: string) => {
      if (currentScreen === QUALITY_MENU_ID) {
        onSetQuality?.(itemId);
      } else if (currentScreen === SPEED_MENU_ID) {
        onSetPlaybackSpeed?.(itemId);
      }
    },
    [onSetPlaybackSpeed, onSetQuality]
  );

  return (
    <MenuWrapper
      isOpen={isOpen}
      onClose={onClose}
      menuOptions={menuOptions}
      onChooseItem={onChooseItem}
      buttonName="settings-menu-button"
    />
  );
}
