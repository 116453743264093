import type { HTMLAttributes, ReactNode } from 'react';

import { classNames } from '@movingimage-evp/mi-ui-component-library';

import styles from './post-live-event-infobox.module.css';

export type PostLiveEventInfoboxProps = {
  icon?: ReactNode;
  title?: string;
  value?: string | number;
} & HTMLAttributes<HTMLDivElement>;

/**
 * @param {ReactNode} [icon] Icon to be display.
 * @default undefined
 * @param {string} [title] Title of the element.
 * @default undefined
 * @param {string} [value] Value of to the element.
 * @default undefined
 */
export function PostLiveEventInfobox({ className, icon, title, value, ...props }: PostLiveEventInfoboxProps) {
  return (
    <div className={classNames(styles.wrapper, className)} data-testid="post-live-event-infobox" {...props}>
      <div className={styles.mainInfo}>
        <div className={styles.title} data-testid="post-live-event-infobox-title">
          {icon}
          {title}
        </div>
        <span data-testid="post-live-event-infobox-value">{value}</span>
      </div>

      <div className={styles.content}>{icon}</div>
    </div>
  );
}
