import type { RestrictionType } from '../whitelist-modal/sso-whitelist-type-modal';

import {
  GhostButton,
  IconButton,
  Modal,
  Paragraph,
  PrimaryButton,
  Tooltip,
  TrashIcon,
  UserIcon,
} from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '../../../../components/search';

import styles from './whitelist.module.css';

type WhitelistProps = {
  type: RestrictionType;
  items: string[];
  addMoreButtonVisible?: boolean;
  onAdd: () => void;
  onRemove: (item: string) => void;
  onRemoveAll: () => void;
};

export function Whitelist({ type, items, addMoreButtonVisible, onAdd, onRemove, onRemoveAll }: WhitelistProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);

  const testId = `${type}-whitelist`;

  if (items.length === 0) return null;

  const showDeleteConfirmationModal = () => {
    setDeleteConfirmationVisible(true);
  };

  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationVisible(false);
  };

  const handleRemoveAll = () => {
    closeDeleteConfirmationModal();
    onRemoveAll();
  };

  const filteredItems = items.filter((item) => item.toLowerCase().includes(search.toLowerCase()));

  return (
    <div data-testid={testId} className={styles.wrapper}>
      <header>
        <span data-testid={`${testId}-length`}>
          {t(`manager.webcastSetup.viewerAccess.whitelist.attendesNumber.${type}`)} ({items.length})
        </span>

        {addMoreButtonVisible && (
          <PrimaryButton data-testid={`${testId}-add-attendees-button`} small onClick={onAdd}>
            {t('manager.webcastSetup.viewerAccess.whitelist.addAttendees')}
          </PrimaryButton>
        )}

        <GhostButton data-testid={`${testId}-delete-all-button`} small onClick={showDeleteConfirmationModal}>
          {t('manager.webcastSetup.viewerAccess.whitelist.deleteAll')}
        </GhostButton>
      </header>

      <Search
        placeholder={t('manager.webcastSetup.viewerAccess.whitelist.searchForValue')}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onClear={() => setSearch('')}
      />

      <div data-testid={`${testId}-items`} className={styles.items}>
        {filteredItems.map((item, index) => (
          <div key={index} data-testid={`${testId}-value-${index}`} className={styles.item}>
            <span>
              <UserIcon />
              {item}
            </span>

            <Tooltip label={t('manager.webcastSetup.viewerAccess.whitelist.removeValue')}>
              <IconButton
                data-testid={`${testId}-value-${index}-remove-button`}
                type="button"
                aria-label={t('manager.webcastSetup.viewerAccess.whitelist.removeValue')}
                rounded
                onClick={() => onRemove(item)}
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </div>
        ))}

        {filteredItems.length === 0 && (
          <Paragraph data-testid={`${testId}-no-results`}>
            {t('manager.webcastSetup.viewerAccess.whitelist.noValuesFound')}
          </Paragraph>
        )}
      </div>

      <Modal
        data-testid="delete-confirmation-modal"
        title={t('manager.webcastSetup.viewerAccess.whitelist.deleteConfirmationModal.title')}
        isOpen={deleteConfirmationVisible}
        onClose={closeDeleteConfirmationModal}
        footer={
          <div className={styles.deleteConfirmationModalFooter}>
            <GhostButton
              data-testid="delete-confirmation-modal-cancel-button"
              small
              onClick={closeDeleteConfirmationModal}
            >
              {t('manager.webcastSetup.viewerAccess.whitelist.deleteConfirmationModal.footer.cancel')}
            </GhostButton>

            <PrimaryButton data-testid="delete-confirmation-modal-submit-button" small onClick={handleRemoveAll}>
              {t('manager.webcastSetup.viewerAccess.whitelist.deleteConfirmationModal.footer.confirm')}
            </PrimaryButton>
          </div>
        }
      >
        <Paragraph>{t('manager.webcastSetup.viewerAccess.whitelist.deleteConfirmationModal.description')}</Paragraph>
      </Modal>
    </div>
  );
}
