import { CreditCardIcon, FileOutlineIcon, MenuButton, SecurityIcon } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

import accessDeniedStudioImage from '../../../assets/access-denied-studio.svg';
import { ErrorPage } from '../../../components/error-page/error-page';
import { useAbsoluteRoutes } from '../../../routes';
import { useFeatureToggle } from '../../../service/feature-toggle/feature-toggle';
import { useUserPermissions } from '../../hooks/user-permissions';
import managerStyles from '../../manager.module.css';

export function ChannelSettings() {
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();
  const { isAccessToChannelSettingsAllowed } = useUserPermissions();

  const detailsAndUsageAvailable = useFeatureToggle('detailsAndUsage');

  if (!isAccessToChannelSettingsAllowed) {
    return (
      <ErrorPage
        data-testid="access-denied"
        title={t('common.accessDenied.title')}
        subTitle={t('common.accessDenied.subTitle')}
        image={accessDeniedStudioImage}
        style={{ gridArea: 'section', height: 'unset', background: 'none' }}
      />
    );
  }

  return (
    <section className={managerStyles.section} data-testid="channel-settings">
      <nav className={managerStyles.navigation}>
        {detailsAndUsageAvailable && (
          <MenuButton
            component={NavLink}
            to={routes.channelSettings_planDetailsAndUsage}
            data-testid="channel-settings-plan-details-and-usage-link"
          >
            <CreditCardIcon />
            {t('manager.channelSettings.navigation.planDetailsAndUsage')}
          </MenuButton>
        )}

        <MenuButton
          component={NavLink}
          to={routes.channelSettings_compliance}
          data-testid="channel-settings-compliance"
        >
          <FileOutlineIcon />
          {t('manager.channelSettings.navigation.compliance')}
        </MenuButton>

        <MenuButton component={NavLink} to={routes.channelSettings_security} data-testid="channel-settings-security">
          <SecurityIcon />
          {t('manager.channelSettings.navigation.security')}
        </MenuButton>
      </nav>

      <Outlet />
    </section>
  );
}
