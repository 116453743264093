import { UserInterfaceProps } from './user-interface.types';
import { useRef, useState, Suspense, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import { FullscreenButton } from '../fullscreen-button';
import { LoopButton } from '../loop-button';
import { PlayButton } from '../play-button';
import { StepBackButton, StepForwardButton } from '../step-buttons';
import { Subtitles } from '../subtitles';
import { Timeline } from '../timeline';
import { Timer } from '../timer';
import { Trimmer } from '../trimmer';
import { Overlay } from '../overlay';
import { Volume } from '../volume';
import { SettingsMenu, SettingsMenuButton } from '../settings-menu';
import { LanguageMenu, LanguageMenuButton } from '../language-menu';
import { Spinner } from '../spinner/spinner';
import { WatermarkOverlay } from '../watermark-overlay';
import { useKeyboardShortcuts } from './hooks/use-keyboard-shortcuts';
import { useHover } from '../../hooks/use-hover';
import { useMouseMoving } from '../../hooks/use-mouse-moving';
import { useDynamicSize } from '../../hooks/use-dynamic-size';
import { useKeyboardNavigating } from '../../hooks/use-keyboard-navigating';
import { useElementsVisibilities } from './hooks/use-elements-visibilities';
import { AudioAnimationOverlay } from '../audio-animation-overlay';
import { TopBar } from '../top-bar/top-bar';
import { LiveBadge } from '../../components/live-badge';
import { MobileOverlay } from '../../components/mobile-overlay';
import { ChaptersMenu, ChaptersMenuButton } from '../../components/chapters-menu';
import { RelatedVideos } from '../related-videos/related-videos';

import styles from './user-interfaces.module.css';

export function UserInterface({
  elementVisibilitySettings = {},
  breakpoints = [],
  isMobile = false,
  isPlaying = false,
  isStopped = true,
  logoUrl = '',
  title = '',
  currentTime = 0,
  totalTime = 0,
  bufferPercentage = 0,
  isLooping = false,
  volume = 1,
  isFullscreen = false,
  liveUI = false,
  audioOnly = false,
  audioOnlyImageUrl = '',
  subtitlesEnabled = false,
  subtitle = '',
  subtitleOptionId = '',
  subtitleOptions = [],
  qualityId = '',
  qualities = [],
  autoQualityOptionAvailable = true,
  playbackSpeedId = '',
  playbackSpeeds = [],
  storyboard,
  chapters,
  downloadSources,
  watermark,
  isTrimmable = false,
  trimStartTime = 0,
  trimEndTime = 0,
  isMuted = false,
  onPlaybackToggle,
  onTimeChange,
  onTimeDeltaChange,
  onLoopButtonClick,
  onVolumeChange,
  onVolumeDeltaChange,
  onMuteChange,
  onFullscreenToggle,
  onSetSubtitle,
  onSetQuality,
  onSetPlaybackSpeed,
  onTrimStartTimeChange,
  onTrimEndTimeChange,
  audioTrackId = '',
  audioTracks = [],
  onSetAudioTrack,
  onOverlayVisibilityChange,
  onChapterClick,
  relatedVideos,
  onRelatedVideoClick,
  theme,
}: UserInterfaceProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const topBarRef = useRef<HTMLDivElement>(null);
  const bottomBarRef = useRef<HTMLDivElement>(null);
  const isMouseMovingOverWrapper = useMouseMoving(wrapperRef);
  const isKeyboardFocusOverTopBar = useKeyboardNavigating(topBarRef);
  const isKeyboardFocusOverBottomBar = useKeyboardNavigating(bottomBarRef);
  const isBottomBarHovered = useHover(bottomBarRef);
  const { width: wrapperWidth } = useDynamicSize(wrapperRef);
  const {
    showLogo,
    showTitle,
    showTopbar,
    showPlayButton,
    showTimeline,
    showVolumeInput,
    showVolumeSlider,
    showVideoLength,
    showLoopButton,
    showSubtitles,
    showSettingsButton,
    showFullscreenButton,
    showPlaybackSpeed,
    showBigPlayButton,
    showStepButtons,
    showChaptersButton,
    showRelatedVideos: relatedVideosAvailable,
    verticalVolumeSlider,
    smallScreen,
  } = useElementsVisibilities(wrapperWidth, elementVisibilitySettings, breakpoints);

  const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false);
  const [languageMenuIsOpen, setLanguageMenuIsOpen] = useState(false);
  const [chaptersMenuIsOpen, setChaptersMenuIsOpen] = useState(false);
  const [dlSourcesMenuMenuIsOpen, setDlSourcesMenuIsOpen] = useState(false);
  const menuIsOpen = chaptersMenuIsOpen || languageMenuIsOpen || settingsMenuIsOpen || dlSourcesMenuMenuIsOpen;

  const lastKeyboardAction = useKeyboardShortcuts(
    wrapperRef,
    onVolumeDeltaChange,
    onTimeDeltaChange,
    onMuteChange,
    isMuted,
    menuIsOpen,
    onPlaybackToggle,
    onFullscreenToggle
  );

  const toggleDlSourcesMenu = useCallback(() => {
    setDlSourcesMenuIsOpen((state) => !state);
  }, []);

  const closeDlSourcesMenu = useCallback(() => {
    setDlSourcesMenuIsOpen(false);
  }, []);

  const toggleSettingsMenu = useCallback(() => {
    setSettingsMenuIsOpen((state) => !state);
  }, []);

  const closeSettingsMenu = useCallback(() => {
    setSettingsMenuIsOpen(false);
  }, []);

  const toggleLanguageMenu = useCallback(() => {
    setLanguageMenuIsOpen((state) => !state);
  }, []);

  const toggleChaptersMenu = useCallback(() => {
    setChaptersMenuIsOpen((state) => !state);
  }, []);

  const closeChaptersMenu = useCallback(() => {
    setChaptersMenuIsOpen(false);
  }, []);

  const closeLanguageMenu = useCallback(() => {
    setLanguageMenuIsOpen(false);
  }, []);

  const onBackButtonClick = useCallback(() => {
    onTimeDeltaChange?.(-10000);
  }, [onTimeDeltaChange]);

  const onForwardButtonClick = useCallback(() => {
    onTimeDeltaChange?.(10000);
  }, [onTimeDeltaChange]);

  const controlsAreHidden =
    isPlaying && !isMouseMovingOverWrapper && !isKeyboardFocusOverBottomBar && !isKeyboardFocusOverTopBar;
  const logoIsVisible = showLogo && logoUrl !== '';
  const titleIsVisible = showTitle && title !== '';
  const downloadSourcesExist = downloadSources && downloadSources.length > 0;
  const timelineIsVisible = showTimeline && totalTime > 0;
  const showRelatedVideos = !!relatedVideos?.length && isStopped && relatedVideosAvailable;
  const topBarIsVisible = showTopbar && !showRelatedVideos && (logoIsVisible || titleIsVisible || downloadSourcesExist);
  const subtitlesAreVisible = subtitlesEnabled && subtitle !== '';
  const subtitlesHaveSpaceForBottomBar = isBottomBarHovered || !controlsAreHidden;
  const languageMenuIsVisible =
    showSubtitles && ((audioTracks && audioTracks?.length > 0) || subtitleOptions.length > 0);
  const chaptersMenuIsVisible = showChaptersButton && chapters && chapters?.length > 0;

  useEffect(() => {
    onOverlayVisibilityChange?.(controlsAreHidden);
  }, [controlsAreHidden, onOverlayVisibilityChange]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.wrapper, { smallScreen }, { menuIsOpen }, { [theme?.toString() || 'dark']: true })}
      data-testid="player-wrapper"
      tabIndex={-1}
    >
      <Spinner />

      <WatermarkOverlay watermark={watermark} />

      {topBarIsVisible && (
        <TopBar
          ref={topBarRef}
          isHidden={controlsAreHidden}
          logoUrl={logoIsVisible ? logoUrl : ''}
          title={titleIsVisible ? title : ''}
          downloadSources={downloadSources}
          downloadSourcesMenuOpen={dlSourcesMenuMenuIsOpen}
          onDownloadSourcesMenuButtonClick={toggleDlSourcesMenu}
          onDownloadSourcesMenuClose={closeDlSourcesMenu}
        />
      )}

      {showRelatedVideos && (
        <RelatedVideos videos={relatedVideos} onClick={onRelatedVideoClick} smallScreen={smallScreen} />
      )}

      {audioOnly && <AudioAnimationOverlay imageUrl={audioOnlyImageUrl} isPlaying={isPlaying} isStopped={isStopped} />}

      <div
        ref={bottomBarRef}
        className={classNames(styles.bottomBar, { [styles.hidden]: !isTrimmable && controlsAreHidden })}
        data-testid="bottom-bar"
      >
        {timelineIsVisible && (
          <Suspense fallback={null}>
            {isTrimmable ? (
              <Trimmer
                wrapper={wrapperRef.current}
                isPlaying={isPlaying}
                startTime={trimStartTime}
                endTime={trimEndTime}
                currentTime={currentTime}
                totalTime={totalTime}
                storyboard={storyboard}
                onChange={onTimeChange}
                onTrimStartTimeChange={onTrimStartTimeChange}
                onTrimEndTimeChange={onTrimEndTimeChange}
              />
            ) : (
              <div>
                {!liveUI && (
                  <Timer
                    className={classNames('left-side', styles.timelineTimer)}
                    currentTime={currentTime}
                    totalTime={totalTime}
                  />
                )}
                <Timeline
                  currentTime={currentTime}
                  totalTime={totalTime}
                  bufferPercentage={bufferPercentage}
                  liveUI={liveUI}
                  isMobile={isMobile}
                  isSmallScreen={smallScreen}
                  storyboard={storyboard}
                  onChange={onTimeChange}
                  chapters={chapters}
                />
              </div>
            )}
          </Suspense>
        )}

        <div className={styles.buttonsSection} data-testid="buttons-bottom-bar">
          {showPlayButton && <PlayButton className="left-side" isPlaying={isPlaying} onClick={onPlaybackToggle} />}
          {showVolumeInput && (
            <Volume
              className="left-side"
              value={volume}
              isMuted={isMuted}
              showVolumeSlider={showVolumeSlider}
              verticalVolumeSlider={verticalVolumeSlider}
              onChange={onVolumeChange}
              onMuteChange={onMuteChange}
            />
          )}
          {showStepButtons && (
            <>
              <StepBackButton className="left-side" onClick={onBackButtonClick} />
              <StepForwardButton className="left-side" onClick={onForwardButtonClick} />
            </>
          )}
          {showVideoLength &&
            (!liveUI ? (
              <Timer className="left-side" currentTime={currentTime} totalTime={totalTime} />
            ) : (
              <LiveBadge className="left-side" />
            ))}
          {showLoopButton && <LoopButton className="right-side" isLooping={isLooping} onClick={onLoopButtonClick} />}

          {chaptersMenuIsVisible && (
            <>
              <ChaptersMenuButton className="right-side" isActive={chaptersMenuIsOpen} onClick={toggleChaptersMenu} />
              <ChaptersMenu
                options={chapters}
                isOpen={chaptersMenuIsOpen}
                onClose={closeChaptersMenu}
                onChapterClick={onChapterClick}
                currentTime={currentTime}
              />
            </>
          )}
          {languageMenuIsVisible && (
            <>
              <LanguageMenuButton className="right-side" isActive={languageMenuIsOpen} onClick={toggleLanguageMenu} />
              <LanguageMenu
                isOpen={languageMenuIsOpen}
                chosenSubtitleId={subtitleOptionId}
                subtitles={subtitleOptions}
                subtitlesEnabled={subtitlesEnabled}
                onSetSubtitle={onSetSubtitle}
                onClose={closeLanguageMenu}
                audioTracks={audioTracks}
                onSetAudioTrack={onSetAudioTrack}
                chosenAudioTrackId={audioTrackId}
              />
            </>
          )}

          {showSettingsButton && (
            <>
              <SettingsMenuButton className="right-side" isActive={settingsMenuIsOpen} onClick={toggleSettingsMenu} />
              <SettingsMenu
                isOpen={settingsMenuIsOpen}
                chosenQualityId={qualityId}
                qualities={qualities}
                autoQualityOptionAvailable={autoQualityOptionAvailable}
                chosenPlaybackSpeedId={playbackSpeedId}
                playbackSpeeds={playbackSpeeds}
                hidePlaybackSpeed={!showPlaybackSpeed}
                onSetQuality={onSetQuality}
                onSetPlaybackSpeed={onSetPlaybackSpeed}
                onClose={closeSettingsMenu}
              />
            </>
          )}

          {showFullscreenButton && (
            <FullscreenButton className="right-side" isFullscreen={isFullscreen} onClick={onFullscreenToggle} />
          )}
        </div>
      </div>

      {subtitlesAreVisible && (
        <Subtitles withSpaceForBottomBar={subtitlesHaveSpaceForBottomBar} wrapperWidth={wrapperWidth}>
          {subtitle}
        </Subtitles>
      )}

      {!showRelatedVideos ? (
        !isMobile ? (
          <Overlay
            key={showBigPlayButton ? 'big-play-button' : 'big-play-button-disabled'} // To force re-mount when showBigPlayButton changes
            isPlaying={isPlaying}
            isStopped={isStopped}
            isTrimmable={isTrimmable}
            volume={volume}
            isMuted={isMuted}
            wrapperRef={wrapperRef}
            onClick={onPlaybackToggle}
            lastKeyboardAction={lastKeyboardAction}
            enableBigPlayButton={showBigPlayButton}
          />
        ) : (
          <MobileOverlay
            isPlaying={isPlaying}
            liveUI={false}
            isHidden={controlsAreHidden}
            onPlayClick={onPlaybackToggle}
            onBackButtonClick={onBackButtonClick}
            onForwardButtonClick={onForwardButtonClick}
            showBigPlayButton={true}
          />
        )
      ) : null}
    </div>
  );
}
