import type { WebcastContent } from '../../../generated/graphql-manager';
import type { WebcastWithTitle } from '../../pages/webcasts/types';

import { useParams } from 'react-router-dom';

import { useGetPrimaryTitleQuery } from '../../../generated/graphql-manager';

export function useGetPrimaryTitle(): WebcastWithTitle['title'] {
  const { webcastId = '' } = useParams();
  const { data } = useGetPrimaryTitleQuery({ variables: { webcastId }, skip: !webcastId });

  const { primaryLanguage, contents } = data?.webcast || {};

  return getWebcastTitle(primaryLanguage, contents);
}

export const getWebcastTitle = (
  primaryLanguage?: WebcastWithTitle['primaryLanguage'],
  contents?: Partial<WebcastContent>[]
): WebcastWithTitle['title'] => {
  const primaryTitle = contents?.find((content) => content.language === primaryLanguage)?.title;

  return primaryTitle || '';
};
