import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { BigPlayButton } from '../big-play-button';
import { StepBackIcon } from '../../icons/step-back';
import { StepForwardIcon } from '../../icons/step-forward';

import styles from './mobile-overlay.module.css';

type Props = {
  isPlaying?: boolean;
  liveUI?: boolean;
  isHidden?: boolean;
  doubleClickDelay?: number;
  onPlayClick?: () => void;
  onBackButtonClick?: () => void;
  onForwardButtonClick?: () => void;
  showBigPlayButton?: boolean;
};

export function MobileOverlay({
  isPlaying = false,
  liveUI = false,
  isHidden = false,
  doubleClickDelay = 300,
  onPlayClick,
  onBackButtonClick,
  onForwardButtonClick,
  showBigPlayButton = true,
}: Props) {
  const { t } = useTranslation();

  const [showStepBackIcon, setShowStepBackIcon] = useState(false);

  const backButtonClick = () => {
    setShowStepBackIcon(true);
    onBackButtonClick?.();
  };

  const handleStepBackIconAnimationEnd = () => {
    setShowStepBackIcon(false);
  };

  const [showStepForwardIcon, setShowStepForwardIcon] = useState(false);

  const forwardButtonClick = () => {
    setShowStepForwardIcon(true);
    onForwardButtonClick?.();
  };

  const handleStepForwardIconAnimationEnd = () => {
    setShowStepForwardIcon(false);
  };

  const [clickTimestamp, setClickTimestamp] = useState(0);

  const handleStepButtonClick = (callback?: () => void) => () => {
    const now = Date.now();

    if (now - clickTimestamp < doubleClickDelay) callback?.();

    setClickTimestamp(now);
  };

  return (
    <>
      <div className={classNames(styles.wrapper, { [styles.hidden]: isHidden })} data-testid="overlay">
        {!liveUI && (
          <button
            type="button"
            name="step-back-mobile-button"
            aria-label={t('ui.step-back.button.label')}
            className={styles.stepButton}
            onClick={handleStepButtonClick(backButtonClick)}
            data-testid="step-back-mobile-button"
          >
            {showStepBackIcon && <StepBackIcon onAnimationEnd={handleStepBackIconAnimationEnd} />}
          </button>
        )}

        {!liveUI && (
          <button
            type="button"
            name="step-forward-mobile-button"
            aria-label={t('ui.step-forward.button.label')}
            className={styles.stepButton}
            onClick={handleStepButtonClick(forwardButtonClick)}
            data-testid="step-forward-mobile-button"
          >
            {showStepForwardIcon && <StepForwardIcon onAnimationEnd={handleStepForwardIconAnimationEnd} />}
          </button>
        )}
      </div>

      {showBigPlayButton && (
        <BigPlayButton
          isPlaying={isPlaying}
          onClick={onPlayClick}
          className={isHidden ? styles.hiddenButton : undefined}
        />
      )}
    </>
  );
}
