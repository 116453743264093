import type { BaseChannel, MetaData, ThreadInfoUpdateEvent, User } from '@sendbird/chat/lib/__definition';
import type { OpenChannel, SendbirdOpenChat } from '@sendbird/chat/openChannel';
import type { ReactNode } from 'react';

import SendbirdChat, { ConnectionHandler } from '@sendbird/chat';
import { OpenChannelHandler, OpenChannelModule } from '@sendbird/chat/openChannel';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as generateId } from 'uuid';

import {
  type Action,
  type Chat,
  type ChatConfig,
  type ChatMessage,
  MESSAGES_LIMIT,
  type Reaction,
  type ReactionKey,
} from './chat.types';
import { setUserInformation } from '../../utils/user-information-utils';

export const Context = createContext<Chat>({
  user: undefined,
  messages: [],
  connected: false,
  connecting: false,
  providerError: undefined,
  userActionError: undefined,
  sendingMessage: false,
  editingMessage: false,
  deletingMessage: false,
  actions: [],
  connectUser: () => {},
  connectModerator: () => {},
  sendMessage: () => Promise.resolve(true),
  sendThreadMessage: () => Promise.resolve(undefined),
  editMessage: () => Promise.resolve(true),
  deleteMessage: () => Promise.resolve(true),
  reactToMessage: () => {},
  getReactionsByMessageId: () => ({
    heart: [],
    thumbsUp: [],
    laugh: [],
  }),
  getNumberOfReactionsByMessageId: () => 0,
  hasUserReactedToMessage: () => false,
  voteForMessage: () => {},
  getVotesByMessageId: () => 0,
  hasUserVotedForMessage: () => false,
});

export function ChatProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation();

  const sendbird = useRef<SendbirdOpenChat>();
  const channelRef = useRef<OpenChannel>();
  const errorTimeout = useRef<number>();

  const [user, setUser] = useState<User>();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [metadata, setMetadata] = useState<MetaData>({});
  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [editingMessage, setEditingMessage] = useState(false);
  const [deletingMessage, setDeletingMessage] = useState(false);
  const [userActionError, setUserActionError] = useState<string>();
  const [providerError, setProviderError] = useState<string>();

  let actions: Action[] = [];

  try {
    actions = Object.entries(metadata).map(([id, value]) => {
      const { type, userId, messageId } = JSON.parse(value);
      return { id, type, userId, messageId };
    });
  } catch (error) {
    console.error(error);
  }

  const createChat = async ({ userId, nickname, applicationId, channelUrl, eventId }: ChatConfig) => {
    if (!applicationId || !channelUrl) return setProviderError(t('common.chat.errors.missingConfiguration'));

    setConnecting(true);

    const connectionHandler = new ConnectionHandler({
      onReconnectSucceeded: () => {
        channelRef.current?.refresh();
        loadAllMessages();
      },
    });

    const chat = SendbirdChat.init({
      appId: applicationId,
      modules: [new OpenChannelModule()],
      customApiHost: (process.env.REACT_APP_SENDBIRD_CUSTOM_API_HOST || '').replace('{{id}}', applicationId),
      customWebSocketHost: (process.env.REACT_APP_SENDBIRD_CUSTOM_WS_HOST || '').replace('{{id}}', applicationId),
    });

    chat.addConnectionHandler('main-connection-handler', connectionHandler);
    sendbird.current = chat as SendbirdOpenChat;

    try {
      await chat.connect(userId);
    } catch {
      setProviderError(t('common.chat.errors.cannotConnectToChat'));
      return setConnecting(false);
    }

    chat.openChannel.addOpenChannelHandler(
      'main-handler',
      new OpenChannelHandler({
        onMessageReceived: (_, newMessage) => {
          if (!newMessage.parentMessage) setMessages((state) => [...state, newMessage as ChatMessage]);
        },
        onMessageUpdated: (_, updatedMessage) => {
          setMessages((state) =>
            state.map((message) =>
              message.messageId === updatedMessage.messageId ? (updatedMessage as ChatMessage) : message
            )
          );
        },
        onThreadInfoUpdated: async (channel: BaseChannel, threadInfoUpdateEvent: ThreadInfoUpdateEvent) => {
          const updatedMessage = (await chat.message.getMessage({
            messageId: threadInfoUpdateEvent.targetMessageId,
            channelType: channel.channelType,
            channelUrl: channel.url,
            includeThreadInfo: true,
          })) as ChatMessage;

          setMessages((state) =>
            state.map((existingMessage) =>
              existingMessage.messageId === updatedMessage.messageId ? updatedMessage : existingMessage
            )
          );
        },
        onMessageDeleted: (_, messageId) => {
          setMessages((state) => state.filter((item) => item.messageId !== messageId));
        },
        onMetaDataCreated(_, metaData) {
          setMetadata((state) => ({ ...state, ...metaData }));
        },
        onMetaDataDeleted(_, metaDataKeys) {
          metaDataKeys.forEach((key) => {
            setMetadata((state) => {
              const { [key]: _, ...newState } = state;
              return newState;
            });
          });
        },
      })
    );

    const user = chat.currentUser;
    const channel = await chat.openChannel.getChannel(channelUrl);
    channelRef.current = channel;

    if (nickname) {
      setUserInformation(eventId, { nickname });
      await chat.updateCurrentUserInfo({ nickname });
    }

    try {
      await channel.enter();
    } catch {
      setProviderError(t('common.chat.errors.cannotConnectToChannel'));
      return setConnecting(false);
    }

    if (user) setUser(user);
    await loadAllMessages();
    setMetadata(await channel.getAllMetaData());
    setConnecting(false);
    setConnected(true);
  };

  const getMessagesByMessageId = async (id: number) =>
    id
      ? ((await channelRef.current?.getMessagesByMessageId(id, {
          prevResultSize: MESSAGES_LIMIT,
          nextResultSize: 0,
          includeParentMessageInfo: true,
          includeThreadInfo: true,
        })) as ChatMessage[])
      : [];

  const getMessagesByTimestamp = async (timestamp: number) =>
    timestamp
      ? ((await channelRef.current?.getMessagesByTimestamp(timestamp, {
          prevResultSize: MESSAGES_LIMIT,
          nextResultSize: 0,
          includeParentMessageInfo: true,
          includeThreadInfo: true,
        })) as ChatMessage[])
      : [];

  const loadAllMessages = async () => {
    const latestMessages = await getMessagesByTimestamp(Date.now());

    if (latestMessages.length < MESSAGES_LIMIT) {
      setMessages(latestMessages);
      return;
    }

    let firstMessageId = latestMessages[0]?.messageId;
    const allMessages = [...latestMessages];

    while (firstMessageId) {
      const earlierMessages = await getMessagesByMessageId(firstMessageId);
      firstMessageId = earlierMessages[0]?.messageId;
      allMessages.unshift(...earlierMessages);
    }

    setMessages(allMessages);
  };

  const connectUser = ({ userId, nickname, applicationId, channelUrl, eventId }: ChatConfig) => {
    setUserInformation(eventId, { userId });
    createChat({ userId, nickname, applicationId, channelUrl, eventId });
  };

  const connectModerator = ({ userId, applicationId, channelUrl, eventId }: ChatConfig) => {
    if (!userId) return setProviderError(t('common.chat.errors.noModeratorIds'));

    createChat({ userId, applicationId, channelUrl, eventId });
  };

  const sendMessage = (message: ChatMessage['message'], customType?: ChatMessage['customType']) =>
    new Promise<boolean>((resolve) => {
      if (!customType && !message.trim()) return resolve(false);

      setSendingMessage(true);

      channelRef.current
        ?.sendUserMessage({ message, customType })
        .onSucceeded((message) => {
          setMessages((state) => [...state, message as ChatMessage]);
          setSendingMessage(false);
          resolve(true);
        })
        .onFailed(() => {
          setUserActionError(t('common.chat.errors.sendMessage'));
          setSendingMessage(false);
          resolve(false);
        });
    });

  const sendThreadMessage = (parentMessageId: number, message: ChatMessage['message']) =>
    new Promise<ChatMessage | undefined>((resolve) => {
      if (!message.trim()) return resolve(undefined);

      channelRef.current
        ?.sendUserMessage({ message, parentMessageId })
        .onSucceeded((message) => resolve(message as ChatMessage))
        .onFailed(() => {
          setUserActionError(t('common.chat.errors.sendThreadMessage'));
          resolve(undefined);
        });
    });

  const editMessage = async (
    messageId: ChatMessage['messageId'],
    message?: ChatMessage['message'],
    customType?: ChatMessage['customType']
  ) => {
    if (!customType && !message?.trim()) return false;

    const handleSuccess = (updatedMessage: ChatMessage) => {
      setMessages((state) => state.map((item) => (item.messageId === messageId ? updatedMessage : item)));
      setEditingMessage(false);
      return true;
    };

    const handleError = () => {
      setUserActionError(t('common.chat.errors.editMessage'));
      setEditingMessage(false);
      return false;
    };

    setEditingMessage(true);

    try {
      const updatedMessage = await channelRef.current?.updateUserMessage(messageId, { message, customType });
      return updatedMessage ? handleSuccess(updatedMessage as ChatMessage) : handleError();
    } catch {
      return handleError();
    }
  };

  const deleteMessage = async (message: ChatMessage) => {
    setDeletingMessage(true);

    try {
      await channelRef.current?.deleteMessage(message);
      setDeletingMessage(false);
      return true;
    } catch {
      setUserActionError(t('common.chat.errors.deleteMessage'));
      setDeletingMessage(false);
      return false;
    }
  };

  const reactToMessage = (messageId: ChatMessage['messageId'], type: ReactionKey) => {
    if (!user) return;

    const existingReaction = actions.find(
      (action) => action.userId === user.userId && action.messageId === messageId && action.type === type
    );

    if (existingReaction) {
      channelRef.current?.deleteMetaData(existingReaction.id);
    } else {
      channelRef.current?.createMetaData({
        [generateId()]: JSON.stringify({ type, userId: user.userId, messageId }),
      });
    }
  };

  const getReactionsByMessageId = (id: ChatMessage['messageId']): Reaction => {
    const reactions = actions.filter(({ type, messageId }) => type !== 'vote' && messageId === id);

    return {
      heart: reactions.filter(({ type }) => type === 'heart').map(({ userId }) => userId),
      laugh: reactions.filter(({ type }) => type === 'laugh').map(({ userId }) => userId),
      thumbsUp: reactions.filter(({ type }) => type === 'thumbsUp').map(({ userId }) => userId),
    };
  };

  const getNumberOfReactionsByMessageId = (messageId: ChatMessage['messageId'], type: ReactionKey) => {
    return actions.filter((action) => action.messageId === messageId && action.type === type).length;
  };

  const hasUserReactedToMessage = (messageId: ChatMessage['messageId'], type: ReactionKey) => {
    return actions.some(
      (action) => action.userId === user?.userId && action.messageId === messageId && action.type === type
    );
  };

  const voteForMessage = (messageId: ChatMessage['messageId']) => {
    if (!user) return;

    const existingVote = actions.find(
      (action) => action.userId === user.userId && action.messageId === messageId && action.type === 'vote'
    );

    if (existingVote) channelRef.current?.deleteMetaData(existingVote.id);
    else
      channelRef.current?.createMetaData({
        [generateId()]: JSON.stringify({ type: 'vote', userId: user.userId, messageId }),
      });
  };

  const getVotesByMessageId = (messageId: ChatMessage['messageId']) => {
    return actions.filter((action) => action.messageId === messageId && action.type === 'vote').length;
  };

  const hasUserVotedForMessage = (messageId: ChatMessage['messageId']) => {
    return actions.some(
      (action) => action.userId === user?.userId && action.messageId === messageId && action.type === 'vote'
    );
  };

  // Clear user errors after 3 seconds
  useEffect(() => {
    if (userActionError) {
      clearTimeout(errorTimeout.current);
      errorTimeout.current = window.setTimeout(() => setUserActionError(undefined), 3000);
    }
  }, [userActionError]);

  return (
    <Context.Provider
      value={{
        actions,
        user,
        messages,
        connected,
        connecting,
        providerError,
        userActionError,
        sendingMessage,
        editingMessage,
        deletingMessage,
        connectUser,
        connectModerator,
        sendMessage,
        sendThreadMessage,
        editMessage,
        deleteMessage,
        reactToMessage,
        getReactionsByMessageId,
        getNumberOfReactionsByMessageId,
        hasUserReactedToMessage,
        voteForMessage,
        getVotesByMessageId,
        hasUserVotedForMessage,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useChat = () => useContext(Context);
