import { ChevronRightIcon, Modal, SecondaryButton } from '@movingimage-evp/mi-ui-component-library';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { WhitelistModal } from './whitelist-modal';

import styles from './sso-whitelist-type-modal.module.css';

export type RestrictionType = 'emails' | 'employeeIds' | 'groups';

type EmailWhitelistModalProps = {
  isOpen: boolean;
  onConfirm: (type: RestrictionType, items: string[]) => void;
  onClose: () => void;
};

const TYPES = ['emails', 'employeeIds', 'groups'] as RestrictionType[];

export function SsoWhitelistTypeModal({ isOpen, onConfirm, onClose }: EmailWhitelistModalProps) {
  const [restrictionType, setRestrictionType] = useState<RestrictionType>();

  useEffect(() => {
    if (!isOpen) setRestrictionType(undefined);
  }, [isOpen]);

  const clearType = () => {
    setRestrictionType(undefined);
  };

  const confirm = (type: RestrictionType) => (items: string[]) => {
    onConfirm(type, items);
  };

  return (
    <>
      <Modal
        className={styles.modal}
        data-testid="sso-whitelist-type-modal"
        title={t('manager.webcastSetup.viewerAccess.options.sso.ssoWhitelistTypeModalTitle')}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={styles.content}>
          {TYPES.map((type) => (
            <SecondaryButton
              key={type}
              data-testid={`sso-whitelist-${type}-button`}
              onClick={() => setRestrictionType(type)}
            >
              {t(`manager.webcastSetup.viewerAccess.options.sso.ssoWhitelistTypeModalOptions.${type}`)}
              <ChevronRightIcon />
            </SecondaryButton>
          ))}
        </div>
      </Modal>

      {TYPES.map((type) => (
        <WhitelistModal
          key={type}
          type={type}
          isOpen={isOpen && restrictionType === type}
          onGoBack={clearType}
          onClose={onClose}
          onConfirm={confirm(type)}
        />
      ))}
    </>
  );
}
