import type { GetWebcastSummaryManagerQuery, Video } from '../../../../../../generated/graphql-manager';

import {
  CheckIcon,
  FileIcon,
  LinkStyleButton,
  Paragraph,
  PrimaryButton,
  Spinner,
  Tooltip,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { fetchVideoDetails } from '../../../../../../common';
import { useEnableVodMutation, useUpdateVodMutation } from '../../../../../../generated/graphql-manager';
import { secondsToHHMMSS } from '../../../../../../utils';
import { VideoBrowser } from '../../../../../components/video-manager-browser/video-browser';
import { useVmproConnection } from '../../../../../providers/vmpro-connector';
import { EnableVodPopup } from '../enabled-vod-popup/enable-vod-popup';

import styles from './event-summary-vod.module.css';

type EventSummaryVodProps = {
  webcast: GetWebcastSummaryManagerQuery['webcast'];
  onVideoSelect: () => void;
};

type VideoStructuredData = {
  language: string;
  title: string;
  duration?: string;
  thumbnailUrl: string;
};

const VIDEO_MANAGER_OPEN_STATE = 'VIDEO_MANAGER_OPEN_STATE';

export function EventSummaryVod({ webcast, onVideoSelect }: EventSummaryVodProps) {
  const { t } = useTranslation();
  const { isVmproLinked, saveState } = useVmproConnection();
  const [searchParams] = useSearchParams();

  const [videoStructuredData, setVideoStructuredData] = useState<VideoStructuredData[]>([]);

  const [showEnableVodPopup, setShowEnableVodPopup] = useState(false);
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false);
  const [chosenLanguage, setChosenLanguage] = useState<string>();

  const [enableVodMutation, { loading: enablingVod }] = useEnableVodMutation();
  const [updateVodMutation] = useUpdateVodMutation();

  const webcastId = webcast.id;
  const primaryLanguage = webcast?.primaryLanguage || '';
  const additionalLanguages = webcast?.additionalLanguages || [];
  const languages = [primaryLanguage, ...additionalLanguages];
  const streaming = webcast?.streaming;
  const vodVideos = streaming?.vod?.streams;
  const vodEnabled = streaming?.vod?.enabled;
  const areVideosChoosen =
    vodVideos?.length === languages.length && vodVideos.every((vodVideo) => vodVideo.reference.videoId);
  const uploadChannels = webcast?.recordingSettings?.videoManager?.channels;
  const uploadVideoManagerId = webcast?.recordingSettings?.videoManager?.videoManagerId;

  useEffect(() => {
    const previousState = searchParams.get('state');

    if (!previousState) return;

    if (previousState === VIDEO_MANAGER_OPEN_STATE && isVmproLinked) {
      setFileBrowserOpen(true);
    }
  }, [searchParams, isVmproLinked]);

  useEffect(() => {
    let ignoreResult = false;

    if (vodVideos) {
      vodVideos.forEach((vodVideo) => {
        const { reference, metadata } = vodVideo;

        fetchVideoDetails(reference.videoId, reference.channelId).then((videoData) => {
          if (ignoreResult) return;

          const title = videoData?.playerData.metadata?.title;
          const thumbnailUrl = videoData?.playerData.poster;
          const duration = videoData?.playerData.metadata?.durationInMs;

          setVideoStructuredData((prev) => {
            const videoIndex = prev.findIndex((video) => video.language === metadata?.language);

            const videoData = {
              language: metadata?.language || '',
              title: title || '',
              thumbnailUrl: thumbnailUrl || '',
              duration: duration ? secondsToHHMMSS(duration / 1000) : undefined,
            };

            if (videoIndex === -1) {
              return [...prev, videoData];
            }

            return [...prev.slice(0, videoIndex), videoData, ...prev.slice(videoIndex + 1)];
          });
        });
      });
    } else {
      setVideoStructuredData([]);
    }

    return () => {
      ignoreResult = true;
    };
  }, [vodVideos]);

  const handleEnableVod = () => {
    setShowEnableVodPopup(false);

    enableVodMutation({
      variables: { input: { webcastId } },
      onCompleted: onVideoSelect,
    });
  };

  const setVodVideo = ({ id: videoId, channelId }: Video) => {
    if (!chosenLanguage) return;

    setFileBrowserOpen(false);

    updateVodMutation({
      variables: {
        input: {
          webcastId,
          language: chosenLanguage,
          video: {
            channelId,
            videoId,
          },
        },
      },
      onCompleted: onVideoSelect,
    });
  };

  const toggleFileBrowserVisibility = (language?: string) => {
    setChosenLanguage(language);
    setFileBrowserOpen((state) => !state);

    if (!isVmproLinked) {
      saveState(VIDEO_MANAGER_OPEN_STATE);
    }
  };

  return (
    <>
      <div data-testid="vod-container" className={styles.container}>
        <Paragraph>{t('manager.eventSummary.videoOnDemand.title')}</Paragraph>

        {languages.map((language) => {
          const videoInfo = videoStructuredData.find((video) => video.language === language);

          return (
            <div key={language} data-testid={`vod-content-${language}`} className={styles.vodContent}>
              {videoInfo && (
                <span
                  data-testid="selected-video-thumbnail"
                  className={classNames(styles.videoContainer, styles.videoThumbnail)}
                  style={{ backgroundImage: `url('${videoInfo.thumbnailUrl}')` }}
                >
                  <span className={styles.time}>{videoInfo.duration}</span>
                </span>
              )}

              {!videoInfo && (
                <button
                  type="button"
                  data-testid="select-video-button"
                  className={classNames(styles.videoContainer, styles.selectVideoButton)}
                  onClick={() => toggleFileBrowserVisibility(language)}
                >
                  <FileIcon />
                  {t('manager.eventSummary.videoOnDemand.selectVideoButton')}
                </button>
              )}

              <div className={styles.selectedVodContainer}>
                <div className={styles.selectedVodTitle}>
                  <FileIcon />
                  {t('manager.eventSummary.videoOnDemand.selectedVideoTitle')}
                </div>

                {videoInfo && (
                  <>
                    <Tooltip label={videoInfo?.title}>
                      <span className={styles.selectedVodName} data-testid="selected-video-name">
                        {videoInfo?.title}
                      </span>
                    </Tooltip>

                    <LinkStyleButton
                      data-testid="change-video-button"
                      className={styles.selectVideo}
                      onClick={() => toggleFileBrowserVisibility(language)}
                    >
                      {t('manager.eventSummary.videoOnDemand.changeVideoButton')}
                    </LinkStyleButton>
                  </>
                )}
              </div>
            </div>
          );
        })}

        {!vodEnabled ? (
          <Tooltip
            label={
              languages.length > 1
                ? t('manager.eventSummary.videoOnDemand.switchToOnDemandButtonTooltip.multiLanguage')
                : t('manager.eventSummary.videoOnDemand.switchToOnDemandButtonTooltip.singleLanguage')
            }
            hidden={areVideosChoosen}
          >
            <PrimaryButton
              data-testid="enable-vod-button"
              small
              disabled={!areVideosChoosen || enablingVod}
              onClick={() => setShowEnableVodPopup(true)}
              className={styles.onDemandButton}
            >
              {enablingVod ? <Spinner size={18} /> : t('manager.eventSummary.videoOnDemand.switchToOnDemandButton')}
            </PrimaryButton>
          </Tooltip>
        ) : (
          <div data-testid="vod-enabled" className={styles.vodEnabled}>
            {t('manager.eventSummary.videoOnDemand.onDemandSwitchedOn')} <CheckIcon />
          </div>
        )}
      </div>

      <VideoBrowser
        title={t('manager.eventSummary.videoOnDemand.videoBrowserTitle')}
        isOpen={fileBrowserOpen}
        onChooseVideo={setVodVideo}
        onClose={() => toggleFileBrowserVisibility(undefined)}
        initialChannelId={uploadChannels?.[0].id}
        initialVideoManagerId={uploadVideoManagerId}
      />

      <EnableVodPopup
        isOpen={showEnableVodPopup}
        onClose={() => setShowEnableVodPopup(false)}
        onSwitchToOnDemand={handleEnableVod}
      />
    </>
  );
}
