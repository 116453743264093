import { useState, useEffect } from 'react';

import type { VideoJsPlayer } from 'video.js';
import type { QualityLevelList } from 'videojs-contrib-quality-levels';

import type { ProgressiveSources } from '../player.config';
import type { QualityLevelOption } from '../../user-interfaces';

import { isHLSSource } from '../../../utils/is-hls-source';
import { VideoJsNativeEvent } from '../videojs-event';

export function useQualityLevels(
  player: VideoJsPlayer,
  progressiveSources: ProgressiveSources | undefined
): QualityLevelOption[] {
  const [options, setOptions] = useState<QualityLevelOption[]>([]);

  useEffect(() => {
    let qualityLevels: QualityLevelList;

    const onAddQualityLevel = () => {
      const options = createOptionsFromQualityLevels(qualityLevels).sort(byLabel);
      setOptions(options);
    };

    const onLoadSource = () => {
      if (isHLSSource(player.currentType())) {
        qualityLevels = player.qualityLevels();

        const options = createOptionsFromQualityLevels(qualityLevels).sort(byLabel);
        setOptions(options);

        qualityLevels.on('addqualitylevel', onAddQualityLevel);
      } else if (progressiveSources) {
        const options = createOptionsFromProgressiveSources(progressiveSources).sort(byLabel);
        setOptions(options);
      }
    };

    player.on(VideoJsNativeEvent.LOAD_START, onLoadSource);

    return () => {
      player.off(VideoJsNativeEvent.LOAD_START, onLoadSource);

      if (qualityLevels) {
        qualityLevels.off('addqualitylevel', onAddQualityLevel);
      }
    };
  }, [player, progressiveSources]);

  return options;
}

function createOptionsFromProgressiveSources(progressiveSources: ProgressiveSources) {
  return Object.keys(progressiveSources).map((qualityLevel) => ({
    id: qualityLevel,
    height: Number(qualityLevel.replace('p', '')),
  }));
}

function createOptionsFromQualityLevels(qualityLevels: QualityLevelList): QualityLevelOption[] {
  // the hls manifest can have multiple playlists for one quality,
  // but we want to show only one quality menu item
  // and the player choose which playlist is the right one for that quality
  return Array.from(qualityLevels).reduce<QualityLevelOption[]>((levels, current) => {
    const currentId = `${current.height}p`;
    const notIncluded = !levels.some((x) => x.id === currentId);

    if (notIncluded && current.height) {
      levels.push({
        id: currentId,
        height: current.height,
      });
    }

    return levels;
  }, []);
}

function byLabel(a: QualityLevelOption, b: QualityLevelOption) {
  return `${b.height}`.localeCompare(`${a.height}`, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}
