type FilterType = Record<string, object & { timeStamp: number }>;

/**
 * Filters out entries from the given map which are older than the given time
 * @param {FilerType} parsedData The data to filter
 * @param {number} currentTime The current time
 * @param {number} [maximumAge] The maximum age of the entry in hours
 * @default 48
 * @returns {FilterType} Filtered viewer information
 */
export function filterOlderEntries(parsedData: FilterType, currentTime: number, maximumAge = 48): FilterType {
  const filteredViewerInformation: FilterType = {};
  for (const key in parsedData) {
    const userInformation = parsedData[key];
    if (currentTime - userInformation.timeStamp < maximumAge * 60 * 60 * 1000) {
      filteredViewerInformation[key] = userInformation;
    }
  }
  return filteredViewerInformation;
}
