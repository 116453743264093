import type { ColorKeys, ColorSet } from './theme-and-color/theme-and-color';
import type { CSSProperties } from 'react';

import {
  Accordion,
  ImageIcon,
  ThemeIcon,
  TypographyIcon,
  classNames,
  hexToRgbArray,
} from '@movingimage-evp/mi-ui-component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FontAndTypography } from './font-and-typography';
import { LogoAndFavicon } from './logo-and-favicon';
import { ThemeAndColor } from './theme-and-color';
import {
  AppearanceAssetType,
  useGetLsproBrandingSettingsQuery,
  useUpdateLsproFontsMutation,
  useUpdateLsproThemeAndColorMutation,
} from '../../../generated/graphql-manager';
import { State, StreamState } from '../../../generated/graphql-viewer';
import { isValidHexValue, removeTypename } from '../../../utils';
import { Content } from '../../../viewer/components/content';
import { ViewerHeader } from '../../../viewer/views/stream/components/header/header';
import { ViewerPlayer } from '../../../viewer/views/stream/components/viewer-player';
import playerStyles from '../../../viewer/views/stream/stream.module.css';
import { useCurrentUser } from '../../hooks/current-user';

import styles from './branding.module.css';

export function BrandingPage() {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const { webcastId = '' } = useParams();

  const { data } = useGetLsproBrandingSettingsQuery({
    variables: { lsproId },
    onCompleted: ({ lsproSettings }) => {
      setChosenTheme(lsproSettings?.appearance?.theme);
      setBrandingColors((lsproSettings?.appearance?.appearanceColors || []).map(removeTypename));
      setColorErrors(
        (
          lsproSettings?.appearance?.appearanceColors.map(({ theme, ...colors }) =>
            Object.keys(colors).reduce((acc, key) => ({ ...acc, [key]: '' }), { theme, ...colors })
          ) || []
        ).map(removeTypename)
      );
      setHeadingFont(lsproSettings?.appearance?.fonts?.headingFont || '');
      setTextFont(lsproSettings?.appearance?.fonts?.textFont || '');
    },
  });

  const webcast = useRef({
    id: '',
    lsproId: '',
    accountId: '',
    state: State.LIVE,
    plannedStartAt: new Date().toISOString(),
    durationInMinutes: 60,
    primaryLanguage: 'de',
    additionalLanguages: [],
    contents: [
      {
        language: 'de',
        title: t('manager.branding.preview.title'),
        preLive: {},
        live: {
          description: JSON.stringify([
            {
              type: 'paragraph',
              children: [{ text: t('manager.branding.preview.description') }],
            },
            {
              type: 'link',
              href: 'https://www.movingimage.com/',
              children: [{ text: 'movingimage.com' }],
            },
          ]),
        },
        postLive: {},
      },
    ],
    securityPolicies: [],
    streaming: {
      live: {
        streams: [
          {
            playback: {
              primaryUrl: window.Cypress
                ? 'http://localhost:3030/hq/live.m3u8'
                : 'https://e.video-cdn.net/video/hls.m3u8?video-id=4Rh-xDMKzd_FRTgtV7gTEY',
              token: '',
            },
            primaryStatus: {
              streamState: StreamState.CONNECTED,
            },
            backupStatus: {
              streamState: StreamState.CONNECTED,
            },
            metadata: {
              language: 'de',
            },
          },
        ],
      },
    },
    pushedSlides: [],
  });

  const lsproAppearance = data?.lsproSettings?.appearance;
  const assets = lsproAppearance?.assets;

  // LogoAndFavicon
  const squareLogo = assets?.find(({ type }) => type === AppearanceAssetType.LOGO)?.downloadLink || '';
  const horizontalLogo = assets?.find(({ type }) => type === AppearanceAssetType.HORIZONTAL_LOGO)?.downloadLink || '';

  // ThemeAndColor
  const [theme, setChosenTheme] = useState(lsproAppearance?.theme);
  const [appearanceColors, setBrandingColors] = useState<ColorSet[]>(
    (lsproAppearance?.appearanceColors || []).map(removeTypename)
  );
  const [colorErrors, setColorErrors] = useState<ColorSet[]>([]);

  const [updateLsproThemeAndColorMutation, { loading: savingColors }] = useUpdateLsproThemeAndColorMutation();

  const hasErrors = colorErrors?.some(({ theme, ...errors }) => Object.values(errors).some(Boolean));
  const currentColors = appearanceColors?.find((colorSet) => colorSet.theme === theme);
  const primaryColor = currentColors?.primaryColor || '';
  const backgroundColor = currentColors?.backgroundColor || '';
  const cardColor = currentColors?.cardColor || '';
  const headingFontColor = currentColors?.headingFontColor || '';
  const textFontColor = currentColors?.textFontColor || '';

  const handleColorChange = (name: ColorKeys, value: string) => {
    setColorErrors((state) => state.map((color) => (color.theme === theme ? { ...color, [name]: '' } : color)));

    setBrandingColors((state) => state?.map((color) => (color.theme === theme ? { ...color, [name]: value } : color)));
  };

  const validateColorValue = (name: ColorKeys) => {
    setColorErrors((state) =>
      state.map((colorSet) => {
        if (colorSet.theme !== theme) return colorSet;

        const value = appearanceColors?.find((colorSet) => colorSet.theme === theme)?.[name] || '';

        return {
          ...colorSet,
          [name]: isValidHexValue(value) ? '' : t('manager.branding.themeAndColor.errors.invalidColorFormat'),
        };
      })
    );
  };

  const handleThemeAndColorSave = () => {
    if (hasErrors || savingColors) return;

    updateLsproThemeAndColorMutation({
      variables: {
        input: {
          lsproId,
          appearanceColors,
          theme,
        },
      },
    });
  };

  // FontAndTypography
  const [headingFont, setHeadingFont] = useState(lsproAppearance?.fonts.headingFont || '');
  const [textFont, setTextFont] = useState(lsproAppearance?.fonts.textFont || '');

  const [updateLsproFontsMutation, { loading: savingFonts }] = useUpdateLsproFontsMutation();

  const handleFontAndTypographySave = () => {
    if (savingFonts) return;

    updateLsproFontsMutation({
      variables: {
        input: {
          lsproId,
          fonts: {
            headingFont,
            textFont,
          },
        },
      },
    });
  };

  if (!theme) return null;

  return (
    <section className={classNames(styles.wrapper, playerStyles.wrapper)}>
      <div className={styles.menu}>
        <Accordion
          data-testid="branding-accordion-logo-and-favicon"
          buttonLabel={t('manager.branding.logoAndFavicon.title')}
          buttonContent={
            <>
              <ImageIcon />
              {t('manager.branding.logoAndFavicon.title')}
            </>
          }
        >
          <LogoAndFavicon />
        </Accordion>

        <Accordion
          data-testid="branding-accordion-theme-and-color"
          isInitiallyOpen
          buttonLabel={t('manager.branding.themeAndColor.title')}
          buttonContent={
            <>
              <ThemeIcon />
              {t('manager.branding.themeAndColor.title')}
            </>
          }
        >
          <ThemeAndColor
            theme={theme}
            appearanceColors={appearanceColors}
            errors={colorErrors}
            saving={savingColors}
            savingDisabled={hasErrors}
            onColorChange={handleColorChange}
            onInputBlur={validateColorValue}
            onThemeChange={setChosenTheme}
            onThemeAndColorSave={handleThemeAndColorSave}
          />
        </Accordion>

        <Accordion
          data-testid="branding-accordion-font-and-typography"
          buttonLabel={t('manager.branding.fontAndTypography.title')}
          buttonContent={
            <>
              <TypographyIcon />
              {t('manager.branding.fontAndTypography.title')}
            </>
          }
        >
          <FontAndTypography
            headingFont={headingFont}
            textFont={textFont}
            saving={savingFonts}
            onHeadingFontChange={setHeadingFont}
            onTextFontChange={setTextFont}
            onSave={handleFontAndTypographySave}
          />
        </Accordion>
      </div>

      <div
        data-testid="branding-preview"
        className={styles.preview}
        data-theme={theme.toLowerCase()}
        style={
          {
            '--primaryColor': hexToRgbArray(primaryColor).join(','),
            '--backgroundColor': hexToRgbArray(backgroundColor).join(','),
            '--cardColor': hexToRgbArray(cardColor).join(','),
            '--headingFontColor': hexToRgbArray(headingFontColor).join(','),
            '--textFontColor': hexToRgbArray(textFontColor).join(','),
            '--headingFont': headingFont,
            '--textFont': textFont,
          } as CSSProperties
        }
      >
        <ViewerHeader logoUrl={horizontalLogo || squareLogo} />

        <section>
          <div
            data-testid="branding-video-wrapper"
            className={classNames(styles.videoWrapper, playerStyles.videoWrapper)}
          >
            <ViewerPlayer
              className={styles.player}
              accountId={webcast.current.accountId}
              lsproId={lsproId}
              webcastId={webcastId}
              title={webcast.current.contents[0].title || ''}
              logoUrl={squareLogo || horizontalLogo}
              primaryColor={primaryColor}
              currentStream={webcast.current.streaming.live.streams[0]}
              muted
            />
          </div>

          <Content language="de" webcast={webcast.current} />
        </section>
      </div>
    </section>
  );
}
