import type { FeatureToggleName } from './feature-toggle-configuration';
import type { ReactNode } from 'react';

import { useEffect, useState } from 'react';

import { getLocalStorageItem } from '../../utils/local-storage';

export function useFeatureToggle(featureName: FeatureToggleName): boolean {
  const [featureToggleValue, setFeatureToggleValue] = useState(() => {
    const localStorageValue = getLocalStorageItem(featureName);
    return localStorageValue ? JSON.parse(localStorageValue) : false;
  });

  useEffect(() => {
    const localStorageValue = getLocalStorageItem(featureName);
    setFeatureToggleValue(localStorageValue ? JSON.parse(localStorageValue) : false);
  }, [featureName]);

  return featureToggleValue;
}

type Props = {
  featureName: FeatureToggleName;
  children: ReactNode;
};

export function FeatureToggle({ featureName, children }: Props) {
  const isFeatureToggleTurnedOn = useFeatureToggle(featureName);

  if (!isFeatureToggleTurnedOn) return null;

  return <>{children}</>;
}
