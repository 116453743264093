import type { HTMLAttributes } from 'react';

import { Heading, SubHeading, classNames } from '@movingimage-evp/mi-ui-component-library';

import styles from './error-page.module.css';

type Props = {
  title?: string;
  subTitle?: string;
  image?: string;
  'data-testid'?: string;
} & HTMLAttributes<HTMLDivElement>;

export function ErrorPage({ title, subTitle, image, className, ...props }: Props) {
  return (
    <div className={classNames(styles.wrapper, className)} {...props}>
      {image && <img data-testid={`${props['data-testid']}-image`} src={image} alt="" />}
      {title && <Heading data-testid={`${props['data-testid']}-title`}>{title}</Heading>}
      {subTitle && <SubHeading data-testid={`${props['data-testid']}-subtitle`}>{subTitle}</SubHeading>}
    </div>
  );
}
