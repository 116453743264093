import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { noop } from '../../utils';
import styles from '../menu/menu.module.css';
import { Menu } from '../menu/menu';
import { useMenuNavigation } from '../menu/use-menu-navigation';
import { ChaptersMenuButtonName } from './chapters-menu-button';
import { Chapter } from '../../components/timeline/timeline.types';
import { MenuOptions } from '../../components/menu/menu-wrapper';
import { ChaptersMenuItem } from './chapters-menu-item';

import chapterMenuStyles from './chapters-menu-item.module.css';

type ChaptersMenuProps = {
  isOpen?: boolean;
  options: Chapter[];
  onClose?: () => void;
  onChapterClick?: (chapter: Chapter) => void;
  currentTime?: number;
};

export function ChaptersMenu({ isOpen, options, onClose, onChapterClick, currentTime }: ChaptersMenuProps) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const menuOptions: MenuOptions = useMemo(
    () => ({
      id: 'chapters',
      label: t('ui.menu.item.chapters'),
      options: options?.map((option) => ({ id: `${option.start}`, label: option.title })) || [],
    }),
    [options, t]
  );

  const currentActiveChapter = useMemo(
    () =>
      currentTime
        ? options.find(
            (chapter, index, chapters) =>
              currentTime >= chapter.start && (!chapters[index + 1] || currentTime < chapters[index + 1].start)
          )
        : undefined,
    [currentTime, options]
  );

  useMenuNavigation({
    menuOptions,
    isOpen: !!isOpen,
    onClose: onClose ?? noop,
    buttonName: ChaptersMenuButtonName,
    wrapperRef,
  });

  const handleMenuItemClick = useCallback(
    (option: Chapter) => {
      onChapterClick?.(option);
      onClose?.();
    },
    [onClose, onChapterClick]
  );

  // Scroll to active chapter when the menu is opened
  useEffect(() => {
    if (isOpen) {
      wrapperRef.current
        ?.querySelectorAll(`.${chapterMenuStyles.active}`)?.[0]
        ?.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }, [isOpen]);

  return (
    <div ref={wrapperRef} className={classNames(styles.wrapper, !isOpen && styles.closed)} tabIndex={-1}>
      <Menu label={menuOptions.label} onClose={onClose} dataTestId="chapters-menu">
        {options.map((option) => (
          <ChaptersMenuItem
            key={`${option.start}`}
            className={styles.active}
            data-testid={`chapter-item-${option.start}`}
            onClick={() => handleMenuItemClick(option)}
            label={option.title}
            description={option.description || ''}
            startTime={option.start}
            thumbnail={option.thumbnail}
            isActive={!!currentActiveChapter && currentActiveChapter.start === option.start}
          />
        ))}
      </Menu>
    </div>
  );
}
