import type {
  GetWebcastSecurity_ReferrerProtectionPolicyFragment as SecurityPolicies,
  SecurityPolicy,
} from '../../../../generated/graphql-manager';
import type { ApolloCache } from '@apollo/client';

import {
  ChipsInput,
  InputChip,
  InputDescription,
  SaveButton,
  SelectionInputLayout,
  Toggle,
  Tooltip,
  classNames,
  usePrevious,
} from '@movingimage-evp/mi-ui-component-library';
import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';

import { validateInput } from './validate-input';
import { useUpdateReferrerProtectionPolicyMutation } from '../../../../generated/graphql-manager';
import { findReferrerProtectionPolicy } from '../../../../utils/graphql-helpers';

import styles from './restrict-embedding.module.css';

type RestrictEmbeddingProps = {
  webcastId: string;
  securityPolicies?: SecurityPolicy[];
  disabled?: boolean;
  isOpen?: boolean;
  readOnly?: boolean;
  onToggle?: (open: boolean) => void;
  onSaving?: (saving: boolean) => void;
};

type Domain = {
  value: string;
  valid: boolean;
  validationMessage?: string;
};

const DEFAULT_REFERRER_PROTECTION_ENABLED = false;

export function RestrictEmbedding({
  webcastId,
  securityPolicies,
  disabled,
  isOpen,
  readOnly,
  onToggle,
  onSaving,
}: RestrictEmbeddingProps) {
  const mapReferrerProtectionPolicyDomains = (domains: string[]) => {
    return domains.map((domain) => ({ value: domain, valid: true }));
  };

  const referrerProtectionPolicy = findReferrerProtectionPolicy(securityPolicies);

  const [referrerProtectionEnabled, setReferrerProtectionEnabled] = useState(
    referrerProtectionPolicy?.enabled ?? DEFAULT_REFERRER_PROTECTION_ENABLED
  );
  const previousReferrerProtectionEnabled = usePrevious(referrerProtectionEnabled);
  const [domains, setDomains] = useState<Domain[]>(
    mapReferrerProtectionPolicyDomains(referrerProtectionPolicy?.domains || [])
  );
  const [databaseDomains, setDatabaseDomains] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [savingFailed, setSavingFailed] = useState(false);

  const chipsInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (referrerProtectionPolicy) {
      setReferrerProtectionEnabled(referrerProtectionPolicy.enabled ?? DEFAULT_REFERRER_PROTECTION_ENABLED);
      setDomains(mapReferrerProtectionPolicyDomains(referrerProtectionPolicy?.domains || []));
      setDatabaseDomains(referrerProtectionPolicy?.domains || []);
    }
  }, [referrerProtectionPolicy]);

  useEffect(() => {
    if (isOpen && !previousReferrerProtectionEnabled && referrerProtectionEnabled) chipsInputRef?.current?.focus();
  }, [referrerProtectionEnabled, isOpen, previousReferrerProtectionEnabled]);

  const [updateReferrerProtectionMutation] = useUpdateReferrerProtectionPolicyMutation();

  const updateReferrerProtection = (referrerProtectionEnabled: boolean) => {
    const updatedReferredProtectionPolicy = {
      enabled: referrerProtectionEnabled,
      domains: domains.map((domain) => domain.value),
    };

    setSaving(true);

    updateReferrerProtectionMutation({
      variables: {
        input: {
          webcastId,
          ...updatedReferredProtectionPolicy,
        },
      },
      optimisticResponse: {
        updateReferrerProtectionPolicy: {
          __typename: 'UpdateReferrerProtectionPolicySuccess',
          referrerProtectionPolicy: { ...updatedReferredProtectionPolicy },
        },
      },
      update: (cache, { data }) => {
        if (data?.updateReferrerProtectionPolicy.__typename === 'UpdateReferrerProtectionPolicySuccess') {
          updateReferrerProtectionPolicy(data.updateReferrerProtectionPolicy.referrerProtectionPolicy, cache);
        }
      },
      onCompleted: (data) => {
        setSavingFailed(data.updateReferrerProtectionPolicy.__typename !== 'UpdateReferrerProtectionPolicySuccess');
        setSaving(false);
      },
    });
  };

  const toggleReferrerProtection = (referrerProtectionEnabled: boolean) => {
    const updatedReferredProtectionPolicy = {
      enabled: referrerProtectionEnabled,
    };

    onSaving?.(true);

    updateReferrerProtectionMutation({
      variables: {
        input: {
          webcastId,
          ...updatedReferredProtectionPolicy,
        },
      },
      optimisticResponse: {
        updateReferrerProtectionPolicy: {
          __typename: 'UpdateReferrerProtectionPolicySuccess',
          referrerProtectionPolicy: { ...updatedReferredProtectionPolicy },
        },
      },
      onCompleted: () => {
        onSaving?.(false);
      },
      update: (cache, { data }) => {
        if (data?.updateReferrerProtectionPolicy.__typename === 'UpdateReferrerProtectionPolicySuccess') {
          updateReferrerProtectionPolicy(data.updateReferrerProtectionPolicy.referrerProtectionPolicy, cache);
        }
      },
    });
  };

  const updateReferrerProtectionPolicy = (updatedPolicy: Partial<SecurityPolicies>, cache: ApolloCache<any>) => {
    cache.modify({
      id: cache.identify({ __typename: 'Webcast', id: webcastId }),
      fields: {
        securityPolicies: (securityPolicies) => {
          const referrerProtectionPolicyPresent =
            securityPolicies.find((policy: SecurityPolicies) => policy.__typename === 'ReferrerProtectionPolicy') !==
            undefined;

          if (referrerProtectionPolicyPresent) {
            return securityPolicies.map((policy: SecurityPolicies) =>
              policy.__typename === 'ReferrerProtectionPolicy' ? { ...policy, ...updatedPolicy } : policy
            );
          } else {
            return [...securityPolicies, updatedPolicy];
          }
        },
      },
    });
  };

  const validateDomains = (domains: Domain[]) => {
    const validatedDomains: Domain[] = [];
    const validatedValues = new Set<string>();

    for (const domain of domains) {
      const validationMessage = validateInput(domain.value, Array.from(validatedValues));

      const isValid = !validationMessage;
      validatedDomains.push({ value: domain.value, valid: isValid, validationMessage });

      if (isValid) {
        validatedValues.add(domain.value);
      }
    }

    return validatedDomains;
  };

  const onChipsAdded = (values: string[]) => {
    const newDomains = [...domains, ...values.map((value) => ({ value, valid: true }))];
    const validatedDomains = validateDomains(newDomains);
    setDomains(validatedDomains);
  };

  const onChipDeleted = (index: number) => {
    const domainsWithOutDeletedDomain = [...domains.slice(0, index), ...domains.slice(index + 1)];
    const validatedDomains = validateDomains(domainsWithOutDeletedDomain);
    setDomains(validatedDomains);
  };

  const onChipEdit = (value: string, index: number) => {
    const validationMessage = validateInput(
      value,
      domains.map((domain) => domain.value)
    );

    const updatedChips = [
      ...domains.slice(0, index),
      { value, valid: !validationMessage, validationMessage },
      ...domains.slice(index + 1),
    ];

    setDomains(updatedChips);
  };

  const allDomainsDeleted = domains.length === 0 && databaseDomains.length > 0 && referrerProtectionEnabled;

  const handleToggleReffererProtectionPolicy = () => {
    if (!referrerProtectionEnabled) onToggle?.(true);

    setReferrerProtectionEnabled(!referrerProtectionEnabled);

    if (domains.length > 0) {
      toggleReferrerProtection(!referrerProtectionEnabled);
    }
  };

  const onSaveChanges = () => {
    if (allDomainsDeleted) {
      setReferrerProtectionEnabled(false);
    }
    updateReferrerProtection(allDomainsDeleted ? false : referrerProtectionEnabled);
  };

  const validationMessage = useMemo(() => {
    const invalidDomains = domains.filter((domain) => !domain.valid);

    if (invalidDomains.length === 0) return undefined;
    if (invalidDomains.length === 1) return invalidDomains[0].validationMessage;

    return t('manager.webcastSetup.embedding.restrictEmbedding.errorMessages.multipleErrors');
  }, [domains]);

  const saveButtonDisabled = useMemo(() => {
    if (allDomainsDeleted) return false;
    if (domains.length === 0) return true;
    if (!referrerProtectionEnabled) return true;
    if (validationMessage && validationMessage.length > 0) return true;

    return disabled;
  }, [allDomainsDeleted, domains, referrerProtectionEnabled, validationMessage, disabled]);

  const inputInfo = useMemo(() => {
    if (!referrerProtectionEnabled) {
      return t('manager.webcastSetup.embedding.restrictEmbedding.disabledInputInfo');
    }

    if (validationMessage === undefined || validationMessage.length === 0) {
      return t('manager.webcastSetup.embedding.restrictEmbedding.inputInfo');
    }

    return validationMessage;
  }, [referrerProtectionEnabled, validationMessage]);

  const submitButtonInfo = useMemo(() => {
    if (allDomainsDeleted) {
      return t('manager.webcastSetup.embedding.restrictEmbedding.saveWithoutDomainsWarning');
    }
  }, [allDomainsDeleted]);

  const chipsInput = (
    <ChipsInput
      ref={chipsInputRef}
      separators={['Enter', ',', ' ']}
      onChipsAdded={onChipsAdded}
      disabled={!referrerProtectionEnabled || disabled || readOnly}
    >
      {domains?.map((domain, index) => (
        <InputChip
          key={`${domain.value}-${index}`}
          value={domain.value}
          invalid={!domain.valid}
          disabled={!referrerProtectionEnabled || disabled || readOnly}
          onDelete={() => onChipDeleted(index)}
          onEdit={(value: string) => onChipEdit(value, index)}
        />
      ))}
    </ChipsInput>
  );

  if (readOnly) return chipsInput;

  return (
    <>
      <SelectionInputLayout
        data-testid="restrict-embedding"
        hintMessage={t('manager.webcastSetup.embedding.restrictEmbedding.info')}
        variant="ghost"
        checked={referrerProtectionEnabled}
        disabled={disabled}
        inputElement={
          <Toggle
            data-testid="restrict-embedding-toggle"
            checked={referrerProtectionEnabled}
            disabled={disabled}
            onChange={handleToggleReffererProtectionPolicy}
          >
            {t('manager.webcastSetup.embedding.restrictEmbedding.label')}
          </Toggle>
        }
      >
        <InputDescription
          inputId="allowed-domains"
          hint={t('manager.webcastSetup.embedding.restrictEmbedding.hint')}
          label={`${t('manager.webcastSetup.embedding.restrictEmbedding.allowedDomainsLabel')}${
            referrerProtectionEnabled ? ' *' : ''
          }`}
        >
          {chipsInput}
        </InputDescription>

        <div
          data-testid="input-info"
          className={classNames(styles.inputInfo, validationMessage && referrerProtectionEnabled && styles.invalid)}
        >
          {inputInfo}
        </div>

        <Tooltip
          data-testid="submit-button-tooltip"
          hidden={!saveButtonDisabled || disabled}
          label={t('manager.webcastSetup.embedding.restrictEmbedding.saveInfo')}
        >
          <SaveButton
            key="submit-button"
            data-testid="submit-button"
            saving={saving}
            savingFailed={savingFailed}
            onClick={onSaveChanges}
            disabled={saveButtonDisabled}
            className={classNames(!saving && styles.center, styles.saveButton)}
            small
          />
        </Tooltip>

        {submitButtonInfo && (
          <div data-testid="submit-button-info" className={styles.buttonInfo}>
            {submitButtonInfo}
          </div>
        )}
      </SelectionInputLayout>
    </>
  );
}
