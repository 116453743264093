import type { CSSProperties, HTMLAttributes } from 'react';

import { clamp, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getHoursAndMinutes } from '../../../utils';

import styles from './plan-usage.module.css';

type Props = {
  usedMinutes: number;
  quota: number;
} & HTMLAttributes<HTMLDivElement>;

export function PlanUsage({ usedMinutes, quota, ...props }: Props) {
  const { t } = useTranslation();

  const usage = usedMinutes / quota;
  const usedPercentage = isNaN(usage) ? 0 : usage * 100;
  const isOverused = usedPercentage > 100;
  const totalHours = Math.ceil((isOverused ? usedMinutes : quota) / 60);
  const totalHoursArray = Array.from(Array(totalHours + 1).keys());
  const overusedMinutes = totalHours * 60 - quota;

  /* Usage bar styles */
  const _usageWidth = (usedMinutes / (totalHours * 60)) * 100;
  const usageWidth = isOverused ? 100 : clamp(_usageWidth, 0, 100);
  const overUsageWidth = !isOverused ? 0 : ((usedMinutes - quota) / overusedMinutes) * 100;
  const usageColor = usedPercentage <= 50 ? 'primary' : usedPercentage <= 70 ? 'warning' : 'error';

  /* Description copy */
  const getHoursAndMinutesCopy = getHoursAndMinutes(usedMinutes - (isOverused ? quota : 0));
  const usedPercentageCopy = `${usedPercentage.toFixed(0)}%`;
  let usageDescription = [getHoursAndMinutesCopy, usedPercentageCopy].filter(Boolean).join(' - ');
  if (isOverused) usageDescription = `${getHoursAndMinutesCopy} ${t('manager.components.planUsage.overuse')}`;

  /* Description position */
  const usageDescriptionRef = useRef<HTMLSpanElement>(null);
  const currentUsageRef = useRef<HTMLDivElement>(null);
  const [descriptionPositionOffset, setDescriptionPositionOffset] = useState(0);

  const calculateOffset = () => {
    const descriptionWidth = usageDescriptionRef.current?.offsetWidth || 0;
    const currentUsageWidth = currentUsageRef.current?.offsetWidth || 0;
    setDescriptionPositionOffset(Math.min(0, currentUsageWidth - descriptionWidth));
  };

  useLayoutEffect(calculateOffset, [usedPercentage]);

  useEffect(() => {
    window.addEventListener('resize', calculateOffset);

    return () => {
      window.removeEventListener('resize', calculateOffset);
    };
  }, []);

  const copyPositionForOverUsage = ((totalHours * 60 - usedMinutes) / (totalHours * 60)) * 100;
  const descriptionRightPosition = isOverused ? copyPositionForOverUsage : 100 - usageWidth;
  const descriptionRightPositionWithOffset = `calc(${descriptionRightPosition}% + (${descriptionPositionOffset}px))`;

  if (usedMinutes < 0 || quota < 0) return null;

  return (
    <div className={styles.wrapper} data-testid="plan-usage" {...props}>
      <div className={styles.planUsageWrapper}>
        <span
          ref={usageDescriptionRef}
          data-testid="plan-usage-description"
          className={styles.usageDescription}
          style={{
            right: isOverused ? `${descriptionRightPosition}%` : descriptionRightPositionWithOffset,
            color: `${isOverused ? 'var(--mi-error-60)' : 'inherit'}`,
          }}
        >
          {usageDescription}
        </span>

        <div className={classNames(styles.planUsage, styles.underused)} style={{ flex: quota }}>
          <div
            ref={currentUsageRef}
            data-testid="plan-usage-current"
            className={styles.usage}
            style={{ width: `${usageWidth}%`, '--usage-color': `var(--mi-${usageColor}-main)` } as CSSProperties}
          />
        </div>

        {isOverused && (
          <div className={classNames(styles.planUsage, styles.overused)} style={{ flex: overusedMinutes }}>
            <div
              data-testid="plan-usage-overuse"
              className={styles.usage}
              style={{ width: `${overUsageWidth}%`, '--usage-color': 'var(--mi-error-60)' } as CSSProperties}
            />
          </div>
        )}
      </div>

      <div data-testid="plan-usage-hours" className={styles.hours}>
        {totalHoursArray.length > 0 &&
          totalHoursArray.map((hour) => (
            <span data-testid="plan-usage-hour" key={hour}>
              {hour}
            </span>
          ))}
      </div>
    </div>
  );
}
