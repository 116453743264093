import { useState, useEffect } from 'react';
import { AudioTrack, LanguageMenuOption } from '../language-menu.types';
import { TFunction, i18n } from 'i18next';
import { SubtitleOption } from '../../settings-menu';
import { getSubtitleTranslations } from '../../settings-menu/settings-menu.utils';
import { data } from '../languages';
import { useTranslation } from 'react-i18next';

export function useLanguageMenuItems(
  audioTracks: AudioTrack[] | undefined,
  subtitles: SubtitleOption[] | undefined,
  chosenSubtitleId: string | undefined
) {
  const { t, i18n } = useTranslation();
  const [menuItems, setMenuItems] = useState<LanguageMenuOption[]>([]);

  const findLabel = (audioTrackName: string, audioTrackLabel: string): string => {
    if (audioTrackName !== audioTrackLabel) {
      return audioTrackLabel;
    }
    const key = Object.keys(data).find((key: string) => key === audioTrackLabel);
    return key ? data[key] : 'Undefined language';
  };

  useEffect(() => {
    const getSubtitleMenuOptions = (
      t: TFunction<'translation', undefined>,
      i18n: i18n,
      items: SubtitleOption[]
    ): LanguageMenuOption[] => {
      const subtitleItems: LanguageMenuOption[] = items.map((item: SubtitleOption) => {
        const { label, languageCode } = getSubtitleTranslations(t, i18n, item);
        return {
          id: item.id,
          label,
          languageCode,
          type: 'subtitle',
          active: item.id === chosenSubtitleId,
        };
      });
      if (subtitleItems.length > 0) {
        subtitleItems.unshift({
          id: 'off',
          label: t('ui.subtitles.menu-item.label.off'),
          languageCode: undefined,
          type: 'subtitle',
          active: false,
        });
      }
      return subtitleItems;
    };

    const getAudioTracksMenuOptions = (items: AudioTrack[]): LanguageMenuOption[] => {
      return items.map((item: AudioTrack) => ({
        label: findLabel(item.language, item.label),
        enabled: item.enabled,
        languageCode: item.language,
        kind: item.kind,
        id: item.id,
        type: 'audio',
        active: item.enabled,
      }));
    };

    const items = [];
    if (audioTracks && audioTracks.length > 0) {
      items.push(...getAudioTracksMenuOptions(audioTracks));
    }
    if (subtitles) {
      items.push(...getSubtitleMenuOptions(t, i18n, subtitles));
    }
    setMenuItems(items);
  }, [audioTracks, chosenSubtitleId, i18n, subtitles, t]);

  return {
    menuItems,
  };
}
