import { useMemo } from 'react';
import { getRandomNumber } from '../../utils/get-random-number';
import styles from './waveform.module.css';

type Props = {
  isPlaying: boolean;
  isStopped: boolean;
};

const durations = [...Array(20)].map((_) => `${getRandomNumber()}s`);

export function Waveform({ isPlaying, isStopped }: Props) {
  const bars = useMemo(
    () =>
      durations.map((animationDuration, index) => (
        <div
          key={index}
          className={styles.bar}
          style={{
            animationDuration,
            animationPlayState: isPlaying && !isStopped ? 'running' : 'paused',
          }}
        ></div>
      )),
    [isPlaying, isStopped]
  );

  return (
    <div className={styles.waveform} data-is-playing={isPlaying} data-is-stopped={isStopped} data-testid="waveform">
      {bars}
    </div>
  );
}
