import { VideoJsPlayer } from 'video.js';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { PlayerTextTrack } from '../player.config';
import { VideoJsNativeEvent } from '../videojs-event';
import { SubtitleOption } from 'components/user-interfaces';
import { useCanPlay } from './use-can-play';
import { useTranslation } from 'react-i18next';
import { setMinimumDisplayTime } from '../utils/parse-data-cues';

export interface Subtitle {
  text: string;
}

// activeTrack mode: disabled, or not set -> video doesnt dispatch cuechange events
// activeTrack mode: showing -> video dispatch cuechange events

export function useSubtitles(
  player: VideoJsPlayer,
  playerLanguage: string | undefined,
  preferredBrowserLanguages: ReadonlyArray<string>,
  trackOptions: PlayerTextTrack[],
  selectedSubtitleId: string = '',
  subtitleEnabled: boolean,
  subtitleFallbackLanguage: string,
  setSelectedSubtitleId: Dispatch<SetStateAction<string>>
): {
  subtitle: Subtitle | null;
  subtitleOptions: SubtitleOption[];
} {
  const [subtitle, setSubtitle] = useState<Subtitle | null>(null);
  const [subtitleOptions, setSubtitleOptions] = useState<SubtitleOption[]>([]);
  const canPlay = useCanPlay(player);
  const { i18n } = useTranslation();
  const minDisplayTime = 2;

  // Detect when subtitles tracks are added/removed from the player
  useEffect(() => {
    const onAddTrack = ({ track }: TrackEvent) => {
      if (isValidSubtitleOrCaption(track)) {
        // Add track to the state, if it does not exist yet
        setSubtitleOptions((prevState) =>
          prevState.find((t) => t.id === track.id) ? prevState : [...prevState, mapTextTrackToSubtitleOption(track)]
        );
      }
    };

    const onRemoveTrack = ({ track }: TrackEvent) => {
      if (isValidSubtitleOrCaption(track)) {
        // Remove the track from the state
        setSubtitleOptions((prevState) => prevState.filter((t) => t.id !== track.id));

        // If the removed track was the selected one, set the selected track to off
        setSelectedSubtitleId((prevState) => (prevState === track.id ? '' : prevState));
      }
    };

    // Add 'addtrack' and 'removetrack' event listeners
    player.textTracks().addEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
    player.textTracks().addEventListener(VideoJsNativeEvent.REMOVE_TRACK, onRemoveTrack);

    return () => {
      player.textTracks().removeEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
      player.textTracks().removeEventListener(VideoJsNativeEvent.REMOVE_TRACK, onRemoveTrack);
    };
  }, [player, setSelectedSubtitleId]);

  // Add external subtitles to player
  useEffect(() => {
    if (trackOptions?.length) {
      const addedTracks: HTMLTrackElement[] = [];
      const setExternalSubtitles = () => {
        trackOptions.forEach((trackOption) => {
          // If the track is not added yet, add it to the player
          const loadedTrack = getSubtitleTrackFromPlayer(player, trackOption.id);
          if (!loadedTrack) {
            addedTracks.push(player.addRemoteTextTrack(trackOption, true));
          }
        });
      };

      player.on(VideoJsNativeEvent.LOAD_START, setExternalSubtitles);

      return () => {
        addedTracks.forEach((track) => player.removeRemoteTextTrack(track));

        player.off(VideoJsNativeEvent.LOAD_START, setExternalSubtitles);
      };
    }
  }, [player, trackOptions]);

  // Set the default subtitle language
  useEffect(() => {
    if (!canPlay || !player) return;

    const findTrack = (tracks: TextTrack[], language: string): TextTrack | undefined => {
      if (!language || typeof language !== 'string') return;

      const preferredLanguage = language.toLowerCase();
      let track = tracks.find((track) => track.language.toLowerCase() === preferredLanguage);

      if (!track) {
        const fallbackLanguage = preferredLanguage.substring(0, 2);

        track = tracks.find((track) => track.language.toLowerCase() === fallbackLanguage);
      }

      return track;
    };

    if (!selectedSubtitleId && subtitleEnabled) {
      const tracks = Array.from(player.textTracks()).filter(isValidSubtitleOrCaption);

      if (tracks.length > 0) {
        let track: TextTrack | undefined;

        // 1. try to match preferred browser languages from top to bottom
        if (preferredBrowserLanguages.length > 0) {
          for (const preferredBrowserLanguage of preferredBrowserLanguages) {
            track = findTrack(tracks, preferredBrowserLanguage);

            if (track) {
              setSelectedSubtitleId(track.id);
              return;
            }
          }
        }

        // 2. try to fallback to the configured fallback language
        if (subtitleFallbackLanguage) {
          track = findTrack(tracks, subtitleFallbackLanguage);

          if (track) {
            setSelectedSubtitleId(track.id);
            return;
          }
        }

        // 3. try to fallback to the player language
        if (playerLanguage) {
          track = findTrack(tracks, playerLanguage);

          if (track) {
            setSelectedSubtitleId(track.id);
            return;
          }
        }

        // 4. try to fallback to the player resolved language
        if (i18n.resolvedLanguage) {
          track = findTrack(tracks, i18n.resolvedLanguage);

          if (track) {
            setSelectedSubtitleId(track.id);
            return;
          }
        }

        // 5. try to fallback to the first available subtitle
        if (tracks.length > 0) {
          setSelectedSubtitleId(tracks[0].id);
          return;
        }
      }
    }
  }, [
    player,
    playerLanguage,
    preferredBrowserLanguages,
    i18n,
    canPlay,
    subtitleEnabled,
    trackOptions,
    selectedSubtitleId,
    subtitleFallbackLanguage,
    setSelectedSubtitleId,
  ]);

  // Activate the current selected subtitle (and deactivate the previous one) when selectedSubtitleId changes
  useEffect(() => {
    if (!canPlay || !player) return;

    if (selectedSubtitleId && selectedSubtitleId !== 'off') {
      const trackToActivate = Array.from(player.textTracks()).find(
        (trackOption) => trackOption.id === selectedSubtitleId
      );

      if (trackToActivate) {
        const onCueChange = () => {
          setMinimumDisplayTime(trackToActivate, minDisplayTime);
          setSubtitleByActiveCue(trackToActivate);
        };

        // Activate the selected track
        trackToActivate.mode = 'hidden';
        trackToActivate.addEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);

        setMinimumDisplayTime(trackToActivate, minDisplayTime);

        // Read currently active cue
        setSubtitleByActiveCue(trackToActivate);

        return () => {
          // Deactivate previous track
          trackToActivate.mode = 'disabled';
          trackToActivate.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);
        };
      }
    } else {
      setSubtitle(null);
    }

    function setSubtitleByActiveCue(track: TextTrack) {
      if (track && track.activeCues?.length) {
        const activeCue = track.activeCues[0] as VTTCue;

        if (activeCue) {
          setSubtitle({ text: activeCue.text });
        } else {
          setSubtitle(null);
        }
      } else {
        setSubtitle(null);
      }
    }
  }, [player, canPlay, trackOptions, selectedSubtitleId]);

  return { subtitle, subtitleOptions };
}

function mapTextTrackToSubtitleOption(track: TextTrack): SubtitleOption {
  return {
    id: track.id,
    languageCode: track.language,
    englishLabel: track.label,
  };
}

function getSubtitleTrackFromPlayer(player: VideoJsPlayer, id: string = ''): TextTrack | undefined {
  return Array.from(player.textTracks()).find((track) => isValidSubtitleOrCaption(track) && track.id === id);
}

function isValidSubtitleOrCaption(track: TextTrack | null): track is TextTrack {
  return !!(
    track &&
    track.id &&
    track.language &&
    track.label &&
    (track.kind === 'subtitles' || track.kind === 'captions')
  );
}
