/**
 * Creates a new object with only the properties that are defined (not undefined nor null). This only does a shallow filter / copy.
 *
 * @param object Object to filter properties from.
 * @returns A copy of the object with only the defined properties.
 */
export function definedProps<T extends Record<string, any>>(object: T) {
  return Object.fromEntries(
    Object.entries(object).filter(([_key, value]) => value !== undefined && value !== null)
  ) as T;
}
