import type { Asset, Video, VideoReference } from '../../../../../generated/graphql-manager';

import {
  AlertIcon,
  CloseIcon,
  FileUploader,
  IconButton,
  ImageOutlineIcon,
  LinkStyleButton,
  PlayerIcon,
  Spinner,
  Tooltip,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { fetchVideoDetails } from '../../../../../common';
import {
  AssetUploadStatusCode,
  EventStage,
  useCreateWebcastCoverImageMutation,
  useDeleteWebcastCoverImageMutation,
  useUpdateWebcastCoversMutation,
} from '../../../../../generated/graphql-manager';
import { uploadFile } from '../../../../../utils';
import { VideoBrowser } from '../../../../components/video-manager-browser/video-browser';
import { useVmproConnection } from '../../../../providers/vmpro-connector';
import { EventCover } from '../event-cover';

import styles from './cover-media-uploader.module.css';

type Props = {
  eventStage: EventStage;
  language: string;
  testId: string;
  coverImage?: Asset | null;
  coverVideo?: VideoReference | null;
  disabled?: boolean;
  refetch: (options?: { poll: boolean }) => void;
  onLoadStart?: () => void;
  onLoadEnd?: () => void;
};

export function CoverMediaUploader({
  eventStage,
  language,
  testId,
  coverImage,
  coverVideo,
  disabled,
  refetch,
  onLoadStart,
  onLoadEnd,
}: Props) {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const xhrRef = useRef<XMLHttpRequest>();
  const { isVmproLinked, saveState } = useVmproConnection();

  const [videoStructuredData, setVideoStructuredData] = useState<{ title: string; thumbnailUrl: string }>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploaderError, setUploaderError] = useState('');
  const [serverError, setServerError] = useState('');
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false);

  const [createCoverImageMutation, { loading: creatingCoverImage }] = useCreateWebcastCoverImageMutation();
  const [deleteCoverImageMutation, { loading: deletingCoverImage }] = useDeleteWebcastCoverImageMutation();
  const [updateCoversMutation, { loading: updatingWebcast }] = useUpdateWebcastCoversMutation();

  const imageUploaded = coverImage?.status.code === AssetUploadStatusCode.UPLOADED;
  const imagePending = coverImage?.status.code === AssetUploadStatusCode.PENDING;
  const imageFailed = coverImage?.status.code === AssetUploadStatusCode.FAILED;
  const loading = imagePending || updatingWebcast || creatingCoverImage || deletingCoverImage;
  const videoCoversAvailable = eventStage !== EventStage.LIVE;
  const coverImageVisible = imageUploaded && coverImage?.downloadLink;

  const [fileUploaderAvailable, setFileUploaderAvailable] = useState(true);
  const fileUploaderVisible = fileUploaderAvailable && !coverImageVisible && !videoStructuredData;
  const hasError = serverError || imageFailed;
  const hasContent = fileUploaderVisible || coverImageVisible || videoStructuredData || hasError;

  useEffect(() => {
    if (creatingCoverImage || deletingCoverImage || updatingWebcast) onLoadStart?.();
    else onLoadEnd?.();
  }, [creatingCoverImage, deletingCoverImage, updatingWebcast, onLoadEnd, onLoadStart]);

  const stageNameForApi = {
    [EventStage.PRELIVE]: 'preLive',
    [EventStage.LIVE]: 'live',
    [EventStage.POSTLIVE]: 'postLive',
  }[eventStage];

  const toggleFileBrowserVisibility = () => {
    setFileBrowserOpen((state) => !state);
    if (!isVmproLinked) saveState(eventStage);
  };

  const cancelUpload = async () => {
    xhrRef.current?.abort();
    await deleteCoverImage();
    refetch();
  };

  const createCoverImage = (files: File | File[]) => {
    const file = Array.isArray(files) ? files[0] : files;

    setUploaderError('');
    setUploadProgress(0);

    createCoverImageMutation({
      variables: { webcastId, eventStage, language },
      onCompleted: (data) => {
        if (data.createWebcastCoverImage.__typename !== 'AssetCreateResultSuccess') {
          return setServerError(t('manager.webcastSetup.media.errors.imageUploadFailed'));
        }

        if (coverVideo) deleteCoverVideo();

        const {
          uploadLink: { url, token },
        } = data.createWebcastCoverImage;

        xhrRef.current = uploadFile({
          file,
          token,
          url,
          onProgress: setUploadProgress,
          onSuccess: () => {
            setFileUploaderAvailable(false);
            refetch({ poll: true });
          },
          onError: () => setUploaderError(t('manager.webcastSetup.media.errors.imageUploadFailed')),
        });
      },
    });
  };

  const deleteCoverImage = () =>
    deleteCoverImageMutation({
      variables: { webcastId, eventStage, language },
      onCompleted: () => {
        refetch();
        setFileUploaderAvailable(true);
      },
    });

  const createCoverVideo = async ({ id: videoId, channelId }: Video) => {
    setFileUploaderAvailable(false);
    toggleFileBrowserVisibility();

    if (coverImage) await deleteCoverImage();

    updateCoversMutation({
      variables: {
        input: {
          id: webcastId,
          contents: [{ language, [stageNameForApi]: { video: { reference: { videoId, channelId } } } }],
        },
      },
      onCompleted: (data) => {
        if (data.updateWebcast.__typename !== 'UpdateWebcastSuccess') {
          return setServerError(t('manager.webcastSetup.media.errors.videoSaveFailed'));
        }
        refetch();
      },
    });
  };

  const deleteCoverVideo = () => {
    updateCoversMutation({
      variables: {
        input: {
          id: webcastId,
          contents: [{ language, [stageNameForApi]: { video: { reference: null } } }],
        },
      },
      onCompleted: (data) => {
        if (data.updateWebcast.__typename !== 'UpdateWebcastSuccess') {
          return setServerError(t('manager.webcastSetup.media.errors.videoDeleteFailed'));
        }
        refetch();
        setFileUploaderAvailable(true);
      },
    });
  };

  useEffect(() => {
    let ignoreResult = false;

    if (coverVideo) {
      fetchVideoDetails(coverVideo.videoId, coverVideo.channelId).then((videoData) => {
        if (ignoreResult) return;

        setVideoStructuredData({
          title: videoData.playerData?.metadata?.title || '',
          thumbnailUrl: videoData.playerData?.poster || '',
        });
      });
    } else {
      setVideoStructuredData(undefined);
    }

    return () => {
      ignoreResult = true;
    };
  }, [coverVideo]);

  useEffect(() => {
    const previousState = searchParams.get('state');

    if (!previousState) return;

    if (previousState === eventStage && isVmproLinked) {
      setFileBrowserOpen(true);
    }
  }, [eventStage, searchParams, isVmproLinked]);

  return (
    <>
      <div className={styles.wrapper}>
        {(loading || !hasContent) && <Spinner size={40} className={styles.spinner} />}

        {fileUploaderVisible && (
          <>
            <FileUploader
              info={
                <Trans
                  i18nKey="manager.webcastSetup.media.languageContent.fileUploader.uploaderHeading"
                  components={{ u: <u /> }}
                />
              }
              icon={null}
              acceptedFormats=".jpg, .jpeg, .png, .svg, .gif"
              maximumFileSize={3145728} // 3MB
              disabled={creatingCoverImage || disabled}
              progress={uploadProgress}
              errorMessage={uploaderError}
              testIdPrefix={`${testId}-cover-`}
              onChange={createCoverImage}
              onClose={cancelUpload}
              additionalUploadInformation={[
                t('manager.webcastSetup.media.coverImageUploder.restrictions'),
                t('manager.webcastSetup.media.coverImageUploder.recommendations'),
              ]}
            />

            {videoCoversAvailable && !hasError && (
              <LinkStyleButton
                className={styles.linkStyleButton}
                data-testid={`${testId}-video-manager-button`}
                disabled={loading || disabled}
                onClick={toggleFileBrowserVisibility}
              >
                {t('manager.webcastSetup.media.videoBrowserButton')}
              </LinkStyleButton>
            )}
          </>
        )}

        {coverImageVisible && (
          <EventCover
            imageUrl={coverImage.downloadLink || ''}
            icon={<ImageOutlineIcon />}
            label={t('manager.webcastSetup.media.coverImage')}
            disabled={deletingCoverImage || disabled}
            testIdPrefix={`${testId}-`}
            onDelete={deleteCoverImage}
          />
        )}

        {videoStructuredData && (
          <EventCover
            imageUrl={videoStructuredData.thumbnailUrl}
            icon={<PlayerIcon />}
            label={videoStructuredData.title}
            disabled={updatingWebcast || disabled}
            testIdPrefix={`${testId}-`}
            onDelete={deleteCoverVideo}
          />
        )}

        {hasError && (
          <span className={styles.errorMessage}>
            <AlertIcon />

            <span data-testid={`${testId}-cover-error-message`}>
              {serverError || t('manager.webcastSetup.media.errors.imageUploadFailed')}
            </span>

            <Tooltip label={t('manager.webcastSetup.media.closeErrorButtonLabel')}>
              <IconButton
                data-testid={`${testId}-cover-error-close-button`}
                rounded
                onClick={() => setServerError('')}
                aria-label={t('manager.webcastSetup.media.closeErrorButtonLabel')}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}
      </div>

      <VideoBrowser
        title={t('manager.webcastSetup.media.videoBrowserTitle')}
        isOpen={fileBrowserOpen}
        onChooseVideo={createCoverVideo}
        onClose={toggleFileBrowserVisibility}
      />
    </>
  );
}
