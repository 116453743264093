import { useMemo } from 'react';

import { MutedIcon } from '../../icons/muted';
import { VolumeIcon } from '../../icons/volume';

import styles from './overlay.module.css';

type Props = {
  volume: number;
  isMuted?: boolean;
};

export function VolumeIndicator({ isMuted, volume }: Props) {
  const displayVolume = useMemo(() => {
    if (volume === undefined) {
      return;
    }

    const volumeInPercentage = isMuted ? 0 : Math.round(volume * 100);
    return `${volumeInPercentage}%`;
  }, [volume, isMuted]);

  return (
    <div className={styles.volumeWrapper} data-testid="volume-wrapper">
      {isMuted ? <MutedIcon width="75" height="75" /> : <VolumeIcon width="75" height="75" />}
      <div className={styles.volumeLabel} data-testid="volume-display">
        {displayVolume}
      </div>
    </div>
  );
}
