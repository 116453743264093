import { format } from 'date-fns';
import i18next from 'i18next';

import { getHoursAndMinutes } from './get-hours-and-minutes';
import { getLocale } from '../i18n';

/**
 * @param {string} [startDate] Event start date
 * @param {number} [duration] Optional event duration
 * @returns {string} Formatted event start date
 */
export function formatEventStartDate(startDate?: string, duration?: number) {
  if (!startDate) return '';

  const date = i18next.t('translations:common.dateAtHour', {
    date: format(new Date(startDate), 'dd.MM.yyyy', { locale: getLocale() }),
    hour: format(new Date(startDate), 'H:mm', { locale: getLocale() }),
  });

  return date + (duration ? ', ' + getHoursAndMinutes(duration) : '');
}
