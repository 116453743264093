import { TFunction } from 'i18next';
import type { MenuOption } from '../components/settings-menu';

export const getPlaybackSpeeds = (t: TFunction<'translation'>): MenuOption[] => [
  { id: '0.25', label: '0.25x' },
  { id: '0.5', label: '0.5x' },
  { id: '0.75', label: '0.75x' },
  { id: '1', label: t('ui.menu.item.speed.normal') },
  { id: '1.25', label: '1.25x' },
  { id: '1.5', label: '1.5x' },
  { id: '1.75', label: '1.75x' },
  { id: '2', label: '2x' },
];
