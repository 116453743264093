import { useTranslation } from 'react-i18next';

import { KeyboardAction } from 'components/user-interfaces/hooks/use-keyboard-shortcuts';
import { StepBackCircleIcon } from '../../icons/step-back-circle';

import styles from './overlay.module.css';

type Props = {
  lastKeyboardAction?: KeyboardAction;
};

export function StepBackIndicator({ lastKeyboardAction }: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.stepIndicatorWrapper} data-testid="step-indicator-wrapper">
      <div className={styles.stepIndicator}>
        <StepBackCircleIcon />
        <div className={styles.stepIndicatorLabel} data-testid="step-indicator-label">
          {lastKeyboardAction === 'ShortTimeDecrease'
            ? t('ui.short-step.overlay.label')
            : t('ui.long-step.overlay.label')}
        </div>
      </div>
    </div>
  );
}
