import { t } from 'i18next';

export function validateAccessCode(value: string) {
  if (!value) {
    return t('manager.webcastSetup.viewerAccess.options.accessCode.validation.required');
  }

  if (value.length < 6 || value.length > 30) {
    return t('manager.webcastSetup.viewerAccess.options.accessCode.validation.length');
  }

  if (/\s/.test(value)) {
    return t('manager.webcastSetup.viewerAccess.options.accessCode.validation.whitespaces');
  }

  if (!/^[a-zA-Z0-9_!#+=?\-@$&*]+$/.test(value)) {
    return t('manager.webcastSetup.viewerAccess.options.accessCode.validation.format');
  }
}
