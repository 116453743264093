/**
 * Evaluates if a given date and hour are before or equal to the comparison date
 * @param {Date} date The date to be checked if it is before comparisonDate;
 * @param {number} timeInHours The given hour to be evaluated;
 * @param {number} timeInMinutes The given minutes to be evaluated;
 * @param {Date} comparisonDate The date to be compared with;
 * @returns {boolean} Return true if it is a passed time;
 */
export function isPassedTime(date: Date, timeInHours: number, timeInMinutes: number, comparisonDate: Date): boolean {
  const dateWithHour = new Date(date.getTime());

  dateWithHour.setHours(timeInHours, timeInMinutes, 0, 0);
  comparisonDate.setHours(comparisonDate.getHours(), comparisonDate.getMinutes(), 0, 0);

  return dateWithHour.getTime() <= comparisonDate.getTime();
}
