import { useTranslation } from 'react-i18next';

import { UsersPanel } from './users-panel';
import accessDeniedStudioImage from '../../../assets/access-denied-studio.svg';
import { ErrorPage } from '../../../components/error-page/error-page';
import { LoadingScreen } from '../../../components/loading-screen';
import { useCurrentUser } from '../../hooks/current-user';
import { useUserPermissions } from '../../hooks/user-permissions';

export function UsersPage() {
  const { loading } = useCurrentUser();
  const { isAccessToUserPageAllowed } = useUserPermissions();
  const { t } = useTranslation();

  if (loading) return <LoadingScreen />;

  if (!isAccessToUserPageAllowed) {
    return (
      <ErrorPage
        data-testid="access-denied"
        title={t('common.accessDenied.title')}
        subTitle={t('common.accessDenied.subTitle')}
        image={accessDeniedStudioImage}
        style={{ gridArea: 'section', height: 'unset', background: 'none' }}
      />
    );
  }

  return <UsersPanel />;
}
