import type { WebcastOrder } from '../../../generated/graphql-manager';
import type { ReactNode } from 'react';

import { createContext, useContext } from 'react';

import { getLocalStorageItem, setLocalStorageItem } from '../../../utils/local-storage';
import { getSessionStorageItem, setSessionStorageItem } from '../../../utils/session-storage';

export const NUMBER_OF_EVENTS = 20;

export type EventsView = 'list' | 'grid';

type NumberOfEventsType = { key: 'numberOfEvents'; value: number };
type EventsViewType = { key: 'eventsView'; value: EventsView };
type EventsFilterType = { key: 'eventsFilter'; value: string[] };
type EventsSortingType = { key: 'eventsSorting'; value: WebcastOrder | undefined };
type EventsSearchType = { key: 'eventsSearch'; value: string };
type EventsScrollPositionType = { key: 'eventsScrollPosition'; value: number };
type PreviousChannelIdType = { key: 'previousChannelId'; value: string };
type IframeTestPageType = { key: 'iframeTestPage'; value: { sharingUrl: string; width: number; height: number } };

type AvailableTypes =
  | NumberOfEventsType
  | EventsViewType
  | EventsFilterType
  | EventsSortingType
  | EventsSearchType
  | EventsScrollPositionType
  | PreviousChannelIdType
  | IframeTestPageType;

type UiStateContextValue = {
  getNumberOfEvents: () => NumberOfEventsType['value'];
  getEventsView: () => EventsViewType['value'];
  getEventsFilter: () => EventsFilterType['value'];
  getEventsSorting: () => EventsSortingType['value'];
  getEventsSearch: () => EventsSearchType['value'];
  getEventsScrollPosition: () => EventsScrollPositionType['value'];
  getPreviousChannelId: () => PreviousChannelIdType['value'];
  getIframeTestPage: () => IframeTestPageType['value'];
  saveUiState: (data: AvailableTypes) => void;
};

const initialUiStateContext: UiStateContextValue = {
  getNumberOfEvents: () => NUMBER_OF_EVENTS,
  getEventsView: () => 'grid',
  getEventsFilter: () => [],
  getEventsSorting: () => undefined,
  getEventsSearch: () => '',
  getEventsScrollPosition: () => 0,
  getPreviousChannelId: () => '',
  getIframeTestPage: () => ({ sharingUrl: '', width: 800, height: 450 }),
  saveUiState: () => {},
};

export const UiStateContext = createContext<UiStateContextValue>(initialUiStateContext);

export function UiStateProvider({ children }: { children: ReactNode }) {
  const getNumberOfEvents = () => {
    let value = Number(getSessionStorageItem('numberOfEvents'));

    if (isNaN(value) || value < initialUiStateContext.getNumberOfEvents())
      value = initialUiStateContext.getNumberOfEvents();

    return value as NumberOfEventsType['value'];
  };

  const getEventsView = () => {
    return (getLocalStorageItem('eventsView') as EventsViewType['value']) || initialUiStateContext.getEventsView();
  };

  const getEventsFilter = () => {
    try {
      return JSON.parse(getSessionStorageItem('eventsFilter') || '[]') as EventsFilterType['value'];
    } catch {
      return initialUiStateContext.getEventsFilter();
    }
  };

  const getEventsSorting = () => {
    const value = getSessionStorageItem('eventsSorting');

    if (value === 'undefined') return undefined;

    return (value || initialUiStateContext.getEventsSorting()) as EventsSortingType['value'];
  };

  const getEventsSearch = () => {
    return getSessionStorageItem('eventsSearch') || initialUiStateContext.getEventsSearch();
  };

  const getEventsScrollPosition = () => {
    return Number(getSessionStorageItem('eventsScrollPosition')) || initialUiStateContext.getEventsScrollPosition();
  };

  const getPreviousChannelId = () => {
    return (
      (getLocalStorageItem('previousChannelId') as PreviousChannelIdType['value']) ||
      initialUiStateContext.getPreviousChannelId()
    );
  };

  const getIframeTestPage = () => {
    const value = getLocalStorageItem('iframeTestPage');

    if (!value) return initialUiStateContext.getIframeTestPage();

    try {
      return JSON.parse(value) as IframeTestPageType['value'];
    } catch {
      return initialUiStateContext.getIframeTestPage();
    }
  };

  const saveUiState = ({ key, value }: AvailableTypes) => {
    const isLocalStorageValue = key === 'eventsView' || key === 'previousChannelId' || key === 'iframeTestPage';
    const functionToCall = isLocalStorageValue ? setLocalStorageItem : setSessionStorageItem;

    functionToCall(key, typeof value === 'string' ? value : JSON.stringify(value));
  };

  return (
    <UiStateContext.Provider
      value={{
        getNumberOfEvents,
        getEventsView,
        getEventsFilter,
        getEventsSorting,
        getEventsSearch,
        getEventsScrollPosition,
        getPreviousChannelId,
        getIframeTestPage,
        saveUiState,
      }}
    >
      {children}
    </UiStateContext.Provider>
  );
}

export const useUiState = () => useContext(UiStateContext);
