import type { LiveStreamPro } from '../../../generated/graphql-manager';
import type { SortOrder } from '@movingimage-evp/mi-ui-component-library';

import { Heading, Table, classNames, getNextSorting, sort } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChannelLink } from '../../components/navigation/channel-link';
import { useCurrentUser } from '../../hooks/current-user';
import managerStyles from '../../manager.module.css';

import styles from './channels-page.module.css';

export function ChannelsPage() {
  const { t } = useTranslation();
  const { accounts } = useCurrentUser();
  const [sorting, setSorting] = useState<{ id: keyof LiveStreamPro; order?: SortOrder }>({ id: 'name', order: 'asc' });

  const channels = accounts.map((account) => account.liveStreamPro);
  const sortedChannels = sorting?.order ? sort([...channels], sorting.id, sorting.order) : channels;

  const handleSortChange = (columnId: keyof LiveStreamPro) => {
    if (columnId !== sorting?.id) return setSorting({ id: columnId, order: 'asc' });
    setSorting({ ...sorting, order: getNextSorting(sorting.order) });
  };

  return (
    <section className={classNames(managerStyles.section, styles.channelsPage)}>
      <Heading>{t('channels.title')}</Heading>

      <div className={styles.scroll}>
        <Table className={styles.table} data-testid="channels-table">
          <Table.Header>
            <Table.Row tabIndex={undefined}>
              <Table.HeaderCell
                data-testid="channel-name-header-cell"
                sorting={{
                  onSortingChange: () => handleSortChange('name'),
                  order: sorting?.id === 'name' ? sorting?.order : undefined,
                }}
              >
                {t('channels.columnsTitle.name')}
              </Table.HeaderCell>

              <Table.HeaderCell
                data-testid="channel-id-header-cell"
                sorting={{
                  onSortingChange: () => handleSortChange('id'),
                  order: sorting?.id === 'id' ? sorting?.order : undefined,
                }}
              >
                {t('channels.columnsTitle.id')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {sortedChannels.map((channel) => (
              <Table.Row key={`${channel.id}-row`} data-testid="channel-row">
                <Table.Cell data-testid="channel-name">
                  <ChannelLink channel={channel} style={{ width: 'fit-content' }} />
                </Table.Cell>

                <Table.Cell data-testid="channel-id">{channel.id}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </section>
  );
}
