import type { HTMLAttributes, InputHTMLAttributes } from 'react';

import { CloseIcon, IconButton, SearchIcon, Tooltip, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import styles from './search.module.css';

type Props = {
  onClear?: () => void;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
} & InputHTMLAttributes<HTMLInputElement>;

export function Search({ onClear, wrapperProps, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <div data-testid="search" {...wrapperProps} className={classNames(styles.wrapper, wrapperProps?.className)}>
      <SearchIcon />
      <input {...props} />

      {props.value && onClear && (
        <Tooltip label={t('common.clearSearch')}>
          <IconButton
            data-testid="clear-search"
            type="button"
            rounded
            aria-label={t('common.clearSearch')}
            onClick={onClear}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
