import i18next from 'i18next';

/**
 * @param {number} totalMinutes The given minutes
 * @returns {string} Hours and minutes
 */
export function getHoursAndMinutes(totalMinutes: number) {
  const hoursVisible = totalMinutes > 90;
  const hours = hoursVisible && Math.floor(totalMinutes / 60);
  const minutes = hoursVisible ? totalMinutes % 60 : totalMinutes;

  const hoursCopy = 'translations:common.getHoursAndMinutes.hours.count';
  const minutesCopy = 'translations:common.getHoursAndMinutes.minutes.count';

  const hoursToDisplay = hours && i18next.t(hoursCopy, { count: hours });
  const minutesToDisplay = minutes && i18next.t(minutesCopy, { count: minutes });

  if (!hoursToDisplay && !minutesToDisplay) return '';
  if (!hoursToDisplay && minutesToDisplay) return minutesToDisplay;
  if (hoursToDisplay && !minutesToDisplay) return hoursToDisplay;
  return `${hoursToDisplay} ${minutesToDisplay}`;
}
