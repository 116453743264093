import type { Resource } from 'i18next';

import de from './de.json';
import de_format from './de_format.json';
import en from './en.json';
import en_format from './en_format.json';
import fr from './fr.json';
import fr_format from './fr_format.json';
import it from './it.json';
import it_format from './it_format.json';

export const resources: Resource = {
  en: {
    translations: en,
    formats: en_format,
  },
  de: {
    translations: de,
    formats: de_format,
  },
  fr: {
    translations: fr,
    formats: fr_format,
  },
  it: {
    translations: it,
    formats: it_format,
  },
};
