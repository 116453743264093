import type { ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './subtitles.module.css';

type Props = {
  textColor?: string;
  backgroundColor?: string;
  withSpaceForBottomBar?: boolean;
  children?: ReactNode;
  wrapperWidth?: number;
};

export function Subtitles({ textColor, backgroundColor, withSpaceForBottomBar, children, wrapperWidth = 0 }: Props) {
  return (
    <div
      data-testid="subtitle"
      className={classNames(styles.subtitles, { [styles.withSpaceForBottomBar]: withSpaceForBottomBar })}
      style={{ color: textColor, background: backgroundColor, '--wrapperWidth': wrapperWidth } as CSSProperties}
    >
      {children}
    </div>
  );
}
