import type { TransferProgress } from './transfer-progress';

export type ActionTypes =
  | { type: 'ADD_INDICATOR'; payload: { indicator: TransferProgress } }
  | { type: 'UPDATE_STATUS'; payload: { id: TransferProgress['id']; status: TransferProgress['status'] } }
  | {
      type: 'UPDATE_PROGRESS';
      payload: {
        id: TransferProgress['id'];
        progressPercentage: TransferProgress['progressPercentage'];
        remainingTime?: TransferProgress['remainingTime'];
      };
    }
  | { type: 'CANCEL'; payload: { id: TransferProgress['id'] } };

export function ProgressReducer(state: TransferProgress[], action: ActionTypes): TransferProgress[] {
  switch (action.type) {
    case 'ADD_INDICATOR':
      return [...state, action.payload.indicator];
    case 'UPDATE_STATUS':
      return state.map((item: TransferProgress) =>
        item.id === action.payload.id ? { ...item, status: action.payload.status } : item
      );
    case 'UPDATE_PROGRESS':
      return state.map((item: TransferProgress) =>
        item.id === action.payload.id
          ? {
              ...item,
              progressPercentage: action.payload.progressPercentage,
              remainingTime: action.payload.remainingTime,
            }
          : item
      );
    case 'CANCEL':
      return state.filter((item: TransferProgress) => item.id !== action.payload.id);
    default:
      return state;
  }
}
