import type { BaseValues, PresentationStatus } from './types';
import type {
  DeletePresentationMutation,
  GetWebcastPresentationsQuery,
  Presentation,
} from '../../../generated/graphql-manager';
import type { FetchResult } from '@apollo/client';
import type { ReactNode } from 'react';

import { useApolloClient } from '@apollo/client';
import { createContext, useContext, useRef, useState } from 'react';

import { PresentationStatusPoller } from './presentation-status-poller';
import {
  GetWebcastPresentationsDocument as GET_WEBCAST_PRESENTATIONS,
  ProgressStatus,
  useCreatePresentationMutation,
  useDeletePresentationMutation,
} from '../../../generated/graphql-manager';
import { updateObjectInArray, uploadFile } from '../../../utils';
import { useProgressContext } from '../progress-context/progress-context';

type UploadPresentation = ({
  webcastId,
  language,
  file,
  onSuccess,
}: {
  webcastId: BaseValues['webcastId'];
  language: Presentation['language'];
  file: File;
  onSuccess: () => void;
}) => void;

type Context = {
  presentationStatuses: PresentationStatus[];
  uploadPresentation: UploadPresentation;
  deletePresentation: (presentation: PresentationStatus) => Promise<FetchResult<DeletePresentationMutation>>;
  readPresentationsForLanguage: (
    webcastId: BaseValues['webcastId'],
    language: Presentation['language']
  ) => Presentation[];
  presentationLoading: boolean;
};

const createPresentationStatus = (baseValues: BaseValues): PresentationStatus => ({
  uploadProgress: 0,
  totalSlides: null,
  processedSlides: null,
  ...baseValues,
});

const PresentationsProcessorContext = createContext<Context>({
  presentationStatuses: [],
  uploadPresentation: () => {},
  deletePresentation: () => Promise.resolve({ data: null }),
  readPresentationsForLanguage: () => [],
  presentationLoading: false,
});

export const usePresentationsProcessor = () => useContext(PresentationsProcessorContext);

export function PresentationsProcessorProvider({ children }: { children: ReactNode }) {
  const xhrRef = useRef<Record<string, XMLHttpRequest>>({});
  const client = useApolloClient();
  const { addProgressIndicator, updateStatus, updateProgress, cancelProgressIndicator } = useProgressContext();
  const [presentationStatuses, setPresentationStatuses] = useState<PresentationStatus[]>([]);

  const [createPresentationMutation, createPresentationMutationResponse] = useCreatePresentationMutation();

  const updatePresentationStatus = (updatedStatus: PresentationStatus) => {
    setPresentationStatuses((statuses) => {
      const existingStatus: PresentationStatus | undefined = statuses.find(
        (currentStatus) => currentStatus.id === updatedStatus.id
      );

      const newStatus = createPresentationStatus({ ...(existingStatus || {}), ...updatedStatus });

      return updateObjectInArray(statuses, newStatus, 'id');
    });
  };

  const uploadPresentation = ({
    webcastId,
    language,
    file,
    onSuccess,
  }: {
    webcastId: BaseValues['webcastId'];
    language: Presentation['language'];
    file: File;
    onSuccess?: () => void;
  }) => {
    createPresentationMutation({
      variables: {
        createPresentationInput: {
          webcastId,
          language,
          sourceFileName: file.name,
        },
        groupId: readPresentations(webcastId)?.[0]?.groupId,
      },
      optimisticResponse: {
        createPresentation: {
          __typename: 'CreatePresentationSuccess',
          presentation: {
            __typename: 'Presentation',
            id: 'optimistic-response-id-placeholder',
            groupId: 'optimistic-response-groupId-placeholder',
            uploadLink: {
              __typename: 'UploadLink',
              url: 'optimistic-response-uploadLink-url-placeholder',
              token: 'optimistic-response-uploadLink-token-placeholder',
            },
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.createPresentation.__typename !== 'CreatePresentationSuccess') return;

        const optimisticPresentation: Presentation = {
          __typename: 'Presentation',
          id: data.createPresentation.presentation.id,
          groupId: data.createPresentation.presentation.groupId,
          language,
          downloadLink: null,
          sourceFileName: file.name,
          uploadLink: {
            __typename: 'UploadLink',
            url: data.createPresentation.presentation.uploadLink.url,
            token: data.createPresentation.presentation.uploadLink.token,
          },
          status: {
            __typename: 'PresentationStatus',
            uploadStatus: {
              progress: ProgressStatus.NOT_STARTED,
              __typename: 'PresentationUploadStatus',
            },
            slidesExtractionStatus: {
              __typename: 'PresentationSlidesExtractionStatus',
              progress: ProgressStatus.NOT_STARTED,
              totalSlides: null,
              processedSlides: null,
            },
          },
          slides: [],
        };

        cache.modify({
          id: cache.identify({ __typename: 'Webcast', id: webcastId }),
          fields: {
            presentations: (presentations) => [...(presentations || []), optimisticPresentation],
          },
        });
      },
      onCompleted: (data) => {
        if (data.createPresentation.__typename !== 'CreatePresentationSuccess') return;

        const {
          id,
          groupId,
          uploadLink: { url, token },
        } = data.createPresentation.presentation;

        updatePresentationStatus({ id, webcastId, language, groupId });
        addProgressIndicator({ id, fileName: file.name, status: 'TRANSFERRING', progressPercentage: 0 });

        xhrRef.current[id] = uploadFile({
          file,
          token,
          url,
          onProgress: (uploadProgress) => {
            updateProgress(id, uploadProgress / 2);
            updatePresentationStatus({ id, webcastId, language, groupId, uploadProgress });
          },
          onSuccess: () => {
            onSuccess?.();
          },
          onError: () => {
            updateStatus(id, 'FAILED');
          },
        });
      },
    });
  };

  const [deletePresentationMutation, deletePresentationMutationResponse] = useDeletePresentationMutation();

  const deletePresentation = ({ id, webcastId, language, groupId }: PresentationStatus) => {
    cancelProcessing(id);

    return deletePresentationMutation({
      variables: {
        deletePresentationInput: {
          webcastId,
          language,
          groupId,
        },
      },
      refetchQueries: [{ query: GET_WEBCAST_PRESENTATIONS, variables: { webcastId } }],
    });
  };

  const cancelProcessing = (id: Presentation['id']) => {
    xhrRef.current[id]?.abort();
    removePresentationStatus(id);
    cancelProgressIndicator(id);
  };

  const removePresentationStatus = (id: Presentation['id']) => {
    setPresentationStatuses((state) => state.filter((status) => status.id !== id));
  };

  const readPresentations = (webcastId: BaseValues['webcastId']) => {
    const cache = client.readQuery<GetWebcastPresentationsQuery>({
      query: GET_WEBCAST_PRESENTATIONS,
      variables: { webcastId },
    });

    return cache?.webcast?.presentations;
  };

  const readPresentationsForLanguage = (webcastId: BaseValues['webcastId'], language: Presentation['language']) => {
    return readPresentations(webcastId)?.filter((presentation) => presentation.language === language) || [];
  };

  return (
    <PresentationsProcessorContext.Provider
      value={{
        presentationStatuses,
        uploadPresentation,
        deletePresentation,
        readPresentationsForLanguage,
        presentationLoading: createPresentationMutationResponse.loading || deletePresentationMutationResponse.loading,
      }}
    >
      {presentationStatuses.map((presentationStatus) => (
        <PresentationStatusPoller
          key={presentationStatus.id}
          id={presentationStatus.id}
          webcastId={presentationStatus.webcastId}
          onFinish={removePresentationStatus}
          onFail={removePresentationStatus}
        />
      ))}

      {children}
    </PresentationsProcessorContext.Provider>
  );
}
