import type { Role, User } from '../../../../generated/graphql-manager';
import type { ChangeEvent } from 'react';

import { Modal, PrimaryButton, SecondaryButton, Spinner } from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSetUserRolesMutation } from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';
import { RoleSelector } from '../components/role-select/role-select';

import styles from './edit-user-modal.module.css';

type EditUserModalProps = {
  user: User | undefined;
  isOpen: boolean;
  onClose: () => void;
  className: string;
};

export function EditUserModal({ user, isOpen, className, onClose }: EditUserModalProps) {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(false);

  const [setUserRolesMutation] = useSetUserRolesMutation();

  const handleConfirm = () => {
    setLoading(true);

    if (user) {
      setUserRolesMutation({
        variables: {
          input: {
            id: user.id,
            lsproId,
            roles,
          },
        },
        onCompleted: () => {
          setLoading(false);
          onClose();
        },
      });
    }
  };

  useEffect(() => {
    setRoles(user?.roles || []);
  }, [user?.roles]);

  const handleRolesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name as Role;
    const addedRole = event.currentTarget.checked && name;

    setRoles((state) => {
      if (addedRole) return [...state, addedRole];
      return state.filter((role) => role !== name);
    });
  };

  return (
    <Modal
      data-testid="edit-user-modal"
      title={t('users.editUserModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      className={className}
      footer={
        <>
          <PrimaryButton
            data-testid="confirm-edit-user-button"
            type="button"
            disabled={loading || roles.length === 0}
            onClick={handleConfirm}
          >
            {loading ? <Spinner /> : t('common.save')}
          </PrimaryButton>

          <SecondaryButton data-testid="cancel-edit-user-button" type="button" onClick={onClose}>
            {t('common.cancel')}
          </SecondaryButton>
        </>
      }
    >
      <div className={styles.modalContent}>
        <p data-testid="edit-user-email">{user?.email}</p>

        <RoleSelector selectedRoles={roles} onSelectedRolesChange={handleRolesChange} />
      </div>
    </Modal>
  );
}
