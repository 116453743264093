import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { LoopIcon } from '../../icons/loop';
import commonStyles from '../../styles/common.module.css';

type Props = {
  className?: string;
  isLooping?: boolean;
  onClick?: () => void;
};

export function LoopButton({ isLooping, onClick, className }: Props) {
  const { t } = useTranslation();

  const label = isLooping ? t('ui.loop.button.label.active') : t('ui.loop.button.label.inactive');

  return (
    <button
      type="button"
      name="loop-button"
      className={classNames(className, commonStyles.button, { [commonStyles.active]: isLooping })}
      onClick={onClick}
    >
      <Tooltip>{label}</Tooltip>
      <LoopIcon />
    </button>
  );
}
