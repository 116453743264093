import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile, formatFileSize, noop } from '../../utils';
import { DownloadIcon } from '../../icons/download';
import { DownloadSourcesMenuButtonName } from './download-sources-menu-button';

import styles from '../menu/menu.module.css';
import { Menu } from '../menu/menu';
import classNames from 'classnames';
import MenuItem from '../menu/menu-item';
import { useMenuNavigation } from '../menu/use-menu-navigation';

type DownloadSourcesMenuItem = {
  id: string;
  label: string;
  fileSize: number;
  url: string;
};

type DownloadSourcesMenuProps = {
  isOpen?: boolean;
  options: DownloadSourcesMenuItem[];
  onClose?: () => void;
};

export function DownloadSourcesMenu({ isOpen, options, onClose }: DownloadSourcesMenuProps) {
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const menuOptions = useMemo(
    () => ({
      id: 'downloads',
      label: t('ui.menu.item.downloads'),
      options: options?.map((option) => ({ id: option.id, label: option.label })) || [],
    }),
    [options, t]
  );

  useMenuNavigation({
    menuOptions,
    isOpen: !!isOpen,
    onClose: onClose ?? noop,
    buttonName: DownloadSourcesMenuButtonName,
    wrapperRef,
  });

  const handleMenuItemClick = useCallback(
    (option: DownloadSourcesMenuItem) => {
      downloadFile(option.url);
      onClose?.();
    },
    [onClose]
  );

  return (
    <div ref={wrapperRef} className={classNames(styles.wrapper, styles.top, !isOpen && styles.closed)} tabIndex={-1}>
      <Menu label={t('ui.menu.item.downloads')} onClose={onClose} dataTestId="download-sources-menu">
        {options.map((option) => (
          <MenuItem
            key={option.id}
            className={styles.active}
            data-testid={`download-sources-item-${option.id}`}
            onClick={() => handleMenuItemClick(option)}
            label={option.label}
            currentValue={formatFileSize(option.fileSize, i18n.language)}
            rightIcon={<DownloadIcon />}
          />
        ))}
      </Menu>
    </div>
  );
}
