import type { Contributor } from '../event-permissions';

import { IconButton, Paragraph, Spinner, Tooltip, TrashIcon } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  GetWebcastPermissionsDocument as GET_EVENT_PERMISSIONS,
  ItemListAction,
  useManageEventPermissionsMutation,
} from 'src/generated/graphql-manager';
import { useCurrentUser } from 'src/manager/hooks/current-user';
import { useUserPermissions } from 'src/manager/hooks/user-permissions';

import { Search } from '../../../../components/search';

import styles from './contributors-list.module.css';

export function ContributorsList({ contributors }: { contributors: Contributor[] }) {
  const { webcastId = '' } = useParams();
  const { userId, isAdminUser } = useCurrentUser();
  const { isEventEditingAllowed } = useUserPermissions();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [deleteContributorId, setDeleteContributorId] = useState<Contributor['id'] | null>(null);

  const [deleteContributor, { loading: deleteLoading }] = useManageEventPermissionsMutation();

  const handleDeleteContributor = (contributor: Contributor) => {
    setDeleteContributorId(contributor.id);

    deleteContributor({
      variables: {
        input: {
          webcastId,
          [contributor.type]: {
            items: [contributor.id],
            action: ItemListAction.REMOVE,
          },
        },
      },
      refetchQueries: [{ query: GET_EVENT_PERMISSIONS, variables: { webcastId } }],
      onCompleted: () => {
        setDeleteContributorId(null);
      },
    });
  };

  if (contributors.length === 0) return null;

  const filteredUsers = contributors.filter(
    (user) =>
      user.firstName?.toLowerCase().includes(search.toLowerCase()) ||
      user.lastName?.toLowerCase().includes(search.toLowerCase()) ||
      user.email?.toLowerCase().includes(search.toLowerCase())
  );

  const isOnlyOneOwner = contributors.filter((contributor) => contributor.type === 'owners').length === 1;
  const isLoginUserOwner = contributors.some(
    (contributor) => contributor.type === 'owners' && contributor.id === userId
  );

  const disableDeleteButton = (user: Contributor) => {
    const noAdminUser = !isAdminUser && user.id === userId;

    return (isOnlyOneOwner && user.type === 'owners') || !isEventEditingAllowed || noAdminUser;
  };

  const getDeleteContributorText = (userType: Contributor['type']) => {
    return isOnlyOneOwner && userType === 'owners'
      ? t('manager.webcastSetup.eventPermissions.options.private.onlyOwner')
      : t('manager.webcastSetup.eventPermissions.options.private.removeButton');
  };
  return (
    <div data-testid="contributors-list" className={styles.wrapper}>
      {contributors?.length > 10 && (
        <Search
          placeholder={t('manager.webcastSetup.eventPermissions.options.private.searchPlaceholder')}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onClear={() => setSearch('')}
        />
      )}

      <div data-testid="contributors-list-items" className={styles.items}>
        {filteredUsers.map((user, index) => (
          <div key={index} className={styles.item} data-testid="contributor-item">
            <span data-testid={`contributor-${index}-label`}>
              {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email}
            </span>

            <div className={styles.element}>
              <span data-testid={`contributor-${index}-role`}>
                {user.type === 'owners'
                  ? t('manager.webcastSetup.eventPermissions.options.private.owner')
                  : t('manager.webcastSetup.eventPermissions.options.private.collabolator')}
              </span>

              {(isAdminUser || isLoginUserOwner) && (
                <Tooltip label={getDeleteContributorText(user.type)}>
                  <IconButton
                    data-testid={`contributor-${index}-remove-button`}
                    type="button"
                    aria-label={getDeleteContributorText(user.type)}
                    rounded
                    disabled={disableDeleteButton(user)}
                    onClick={() => handleDeleteContributor(user)}
                  >
                    {deleteLoading && deleteContributorId === user.id ? <Spinner size={16} /> : <TrashIcon />}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        ))}

        {filteredUsers.length === 0 && (
          <Paragraph data-testid="contributors-no-results">
            {t('manager.webcastSetup.eventPermissions.options.private.noResults')}
          </Paragraph>
        )}
      </div>
    </div>
  );
}
