/**
 * Adds or removes a value from an array depending on whether it is already present or not.
 * @param {Type[]} array The array to modify.
 * @param {Type} value The value to add or remove.
 * @returns {Type[]} The modified array.
 */
export const addOrRemoveValueFromArray = <Type>(array: Type[], value: Type) => {
  const index = array.indexOf(value);

  if (index === -1) return [...array, value];

  return [...array.slice(0, index), ...array.slice(index + 1)];
};

/**
 * Adds or removes an item (object) from an array depending on whether it is already present or not.
 * @param {Type[]} array The array to modify.
 * @param {Type} item The item to add or remove.
 * @param {keyof Type} property The property (object's key) to use for comparison.
 * @returns {Type[]} The modified array.
 */
export const addOrRemoveObjectFromArray = <Type>(array: Type[], item: Type, property: keyof Type) => {
  const index = array.findIndex((arrayItem) => arrayItem[property] === item[property]);

  if (index === -1) return [...array, item];

  return [...array.slice(0, index), ...array.slice(index + 1)];
};

/**
 * Replaces or adds an object to an array depending on whether it is already present or not.
 * @param {Type[]} array The array to modify.
 * @param {Type} item The item to replace or add.
 * @param {keyof Type} property The property (object's key) to use for comparison.
 * @returns {Type[]} The modified array.
 */
export const updateObjectInArray = <Type>(array: Type[], item: Type, property: keyof Type) => {
  const index = array.findIndex((arrayItem) => arrayItem[property] === item[property]);

  if (index === -1) return [...array, item];

  return [...array.slice(0, index), item, ...array.slice(index + 1)];
};
