import type {
  AccessCodePolicy,
  EmailRegistrationPolicy,
  EmailWhitelistPolicy,
  ReferrerProtectionPolicy,
  SecurityPolicy,
  SingleSignOnPolicy,
  TokenProtectionPolicy,
} from '../generated/graphql-manager';

import { SecurityPolicyType, type SecurityPolicy as ViewerSecurityPolicy } from '../generated/graphql-viewer';

// --- Manager ---

export const findAccessCodePolicy = (policies: Partial<SecurityPolicy>[] = []) =>
  policies.find((policy): policy is AccessCodePolicy => policy.__typename === 'AccessCodePolicy');

export const findSingleSignOnPolicy = (policies: Partial<SecurityPolicy>[] = []) =>
  policies.find((policy): policy is SingleSignOnPolicy => policy.__typename === 'SingleSignOnPolicy');

export const findReferrerProtectionPolicy = (policies: Partial<SecurityPolicy>[] = []) =>
  policies.find((policy): policy is ReferrerProtectionPolicy => policy.__typename === 'ReferrerProtectionPolicy');

export const findEmailRegistrationPolicy = (policies: Partial<SecurityPolicy>[] = []) =>
  policies.find((policy): policy is EmailRegistrationPolicy => policy.__typename === 'EmailRegistrationPolicy');

export const findEmailWhitelistPolicy = (policies: Partial<SecurityPolicy>[] = []) =>
  policies.find((policy): policy is EmailWhitelistPolicy => policy.__typename === 'EmailWhitelistPolicy');

export const findTokenProtectionPolicy = (policies: Partial<SecurityPolicy>[] = []) =>
  policies.find((policy): policy is TokenProtectionPolicy => policy.__typename === 'TokenProtectionPolicy');

// --- Viewer ---

export const findViewerAccessCodePolicy = (policies: ViewerSecurityPolicy[] = []) =>
  policies.find(({ type }) => type === SecurityPolicyType.ACCESS_CODE);

export const findViewerReferrerProtectionPolicy = (policies: ViewerSecurityPolicy[] = []) =>
  policies.find(({ type }) => type === SecurityPolicyType.REFERRER_PROTECTION);

export const findViewerEmailRegistrationPolicy = (policies: ViewerSecurityPolicy[] = []) =>
  policies.find(({ type }) => type === SecurityPolicyType.EMAIL_REGISTRATION);

export const findViewerSingleSignOnPolicy = (policies: ViewerSecurityPolicy[] = []) =>
  policies.find(({ type }) => type === SecurityPolicyType.SINGLE_SIGN_ON);

export const findViewerEmailWhitelistPolicy = (policies: ViewerSecurityPolicy[] = []) =>
  policies.find(({ type }) => type === SecurityPolicyType.EMAIL_WHITELIST);

export const findViewerTokenProtectionPolicy = (policies: ViewerSecurityPolicy[] = []) =>
  policies.find(({ type }) => type === SecurityPolicyType.TOKEN_PROTECTION);
