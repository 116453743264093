import { AutoQualityId } from '../user-interfaces';
import { ProgressiveSources } from './player.config';
import { PlayerOptions } from './player.interface';

export function getInitialQualityId(playerOptions: PlayerOptions, src: string) {
  if (playerOptions.sources.hls) {
    return AutoQualityId;
  }

  const currentSource = src;
  if (currentSource !== undefined && playerOptions.sources.progressive) {
    const quality = findQualityForCurrentSource(currentSource, playerOptions.sources.progressive);
    if (quality) {
      return quality;
    }
  }
}

function findQualityForCurrentSource(currentSource: string, progressiveSources: ProgressiveSources) {
  return Object.keys(progressiveSources).find((quality) => {
    const sourceObject = progressiveSources[quality];
    return sourceObject.find((source) => source.src === currentSource) !== undefined;
  });
}
