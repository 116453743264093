import i18n, { InitOptions } from 'i18next';

import { resources } from './translations';

/**
 * conventions
 * - lowercase
 * - '.' for separation the keys (e.g. messages.welcome)
 * - '-' instead of camelCase
 * - ':' for the namespace
 * - '_' for the context (e.g. male / female) . more: https://www.i18next.com/translation-function/context
 * - react scapes values for us
 *
 * e.g. todo.add.input-placeholder
 */

export const getI18nInstance = (lng?: string) => {
  const i18nInstance = i18n.createInstance();
  const initOptions: InitOptions = {
    lng,
    resources,
    fallbackLng: 'en',
    ns: ['translations', 'formats'],
    defaultNS: 'translations',
    debug: false,
    returnNull: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
  };

  i18nInstance.init(initOptions);
  return i18nInstance;
};
