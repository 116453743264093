import { ProgressiveSources, PlayerSources } from '../player.config';

import videojs from 'video.js';

export function getVideoJSSources(sources: PlayerSources, preferredQuality?: string): videojs.Tech.SourceObject[] {
  let videoJsSources: videojs.Tech.SourceObject[] = [];

  if (sources.hls) {
    videoJsSources.push(sources.hls);
  }

  if (sources.progressive) {
    const progressiveSources: ProgressiveSources = sources.progressive;
    const qualities = Object.keys(progressiveSources);

    if (preferredQuality && progressiveSources[preferredQuality]) {
      videoJsSources = [...videoJsSources, ...progressiveSources[preferredQuality]];
    } else {
      const sources = qualities.map((quality) => progressiveSources[quality]);
      videoJsSources = [...videoJsSources, ...sources.flat()];
    }
  }

  return videoJsSources;
}
