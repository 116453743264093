import type { GetWebcastStreamQuery, GetWebcastViewerQuery, Webcast } from '../../../generated/graphql-viewer';
import type { HTMLAttributes } from 'react';

import { CalendarSmallIcon, Heading, Paragraph, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RichTextEditor, parseContent } from '../../../components/rich-text-editor';
import { State } from '../../../generated/graphql-viewer';
import { getLocale } from '../../../i18n';
import { formatEventStartDate, isRichTextEditorContentEmpty } from '../../../utils';
import { ComplianceLinks } from '../compliance-links';
import { getCounterText } from '../get-counter-text';

import styles from './content.module.css';

type Props = {
  webcast: GetWebcastStreamQuery['webcast'] & GetWebcastViewerQuery['webcast'];
  language?: string;
} & HTMLAttributes<HTMLDivElement>;

export function Content({ webcast, className, language, ...props }: Props) {
  const locale = getLocale(language);
  const { t } = useTranslation();

  const [startTimeCounter, setStartTimeCounter] = useState('');

  const webcastState = webcast?.state || State.PRELIVE;
  const webcastIsLive = webcastState === State.LIVE;

  const updateCounter = useCallback(
    (plannedStartAt: Webcast['plannedStartAt'], state: Webcast['state']) => {
      const startDate = new Date(plannedStartAt);
      const now = new Date();
      const counterText = getCounterText(startDate, now, state, locale, t);

      setStartTimeCounter(counterText);
    },
    [locale, t]
  );

  useEffect(() => {
    if (!webcast?.plannedStartAt) return;

    updateCounter(webcast.plannedStartAt, webcastState);

    const interval = setInterval(() => updateCounter(webcast.plannedStartAt, webcastState), 1000);

    return () => clearInterval(interval);
  }, [webcast?.plannedStartAt, webcastState, updateCounter]);

  const currentLanguageContent = webcast?.contents.find((content) => content.language === language);
  const primaryLanguageContent = webcast?.contents.find((content) => content.language === webcast?.primaryLanguage);
  const primaryLanguageTitle = primaryLanguageContent?.title;

  const content = {
    [State.PRELIVE]: currentLanguageContent?.preLive?.description,
    [State.LIVE]: currentLanguageContent?.live?.description,
    [State.LIVE_TEST]: currentLanguageContent?.live?.description,
    [State.POSTLIVE]: currentLanguageContent?.postLive?.description,
  }[webcastState];

  const contentAvailable = !isRichTextEditorContentEmpty(content);

  if (!webcast) return null;

  return (
    <div className={classNames(styles.wrapper, className, 'lspro-scrollbars')} {...props}>
      {!webcastIsLive && startTimeCounter && (
        <div
          className={classNames(styles.stateBadge, webcastState === State.POSTLIVE && styles.outro)}
          data-testid="event-state-badge"
        >
          {startTimeCounter}
          {webcastState === State.PRELIVE ? <CalendarSmallIcon /> : <span className={styles.dot} />}
        </div>
      )}

      <Paragraph data-testid="event-date">
        {formatEventStartDate(webcast.plannedStartAt, webcast.durationInMinutes)}
      </Paragraph>

      <div>
        <Heading data-testid="event-title">{currentLanguageContent?.title || primaryLanguageTitle}</Heading>

        {contentAvailable && (
          <RichTextEditor
            // Slate editor doesn't reload when it's value changes,
            // so we need to force it to reload by changing the key
            // whenever the content changes
            key={content}
            data-testid="event-content-rich-text-editor"
            readOnly
            value={parseContent(content)}
          />
        )}

        <ComplianceLinks complianceForms={webcast.complianceForms} />
      </div>
    </div>
  );
}
