import { useEffect } from 'react';
import type { MutableRefObject } from 'react';

/**
 * Calls the callback function when clicked outside the element passed as ref.
 * @param {MutableRefObject<HTMLElement | null>} ref
 * @param {() => void} callback
 */
export function useOnClickOutside(
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void,
  ignoredElementName?: string
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;

      if (
        !ref.current ||
        ref.current.contains(clickedElement) ||
        clickedElement.getAttribute('name') === ignoredElementName
      ) {
        return;
      }

      callback();
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, callback, ignoredElementName]);
}
