export function getLocalStorageItem(itemName: string) {
  try {
    return localStorage.getItem(itemName);
  } catch {
    return;
  }
}

export function setLocalStorageItem(itemName: string, value: string) {
  try {
    localStorage.setItem(itemName, value);
  } catch {
    return;
  }
}

export function removeLocalStorageItem(itemName: string) {
  try {
    localStorage.removeItem(itemName);
  } catch {
    return;
  }
}
