export function getObjectFromSessionStorage<Type extends object>(
  initialData: Type,
  itemName: string,
  subItem?: string
): Type {
  const item = getSessionStorageItem(itemName);

  if (item) {
    try {
      const parsedJSON = subItem && item ? JSON.parse(item)[subItem] : JSON.parse(item);

      const expectedProperties = Object.keys(initialData);

      if (expectedProperties.every((prop) => prop in parsedJSON)) {
        return parsedJSON;
      }
    } catch {
      return initialData;
    }
  }

  return initialData;
}

export function getSessionStorageItem(itemName: string) {
  try {
    return sessionStorage.getItem(itemName);
  } catch {
    return;
  }
}

export function setSessionStorageItem(itemName: string, value: string) {
  try {
    sessionStorage.setItem(itemName, value);
  } catch {
    return;
  }
}

export function removeSessionStorageItem(itemName: string) {
  try {
    sessionStorage.removeItem(itemName);
  } catch {
    return;
  }
}
