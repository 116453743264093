import type { ReactNode } from 'react';

import { Accordion, PlusIcon } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './media-accordion.module.css';

type Props = {
  testId: string;
  visible?: boolean;
  children?: ReactNode;
};

export function MediaAccordion({ testId, visible, children }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(!visible);

  const label = isOpen ? t('manager.webcastSetup.media.hideUploadMedia') : t('manager.webcastSetup.media.uploadMedia');

  return (
    <div className={styles.accordionWrapper}>
      {visible && (
        <Accordion
          data-testid={`media-accordion-${testId}`}
          buttonId={`media-accordion-button-${testId}`}
          compact
          isOpen={isOpen}
          onToggle={setIsOpen}
          buttonLabel={label}
          buttonContent={
            <>
              <PlusIcon />
              {label}
            </>
          }
        >
          {children}
        </Accordion>
      )}

      {!visible && children}
    </div>
  );
}
