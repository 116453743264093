import { useState, useEffect, useRef } from 'react';
import type { MutableRefObject } from 'react';

/**
 * Returns a boolean of whether a mouse is being moved over a DOM element.
 * The return value is set to `false` when the mouse is not being moved for `mouseStopDelay` number of seconds.
 * @param ref A React ref to the DOM element.
 * @param mouseStopDelay The number of seconds to wait before setting the return value to `false`.
 * @returns A boolean of whether a mouse is being moved over the DOM element.
 */
export function useMouseMoving(ref: MutableRefObject<HTMLElement | null>, mouseStopDelay = 3000) {
  const timeoutRef: { current: number | undefined } = useRef();
  const [isMouseMoving, setIsMouseMoving] = useState(false);

  useEffect(() => {
    const node = ref?.current;

    if (!node) return;

    const handleMouseMove = () => {
      clearTimeout(timeoutRef.current);

      setIsMouseMoving(true);

      timeoutRef.current = window.setTimeout(() => {
        setIsMouseMoving(false);
      }, mouseStopDelay);
    };

    const handleMouseLeave = () => {
      clearTimeout(timeoutRef.current);
      setIsMouseMoving(false);
    };

    node.addEventListener('mousemove', handleMouseMove);
    node.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      clearTimeout(timeoutRef.current);
      node.removeEventListener('mousemove', handleMouseMove);
      node.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [ref, mouseStopDelay]);

  return isMouseMoving;
}
