export const Key = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  Escape: 'Escape',
  Tab: 'Tab',
  F: 'f',
  J: 'j',
  L: 'l',
  M: 'm',
  Space: ' ',
} as const;
