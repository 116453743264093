import type { Webcast } from '../../../../generated/graphql-manager';
import type { Descendant } from 'slate';

import { Heading, Paragraph, Tab, Tabs } from '@movingimage-evp/mi-ui-component-library';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LanguageEditors } from './language-editors';
import { parseContent } from '../../../../components/rich-text-editor';
import {
  useGetWebcastDescriptionQuery,
  useUpdateWebcastDescriptionMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { getLanguageKey } from '../../../../utils';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './event-description.module.css';

export function EventDescriptionPage() {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const [savingFailed, setSavingFailed] = useState(false);
  const [editorRefreshKeys, setEditorRefreshKeys] = useState([Math.random(), Math.random(), Math.random()]);

  const getWebcastDescriptions = (contents: Webcast['contents']) => {
    setDescriptions(
      contents?.map((content) => ({
        language: content.language,
        preLive: parseContent(content.preLive?.description),
        live: parseContent(content.live?.description),
        postLive: parseContent(content.postLive?.description),
      })) || []
    );

    // RichTextEditor component is not re-rendering when the value prop changes, so we need to force it to do so
    setEditorRefreshKeys([Math.random(), Math.random(), Math.random()]);
  };

  const { data, loading } = useGetWebcastDescriptionQuery({
    variables: { webcastId },
    skip: !webcastId,
    onCompleted(data) {
      getWebcastDescriptions(data?.webcast?.contents);
    },
  });

  const webcast = data?.webcast;
  const primaryLanguage = webcast?.primaryLanguage || '';
  const additionalLanguages = webcast?.additionalLanguages || [];
  const languages = [primaryLanguage, ...additionalLanguages];

  const [descriptions, setDescriptions] = useState(
    webcast?.contents.map((content) => ({
      language: content.language,
      preLive: parseContent(content.preLive?.description),
      live: parseContent(content.live?.description),
      postLive: parseContent(content.postLive?.description),
    })) || []
  );

  const eventDescription = (language: string, type: 'preLive' | 'live' | 'postLive') => {
    return descriptions.find((description) => description.language === language)?.[type];
  };

  const handleDescriptionChange = (value: Descendant[], language: string, type: 'preLive' | 'live' | 'postLive') => {
    setDescriptions((prevDescriptions) =>
      prevDescriptions.map((description) => {
        if (description.language === language) {
          return {
            ...description,
            [type]: value,
          };
        }

        return description;
      })
    );
  };

  const [updateWebcastDescriptionMutation, { loading: updatingWebcastDescription }] =
    useUpdateWebcastDescriptionMutation();

  const saveChanges = () => {
    updateWebcastDescriptionMutation({
      variables: {
        input: {
          id: webcastId,
          contents: data?.webcast.contents?.map((content) => ({
            language: content.language,
            preLive: {
              description: JSON.stringify(eventDescription(content.language, 'preLive')),
            },
            live: {
              description: JSON.stringify(eventDescription(content.language, 'live')),
            },
            postLive: {
              description: JSON.stringify(eventDescription(content.language, 'postLive')),
            },
          })),
        },
      },
      optimisticResponse: {
        updateWebcast: {
          __typename: 'UpdateWebcastSuccess',
          webcast: {
            id: webcastId,
            contents: descriptions.map((description) => ({
              language: description.language,
              preLive: {
                description: JSON.stringify(description.preLive),
              },
              live: {
                description: JSON.stringify(description.live),
              },
              postLive: {
                description: JSON.stringify(description.postLive),
              },
            })),
          },
        },
      },
      onCompleted: (data) => {
        if (data.updateWebcast.__typename === 'UpdateWebcastSuccess') {
          getWebcastDescriptions(data.updateWebcast.webcast.contents);
        }

        setSavingFailed(data.updateWebcast.__typename !== 'UpdateWebcastSuccess');
      },
    });
  };

  const handleTabChange = useCallback(() => {
    setEditorRefreshKeys([Math.random(), Math.random(), Math.random()]);
  }, []);

  return (
    <main className={managerStyles.main} data-testid="event-description-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.eventDescription.title')}</Heading>
      <Paragraph>{t('manager.webcastSetup.eventDescription.heading')}</Paragraph>

      {!loading && (
        <>
          {languages.length === 1 && (
            <LanguageEditors
              language={primaryLanguage}
              preLiveDescription={eventDescription(primaryLanguage, 'preLive')}
              liveDescription={eventDescription(primaryLanguage, 'live')}
              postLiveDescription={eventDescription(primaryLanguage, 'postLive')}
              editorRefreshKeys={editorRefreshKeys}
              onDescriptionChange={handleDescriptionChange}
            />
          )}

          {languages.length > 1 && (
            <Tabs compact={languages.length <= 4} onSelectTab={handleTabChange}>
              {languages.map((language) => {
                const isPrimaryLanguage = language === primaryLanguage;

                const translatedLanguage = t(
                  `manager.webcastSetup.language.supportedLanguages.${getLanguageKey(language)}`
                );

                const label = isPrimaryLanguage
                  ? t('manager.webcastSetup.eventDescription.primaryLanguage', { primaryLanguage: translatedLanguage })
                  : translatedLanguage;

                return (
                  <Tab label={label} key={language} data-testid={`event-description-tab-${language}`}>
                    <LanguageEditors
                      language={language}
                      preLiveDescription={eventDescription(language, 'preLive')}
                      liveDescription={eventDescription(language, 'live')}
                      postLiveDescription={eventDescription(language, 'postLive')}
                      editorRefreshKeys={editorRefreshKeys}
                      onDescriptionChange={handleDescriptionChange}
                      className={styles.multilanguageWrapper}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          )}

          <SetupPageFooter
            nextLabel={t('manager.webcastSetup.eventDescription.nextStep.label')}
            route={routes.webcastSetup_media}
            showActiveSaveButton
            saveButtonDisabled={!isEventEditingAllowed}
            saving={updatingWebcastDescription}
            savingFailed={savingFailed}
            onSaveButtonClick={saveChanges}
          />
        </>
      )}
    </main>
  );
}
