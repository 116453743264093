import type { PlayerTextTrack } from '../components/player/player.config';

/**
 * Checks if a subtitle is valid.
 * @param {TextTrack[]} tracks The array with the current subtitles.
 * @param {PlayerTextTrack} subtitle The subtitle to check.
 * @returns {boolean} True when the subtitle is valid and not already included, otherwise false.
 */
export function isValidSubtitle(tracks: TextTrack[], subtitle: PlayerTextTrack): boolean {
  const hasValidId = [undefined, null, ''].includes(subtitle?.id) === false;
  const isIncluded = hasValidId && Boolean(tracks.find((track) => track.id === subtitle.id));

  return (
    !isIncluded &&
    subtitle.kind === 'subtitles' &&
    Boolean(subtitle.label) &&
    Boolean(subtitle.src) &&
    Boolean(subtitle.srclang)
  );
}
