import { useState, useEffect } from 'react';
import type { MutableRefObject } from 'react';

/**
 * Returns `true` when mouse cursor moves over a DOM element and `false` when it leaves.
 * @param ref A React ref to the DOM element.
 * @returns `true` when mouse cursor moves over the element and `false` when it leaves.
 */
export function useHover(ref: MutableRefObject<HTMLElement | null>) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => setIsHovering(true);
  const handleMouseOut = () => setIsHovering(false);

  useEffect(() => {
    const node = ref?.current;

    if (!node) return;

    node.addEventListener('mouseover', handleMouseOver);
    node.addEventListener('mouseout', handleMouseOut);

    return () => {
      node.removeEventListener('mouseover', handleMouseOver);
      node.removeEventListener('mouseout', handleMouseOut);
    };
  }, [ref]);

  return isHovering;
}
