import { useLocation } from 'react-router-dom';

import { useAbsoluteRoutes } from '../../../routes';
import { useCurrentUser } from '../current-user';

export function useUserPermissions(channelId?: string) {
  const { isAdminUser, isEditorUser, isProducerUser, isModeratorUser } = useCurrentUser(channelId);
  const { pathname } = useLocation();
  const routes = useAbsoluteRoutes();

  const isEventDeletionAllowed = isAdminUser || isEditorUser;
  const isEventDuplicationAllowed = isAdminUser || isEditorUser;
  const isEventEditingAllowed = isAdminUser || isEditorUser;
  const isEventCreationAllowed = isAdminUser || isEditorUser;
  const isEventReportingAllowed = isAdminUser || isEditorUser;

  const isAccessToUserPageAllowed = isAdminUser;
  const isAccessToChannelSettingsAllowed = isAdminUser;

  const isAccessToEventSetupAllowed = isAdminUser || isEditorUser || isProducerUser;
  const isEditorAndModerator = isEditorUser && isModeratorUser;

  const isAccessToStudioAllowed = isAdminUser || isProducerUser || isModeratorUser;
  const isAccessToStudioMessagingAllowed = isAdminUser || isModeratorUser;
  const isFullAccessToStudioAllowed = isAdminUser || isProducerUser;
  const isModeratorAccessingMessagingStudio = isModeratorUser && pathname.includes(routes.studio_messaging);

  const hasNoPermissions = !isAdminUser && !isEditorUser && !isProducerUser && !isModeratorUser;

  return {
    hasNoPermissions,
    isAccessToChannelSettingsAllowed,
    isAccessToEventSetupAllowed,
    isAccessToStudioAllowed,
    isAccessToStudioMessagingAllowed,
    isAccessToUserPageAllowed,
    isEditorAndModerator,
    isEventCreationAllowed,
    isEventDeletionAllowed,
    isEventDuplicationAllowed,
    isEventEditingAllowed,
    isEventReportingAllowed,
    isFullAccessToStudioAllowed,
    isModeratorAccessingMessagingStudio,
  };
}
