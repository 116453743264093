import type { PlayerConfig } from '@movingimage-evp/player-library';

import i18next from 'i18next';

export type VideoData = {
  title: string;
  playerData: Exclude<PlayerConfig, 'metadata'> & {
    metadata: { title?: string; durationInMs?: number; videoId?: string };
  };
};

export const fetchVideoDetails = async (videoId: string, channelId: string) => {
  let response: Response;

  try {
    response = await fetch(`${process.env.REACT_APP_VIDEO_ENDPOINT}/${videoId}/details?channel-id=${channelId}`);
  } catch {
    throw new Error(i18next.t('translations:common.videoDetails.failedMessage', { videoId }));
  }

  if (!response?.ok) throw response;

  let json: VideoData;

  try {
    json = await response.json();
  } catch {
    throw new Error(i18next.t('translations:common.videoDetails.failedJSONMessage', { videoId }));
  }

  // Subtitle tracks need to have an ID to be displayed in the Player and hide React duplicated keys warning
  const playerConfigWithSubtitleIds = {
    ...json,
    playerData: {
      ...json.playerData,
      tracks: json.playerData.tracks?.map((track, index) => ({ ...track, id: `${index}-${track.srclang}` })),
    },
  };

  return playerConfigWithSubtitleIds;
};
