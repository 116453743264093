import { StrictMode, useEffect, useMemo, useRef, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Root, createRoot } from 'react-dom/client';
import videojs, { VideoJsPlayer } from 'video.js';

import { useVideoHealthCheckForNativeHls } from './hooks/use-video-health-check-for-native-hls';
import { useVideoJsOptions } from './hooks/use-videojs-options';
import { getI18nInstance } from '../../i18n/get-i18n-instance';
import { getPackageVersion } from '../../utils';
import { initializeRamp } from './utils/ramp';
import { PlayerOptions } from './player.interface';
import { PlayerUIConnector } from './player-ui.connector';

import styles from './video.module.css';
import { usePlayerColorsStyles } from '../user-interfaces/hooks/use-player-colors-styles';

// Uncomment to enable logging
// videojs.log.level('debug');

type Props = {
  options: PlayerOptions;
};

const VideoJsComponent = videojs.getComponent('Component');

export const VideoJS = ({ options }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);
  const rootRef = useRef<Root | null>(null);

  const i18nInstance = useMemo(() => getI18nInstance(options.lng), [options.lng]);

  const [videoJsElement, setVideoJsElement] = useState<Element | null>(null);

  options.plugins?.ramp && initializeRamp();

  const videoJsOptions = useVideoJsOptions(
    options.settings.maxPlaylistRetries,
    options.settings.looping,
    options.settings.muted,
    options.settings.aspectRatio,
    options.settings.videoLayout,
    options.poster
  );

  useVideoHealthCheckForNativeHls(playerRef.current);

  // Create / Update Video.js player
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (!playerRef.current) {
      const videoElement = containerRef.current.appendChild(document.createElement('video-js'));

      containerRef.current.appendChild(videoElement);
      playerRef.current = videojs(videoElement, videoJsOptions, () => {
        if (playerRef.current) {
          const vjsComponent = new VideoJsComponent(playerRef.current);
          playerRef.current.addChild(vjsComponent);

          setVideoJsElement(vjsComponent.el());
        }
      });
    } else {
      const player = playerRef.current;

      player.loop(!!videoJsOptions.loop);
      player.poster(videoJsOptions.poster || '');
      player.muted(!!videoJsOptions.muted);
      videoJsOptions.aspectRatio && player.aspectRatio(videoJsOptions.aspectRatio);
    }
  }, [videoJsOptions]);

  // Render UI
  useEffect(() => {
    if (!videoJsElement || !options) {
      return;
    }

    const timeoutId = setTimeout(() => {
      if (!videoJsElement || !options || !playerRef.current) {
        return;
      }

      if (!rootRef.current) {
        rootRef.current = createRoot(videoJsElement);
      }

      rootRef.current.render(
        <StrictMode>
          <I18nextProvider i18n={i18nInstance}>
            <PlayerUIConnector player={playerRef.current} playerOptions={options} />
          </I18nextProvider>
        </StrictMode>
      );
    });

    return () => {
      clearTimeout(timeoutId);
    };
  }, [options, videoJsElement, i18nInstance]);

  // Dispose Video.js player
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }

      setTimeout(() => {
        rootRef.current?.unmount();
        rootRef.current = null;
      });
    };
  }, []);

  const playerColors = usePlayerColorsStyles({
    theme: options.settings.theme,
    customPrimaryColor: options.settings.primaryColor,
    controlBarColor: options.settings.controlBarColor,
    iconColor: options.settings.iconColor,
  });

  return (
    <div
      data-vjs-player
      data-testid="video-player"
      data-version={getPackageVersion()}
      className={styles.wrapper}
      style={playerColors}
      ref={containerRef}
    ></div>
  );
};
