import {
  IconButton,
  LockIcon,
  Paragraph,
  SubHeading,
  Tooltip,
  TrashIcon,
  VmproIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ChannelPath } from './channel-path';
import { Logo } from '../../../../../components/logo/logo';
import managerStyles from '../../../../manager.module.css';

import styles from './video-manager.module.css';

type Props = {
  videoManagerName: string;
  disabled?: boolean;
  channels?: { id: string; name: string; path: string }[];
  noAccessToChannels?: boolean;
  onDeleteChannel?: (id: string) => void;
  setFileBrowserVisibility?: (open: boolean) => void;
};

export function VideoManagerChannels({
  videoManagerName,
  channels = [],
  disabled,
  noAccessToChannels,
  onDeleteChannel,
  setFileBrowserVisibility,
}: Props) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div data-testid="video-manager-channels" className={classNames(styles.channelsList, disabled && styles.disabled)}>
      <div className={styles.header}>
        <Logo width="80" height="80" />

        <div>
          <Paragraph className={managerStyles.grayText}>
            {t('manager.webcastSetup.eventRecording.videoManager.title')}
          </Paragraph>

          <SubHeading>{videoManagerName}</SubHeading>
        </div>
      </div>

      <div className={styles.channelsList} ref={ref}>
        {channels.map((channel) => (
          <div
            data-testid={`channel-${channel.id}`}
            key={channel.id}
            className={classNames(styles.wrapper, disabled && styles.channelItemDisabled)}
          >
            <VmproIcon />

            <ChannelPath path={channel.path} />

            {onDeleteChannel && (
              <Tooltip label={t('manager.webcastSetup.eventRecording.videoManager.deleteChannel')}>
                <IconButton
                  rounded
                  disabled={disabled}
                  onClick={() => onDeleteChannel(channel.id)}
                  aria-label={t('manager.webcastSetup.eventRecording.videoManager.deleteChannel')}
                >
                  <TrashIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ))}

        {noAccessToChannels && (
          <div data-testid="no-access-to-channel" className={styles.wrapper}>
            <Tooltip label={t('manager.webcastSetup.eventRecording.videoManager.channelNotAvailableHint')}>
              <div className={styles.prefix}>
                <LockIcon className={styles.grayText} />
              </div>

              <Paragraph className={classNames(styles.label, styles.grayText)} data-testid="no-access-to-channel-info">
                {t('manager.webcastSetup.eventRecording.videoManager.channelNotAvailable')}
              </Paragraph>
            </Tooltip>
          </div>
        )}

        {setFileBrowserVisibility && (
          <div
            data-testid="open-channel-browser"
            className={classNames(styles.wrapper, !disabled && styles.clickable)}
            aria-disabled={disabled}
            onClick={!disabled ? () => setFileBrowserVisibility(true) : undefined}
          >
            <VmproIcon />

            <Paragraph className={styles.label}>
              {t('manager.webcastSetup.eventRecording.videoManager.selectAnotherChannel')}
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  );
}
