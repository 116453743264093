// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtitles_subtitles__5GFlm {
  display: flex;
  align-self: center;

  margin: 0 16px;
  padding: 0.4em 1em;

  position: absolute;
  bottom: 24px;

  background: var(--videoBackground); /* backwards compatible */
  pointer-events: none;

  font-size: clamp(0.75rem, calc(var(--wrapperWidth) / 50 * 0.0625rem), 1.5rem);
  text-align: center;

  color: var(--textColor);

  transition: bottom 0.2s;
}

@supports (color: color-mix(in lch, red, blue)) {
.subtitles_subtitles__5GFlm {
  background: color-mix(in srgb, var(--videoBackground) 75%, transparent);
}
}

.subtitles_withSpaceForBottomBar__LOUq7 {
  bottom: 180px;
  bottom: clamp(90px, 25%, 180px);
}
`, "",{"version":3,"sources":["webpack://./src/components/subtitles/subtitles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;;EAElB,cAAc;EACd,kBAAkB;;EAElB,kBAAkB;EAClB,YAAY;;EAEZ,kCAAkC,EAAE,yBAAyB;EAE7D,oBAAoB;;EAEpB,6EAA6E;EAC7E,kBAAkB;;EAElB,uBAAuB;;EAEvB,uBAAuB;AACzB;;AApBA;AAAA;EAWE,uEAAuE;AASzE;AAAA;;AAEA;EACE,aAAa;EACb,+BAA+B;AACjC","sourcesContent":[".subtitles {\n  display: flex;\n  align-self: center;\n\n  margin: 0 16px;\n  padding: 0.4em 1em;\n\n  position: absolute;\n  bottom: 24px;\n\n  background: var(--videoBackground); /* backwards compatible */\n  background: color-mix(in srgb, var(--videoBackground) 75%, transparent);\n  pointer-events: none;\n\n  font-size: clamp(0.75rem, calc(var(--wrapperWidth) / 50 * 0.0625rem), 1.5rem);\n  text-align: center;\n\n  color: var(--textColor);\n\n  transition: bottom 0.2s;\n}\n\n.withSpaceForBottomBar {\n  bottom: 180px;\n  bottom: clamp(90px, 25%, 180px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitles": `subtitles_subtitles__5GFlm`,
	"withSpaceForBottomBar": `subtitles_withSpaceForBottomBar__LOUq7`
};
export default ___CSS_LOADER_EXPORT___;
