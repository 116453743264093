import styles from './chapters-menu-item.module.css';
import { getFormattedTime } from '../../utils/time';
import classNames from 'classnames';

interface Props extends React.ComponentProps<'button'> {
  label?: React.ReactNode;
  description?: string;
  startTime?: number;
  isActive?: boolean;
  thumbnail?: string;
}

export const ChaptersMenuItem: React.FC<Props> = ({
  label,
  description,
  startTime,
  thumbnail,
  isActive,
  onClick,
  ...props
}) => {
  return (
    <button {...props} type="button" role="menuitem" onClick={onClick} className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.thumbnail} style={{ backgroundImage: `url("${thumbnail}")` }} />
          <div className={styles.titleTimeWrapper}>
            <div className={styles.title}>{label}</div>
            <div className={classNames(styles.startTime, { [styles.active]: isActive })}>
              {getFormattedTime(startTime)}
            </div>
          </div>
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </button>
  );
};
