export const formatFileSize = (bytes: number, language?: string, decimals?: number): string => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let i = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  const displayDecimals = decimals || i > 1 ? 1 : 0;
  const displayLanguage = language || 'en';

  return parseFloat(bytes.toFixed(displayDecimals)).toLocaleString(displayLanguage) + ' ' + units[i];
};
