import { Waveform } from '../waveform';
import styles from './audio-animation-overlay.module.css';
import { useTranslation } from 'react-i18next';

type Props = {
  isPlaying?: boolean;
  isStopped?: boolean;
  imageUrl?: string;
};

export function AudioAnimationOverlay({ isPlaying = false, isStopped = true, imageUrl }: Props) {
  const { t } = useTranslation();

  return imageUrl ? (
    <img
      src={imageUrl}
      alt={t('ui.audio-image-overlay.alt')}
      className={styles.image}
      data-testid="audio-image-overlay"
    />
  ) : (
    <div className={styles.wrapper} data-testid="audio-animation-overlay">
      <Waveform isPlaying={isPlaying} isStopped={isStopped} />
    </div>
  );
}
