import type { AdditionalValues } from './types';
import type { Presentation } from '../../../generated/graphql-manager';

import { ProgressStatus } from '../../../generated/graphql-manager';

export const getPresentationStatus = (presentation?: Presentation): AdditionalValues => {
  const processedSlides = presentation?.status.slidesExtractionStatus.processedSlides;
  const totalSlides = presentation?.status.slidesExtractionStatus.totalSlides;
  const slideExtractionStatus = presentation?.status.slidesExtractionStatus.progress;
  const uploadStatus = presentation?.status.uploadStatus.progress;
  const slidesInfoAvailable = processedSlides !== null && totalSlides !== null;
  const processingPassed = uploadStatus === ProgressStatus.PASSED && slideExtractionStatus === ProgressStatus.PASSED;
  const processingFailed = uploadStatus === ProgressStatus.FAILED || slideExtractionStatus === ProgressStatus.FAILED;
  const processingFinished = processingFailed || processingPassed;

  return { totalSlides, processedSlides, slidesInfoAvailable, processingPassed, processingFailed, processingFinished };
};
