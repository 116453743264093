import type { ReactNode } from 'react';

import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetIsVmproLinkedQuery, useLinkVmproMutation } from '../../../generated/graphql-manager';

type VmproConnectionContextValue = {
  connectionModalVisible: boolean;
  isVmproLinked?: boolean;
  showConnectionModal: () => void;
  closeConnectionModal: () => void;
  callKeycloak: () => void;
  saveState: (state: string) => void;
};

const initialContext: VmproConnectionContextValue = {
  connectionModalVisible: false,
  isVmproLinked: undefined,
  showConnectionModal: () => {},
  closeConnectionModal: () => {},
  callKeycloak: () => {},
  saveState: () => {},
};

const VmproConnectionContext = createContext<VmproConnectionContextValue>(initialContext);

export const useVmproConnection = () => useContext(VmproConnectionContext);

export function VmproConnectionProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();
  const authorizationCode = searchParams.get('code');

  const [eventState, setEventState] = useState('');
  const [connectionModalVisible, setConnectionModalVisible] = useState(false);
  const getGetIsVmproLinkedResponse = useGetIsVmproLinkedQuery();
  const isVmproLinked = getGetIsVmproLinkedResponse.data?.currentUser?.isVmproLinked;

  const [linkVmproMutation] = useLinkVmproMutation();

  useEffect(() => {
    if (isVmproLinked === undefined || isVmproLinked || !authorizationCode) return;

    linkVmproMutation({
      variables: {
        input: {
          authorizationCode,
          redirectUri: window.location.href.split('?')[0],
        },
      },
      onCompleted() {
        getGetIsVmproLinkedResponse.refetch();
      },
    });
  }, [isVmproLinked, authorizationCode, getGetIsVmproLinkedResponse, linkVmproMutation]);

  const saveEventState = useCallback((state: string) => {
    setEventState(state);
  }, []);

  const showConnectionModal = useCallback(() => {
    setConnectionModalVisible(true);
  }, []);

  const closeConnectionModal = useCallback(() => {
    setConnectionModalVisible(false);
  }, []);

  const callKeycloak = () => {
    const redirectUri = encodeURIComponent(window.location.href);
    const state = encodeURIComponent(eventState);

    const redirectUriParam = `&redirect_uri=${redirectUri}`;
    const eventStateParam = `${eventState ? `&state=${state}` : ''}`;

    window.location.href = `${process.env.REACT_APP_VMRPO_KEYCLOAK_URI}${redirectUriParam}${eventStateParam}`;
  };

  return (
    <VmproConnectionContext.Provider
      value={{
        connectionModalVisible,
        isVmproLinked,
        showConnectionModal,
        closeConnectionModal,
        callKeycloak,
        saveState: saveEventState,
      }}
    >
      {children}
    </VmproConnectionContext.Provider>
  );
}
