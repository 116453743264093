export const DURATIONS = [
  { value: 30, label: '30m' },
  { value: 60, label: '1h' },
  { value: 90, label: '1h 30m' },
  { value: 120, label: '2h' },
  { value: 180, label: '3h' },
  { value: 240, label: '4h' },
  { value: 300, label: '5h' },
  { value: 360, label: '6h' },
  { value: 420, label: '7h' },
  { value: 480, label: '8h' },
  { value: 540, label: '9h' },
  { value: 600, label: '10h' },
  { value: 720, label: '12h' },
];

export const MINUTES = [
  { value: '0', label: '00' },
  { value: '15', label: '15' },
  { value: '30', label: '30' },
  { value: '45', label: '45' },
];

export const generateNextWholeHourFromNow = () => {
  const today = new Date();
  const hour = today.getHours();
  const minutes = today.getMinutes();

  if (minutes === 0) return hour + 1;
  if (hour === 22) return 0;
  if (hour === 23) return 1;
  return hour + 2;
};
