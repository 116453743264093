import type { TFunction } from 'i18next';

export const getTranslatedMonths = (t: TFunction) => {
  const months = t('manager.channelSettings.planDetailsAndUsage.months', { returnObjects: true });
  if (!Array.isArray(months)) {
    throw new Error(
      'Internal error: Language key manager.channelSettings.planDetailsAndUsage.months should return an array'
    );
  }

  return months;
};
