import { type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { isArrayOfNumbers } from '../../utils/is-array-of-numbers';
import { VolumeIcon } from '../../icons/volume';
import { MutedIcon } from '../../icons/muted';
import styles from './volume.module.css';
import commonStyles from '../../styles/common.module.css';

type Props = {
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  isMuted?: boolean;
  showVolumeSlider?: boolean;
  verticalVolumeSlider?: boolean;
  className?: string;
  onChange?: (value: number) => void;
  onMuteChange?: (value: boolean) => void;
};

export function Volume({
  min = 0,
  max = 1,
  step = 0.1,
  value = 0,
  isMuted = false,
  onChange,
  onMuteChange,
  verticalVolumeSlider = false,
  className,
}: Props) {
  const { t } = useTranslation();

  if (!isArrayOfNumbers([min, max, step, value])) return null;

  const position = isMuted ? 0 : max - min <= 0 ? 0 : ((value - min) * 100) / (max - min);
  const background = `linear-gradient(to right, var(--primaryColor) ${position}%, var(--sliderBackgroundColor) 0)`;

  const handleMuteClick = () => {
    onMuteChange?.(!isMuted);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.valueAsNumber);
  };

  return (
    <div className={classNames(className, styles.wrapper)}>
      <VolumeButton isMuted={isMuted} onClick={handleMuteClick} hideTooltip={verticalVolumeSlider} />

      <div className={classNames(styles.sliderWrapper, verticalVolumeSlider && styles.vertical)}>
        <input
          type="range"
          name="volume-slider"
          data-testid="volume-slider"
          aria-label={t('ui.volume.slider.label')}
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          min={min}
          max={max}
          step={step}
          value={isMuted ? 0 : value}
          onChange={handleChange}
          className={classNames(commonStyles.rangeInput, styles.input)}
          style={{ background }}
        />
      </div>
    </div>
  );
}

type VolumeButtonProps = {
  isMuted: boolean;
  hideTooltip?: boolean;
  onClick: () => void;
};

function VolumeButton({ isMuted, onClick, hideTooltip = false }: VolumeButtonProps) {
  const { t } = useTranslation();

  const label = isMuted ? t('ui.volume.mute-button.label.active') : t('ui.volume.mute-button.label.inactive');

  return (
    <button
      type="button"
      data-testid="mute-button"
      name="mute-button"
      className={commonStyles.button}
      onClick={onClick}
      aria-label={label}
    >
      {!hideTooltip && <Tooltip>{label}</Tooltip>}
      {isMuted ? <MutedIcon /> : <VolumeIcon />}
    </button>
  );
}
