import { Modal, SecondaryButton } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

type EnableVodPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToOnDemand: () => void;
};

export function EnableVodPopup({ isOpen, onClose, onSwitchToOnDemand }: EnableVodPopupProps) {
  const { t } = useTranslation();

  return (
    <Modal
      data-testid="enable-vod-popup"
      title={t('manager.eventSummary.videoOnDemand.popup.title')}
      isOpen={isOpen}
      footer={
        <>
          <SecondaryButton data-testid="cancel-video-on-demand-button" type="button" onClick={onClose}>
            {t('manager.eventSummary.videoOnDemand.popup.cancelButton')}
          </SecondaryButton>

          <SecondaryButton data-testid="confirm-video-on-demand-button" type="button" onClick={onSwitchToOnDemand}>
            {t('manager.eventSummary.videoOnDemand.popup.confirmButton')}
          </SecondaryButton>
        </>
      }
      onClose={onClose}
    >
      {t('manager.eventSummary.videoOnDemand.popup.content')}
    </Modal>
  );
}
