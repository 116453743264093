import { useCallback, useMemo, useRef } from 'react';
import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { isArrayOfNumbers, msToAriaText } from '../../utils';
import { useCursorPosition } from '../../hooks/use-cursor-position';
import { TimelinePreview } from '../timeline-preview';
import styles from './timeline.module.css';
import commonStyles from '../../styles/common.module.css';
import type { Chapter, Storyboard } from './timeline.types';

type Props = {
  currentTime?: number;
  totalTime?: number;
  min?: number;
  max?: number;
  step?: number;
  bufferPercentage?: number;
  liveUI?: boolean;
  isMobile?: boolean;
  isSmallScreen?: boolean;
  storyboard?: Storyboard;
  onChange?: (value: number) => void;
  chapters?: Chapter[];
};

export function Timeline({
  currentTime = 0,
  totalTime = 100,
  min = 0,
  max = totalTime,
  step = 100,
  bufferPercentage = 0,
  liveUI = false,
  isMobile = false,
  isSmallScreen = false,
  storyboard,
  onChange,
  chapters = [],
}: Props) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [{ x: hoverPercentage }] = useCursorPosition(ref, true);
  const ariaText = useMemo(
    () =>
      t('ui.timeline.slider.aria-text', {
        currentTime: msToAriaText(t, currentTime),
        totalTime: msToAriaText(t, totalTime),
      }),
    [t, currentTime, totalTime]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      !liveUI && onChange?.(event.target.valueAsNumber);
    },
    [liveUI, onChange]
  );

  const adjustedChapters = useMemo(
    () => (chapters.length && chapters[0].start === 0 ? chapters : [{ start: 0 }, ...chapters]),
    [chapters]
  );
  const chaptersModel = useMemo(
    () =>
      adjustedChapters.map((chapter, idx) => {
        const start = chapter.start;
        const end = adjustedChapters[idx + 1]?.start || totalTime;
        const width = ((end - start) / totalTime) * 100;
        const left = (start / totalTime) * 100;

        const currentPosition = currentTime;
        const bufferPosition = bufferPercentage * totalTime;
        const hoverPosition = (hoverPercentage / 100) * totalTime;

        const chapterTimelinePercentage = Math.max(0, Math.min(1, (currentPosition - start) / (end - start))) * 100;
        const chapterBufferPercentage = Math.max(0, Math.min(1, (bufferPosition - start) / (end - start))) * 100;
        const chapterHoverPercentage = Math.max(0, Math.min(1, (hoverPosition - start) / (end - start))) * 100;

        const isHover = hoverPosition >= start && hoverPosition <= end;

        return {
          chapter,
          width,
          left,
          chapterTimelinePercentage,
          chapterBufferPercentage,
          chapterHoverPercentage,
          isHover,
        };
      }),
    [adjustedChapters, currentTime, totalTime, bufferPercentage, hoverPercentage]
  );

  const hoveredChapter = useMemo(() => chaptersModel.find(({ isHover }) => isHover)?.chapter, [chaptersModel]);

  if (!isArrayOfNumbers([min, max, step, currentTime, totalTime])) return null;

  return (
    <div ref={ref} data-testid="chapters-timeline" className={styles.wrapper}>
      {chaptersModel.map(
        ({ width, left, chapterTimelinePercentage, chapterBufferPercentage, chapterHoverPercentage, isHover }, idx) => (
          <div
            key={idx}
            data-testid={`chapter-segment-${idx}`}
            className={classNames(styles.indicators, { [styles.indicatorsHover]: isHover })}
            style={{ width: `calc(${width}% - 3px)`, left: `calc(${left}% + 1.5px)` }}
          >
            <div data-testid="buffer" style={{ width: `${chapterBufferPercentage}%` }} />
            <div data-testid="hover" style={{ width: `${chapterHoverPercentage}%` }} />
            <div data-testid="progress" style={{ width: `${chapterTimelinePercentage}%` }} />
          </div>
        )
      )}

      <input
        type="range"
        name="timeline-slider"
        aria-label={t('ui.timeline.slider.label')}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={currentTime}
        aria-valuetext={ariaText}
        min={min}
        max={liveUI ? 1 : max}
        step={step}
        value={liveUI ? 1 : currentTime}
        onChange={handleChange}
        className={classNames(commonStyles.rangeInput, { [commonStyles.animated]: !isMobile }, styles.input)}
      />

      <TimelinePreview
        timelineRef={ref}
        totalTime={totalTime}
        storyboard={storyboard}
        chapter={hoveredChapter}
        hideThumbnail={isSmallScreen}
      />
    </div>
  );
}
