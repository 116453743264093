import { ReactNode } from 'react';

import { ChevronIcon } from '../../icons/chevron';

import styles from '../menu/menu.module.css';
import MenuItem from './menu-item';
import { CloseIcon } from '../../icons/close';
import classNames from 'classnames';

type MenuProps = {
  label: ReactNode;
  onGoBack?: () => void;
  onClose?: () => void;
  hasParent?: boolean;
  showHeader?: boolean;
  dataTestId?: string;
  children?: ReactNode | undefined;
};

export function Menu({
  label,
  hasParent = false,
  showHeader = false,
  dataTestId = '',
  children,
  onGoBack,
  onClose,
}: MenuProps) {
  return (
    <div role="menu" className={styles.menu} data-testid={dataTestId}>
      {/* Menu header */}
      <MenuItem
        data-testid="menu-back-button"
        leftIcon={hasParent && <ChevronIcon className={styles.backIcon} />}
        label={label}
        onClick={hasParent ? onGoBack : undefined}
        disabled={!hasParent}
        tabIndex={hasParent ? 0 : -1}
        className={classNames(styles.menuHeader, showHeader === true && styles.showHeader)}
      />

      {children && (
        <div data-testid="menu-scroll" className={classNames(styles.menuItemsWrapper, 'player-scrollbar')}>
          {children}
        </div>
      )}

      {/* Close icon */}
      <button className={styles.close} onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </button>
    </div>
  );
}
