import type { ButtonHTMLAttributes } from 'react';

import { CloseIcon, classNames } from '@movingimage-evp/mi-ui-component-library';

import styles from './filter.module.css';

export type FilterProps = {
  active: boolean;
  closeIconNotVisible?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * @param {boolean} active - Whether the filter is active or not.
 * @default undefined
 * @param {boolean} [closeIconNotVisible] - Whether the close icon is visible or not.
 * @default undefined
 */
export function Filter({ active, closeIconNotVisible, className, children, ...props }: FilterProps) {
  return (
    <button type="button" className={classNames(styles.wrapper, active && styles.active, className)} {...props}>
      <span className={styles.label}>{children}</span>
      {active && !closeIconNotVisible && <CloseIcon className={styles.closeIcon} />}
    </button>
  );
}
