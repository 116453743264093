import type { LiveStreamPro } from '../../../generated/graphql-manager';
import type { HTMLAttributes } from 'react';

import { ChevronRightIcon, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { relativeRoutes, useAbsoluteRoutes } from '../../../routes';
import { useUserPermissions } from '../../hooks/user-permissions';
import { useUiState } from '../../providers/ui-state-provider/ui-state-provider';

import styles from './channel-link.module.css';

type Props = {
  channel: LiveStreamPro;
} & HTMLAttributes<HTMLAnchorElement>;

export function ChannelLink({ channel, ...props }: Props) {
  const channelId = channel.id;
  const { pathname } = useLocation();
  const { isAccessToUserPageAllowed, isAccessToChannelSettingsAllowed } = useUserPermissions(channelId);
  const { saveUiState } = useUiState();

  const getLocation = () => {
    if (
      (pathname.includes(relativeRoutes.users) && !isAccessToUserPageAllowed) ||
      (pathname.includes(relativeRoutes.channelSettings) && !isAccessToChannelSettingsAllowed) ||
      pathname.includes(relativeRoutes.webcastSetup) ||
      pathname.includes(relativeRoutes.eventSummary) ||
      pathname.includes(relativeRoutes.channels)
    ) {
      return `/${channelId}`;
    }

    const [, , ...rest] = pathname.split('/');
    return `/${channelId}/${rest.join('/')}`;
  };

  const handleOnClick = () => {
    // Reset scroll position when navigating to a new channel
    saveUiState({ key: 'eventsScrollPosition', value: 0 });
  };

  return (
    <Link
      type="button"
      className={classNames(styles.button, styles.channel)}
      title={channel.name}
      to={getLocation()}
      data-testid={`channel-link-${channelId}`}
      onClick={handleOnClick}
      {...props}
    >
      <span>{channel.name}</span> <ChevronRightIcon />
    </Link>
  );
}

export function ChannelsLink() {
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();

  return (
    <Link
      to={routes.channels}
      className={classNames(styles.button, styles.link, styles.viewAllChannelsButton)}
      data-testid="view-all-channels-button"
    >
      {t('common.viewAll')} <ChevronRightIcon />
    </Link>
  );
}
