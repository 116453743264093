import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { LanguageIcon } from '../../icons/language';
import { Tooltip } from '../tooltip';
import commonStyles from '../../styles/common.module.css';

type Props = {
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
};

export function LanguageMenuButton({ isActive, className, onClick }: Props) {
  const { t } = useTranslation();

  const label = isActive ? t('ui.language.menu.button.label.active') : t('ui.language.menu.button.label.inactive');

  return (
    <button
      data-testid="language-menu-button"
      type="button"
      name="language-menu-button"
      aria-expanded={isActive}
      aria-haspopup="true"
      className={classNames(commonStyles.button, className)}
      onClick={onClick}
    >
      <Tooltip>{label}</Tooltip>
      <LanguageIcon />
    </button>
  );
}
