import { hexToRgbArray } from '@movingimage-evp/mi-ui-component-library';
import { useEffect } from 'react';

import { Theme, useGetLsproBrandingSettingsQuery } from '../../generated/graphql-manager';
import { useCurrentUser } from '../../manager/hooks/current-user';
import { addCssVariables } from '../../utils';

export function AdminCustomAppearance() {
  const { lsproId } = useCurrentUser();
  const { data } = useGetLsproBrandingSettingsQuery({ variables: { lsproId }, skip: !lsproId });

  const appearanceColors = data?.configuration?.appearanceColors;
  const currentColors = appearanceColors?.find(({ theme }) => theme === Theme.DARK);

  useEffect(() => {
    if (!currentColors) return;

    addCssVariables(
      {
        '--primaryColor': hexToRgbArray(currentColors.primaryColor).join(','),
        '--backgroundColor': hexToRgbArray(currentColors.backgroundColor).join(','),
        '--cardColor': hexToRgbArray(currentColors.cardColor).join(','),
        '--headingFontColor': hexToRgbArray(currentColors.headingFontColor).join(','),
        '--textFontColor': hexToRgbArray(currentColors.textFontColor).join(','),
      },
      Theme.DARK.toLowerCase()
    );
  }, [currentColors]);

  return null;
}
