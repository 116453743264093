import type { VideoManager } from '../../../../generated/graphql-manager';

import { Accordion, Tooltip, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetRecordingSettingsQuery, useGetVideoManagerQuery } from '../../../../generated/graphql-manager';
import { notEmpty } from '../../../../utils/typeguards';
import { useVmproConnection } from '../../../providers/vmpro-connector';
import { getPathForChannel } from '../event-recording/event-recording-utils';
import { VideoManagerChannels } from '../event-recording/video-manager-channels';

import commonStyles from './setup-summary.module.css';

export function RecordingInfo() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const { isVmproLinked } = useVmproConnection();

  const [videoManagerId, setVideoManagerId] = useState<VideoManager['id'] | undefined>();
  const [videoManagerName, setVideoManagerName] = useState('');

  const getWebcastRecordingSettingsResponse = useGetRecordingSettingsQuery({
    variables: { webcastId },
    onCompleted: (data) => {
      const videoManagerId = data.webcast.recordingSettings?.videoManager?.videoManagerId;
      if (videoManagerId) setVideoManagerId(videoManagerId);
    },
  });

  const webcast = getWebcastRecordingSettingsResponse.data?.webcast;
  const recordingSettings = webcast?.recordingSettings;

  const getCurrentVideoManagerResponse = useGetVideoManagerQuery({
    skip: !videoManagerId || !isVmproLinked,
    variables: { videoManagerId: videoManagerId || '' },
    onCompleted: (data) => {
      setVideoManagerName(data.videoManager.__typename === 'VideoManager' ? data.videoManager.name : '');
    },
  });

  const channelsWithPaths = useMemo(() => {
    const videoManagersChannels =
      getCurrentVideoManagerResponse.data?.videoManager.__typename === 'VideoManager'
        ? getCurrentVideoManagerResponse.data.videoManager.channels || []
        : [];

    return (recordingSettings?.videoManager?.channels || [])
      .map((channel) => {
        const videoManagerChannel = videoManagersChannels.find(({ id }) => id === channel?.id);

        if (!videoManagerChannel) return null;

        const path = getPathForChannel(videoManagerChannel, videoManagersChannels);

        return {
          path: path.replace('root_channel', t('components.videoManager.homeFolder')),
          ...videoManagerChannel,
        };
      })
      .filter(notEmpty);
  }, [getCurrentVideoManagerResponse, recordingSettings, t]);

  const { enabled, videoManager } = recordingSettings || {};
  const channelsChosen = isVmproLinked && videoManager?.channels && videoManager?.channels.length > 0;

  let label = '';
  if (enabled === false) label = t('manager.webcastSetup.summary.recording.off');
  if (enabled && !channelsChosen) label = t('manager.webcastSetup.summary.recording.onWithoutVmpro');
  if (enabled && channelsChosen) label = videoManagerName;

  const channelsNotAvailable =
    (isVmproLinked && getCurrentVideoManagerResponse.error !== undefined) ||
    (!isVmproLinked && Boolean(recordingSettings?.videoManager?.channels?.length));
  const recordingLabel = channelsNotAvailable ? t('manager.webcastSetup.summary.recording.channelNotAvailable') : label;

  return (
    <Tooltip label={t('manager.webcastSetup.summary.recording.channelNotAvailableHint')} hidden={!channelsNotAvailable}>
      <Accordion
        data-testid="recording-info-accordion"
        disabled={!channelsChosen || channelsNotAvailable}
        buttonContent={
          <div className={classNames(commonStyles.accordionLabel, !channelsChosen && commonStyles.disabled)}>
            <span>{t('manager.webcastSetup.summary.recording.title')}</span>
            <span data-testid="recording-info-label">{recordingLabel}</span>
          </div>
        }
      >
        <VideoManagerChannels disabled channels={channelsWithPaths} videoManagerName={videoManagerName} />
      </Accordion>
    </Tooltip>
  );
}
