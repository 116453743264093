export const ChevronIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="currentColor"
    aria-hidden="true"
    role="img"
    data-testid="chevron-icon"
    {...props}
  >
    <path d="M.6 10.6 5.2 6 .6 1.4 2 0l6 6-6 6-1.4-1.4Z" />
  </svg>
);
