import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Tooltip } from '../tooltip';
import { DownloadIcon } from '../../icons/download';

import commonStyles from '../../styles/common.module.css';

type Props = {
  isActive?: boolean;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const DownloadSourcesMenuButtonName = 'download-sources-menu-button';

export function DownloadSourcesMenuButton({ isActive, className, onClick }: Props) {
  const { t } = useTranslation();

  const label = isActive
    ? t('ui.download-source.menu.button.label.active')
    : t('ui.download-source.menu.button.label.inactive');

  return (
    <button
      data-testid={DownloadSourcesMenuButtonName}
      type="button"
      name={DownloadSourcesMenuButtonName}
      aria-expanded={isActive}
      aria-haspopup="true"
      className={classNames(commonStyles.button, className)}
      onClick={onClick}
    >
      <Tooltip direction={'bottom'}>{label}</Tooltip>
      <DownloadIcon />
    </button>
  );
}
