import type { SVGProps } from 'react';

export function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="68" height="68" fill="none" viewBox="0 0 68 68" {...props}>
      <path fill="var(--mi-brand-main)" d="M68 0H0v68h68V0Z" />
      <path
        fill="var(--mi-static-white)"
        d="M14.19 41.55v13.8l24.88-13.84-24.88-13.85v13.89Zm31.96-24.53c-3.17 0-5.5 1.83-7.62 4-1.2-2.56-3.27-4.33-6.65-4.33-3.13 0-5.3 1.7-7.28 3.33h-.19l-.48-2.08a7.1 7.1 0 0 0-2.58-.41c-1.63 0-2.58.52-2.58.52v8.84l5.9 3.95v-5.49c2.29-1.54 3.49-2.3 4.64-2.3 2.03 0 3.54 1.05 3.54 4.52v7.22l6.55 3.94V25.36c1.31-1.54 2.98-2.31 4.18-2.31 2.03 0 3.03 1.06 3.03 4.53v13.93c2.38.46 4.82.46 7.2 0V26.67c0-6-2.5-9.65-7.66-9.65Z"
      />
    </svg>
  );
}
