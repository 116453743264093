import type { GetWebcastSummaryManagerQuery } from '../../../../../../generated/graphql-manager';

import { Paragraph, Tooltip } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { getWebcastTitle } from '../../../../../hooks/use-get-primary-title';
import { DownloadRecording } from '../download-recording/download-recording';

import styles from './event-summary-recording.module.css';

type EventSummaryRecordingProps = {
  webcast: GetWebcastSummaryManagerQuery['webcast'];
};

export function EventSummaryRecording({ webcast }: EventSummaryRecordingProps) {
  const { t } = useTranslation();

  const recordingEnabled = webcast?.recordingSettings?.enabled;
  const recordings = webcast?.streaming?.recordings;
  const primaryLanguage = webcast?.primaryLanguage || '';
  const additionalLanguages = webcast?.additionalLanguages || [];
  const languages = [primaryLanguage, ...additionalLanguages];
  const contents = webcast?.contents || [];

  return (
    <div className={styles.container}>
      {recordingEnabled && (
        <>
          <Paragraph data-testid="recording-header">{t('manager.eventSummary.recording.title')}</Paragraph>

          {languages.map((language) => (
            <div className={styles.eventRecordingContent} key={language}>
              <Tooltip label={getWebcastTitle(language, contents)}>
                <Paragraph data-testid={`recording-title-${language}`}>{getWebcastTitle(language, contents)}</Paragraph>
              </Tooltip>

              <DownloadRecording
                className={styles.downloadRecordingButton}
                label={t('manager.eventSummary.recording.downloadButton')}
                recording={recordings?.find((recording) => recording.metadata.language === language)}
                webcastId={webcast.id}
                webcastTitle={getWebcastTitle(language, contents) || ''}
                language={language}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}
