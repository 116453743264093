import type { Storyboard } from '../timeline';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { clamp } from '../../utils/clamp';
import { getFormattedTime } from '../../utils/time';
import { useCursorPosition } from '../../hooks/use-cursor-position';
import { usePrevious } from '../../hooks/use-previous';
import { TrimmerHandleIcon } from '../../icons/trimmer-handle';
import { TimelinePreview } from '../timeline-preview';
import styles from './trimmer.module.css';

/** Returns a width of a DOM element, which defaults to 1. */
const getElementWidth = (element: HTMLElement | null) => (element && element.getBoundingClientRect().width) || 1;

/**
 * Calculates size and positions of the trimming area based on time values.
 * Returned values are in numeric percentage format (numbers from 0 to 100).
 */
const getTrimAreaDimensions = (startTime: number, endTime: number, totalTime: number) => {
  const left = (startTime / (totalTime || 1)) * 100;
  const right = 100 - (endTime / (totalTime || 1)) * 100;
  const width = 100 - left - right;

  return { left, right, width };
};

type Props = {
  wrapper: HTMLDivElement | null;
  isPlaying: boolean;
  startTime: number;
  endTime: number;
  currentTime: number;
  totalTime: number;
  storyboard?: Storyboard;
  minimumTrimAreaWidth?: number;
  onChange?: (value: number) => void;
  onTrimStartTimeChange?: (newTrimStartTime: number) => void;
  onTrimEndTimeChange?: (newTrimEndTime: number) => void;
};

export function Trimmer({
  wrapper,
  isPlaying,
  startTime,
  endTime,
  currentTime,
  totalTime,
  storyboard,
  onChange,
  onTrimStartTimeChange,
  onTrimEndTimeChange,
}: Props) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const trimAreaRef = useRef<HTMLDivElement>(null);
  const timeLabelRef = useRef<HTMLSpanElement>(null);
  const previousStartTime = usePrevious(startTime);
  const previousEndTime = usePrevious(endTime);
  const [{ x: hoverPercentage }] = useCursorPosition(wrapperRef, true, false);
  const [trimAreaIsNarrow, setTrimAreaIsNarrow] = useState(false);
  const [trimAreaDimensions, setTrimAreaDimensions] = useState(getTrimAreaDimensions(startTime, endTime, totalTime));
  const trimAreaClasses = classNames(styles.trimArea, { [styles.narrow]: trimAreaIsNarrow });
  const trimAreaStyles = { left: `${trimAreaDimensions.left}%`, width: `${trimAreaDimensions.width}%` };
  const hoverIndicatorStyles = { left: `${hoverPercentage}%` };

  const currentTimeIndicatorStyles = {
    left: `${clamp(
      (currentTime * 100) / totalTime,
      trimAreaDimensions.left,
      trimAreaDimensions.left + trimAreaDimensions.width
    )}%`,
  };

  const backgroundLineStyles = {
    background: `linear-gradient(to right, var(--primaryColor) ${
      trimAreaDimensions.left
    }%, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0.5) ${
      100 - trimAreaDimensions.right
    }%, var(--primaryColor) 0)`,
  };

  // Make sure playback stays within the trim area.
  useEffect(() => {
    if (currentTime < startTime) onChange?.(startTime);
    if (currentTime > endTime) onChange?.(isPlaying ? startTime : endTime);
  }, [isPlaying, currentTime, endTime, startTime, onChange]);

  /** Converts trim area's minimum width in pixels into percentage of the whole trimmer component width. */
  const getMinimumTrimAreaWidth = () => {
    const minimumTrimAreaWidth = parseInt(getComputedStyle(trimAreaRef.current as Element).minWidth);
    const wrapperWidth = getElementWidth(wrapperRef.current);
    return (minimumTrimAreaWidth / wrapperWidth) * 100;
  };

  // Here we first make sure that start and end values we receive from props are valid,
  // then we update the trim area dimensions.
  useEffect(() => {
    const minimumTrimLength = Math.round(totalTime * (getMinimumTrimAreaWidth() / 100));

    // Check if values stay within the bounds of trimming area.
    if (startTime !== previousStartTime && startTime > endTime - minimumTrimLength) {
      onTrimStartTimeChange?.(endTime - minimumTrimLength);
    }

    if (endTime !== previousEndTime && endTime < startTime + minimumTrimLength) {
      onTrimEndTimeChange?.(startTime + minimumTrimLength);
    }

    if (endTime > totalTime) {
      onTrimEndTimeChange?.(totalTime);
    }

    if (startTime < 0) {
      onTrimStartTimeChange?.(0);
    }

    // Update trim area dimensions.
    const trimAreaWidth = getElementWidth(trimAreaRef.current);
    const timeLabelWidth = getElementWidth(timeLabelRef.current);
    const newTrimAreaDimensions = getTrimAreaDimensions(startTime, endTime, totalTime);

    setTrimAreaIsNarrow(trimAreaWidth <= timeLabelWidth * 2);
    setTrimAreaDimensions(newTrimAreaDimensions);
  }, [startTime, endTime, totalTime, previousStartTime, previousEndTime, onTrimStartTimeChange, onTrimEndTimeChange]);

  const timelineClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== wrapperRef.current) return;

    const left = (event.pageX - event.currentTarget.getBoundingClientRect().left) / getElementWidth(wrapperRef.current);

    onChange?.(clamp(totalTime * left, startTime, endTime));
  };

  const trimStartTimeChange = (distance: number) => {
    const percentage = (distance / getElementWidth(wrapperRef.current)) * 100;

    if (trimAreaDimensions.left + percentage + trimAreaDimensions.right > 100 - getMinimumTrimAreaWidth()) return;

    const newValue = (totalTime * (trimAreaDimensions.left + percentage)) / 100;
    onTrimStartTimeChange?.(Math.round(clamp(newValue, 0, endTime)));
  };

  const trimEndTimeChange = (distance: number) => {
    const percentage = (distance / getElementWidth(wrapperRef.current)) * 100;

    if (trimAreaDimensions.width + percentage < getMinimumTrimAreaWidth()) return;

    const newValue = (totalTime * (100 - trimAreaDimensions.right + percentage)) / 100;
    onTrimEndTimeChange?.(Math.round(clamp(newValue, startTime, totalTime)));
  };

  const startTrimHandleKeyDown = (value: number) => {
    onTrimStartTimeChange?.(Math.round(clamp(startTime + value, 0, endTime)));
  };

  const endTrimHandleKeyDown = (value: number) => {
    onTrimEndTimeChange?.(Math.round(clamp(endTime + value, startTime, totalTime)));
  };

  return (
    <div ref={wrapperRef} className={styles.wrapper} tabIndex={-1} onClick={timelineClick} data-testid="trimmer">
      <div className={styles.backgroundLine} style={backgroundLineStyles} />
      <div className={styles.currentTimeIndicator} style={currentTimeIndicatorStyles} />
      <div className={styles.hoverIndicator} style={hoverIndicatorStyles} />

      <div ref={trimAreaRef} className={trimAreaClasses} style={trimAreaStyles} data-testid="trim-area">
        <span
          className={styles.label}
          aria-label={t('ui.trimmer.start.label')}
          ref={timeLabelRef}
          data-testid="trim-start-time"
        >
          {getFormattedTime(startTime)}
        </span>

        <span className={styles.label} aria-label={t('ui.trimmer.end.label')} data-testid="trim-end-time">
          {getFormattedTime(endTime)}
        </span>

        <TrimHandle
          name="left-trim-handle"
          aria-label={t('ui.trimmer.start.button')}
          wrapper={wrapper}
          onKeyDown={startTrimHandleKeyDown}
          onChange={trimStartTimeChange}
        />

        <TrimHandle
          name="right-trim-handle"
          aria-label={t('ui.trimmer.end.button')}
          wrapper={wrapper}
          onKeyDown={endTrimHandleKeyDown}
          onChange={trimEndTimeChange}
        />
      </div>

      <TimelinePreview timelineRef={wrapperRef} totalTime={totalTime} storyboard={storyboard} style={{ bottom: 73 }} />
    </div>
  );
}

type TrimHandleProps = {
  name: string;
  'aria-label': string;
  wrapper: HTMLDivElement | null;
  onKeyDown: (value: number) => void;
  onChange: (value: number) => void;
};

function TrimHandle({ name, 'aria-label': ariaLabel, wrapper, onKeyDown, onChange }: TrimHandleProps) {
  if (!wrapper) return null;

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    const initialX = event.clientX;

    const drag = (event: MouseEvent) => {
      onChange(event.clientX - initialX);
    };

    const stopDraggging = () => {
      wrapper.removeEventListener('mousemove', drag);
    };

    wrapper.addEventListener('mousemove', drag);
    wrapper.addEventListener('mouseup', stopDraggging);
    wrapper.addEventListener('mouseleave', stopDraggging);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (!['ArrowLeft', 'ArrowRight'].includes(event.key)) return;

    let trimSteps = 500;
    if (event.shiftKey) trimSteps *= 10;
    if (event.key === 'ArrowLeft') trimSteps *= -1;

    onKeyDown(trimSteps);
  };

  return (
    <button name={name} aria-label={ariaLabel} onMouseDown={handleMouseDown} onKeyDown={handleKeyDown}>
      <TrimmerHandleIcon />
    </button>
  );
}
