import type { HTMLProps } from 'react';

import { DownloadIcon, IconButton, Tooltip } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RecordingUploadStatus,
  type GetWebcastSummaryManager_RecordingsFragment as Recordings,
} from '../../../../../../generated/graphql-manager';
import { downloadFile } from '../../../../../../utils';
import { useProgressContext } from '../../../../../providers/progress-context/progress-context';

export type DownloadFileProps = {
  webcastId: string;
  webcastTitle: string;
  recording?: Recordings;
  label: string;
  language: string;
} & HTMLProps<HTMLButtonElement>;

export function DownloadRecording({
  recording,
  webcastId,
  webcastTitle,
  label,
  className,
  language,
}: DownloadFileProps) {
  const { addProgressIndicator, updateStatus, updateProgress, cancelProgressIndicator } = useProgressContext();
  const { t } = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadLink = recording?.downloadLink;
  const downloadName = `${webcastTitle.split(' ').join('')}.mp4`;

  const recordingStatus = recording?.status;
  const downloadReady = recordingStatus === RecordingUploadStatus.READY_FOR_DOWNLOAD;
  const recordingNotReady = recordingStatus === RecordingUploadStatus.NOT_READY;
  const processingFailed = recordingStatus === RecordingUploadStatus.FAILED;

  const handleDownload = () => {
    if (!webcastId || !downloadLink) {
      return;
    }
    let downloadRequest: XMLHttpRequest | undefined;

    const progressbarId = `${webcastId}-${Date.now()}`;

    addProgressIndicator({
      id: progressbarId,
      transferType: 'download',
      fileName: downloadName,
      status: 'TRANSFERRING',
      progressPercentage: 0,
      buttons: {
        TRANSFERRING: {
          onButtonClicked: () => {
            downloadRequest?.abort();
            cancelProgressIndicator(progressbarId);
            setIsDownloading(false);
          },
        },
      },
    });

    downloadFile({
      url: downloadLink,
      fileName: downloadName,
      onError: () => {
        updateStatus(progressbarId, 'FAILED');
        setIsDownloading(false);
      },
      onProgress: (percentComplete: number, minutes: number, seconds: number) =>
        updateProgress(progressbarId, percentComplete, { minutes, seconds }),
      onStart: (request: XMLHttpRequest) => {
        downloadRequest = request;
        setIsDownloading(true);
      },
      onSuccess: () => {
        updateStatus(progressbarId, 'FINISHED');
        setIsDownloading(false);
      },
    });
  };

  const recordingTooltipText = processingFailed
    ? t('manager.eventSummary.recordingFailed')
    : recordingNotReady
      ? t('manager.eventSummary.recordingNotAvailable')
      : isDownloading
        ? t('manager.eventSummary.downloadInProgress')
        : '';

  return (
    <>
      <Tooltip
        label={recordingTooltipText}
        hidden={!recordingTooltipText}
        alwaysVisible={processingFailed}
        data-testid={`event-recording-download-tooltip-${language}`}
      >
        <IconButton
          className={className}
          aria-label={label}
          disabled={isDownloading || !downloadReady}
          onClick={handleDownload}
          data-testid={`event-recording-download-button-${language}`}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
