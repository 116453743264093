import type { ComplianceForms } from '../../../generated/graphql-viewer';

import { useTranslation } from 'react-i18next';

import { RichTextEditor, parseContent } from '../../../components/rich-text-editor';
import { isRichTextEditorContentEmpty } from '../../../utils';

import styles from './compliance-links.module.css';

type Props = {
  complianceForms?: ComplianceForms | null;
};

export function ComplianceLinks({ complianceForms }: Props) {
  const { t } = useTranslation();

  if (!complianceForms) return;

  const includeDefaultComplianceForm = complianceForms.includeDefaultComplianceForm;
  const customLinks = complianceForms.customComplianceForm?.text;
  const defaultLinks = complianceForms.defaultComplianceForm?.text;

  return (
    <div>
      {!isRichTextEditorContentEmpty(customLinks) && (
        <RichTextEditor data-testid="custom-compliance-links" readOnly value={parseContent(customLinks)} />
      )}

      {includeDefaultComplianceForm && !isRichTextEditorContentEmpty(defaultLinks) && (
        <RichTextEditor data-testid="default-compliance-links" readOnly value={parseContent(defaultLinks)} />
      )}

      {includeDefaultComplianceForm && (
        <div className={styles.miComplianceLinksWrapper}>
          <a
            href={t('viewer.views.stream.complianceLinks.imprintUrl')}
            rel="noopener noreferrer"
            target="_blank"
            data-testid="mi-imprint-link"
          >
            {t('viewer.views.stream.complianceLinks.imprint')}
          </a>

          <a
            href={t('viewer.views.stream.complianceLinks.privacyPolicyUrl')}
            rel="noopener noreferrer"
            target="_blank"
            data-testid="mi-privacy-policy-link"
          >
            {t('viewer.views.stream.complianceLinks.privacyPolicy')}
          </a>
        </div>
      )}
    </div>
  );
}
