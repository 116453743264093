export const CryingFaceIcon = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="var(--primaryColor)"
    aria-hidden="true"
    role="img"
    data-testid="crying-face-icon"
  >
    <path d="M14.3 44.3a5.9 5.9 0 0 1-5.9-5.8c0-2.6 2.9-7.5 5.9-11.5 3 4 5.9 8.9 5.9 11.5a5.9 5.9 0 0 1-6 5.8ZM25 .8A24.2 24.2 0 0 0 3.8 36.6a24 24 0 0 1 2.6-6.4 19.3 19.3 0 1 1 18.6 14h-1.7a10.6 10.6 0 0 1-4.7 4c2 .7 4.2 1 6.4 1A24.2 24.2 0 1 0 25 .8Zm0 29c-1 0-2 .1-2.9.3a33 33 0 0 1 2.2 4.6h.7c3 0 5.6 1.2 6.7 3l3.4-3.5A13.1 13.1 0 0 0 25 29.8Zm8.5-14.5c-2 0-3.7 1.7-3.7 3.7 0 1.9 1.7 3.6 3.7 3.6 1.9 0 3.6-1.7 3.6-3.6 0-2-1.7-3.7-3.6-3.7ZM20.2 19c0-2-1.7-3.7-3.7-3.7a3.7 3.7 0 0 0-3.6 3.7c0 1.9 1.7 3.6 3.6 3.6 2 0 3.7-1.7 3.7-3.6Z" />
  </svg>
);
