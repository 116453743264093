import { CopyToClipboard, Heading, InputDescription, TextInput } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { useGetLsproSecuritySettingsQuery } from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';
import managerStyles from '../../../manager.module.css';

export function SecurityPage() {
  const { lsproId } = useCurrentUser();
  const { t } = useTranslation();

  const { data } = useGetLsproSecuritySettingsQuery({ variables: { lsproId } });

  const securityToken = data?.lsproSettings?.securitySettings?.tokenGenerationSecret;

  return (
    <main className={managerStyles.main} data-testid="security-page">
      <Heading className={managerStyles.grayText} data-testid="security-heading">
        {t('manager.channelSettings.security.heading')}
      </Heading>

      <InputDescription inputId="security-token" label={t('manager.channelSettings.security.tokenLabel')}>
        <TextInput readOnly value={securityToken || ''} data-testid="security-token">
          <CopyToClipboard value={securityToken || ''} notificationPosition="left" />
        </TextInput>
      </InputDescription>
    </main>
  );
}
