import { Heading, Paragraph } from '@movingimage-evp/mi-ui-component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaContent } from './media-content';
import { Presentations } from './presentations';
import { EventStage } from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import managerStyles from '../../../manager.module.css';

export function MediaPage() {
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();

  const [saving, setSaving] = useState(false);

  // Some loading states are very quick, so we want to avoid spinner flickering
  // and show it a little longer for a better user experience
  const savingTimeout = useRef<number>();

  const handleLoadStart = () => {
    clearTimeout(savingTimeout.current);
    setSaving(true);
  };

  const handleLoadEnd = () => {
    clearTimeout(savingTimeout.current);

    savingTimeout.current = window.setTimeout(() => {
      setSaving(false);
    }, 800);
  };

  return (
    <main className={managerStyles.main} data-testid="media-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.media.title')}</Heading>
      <Paragraph>{t('manager.webcastSetup.media.heading')}</Paragraph>

      <MediaContent eventStage={EventStage.PRELIVE} onLoadStart={handleLoadStart} onLoadEnd={handleLoadEnd} />
      <MediaContent eventStage={EventStage.POSTLIVE} onLoadStart={handleLoadStart} onLoadEnd={handleLoadEnd} />
      <Presentations onLoadStart={handleLoadStart} onLoadEnd={handleLoadEnd} />

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.media.nextStep.label')}
        route={routes.webcastSetup_viewerAccess}
        saving={saving}
      />
    </main>
  );
}
