import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  Url: { input: string; output: string };
};

export type AccessCodePolicy = {
  __typename?: 'AccessCodePolicy';
  /** Access code */
  accessCode: Scalars['String']['output'];
  /** Determines if the policy is active or not */
  enabled: Scalars['Boolean']['output'];
};

export type AddUsersInput = {
  users: Array<Scalars['String']['input']>;
  webcastId: Scalars['ID']['input'];
};

export type AiSubtitlesFeature = LiveStreamProFeature & {
  __typename?: 'AiSubtitlesFeature';
  enabled: Scalars['Boolean']['output'];
};

/** Webcast Analytics */
export type Analytics = {
  __typename?: 'Analytics';
  /** NPAW: A count of plays which are still consuming video at the end of the minute selected. Use during LIVE */
  activePlays?: Maybe<Scalars['Int']['output']>;
  /**
   * NPAW: Reports the amount of views in which at least one frame of the video is reproduced
   * @deprecated Use activePlays instead for LIVE state and peakActivePlays for POSTLIVE state
   */
  concurrentPlays?: Maybe<Scalars['Int']['output']>;
  /** Highest number of active plays that webcast had in LIVE state. Available only in POSTLIVE, otherwise 0 */
  peakActivePlays?: Maybe<Scalars['Int']['output']>;
  /** NPAW: Play attempts regardless of whether the playback carried on */
  plays?: Maybe<Scalars['Int']['output']>;
  /** NPAW: Number of users who were consuming content */
  uniqueUsers?: Maybe<Scalars['Int']['output']>;
  /** Webcast ID */
  webcastId: Scalars['ID']['output'];
};

export type AppearanceAsset = {
  __typename?: 'AppearanceAsset';
  downloadLink?: Maybe<Scalars['String']['output']>;
  type: AppearanceAssetType;
  uploadLink: UploadLink;
};

export enum AppearanceAssetType {
  FAVICON = 'FAVICON',
  HORIZONTAL_LOGO = 'HORIZONTAL_LOGO',
  LOGO = 'LOGO',
}

/** Requires valid hex color code (e.g. #000 or #000000) */
export type AppearanceColors = {
  __typename?: 'AppearanceColors';
  backgroundColor: Scalars['String']['output'];
  cardColor: Scalars['String']['output'];
  headingFontColor: Scalars['String']['output'];
  primaryColor: Scalars['String']['output'];
  textFontColor: Scalars['String']['output'];
  theme: Theme;
};

export type AppearanceColorsInput = {
  backgroundColor: Scalars['String']['input'];
  cardColor: Scalars['String']['input'];
  headingFontColor: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  textFontColor: Scalars['String']['input'];
  theme: Theme;
};

export type AppearanceFonts = {
  __typename?: 'AppearanceFonts';
  headingFont: Scalars['ID']['output'];
  textFont: Scalars['ID']['output'];
};

export type AppearanceFontsInput = {
  headingFont: Scalars['ID']['input'];
  textFont: Scalars['ID']['input'];
};

export type AppearanceSettings = {
  __typename?: 'AppearanceSettings';
  appearanceColors: Array<AppearanceColors>;
  assets: Array<AppearanceAsset>;
  fonts: AppearanceFonts;
  /**
   * Requires valid hex color code (e.g. #000 or #000000)
   * @deprecated Use appearanceColors instead
   */
  primaryColor: Scalars['String']['output'];
  supportedFonts: Array<Font>;
  theme: Theme;
};

export type Asset = {
  __typename?: 'Asset';
  assetId: Scalars['ID']['output'];
  downloadLink?: Maybe<Scalars['Url']['output']>;
  status: AssetUploadStatus;
};

export type AssetCreateResult = AssetCreateResultFailure | AssetCreateResultSuccess;

export type AssetCreateResultFailure = {
  __typename?: 'AssetCreateResultFailure';
  code: AssetCreateResultFailureCode;
};

export enum AssetCreateResultFailureCode {
  ALREADY_UPLOADED = 'ALREADY_UPLOADED',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  LANGUAGE_NOT_CONFIGURED = 'LANGUAGE_NOT_CONFIGURED',
  LANGUAGE_NOT_SUPPORTED = 'LANGUAGE_NOT_SUPPORTED',
}

export type AssetCreateResultSuccess = {
  __typename?: 'AssetCreateResultSuccess';
  assetId: Scalars['ID']['output'];
  uploadLink: UploadLink;
};

export type AssetDeleteResult = {
  __typename?: 'AssetDeleteResult';
  assetId?: Maybe<Scalars['ID']['output']>;
};

export enum AssetUploadErrorCode {
  SERVER_ERROR = 'SERVER_ERROR',
}

export type AssetUploadStatus = {
  __typename?: 'AssetUploadStatus';
  code: AssetUploadStatusCode;
  error?: Maybe<AssetUploadErrorCode>;
};

export enum AssetUploadStatusCode {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  UPLOADED = 'UPLOADED',
}

export type AudienceRange = {
  __typename?: 'AudienceRange';
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type AudienceRangeInput = {
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
};

export type BlaStreamRoom = {
  __typename?: 'BlaStreamRoom';
  connectionInfo?: Maybe<BlaStreamRoomConnectionInfo>;
  creationForbiddenCause?: Maybe<CreationForbiddenCause>;
  isCreationPermitted: Scalars['Boolean']['output'];
  webcastId: Scalars['ID']['output'];
};

export type BlaStreamRoomConnectionInfo = {
  __typename?: 'BlaStreamRoomConnectionInfo';
  link: Scalars['Url']['output'];
};

export type BlaStreamSimulcast = {
  __typename?: 'BlaStreamSimulcast';
  name: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  serverUrl: Scalars['String']['output'];
  streamKey: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type BrowserBasedStudioFeature = LiveStreamProFeature & {
  __typename?: 'BrowserBasedStudioFeature';
  enabled: Scalars['Boolean']['output'];
};

export type ChangeChatTypeInput = {
  type: ChatType;
  webcastId: Scalars['ID']['input'];
};

/** Change Webcast chat type has failed */
export type ChangeChatTypeProblem = {
  __typename?: 'ChangeChatTypeProblem';
  /** Code indicating the problem */
  code: ChangeChatTypeProblemCode;
};

export enum ChangeChatTypeProblemCode {
  WEBCAST_HAS_CHAT_DISABLED = 'WEBCAST_HAS_CHAT_DISABLED',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type ChangeChatTypeResult = ChangeChatTypeProblem | ChangeChatTypeSuccess;

/** Change Webcast chat type has succeeded */
export type ChangeChatTypeSuccess = {
  __typename?: 'ChangeChatTypeSuccess';
  type: ChatType;
  webcastId: Scalars['ID']['output'];
};

export type Channel = {
  __typename?: 'Channel';
  children: Array<Maybe<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
};

/** Chat configuration */
export type Chat = {
  __typename?: 'Chat';
  applicationId: Scalars['String']['output'];
  channelUrl: Scalars['String']['output'];
  isFrozen: Scalars['Boolean']['output'];
  moderatorIds: Array<Maybe<Scalars['String']['output']>>;
  type: ChatType;
};

export enum ChatType {
  ANONYMOUS = 'ANONYMOUS',
  MODERATED = 'MODERATED',
  UNMODERATED = 'UNMODERATED',
}

export type ComplianceForm = {
  __typename?: 'ComplianceForm';
  text: Scalars['String']['output'];
};

export type ComplianceFormInput = {
  text: Scalars['String']['input'];
};

export type Configuration = {
  __typename?: 'Configuration';
  appearanceColors: Array<AppearanceColors>;
  defaultComplianceForm: ComplianceForm;
  supportedFonts: Array<Scalars['ID']['output']>;
  supportedLanguages: Array<Scalars['String']['output']>;
  supportedSubtitleLanguages: Array<Scalars['String']['output']>;
};

export type CreateAppearanceAssetFailure = {
  __typename?: 'CreateAppearanceAssetFailure';
  code: CreateAppearanceAssetFailureCode;
};

export enum CreateAppearanceAssetFailureCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
}

export type CreateAppearanceAssetInput = {
  assetType: AppearanceAssetType;
  lsproId: Scalars['String']['input'];
};

export type CreateAppearanceAssetResult = CreateAppearanceAssetFailure | CreateAppearanceAssetSuccess;

export type CreateAppearanceAssetSuccess = {
  __typename?: 'CreateAppearanceAssetSuccess';
  asset: AppearanceAsset;
};

export type CreateChatInput = {
  /** Ids of Users that are granted chat moderation rights */
  moderatorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type: ChatType;
  webcastId: Scalars['ID']['input'];
};

/** Create Webcast chat has failed */
export type CreateChatProblem = {
  __typename?: 'CreateChatProblem';
  /** Code indicating the problem */
  code: CreateChatProblemCode;
};

export enum CreateChatProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type CreateChatResult = CreateChatProblem | CreateChatSuccess;

/** Create Webcast chat has succeeded */
export type CreateChatSuccess = {
  __typename?: 'CreateChatSuccess';
  channelUrl: Scalars['String']['output'];
  chatProviderAccountId: Scalars['String']['output'];
};

export type CreateChatUsersProblem = {
  __typename?: 'CreateChatUsersProblem';
  /** Code indicating the problem */
  code: CreateChatUsersProblemCode;
};

export enum CreateChatUsersProblemCode {
  CHAT_ACCOUNT_NOT_INITIALIZED = 'CHAT_ACCOUNT_NOT_INITIALIZED',
  LSPRO_USERS_NOT_FOUND = 'LSPRO_USERS_NOT_FOUND',
}

export type CreateChatUsersResult = CreateChatUsersProblem | CreateChatUsersSuccess;

/** Chat users created */
export type CreateChatUsersSuccess = {
  __typename?: 'CreateChatUsersSuccess';
  userIds: Array<Scalars['String']['output']>;
};

export type CreateFontInput = {
  /** ID of LSPro instance this font will be visible in */
  lsproId: Scalars['ID']['input'];
};

export type CreateFontProblem = {
  __typename?: 'CreateFontProblem';
  code: CreateFontProblemCode;
};

export enum CreateFontProblemCode {
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
}

export type CreateFontResult = CreateFontProblem | CreateFontSuccess;

export type CreateFontSuccess = {
  __typename?: 'CreateFontSuccess';
  fontId: Scalars['ID']['output'];
  uploadLink: UploadLink;
};

export type CreateLiveStreamProInput = {
  accountId: Scalars['String']['input'];
  features?: InputMaybe<Array<UpdateLiveStreamProFeatureInput>>;
  name: Scalars['String']['input'];
};

export type CreatePresentationInput = {
  /** Language (ISO 639-1 code) */
  language: Scalars['String']['input'];
  /** Original name of presentation file */
  sourceFileName: Scalars['String']['input'];
  /** Webcast ID given presentation will be associated with */
  webcastId: Scalars['ID']['input'];
};

export type CreatePresentationProblem = {
  __typename?: 'CreatePresentationProblem';
  /** Code indicating the problem */
  code: CreatePresentationProblemCode;
};

export enum CreatePresentationProblemCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GROUP_ID_NOT_FOUND = 'GROUP_ID_NOT_FOUND',
  LANGUAGE_NOT_CONFIGURED = 'LANGUAGE_NOT_CONFIGURED',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type CreatePresentationResult = CreatePresentationProblem | CreatePresentationSuccess | ValidationErrors;

/** Presentation has been successfully created */
export type CreatePresentationSuccess = {
  __typename?: 'CreatePresentationSuccess';
  /** Created presentation */
  presentation: Presentation;
};

export type CreateWebcastInput = {
  /** Planned duration of the webcast event in minutes */
  durationInMinutes: Scalars['Int']['input'];
  /** Type of protocol for ingestion */
  ingestProtocol?: InputMaybe<IngestProtocol>;
  /** LiveStream Pro id */
  lsproId: Scalars['String']['input'];
  /** Planned start date and time (ISO 8601) */
  plannedStartAt: Scalars['DateTime']['input'];
  /** Webcast event title of primary language */
  title: Scalars['String']['input'];
};

/** Webcast creation failed */
export type CreateWebcastProblem = {
  __typename?: 'CreateWebcastProblem';
  /** Code indicating the problem */
  code: CreateWebcastProblemCode;
};

export enum CreateWebcastProblemCode {
  PLANNED_START_SHOULD_BE_IN_FUTURE = 'PLANNED_START_SHOULD_BE_IN_FUTURE',
}

export type CreateWebcastResult = CreateWebcastProblem | CreateWebcastSuccess | ValidationErrors;

/** Webcast has been successfully created */
export type CreateWebcastSuccess = {
  __typename?: 'CreateWebcastSuccess';
  webcast: Webcast;
};

export enum CreationForbiddenCause {
  LSPRO_FEATURE_NOT_ENABLED = 'LSPRO_FEATURE_NOT_ENABLED',
  WEBCAST_IS_MULTILANG = 'WEBCAST_IS_MULTILANG',
  WEBCAST_IS_NOT_INITIALISED = 'WEBCAST_IS_NOT_INITIALISED',
  WEBCAST_IS_SRT = 'WEBCAST_IS_SRT',
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  accounts: Array<CurrentUserAccount>;
  id: Scalars['ID']['output'];
  isVmproLinked: Scalars['Boolean']['output'];
};

export type CurrentUserAccount = {
  __typename?: 'CurrentUserAccount';
  liveStreamPro: LiveStreamPro;
  userRoles: Array<Role>;
};

export type DeleteAppearanceAssetFailure = {
  __typename?: 'DeleteAppearanceAssetFailure';
  code?: Maybe<DeleteAppearanceAssetFailureCode>;
};

export enum DeleteAppearanceAssetFailureCode {
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
}

export type DeleteAppearanceAssetInput = {
  assetType: AppearanceAssetType;
  lsproId: Scalars['String']['input'];
};

export type DeleteAppearanceAssetResult = DeleteAppearanceAssetFailure | DeleteAppearanceAssetSuccess;

export type DeleteAppearanceAssetSuccess = {
  __typename?: 'DeleteAppearanceAssetSuccess';
  lsproId: Scalars['String']['output'];
};

/** Delete Webcast chat has failed */
export type DeleteChatProblem = {
  __typename?: 'DeleteChatProblem';
  /** Code indicating the problem */
  code: DeleteChatProblemCode;
};

export enum DeleteChatProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type DeleteChatResult = DeleteChatProblem | DeleteChatSuccess;

/** Delete Webcast chat has succeeded */
export type DeleteChatSuccess = {
  __typename?: 'DeleteChatSuccess';
  webcastId: Scalars['ID']['output'];
};

export type DeleteFontProblem = {
  __typename?: 'DeleteFontProblem';
  code: DeleteFontProblemCode;
};

export enum DeleteFontProblemCode {
  FONT_IS_STANDARD = 'FONT_IS_STANDARD',
}

export type DeleteFontResult = DeleteFontProblem | DeleteFontSuccess;

export type DeleteFontSuccess = {
  __typename?: 'DeleteFontSuccess';
  fontId: Scalars['ID']['output'];
};

export type DeletePresentationInput = {
  /** Presentation group ID */
  groupId: Scalars['ID']['input'];
  /** language (ISO 639-1 code) */
  language: Scalars['String']['input'];
  /** Webcast ID associated with given presentation */
  webcastId: Scalars['ID']['input'];
};

/** Presentation deletion has failed */
export type DeletePresentationProblem = {
  __typename?: 'DeletePresentationProblem';
  /** Code indicating the problem */
  code: DeletePresentationProblemCode;
};

export enum DeletePresentationProblemCode {
  PRESENTATION_NOT_FOUND = 'PRESENTATION_NOT_FOUND',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type DeletePresentationResult = DeletePresentationProblem | DeletePresentationSuccess;

/** Presentation has been successfully deleted */
export type DeletePresentationSuccess = {
  __typename?: 'DeletePresentationSuccess';
  /** Deleted presentation */
  presentation: Presentation;
};

export type DeleteUsersInput = {
  userIds: Array<Scalars['ID']['input']>;
  webcastId: Scalars['ID']['input'];
};

/** Webcast delete failed */
export type DeleteWebcastProblem = {
  __typename?: 'DeleteWebcastProblem';
  /** Code indicating the problem */
  code: DeleteWebcastProblemCode;
};

export enum DeleteWebcastProblemCode {
  WEBCAST_IN_LIVE_STATE = 'WEBCAST_IN_LIVE_STATE',
}

export type DeleteWebcastResult = DeleteWebcastProblem | DeleteWebcastSuccess;

/** Webcast has been successfully deleted */
export type DeleteWebcastSuccess = {
  __typename?: 'DeleteWebcastSuccess';
  webcast: Webcast;
};

export type DisableAccessCodePolicyInput = {
  /** Webcast ID given policy will be associated with */
  webcastId: Scalars['ID']['input'];
};

/** Disable security policy has failed */
export type DisableAccessCodePolicyProblem = {
  __typename?: 'DisableAccessCodePolicyProblem';
  /** Code indicating the problem */
  code: DisableAccessCodePolicyProblemCode;
};

export enum DisableAccessCodePolicyProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type DisableAccessCodePolicyResult = DisableAccessCodePolicyProblem | DisableAccessCodePolicySuccess;

/** Access code policy has been successfully disabled */
export type DisableAccessCodePolicySuccess = {
  __typename?: 'DisableAccessCodePolicySuccess';
  /** Access code policy */
  accessCodePolicy: AccessCodePolicy;
};

export type DisableSingleSignOnPolicyInput = {
  /** Webcast ID given policy will be associated with */
  webcastId: Scalars['ID']['input'];
};

/** Disable Single sign-on policy has failed */
export type DisableSingleSignOnPolicyProblem = {
  __typename?: 'DisableSingleSignOnPolicyProblem';
  /** Code indicating the problem */
  code: DisableSingleSignOnPolicyProblemCode;
};

export enum DisableSingleSignOnPolicyProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type DisableSingleSignOnPolicyResult = DisableSingleSignOnPolicyProblem | DisableSingleSignOnPolicySuccess;

/** Single sign-on policy has been successfully disabled */
export type DisableSingleSignOnPolicySuccess = {
  __typename?: 'DisableSingleSignOnPolicySuccess';
  /** Single sign-on policy */
  SingleSignOnPolicy: SingleSignOnPolicy;
};

export type DummyData = {
  __typename?: 'DummyData';
  /** List of lspro IDs */
  lsproIds: Array<Scalars['ID']['output']>;
  /** List of webcast IDs */
  webcastIds: Array<Scalars['ID']['output']>;
};

export type DuplicateWebcastInput = {
  /** Webcast ID */
  id: Scalars['ID']['input'];
};

/** Webcast duplication failed */
export type DuplicateWebcastProblem = {
  __typename?: 'DuplicateWebcastProblem';
  code: DuplicateWebcastProblemCode;
};

export enum DuplicateWebcastProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
}

export type DuplicateWebcastResult = DuplicateWebcastProblem | DuplicateWebcastSuccess | ValidationErrors;

export type DuplicateWebcastSuccess = {
  __typename?: 'DuplicateWebcastSuccess';
  webcast: Webcast;
};

/** Ecdn configuration */
export type ECdnSettings = {
  __typename?: 'ECdnSettings';
  isActive: Scalars['Boolean']['output'];
  type: ECdnType;
};

export type ECdnSettingsInput = {
  enabled: Scalars['Boolean']['input'];
  type?: InputMaybe<ECdnType>;
};

export enum ECdnType {
  HIVE = 'HIVE',
  RAMP = 'RAMP',
  VBRICK_OMNICACHE = 'VBRICK_OMNICACHE',
  VBRICK_P2P = 'VBRICK_P2P',
  VBRICK_P2P_AND_OMNICACHE = 'VBRICK_P2P_AND_OMNICACHE',
}

export type EmailRegistrationPolicy = {
  __typename?: 'EmailRegistrationPolicy';
  /** Indicates if policy is enabled */
  enabled: Scalars['Boolean']['output'];
  /** If true, requires users to fill in first and last names */
  requireNameFields: Scalars['Boolean']['output'];
};

export type EmailWhitelistPolicy = {
  __typename?: 'EmailWhitelistPolicy';
  /**
   * List of whitelisted emails.
   * Email templates with wildcard symbol (*) are supported.
   * All emails are sorted alphabetically, with the wildcard items on top of the list.
   */
  emails: Array<Scalars['String']['output']>;
  /** Is policy enabled. */
  enabled: Scalars['Boolean']['output'];
  /** If true, requires users to fill in first and last names. */
  requireNameFields: Scalars['Boolean']['output'];
};

export type EnableAccessCodePolicyInput = {
  /** Webcast ID given policy will be associated with */
  webcastId: Scalars['ID']['input'];
};

/** Enable access code policy has failed */
export type EnableAccessCodePolicyProblem = {
  __typename?: 'EnableAccessCodePolicyProblem';
  /** Code indicating the problem */
  code: EnableAccessCodePolicyProblemCode;
};

export enum EnableAccessCodePolicyProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type EnableAccessCodePolicyResult = EnableAccessCodePolicyProblem | EnableAccessCodePolicySuccess;

/** Access code policy has been successfully enabled */
export type EnableAccessCodePolicySuccess = {
  __typename?: 'EnableAccessCodePolicySuccess';
  /** Access code policy */
  accessCodePolicy: AccessCodePolicy;
};

export type EnableSingleSignOnPolicyInput = {
  /** List of IDPs for single sign-on */
  idps: Array<Scalars['String']['input']>;
  /** Webcast ID given policy will be associated with */
  webcastId: Scalars['ID']['input'];
};

/** Enable Single sign-on policy has failed */
export type EnableSingleSignOnPolicyProblem = {
  __typename?: 'EnableSingleSignOnPolicyProblem';
  /** Code indicating the problem */
  code: EnableSingleSignOnPolicyProblemCode;
};

export enum EnableSingleSignOnPolicyProblemCode {
  IDP_LIST_EMPTY = 'IDP_LIST_EMPTY',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type EnableSingleSignOnPolicyResult = EnableSingleSignOnPolicyProblem | EnableSingleSignOnPolicySuccess;

/** Single sign-on policy has been successfully enabled */
export type EnableSingleSignOnPolicySuccess = {
  __typename?: 'EnableSingleSignOnPolicySuccess';
  /** Single sign-on policy */
  SingleSignOnPolicy: SingleSignOnPolicy;
};

export type EnableVodInput = {
  webcastId: Scalars['ID']['input'];
};

export type EnableVodProblem = {
  __typename?: 'EnableVodProblem';
  code?: Maybe<EnableVodProblemCode>;
};

export enum EnableVodProblemCode {
  VOD_VIDEO_NOT_CONFIGURED = 'VOD_VIDEO_NOT_CONFIGURED',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
}

export type EnableVodResult = EnableVodProblem | EnableVodSuccess;

export type EnableVodSuccess = {
  __typename?: 'EnableVodSuccess';
  webcastId: Scalars['ID']['output'];
};

export type EventPermission = {
  __typename?: 'EventPermission';
  collaborators: Array<User>;
  owners: Array<User>;
  type: EventPermissionType;
};

export enum EventPermissionType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum EventStage {
  LIVE = 'LIVE',
  POSTLIVE = 'POSTLIVE',
  PRELIVE = 'PRELIVE',
}

export type Font = {
  __typename?: 'Font';
  /** Optional file asset of a font. Should typically be present if this font is not a default one. */
  asset?: Maybe<Asset>;
  /** ID of the font that can be used to assign it to LSPRO instance settings. */
  id: Scalars['ID']['output'];
  /** Marks whether this font is a standard one (i.e. accessible in global configuration). */
  isStandard: Scalars['Boolean']['output'];
  /**
   * Display name of a font (file name could be used if it's not found in metadata).
   * The name can be slightly modified to preserve uniqueness,
   * i.e. "Arial_Custom_1", "Arial_21.11.24" instead of "Arial", since "Arial" is a default font.
   */
  name: Scalars['String']['output'];
};

export type GenerateReportInput = {
  type: ReportType;
  webcastId: Scalars['ID']['input'];
};

export type GenerateReportProblem = {
  __typename?: 'GenerateReportProblem';
  code?: Maybe<GenerateReportProblemCode>;
};

export enum GenerateReportProblemCode {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_POSTLIVE_STATE = 'WEBCAST_NOT_IN_POSTLIVE_STATE',
}

export type GenerateReportResult = GenerateReportProblem | GenerateReportSuccess;

export type GenerateReportSuccess = {
  __typename?: 'GenerateReportSuccess';
  report?: Maybe<Report>;
};

export type GetMonthlyStreamingUsageResult = {
  __typename?: 'GetMonthlyStreamingUsageResult';
  month: Scalars['Int']['output'];
  quota: Quota;
  usedMinutes: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type GetSessionProblem = {
  __typename?: 'GetSessionProblem';
  code?: Maybe<GetSessionProblemCode>;
};

export enum GetSessionProblemCode {
  WEBCAST_IN_PRELIVE_STATE = 'WEBCAST_IN_PRELIVE_STATE',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
}

export type GetSessionResult = GetSessionProblem | GetSessionSuccess;

export type GetSessionSuccess = {
  __typename?: 'GetSessionSuccess';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  liveStreams: Array<LiveStreamInfo>;
  pushedSlides: Array<PushedSlide>;
  startDate: Scalars['DateTime']['output'];
};

export type GetStreamingUsageProblem = {
  __typename?: 'GetStreamingUsageProblem';
  code?: Maybe<GetStreamingUsageProblemCode>;
};

export enum GetStreamingUsageProblemCode {
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
}

export type GetStreamingUsageReportProblem = {
  __typename?: 'GetStreamingUsageReportProblem';
  code: GetStreamingUsageReportProblemCode;
};

export enum GetStreamingUsageReportProblemCode {
  INVALID_DATE_RANGE = 'INVALID_DATE_RANGE',
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
}

export type GetStreamingUsageReportResult = GetStreamingUsageReportProblem | GetStreamingUsageReportSuccess;

export type GetStreamingUsageReportSuccess = {
  __typename?: 'GetStreamingUsageReportSuccess';
  downloadUrl: Scalars['String']['output'];
};

export type GetStreamingUsageResult = GetMonthlyStreamingUsageResult | GetStreamingUsageProblem;

export type GetUsersProblem = {
  __typename?: 'GetUsersProblem';
  code?: Maybe<GetUsersProblemCode>;
};

export enum GetUsersProblemCode {
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
}

export type GetUsersResult = GetUsersProblem | GetUsersSuccess;

export type GetUsersSuccess = {
  __typename?: 'GetUsersSuccess';
  users: Array<User>;
};

export type GetVideoManagerProblem = {
  __typename?: 'GetVideoManagerProblem';
  code: GetVideoManagerProblemCode;
};

export enum GetVideoManagerProblemCode {
  VMPRO_LINK_MISSING = 'VMPRO_LINK_MISSING',
}

export type GetVideoManagerResult = GetVideoManagerProblem | VideoManager;

export type GetVideoManagersProblem = {
  __typename?: 'GetVideoManagersProblem';
  code: GetVideoManagersProblemCode;
};

export enum GetVideoManagersProblemCode {
  VMPRO_LINK_MISSING = 'VMPRO_LINK_MISSING',
}

export type GetVideoManagersResult = GetVideoManagersProblem | GetVideoManagersSuccess;

export type GetVideoManagersSuccess = {
  __typename?: 'GetVideoManagersSuccess';
  videoManagers: Array<VideoManager>;
};

export type IdentityProvider = {
  __typename?: 'IdentityProvider';
  alias: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Ingest = {
  __typename?: 'Ingest';
  /** Backup ingest server url, used in case primary server is down */
  backupServer: Scalars['String']['output'];
  /** Password for authenticating push connection */
  password: Scalars['String']['output'];
  /** Primary ingest server url */
  primaryServer: Scalars['String']['output'];
  /** Stream name / key */
  streamName: Scalars['String']['output'];
  /** User name for authenticating push connection */
  username: Scalars['String']['output'];
};

/**
 * Ingest input can contain either srt or rtmp field.
 * In case both fields are sent, MORE_THAN_ONE_INGEST_FIELD_PROVIDED problem will be returned
 */
export type IngestInput = {
  rtmp?: InputMaybe<RtmpIngestInput>;
  srt?: InputMaybe<SrtIngestInput>;
};

export enum IngestProtocol {
  RTMP = 'RTMP',
  SRT = 'SRT',
}

/** Chat feature for lspro successfully initialized */
export type InitializeChatFeatureResult = {
  __typename?: 'InitializeChatFeatureResult';
  chatProviderAccountId: Scalars['String']['output'];
};

export type InitializeEventProblem = {
  __typename?: 'InitializeEventProblem';
  /** Code indicating the problem */
  code: InitializeEventProblemCode;
};

export enum InitializeEventProblemCode {
  INGEST_PROTOCOL_NOT_CHOSEN = 'INGEST_PROTOCOL_NOT_CHOSEN',
  LANGUAGES_NOT_CHOSEN = 'LANGUAGES_NOT_CHOSEN',
  MULTI_LANGUAGE_SRT_NOT_SUPPORTED = 'MULTI_LANGUAGE_SRT_NOT_SUPPORTED',
  SUBTITLE_GENERATION_SETUP_FAILED = 'SUBTITLE_GENERATION_SETUP_FAILED',
  SUBTITLE_PROVIDER_NOT_AVAILABLE = 'SUBTITLE_PROVIDER_NOT_AVAILABLE',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type InitializeEventResult = InitializeEventProblem | InitializeEventSuccess;

export type InitializeEventSuccess = {
  __typename?: 'InitializeEventSuccess';
  results: Array<StreamingOperationResult>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  lsproId: Scalars['ID']['input'];
  roles: Array<Role>;
};

export type InviteUserProblem = {
  __typename?: 'InviteUserProblem';
  code: InviteUserProblemCode;
};

export enum InviteUserProblemCode {
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
  USER_ALREADY_PRESENT = 'USER_ALREADY_PRESENT',
}

export type InviteUserResult = InviteUserProblem | InviteUserSuccess;

export type InviteUserSuccess = {
  __typename?: 'InviteUserSuccess';
  user: User;
};

export enum ItemListAction {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export enum Language {
  DE = 'DE',
  EN = 'EN',
  HR = 'HR',
  IT = 'IT',
  PL = 'PL',
}

export type LanguageConfigurationInput = {
  /** Set as primary language */
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Language */
  language: Scalars['String']['input'];
};

export type LinkVmproInput = {
  authorizationCode: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LinkVmproProblem = {
  __typename?: 'LinkVmproProblem';
  code: LinkVmproProblemCode;
};

export enum LinkVmproProblemCode {
  AUTHORIZATION_INVALID = 'AUTHORIZATION_INVALID',
}

export type LinkVmproResult = LinkVmproProblem | LinkVmproSuccess;

export type LinkVmproSuccess = {
  __typename?: 'LinkVmproSuccess';
  authorizationCode: Scalars['String']['output'];
  redirectUri: Scalars['String']['output'];
};

export type LiveStreamInfo = {
  __typename?: 'LiveStreamInfo';
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
};

export type LiveStreamPro = {
  __typename?: 'LiveStreamPro';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type LiveStreamProFeature = {
  /** Indicates whether the feature is enabled */
  enabled: Scalars['Boolean']['output'];
};

export enum LiveStreamProFeatureType {
  AI_SUBTITLES = 'AI_SUBTITLES',
  BROWSER_BASED_STUDIO = 'BROWSER_BASED_STUDIO',
  LIVE_STREAMING_HOURS = 'LIVE_STREAMING_HOURS',
}

export type LiveStreamProSettings = {
  __typename?: 'LiveStreamProSettings';
  /** Appearance settings. Includes things like color, themes, logo files, etc. */
  appearance: AppearanceSettings;
  /** Additional compliance form to be displayed. Can be empty. */
  complianceForm: ComplianceForm;
  /** List of feature settings */
  features: Array<LiveStreamProFeature>;
  /** A flag indicating whether user wants to display defaultComplianceForm from global configuration or not. */
  includeDefaultComplianceForm: Scalars['Boolean']['output'];
  /** ID of given LSPRO */
  lsproId: Scalars['String']['output'];
  /** Security settings */
  securitySettings: SecuritySettings;
};

export type LiveStreaming = {
  __typename?: 'LiveStreaming';
  defaultParameters: LiveStreamingDefaultParameters;
  ingestProtocol: IngestProtocol;
  parameters: LiveStreamingParameters;
  streams: Array<Stream>;
};

/** Default live streaming parameters */
export type LiveStreamingDefaultParameters = {
  __typename?: 'LiveStreamingDefaultParameters';
  rtmp: RtmpLiveStreamingDefaultParameters;
  srt: SrtLiveStreamingDefaultParameters;
};

export type LiveStreamingHoursFeature = LiveStreamProFeature & {
  __typename?: 'LiveStreamingHoursFeature';
  enabled: Scalars['Boolean']['output'];
  quotaType: QuotaType;
  value: Scalars['Int']['output'];
};

export type LiveStreamingParameters = RtmpLiveStreamingParameters | SrtLiveStreamingParameters;

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates chat on a given Webcast */
  changeChatType: ChangeChatTypeResult;
  cleanupLiveStreamPro?: Maybe<LiveStreamPro>;
  createAppearanceAsset: CreateAppearanceAssetResult;
  /** Creates chat on a given Webcast */
  createChat: CreateChatResult;
  createChatUsers?: Maybe<CreateChatUsersResult>;
  createFont: CreateFontResult;
  createLiveStreamPro: LiveStreamPro;
  /**
   * Creates presentation for given webcast
   *
   * groupId - unique key that is used to group all language versions for a given presentation.
   */
  createPresentation: CreatePresentationResult;
  /** Creates webcast */
  createWebcast: CreateWebcastResult;
  createWebcastCoverImage: AssetCreateResult;
  deleteAppearanceAsset: DeleteAppearanceAssetResult;
  /** Deletes chat on a given Webcast */
  deleteChat: DeleteChatResult;
  deleteFont: DeleteFontResult;
  /** Deletes presentation */
  deletePresentation: DeletePresentationResult;
  /** Deletes webcast */
  deleteWebcast: DeleteWebcastResult;
  deleteWebcastCoverImage: AssetDeleteResult;
  /**
   * Remove access code policy for given webcast
   * @deprecated use updateAccessCodePolicy instead
   */
  disableAccessCodePolicy: DisableAccessCodePolicyResult;
  /**
   * Remove single sign-on policy for given webcast
   * @deprecated use updateSingleSignOnPolicy instead
   */
  disableSingleSignOnPolicy: DisableSingleSignOnPolicyResult;
  /** Duplicates an existing webcast */
  duplicateWebcast: DuplicateWebcastResult;
  /**
   * Add access code policy for given webcast
   * @deprecated use updateAccessCodePolicy instead
   */
  enableAccessCodePolicy: EnableAccessCodePolicyResult;
  /**
   * Add single sign-on policy for given webcast
   * @deprecated use updateSingleSignOnPolicy instead
   */
  enableSingleSignOnPolicy: EnableSingleSignOnPolicyResult;
  /** Enables VOD for a given Webcast */
  enableVod: EnableVodResult;
  generateReport?: Maybe<GenerateReportResult>;
  /** Initializes chat functionality on LSPro account, allowing for chats on Webcasts to be enabled/disabled */
  initializeChatFeature: InitializeChatFeatureResult;
  initializeEvent: InitializeEventResult;
  /** Invites user to a given LSPRO Manager */
  inviteUser: InviteUserResult;
  linkVmpro: LinkVmproResult;
  /** Injects "slide_push" event to the specified live webcast */
  pushSlide: PushSlideResult;
  /** Removes user from given LSPRO Manager */
  removeUser: RemoveUserResult;
  /**
   * Saves the configuration of existing blastream room with specified webcastId.
   * This configuration is then used as a default template for all new rooms in its parent LSPRO.
   */
  saveBlaStreamRoomSettingsAsDefault: SaveBlaStreamRoomSettingsAsDefaultResult;
  /** Sets roles to user */
  setUserRoles: SetUserRolesResult;
  startEvent: StartEventResult;
  startLiveTest: StartLiveTestResult;
  stopEvent: StopEventResult;
  stopLiveTest: StopLiveTestResult;
  /** Update access code policy for given webcast */
  updateAccessCodePolicy: UpdateAccessCodePolicyResult;
  updateAppearanceSettings: UpdateAppearanceSettingsResult;
  updateEmailRegistrationPolicy: UpdateEmailRegistrationPolicyResult;
  updateEmailWhitelistPolicy: UpdateEmailWhitelistPolicyResult;
  /** Update event permission for given webcast */
  updateEventPermission: UpdateEventPermissionResult;
  updateLiveStreamPro?: Maybe<LiveStreamPro>;
  updateLiveStreamProComplianceForm: UpdateComplianceFormResult;
  /** Update Referrer Protection for given webcast by providing boolean flag and/or list of domains */
  updateReferrerProtectionPolicy: UpdateReferrerProtectionResult;
  /** Update single sign-on policy for given webcast by providing boolean flag and/or list of emails, groups, uids */
  updateSingleSignOnPolicy: UpdateSingleSignOnPolicyResult;
  /** Update Token Protection policy for given webcast by providing boolean flag */
  updateTokenProtectionPolicy: UpdateTokenProtectionResult;
  /** Updates VOD settings for a given Webcast */
  updateVod: UpdateVodResult;
  /** Updates webcast */
  updateWebcast: UpdateWebcastResult;
};

export type MutationChangeChatTypeArgs = {
  input: ChangeChatTypeInput;
};

export type MutationCleanupLiveStreamProArgs = {
  lsproId: Scalars['ID']['input'];
};

export type MutationCreateAppearanceAssetArgs = {
  input: CreateAppearanceAssetInput;
};

export type MutationCreateChatArgs = {
  input: CreateChatInput;
};

export type MutationCreateChatUsersArgs = {
  lsproId: Scalars['ID']['input'];
};

export type MutationCreateFontArgs = {
  input: CreateFontInput;
};

export type MutationCreateLiveStreamProArgs = {
  input: CreateLiveStreamProInput;
};

export type MutationCreatePresentationArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
  input: CreatePresentationInput;
};

export type MutationCreateWebcastArgs = {
  input: CreateWebcastInput;
};

export type MutationCreateWebcastCoverImageArgs = {
  eventStage: EventStage;
  language?: InputMaybe<Scalars['String']['input']>;
  webcastId: Scalars['ID']['input'];
};

export type MutationDeleteAppearanceAssetArgs = {
  input: DeleteAppearanceAssetInput;
};

export type MutationDeleteChatArgs = {
  webcastId: Scalars['ID']['input'];
};

export type MutationDeleteFontArgs = {
  fontId: Scalars['ID']['input'];
};

export type MutationDeletePresentationArgs = {
  input: DeletePresentationInput;
};

export type MutationDeleteWebcastArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteWebcastCoverImageArgs = {
  eventStage: EventStage;
  language?: InputMaybe<Scalars['String']['input']>;
  webcastId: Scalars['ID']['input'];
};

export type MutationDisableAccessCodePolicyArgs = {
  input: DisableAccessCodePolicyInput;
};

export type MutationDisableSingleSignOnPolicyArgs = {
  input: DisableSingleSignOnPolicyInput;
};

export type MutationDuplicateWebcastArgs = {
  input: DuplicateWebcastInput;
};

export type MutationEnableAccessCodePolicyArgs = {
  input: EnableAccessCodePolicyInput;
};

export type MutationEnableSingleSignOnPolicyArgs = {
  input: EnableSingleSignOnPolicyInput;
};

export type MutationEnableVodArgs = {
  input: EnableVodInput;
};

export type MutationGenerateReportArgs = {
  input: GenerateReportInput;
};

export type MutationInitializeChatFeatureArgs = {
  lsproId: Scalars['ID']['input'];
};

export type MutationInitializeEventArgs = {
  webcastId: Scalars['ID']['input'];
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationLinkVmproArgs = {
  input: LinkVmproInput;
};

export type MutationPushSlideArgs = {
  input: PushSlideInput;
};

export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};

export type MutationSaveBlaStreamRoomSettingsAsDefaultArgs = {
  webcastId: Scalars['ID']['input'];
};

export type MutationSetUserRolesArgs = {
  input: SetUserRolesInput;
};

export type MutationStartEventArgs = {
  webcastId: Scalars['ID']['input'];
};

export type MutationStartLiveTestArgs = {
  webcastId: Scalars['ID']['input'];
};

export type MutationStopEventArgs = {
  webcastId: Scalars['ID']['input'];
};

export type MutationStopLiveTestArgs = {
  webcastId: Scalars['ID']['input'];
};

export type MutationUpdateAccessCodePolicyArgs = {
  input: UpdateAccessCodePolicyInput;
};

export type MutationUpdateAppearanceSettingsArgs = {
  input: UpdateAppearanceSettingsInput;
};

export type MutationUpdateEmailRegistrationPolicyArgs = {
  input: UpdateEmailRegistrationPolicyInput;
};

export type MutationUpdateEmailWhitelistPolicyArgs = {
  input: UpdateEmailWhitelistPolicyInput;
};

export type MutationUpdateEventPermissionArgs = {
  input: UpdateEventPermissionInput;
};

export type MutationUpdateLiveStreamProArgs = {
  input: UpdateLiveStreamProInput;
};

export type MutationUpdateLiveStreamProComplianceFormArgs = {
  input: UpdateComplianceFormInput;
};

export type MutationUpdateReferrerProtectionPolicyArgs = {
  input: UpdateReferrerProtectionPolicyInput;
};

export type MutationUpdateSingleSignOnPolicyArgs = {
  input: UpdateSingleSignOnPolicyInput;
};

export type MutationUpdateTokenProtectionPolicyArgs = {
  input: UpdateTokenProtectionPolicyInput;
};

export type MutationUpdateVodArgs = {
  input: UpdateVodInput;
};

export type MutationUpdateWebcastArgs = {
  input: UpdateWebcastInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Pid = {
  __typename?: 'Pid';
  language: Scalars['String']['output'];
  pid: Scalars['Int']['output'];
};

export type PidInput = {
  language: Scalars['String']['input'];
  pid: Scalars['Int']['input'];
};

export type Playback = {
  __typename?: 'Playback';
  backupUrl?: Maybe<Scalars['Url']['output']>;
  primaryUrl: Scalars['Url']['output'];
  token: Scalars['String']['output'];
};

export type Presentation = {
  __typename?: 'Presentation';
  /** Download URL */
  downloadLink?: Maybe<Scalars['String']['output']>;
  /** Unique key that is used to group all language versions for a given presentation. */
  groupId: Scalars['String']['output'];
  /** Presentation identifier */
  id: Scalars['String']['output'];
  /** language (ISO 639-1 code) */
  language: Scalars['String']['output'];
  /** Extracted slide image URLs */
  slides?: Maybe<Array<PresentationSlide>>;
  /** Original name of presentation file */
  sourceFileName: Scalars['String']['output'];
  /** Current status */
  status: PresentationStatus;
  /** Upload link details */
  uploadLink: UploadLink;
};

export type PresentationSlide = {
  __typename?: 'PresentationSlide';
  downloadUrl: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  thumbnailDownloadUrl: Scalars['String']['output'];
};

export type PresentationSlidesExtractionStatus = {
  __typename?: 'PresentationSlidesExtractionStatus';
  processedSlides?: Maybe<Scalars['Int']['output']>;
  progress: ProgressStatus;
  totalSlides?: Maybe<Scalars['Int']['output']>;
};

export type PresentationStatus = {
  __typename?: 'PresentationStatus';
  /** Slide extraction process status */
  slidesExtractionStatus: PresentationSlidesExtractionStatus;
  /** Upload process status */
  uploadStatus: PresentationUploadStatus;
};

export type PresentationUploadStatus = {
  __typename?: 'PresentationUploadStatus';
  progress: ProgressStatus;
};

export enum ProgressStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  PASSED = 'PASSED',
}

export type PushSlideInput = {
  /** Presentation group ID */
  groupId: Scalars['ID']['input'];
  /** slide index */
  index: Scalars['Int']['input'];
  /** Webcast ID associated with given presentation */
  webcastId: Scalars['ID']['input'];
};

/** Slide push has failed */
export type PushSlideProblem = {
  __typename?: 'PushSlideProblem';
  /** Code indicating the problem */
  code: PushSlideProblemCode;
};

export enum PushSlideProblemCode {
  PRESENTATION_NOT_FOUND = 'PRESENTATION_NOT_FOUND',
  PUSH_PARTIALLY_FAILED = 'PUSH_PARTIALLY_FAILED',
  SLIDE_INDEX_OUT_OF_BOUNDS = 'SLIDE_INDEX_OUT_OF_BOUNDS',
  WEBCAST_NOT_LIVE = 'WEBCAST_NOT_LIVE',
}

export type PushSlideResult = PushSlideProblem | PushSlideSuccess | ValidationErrors;

/** Slide has been successfully pushed */
export type PushSlideSuccess = {
  __typename?: 'PushSlideSuccess';
  /** Presentation group ID */
  groupId: Scalars['ID']['output'];
  /** slide index */
  index: Scalars['Int']['output'];
  results: Array<StreamingOperationResult>;
};

export type PushedSlide = {
  __typename?: 'PushedSlide';
  groupId: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  offsetInMs?: Maybe<Scalars['Int']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Queries blastream room connection info. Creates a new room if the room doesn't exist. */
  blaStreamRoom: BlaStreamRoom;
  /** Returns global configuration settings */
  configuration: Configuration;
  /** Returns all the LSPRO Manager accounts for the user who is querying */
  currentUser?: Maybe<CurrentUser>;
  /** Returns IDs of hardcoded dummy data */
  dummyData: DummyData;
  /** Returns IDP List configured on a LSPro */
  identityProviders?: Maybe<Array<IdentityProvider>>;
  /** Returns LSPro settings */
  lsproSettings?: Maybe<LiveStreamProSettings>;
  report?: Maybe<Report>;
  /**
   * Returns active webcast session.
   * Must be called during LIVE state, otherwise problem is returned.
   */
  session: GetSessionResult;
  /** Returns streaming usage for given lspro */
  streamingUsage: GetStreamingUsageResult;
  /** Returns download url to usage report for provided date range */
  streamingUsageReport: GetStreamingUsageReportResult;
  /** Returns all users for given LSPRO Manager */
  users: GetUsersResult;
  videoManager: GetVideoManagerResult;
  videoManagers: GetVideoManagersResult;
  videos: VideoSearchResult;
  /** Returns webcast with given ID */
  webcast: Webcast;
  /** Returns paginated webcast list for a given LSPRO id. */
  webcasts: WebcastConnection;
};

export type QueryBlaStreamRoomArgs = {
  webcastId: Scalars['ID']['input'];
};

export type QueryIdentityProvidersArgs = {
  lsproId: Scalars['ID']['input'];
};

export type QueryLsproSettingsArgs = {
  lsproId: Scalars['ID']['input'];
};

export type QueryReportArgs = {
  reportType?: InputMaybe<ReportType>;
  webcastId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySessionArgs = {
  webcastId: Scalars['ID']['input'];
};

export type QueryStreamingUsageArgs = {
  lsproId: Scalars['ID']['input'];
};

export type QueryStreamingUsageReportArgs = {
  endDate: Scalars['DateTime']['input'];
  lsproId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type QueryUsersArgs = {
  lsproId: Scalars['ID']['input'];
};

export type QueryVideoManagerArgs = {
  videoManagerId: Scalars['ID']['input'];
};

export type QueryVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  channelId: Scalars['ID']['input'];
  first?: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  videoManagerId: Scalars['ID']['input'];
};

export type QueryWebcastArgs = {
  webcastId: Scalars['ID']['input'];
};

export type QueryWebcastsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<WebcastFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  lsproId: Scalars['ID']['input'];
  orderBy?: InputMaybe<WebcastOrder>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type Quota = {
  __typename?: 'Quota';
  type: QuotaType;
  value: Scalars['Int']['output'];
};

export enum QuotaType {
  IN_TOTAL = 'IN_TOTAL',
  PER_MONTH = 'PER_MONTH',
}

export type RecordingMetadata = {
  __typename?: 'RecordingMetadata';
  language?: Maybe<Scalars['String']['output']>;
};

export type RecordingSettings = {
  __typename?: 'RecordingSettings';
  enabled: Scalars['Boolean']['output'];
  videoManager?: Maybe<VideoManagerLink>;
};

export type RecordingSettingsInput = {
  enabled: Scalars['Boolean']['input'];
  videoManager?: InputMaybe<VideoManagerLinkInput>;
};

export enum RecordingState {
  OFF = 'OFF',
  ON = 'ON',
}

export enum RecordingUploadStatus {
  FAILED = 'FAILED',
  NOT_READY = 'NOT_READY',
  READY_FOR_DOWNLOAD = 'READY_FOR_DOWNLOAD',
}

export type ReferrerProtectionPolicy = {
  __typename?: 'ReferrerProtectionPolicy';
  /** List of referrer domains allowed to view the webcast */
  domains?: Maybe<Array<Scalars['String']['output']>>;
  /** Is Referrer Protection enabled */
  enabled: Scalars['Boolean']['output'];
};

export type RemoveUserInput = {
  id: Scalars['ID']['input'];
  lsproId: Scalars['ID']['input'];
};

export type RemoveUserResult = RemoveUserSuccess;

export type RemoveUserSuccess = {
  __typename?: 'RemoveUserSuccess';
  id: Scalars['ID']['output'];
};

export type Report = {
  __typename?: 'Report';
  downloadLink?: Maybe<Scalars['String']['output']>;
  generationFailure?: Maybe<ReportGenerationFailure>;
  id?: Maybe<Scalars['ID']['output']>;
  status: ReportStatus;
  type: ReportType;
  webcastId: Scalars['ID']['output'];
};

export enum ReportFailureCode {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type ReportGenerationFailure = {
  __typename?: 'ReportGenerationFailure';
  code?: Maybe<ReportFailureCode>;
  reason?: Maybe<Scalars['String']['output']>;
};

export enum ReportStatus {
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  NO_DATA = 'NO_DATA',
  READY = 'READY',
  SCHEDULED = 'SCHEDULED',
}

export enum ReportType {
  CHAT_MESSAGES_REPORT = 'CHAT_MESSAGES_REPORT',
  REGISTERED_USERS_REPORT = 'REGISTERED_USERS_REPORT',
  VIEWS_REPORT = 'VIEWS_REPORT',
}

export enum Role {
  ADMIN = 'ADMIN',
  DATA_ANALYST = 'DATA_ANALYST',
  EDITOR = 'EDITOR',
  MODERATOR = 'MODERATOR',
  PRODUCER = 'PRODUCER',
}

export type RtmpIngestInput = {
  parameters: RtmpParametersInput;
};

export type RtmpLiveStreamingDefaultParameters = {
  __typename?: 'RtmpLiveStreamingDefaultParameters';
  authenticationEnabled: Scalars['Boolean']['output'];
};

export type RtmpLiveStreamingParameters = {
  __typename?: 'RtmpLiveStreamingParameters';
  authenticationEnabled: Scalars['Boolean']['output'];
};

export type RtmpParametersInput = {
  /** When authentication is enabled, the rtmp username and password are required for streaming */
  authenticationEnabled?: Scalars['Boolean']['input'];
};

export type SaveBlaStreamRoomSettingsAsDefaultProblem = {
  __typename?: 'SaveBlaStreamRoomSettingsAsDefaultProblem';
  code: SaveBlaStreamRoomSettingsAsDefaultProblemCode;
};

export enum SaveBlaStreamRoomSettingsAsDefaultProblemCode {
  BLASTREAM_ROOM_WITH_SPECIFIED_WEBCAST_ID_DOESNT_EXIST = 'BLASTREAM_ROOM_WITH_SPECIFIED_WEBCAST_ID_DOESNT_EXIST',
}

export type SaveBlaStreamRoomSettingsAsDefaultResult =
  | SaveBlaStreamRoomSettingsAsDefaultProblem
  | SaveBlaStreamRoomSettingsAsDefaultSuccess;

export type SaveBlaStreamRoomSettingsAsDefaultSuccess = {
  __typename?: 'SaveBlaStreamRoomSettingsAsDefaultSuccess';
  _?: Maybe<Scalars['Int']['output']>;
};

export type SecurityPolicy =
  | AccessCodePolicy
  | EmailRegistrationPolicy
  | EmailWhitelistPolicy
  | ReferrerProtectionPolicy
  | SingleSignOnPolicy
  | TokenProtectionPolicy;

export type SecuritySettings = {
  __typename?: 'SecuritySettings';
  /** Secret for generating HMAC tokens */
  tokenGenerationSecret: Scalars['String']['output'];
};

export type SetUserRolesInput = {
  id: Scalars['ID']['input'];
  lsproId: Scalars['ID']['input'];
  roles: Array<Role>;
};

export type SetUserRolesResult = SetUserRolesSuccess;

export type SetUserRolesSuccess = {
  __typename?: 'SetUserRolesSuccess';
  user: User;
};

/** Webcast setup failed */
export type SetupWebcastProblem = {
  __typename?: 'SetupWebcastProblem';
  /** Code indicating the problem */
  code: SetupWebcastProblemCode;
};

export enum SetupWebcastProblemCode {
  INGEST_PROTOCOL_NOT_CHOSEN = 'INGEST_PROTOCOL_NOT_CHOSEN',
  WEBCAST_IS_LIVE = 'WEBCAST_IS_LIVE',
}

export type SetupWebcastResult = SetupWebcastProblem | SetupWebcastSuccess;

/** Webcast has been successfully configured */
export type SetupWebcastSuccess = {
  __typename?: 'SetupWebcastSuccess';
  webcast: Webcast;
};

export type SharingLink = {
  __typename?: 'SharingLink';
  sharingUrl: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type SingleSignOnPolicy = {
  __typename?: 'SingleSignOnPolicy';
  /** Is single sign-on enabled */
  enabled: Scalars['Boolean']['output'];
  /** List of IDPs for single sign-on */
  idps?: Maybe<Array<Scalars['String']['output']>>;
  /** Restrictions for single sign-on */
  restrictions?: Maybe<SingleSignOnRestrictions>;
};

export type SingleSignOnRestrictions = {
  __typename?: 'SingleSignOnRestrictions';
  emails: Array<Scalars['String']['output']>;
  employeeIds: Array<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  groups: Array<Scalars['String']['output']>;
};

export type SrtIngestInput = {
  parameters: SrtParametersInput;
};

export type SrtLiveStreamingDefaultParameters = {
  __typename?: 'SrtLiveStreamingDefaultParameters';
  pids: Array<Pid>;
};

export type SrtLiveStreamingParameters = {
  __typename?: 'SrtLiveStreamingParameters';
  pids: Array<Pid>;
};

export type SrtParametersInput = {
  /** When set to null, default parameters are applied */
  pids?: InputMaybe<Array<PidInput>>;
};

export type StartEventNotEnoughStreamsConnectedProblem = {
  __typename?: 'StartEventNotEnoughStreamsConnectedProblem';
  unconnectedStreams: Array<Stream>;
};

export type StartEventProblem = {
  __typename?: 'StartEventProblem';
  /** Code indicating the problem */
  code: StartEventProblemCode;
};

export enum StartEventProblemCode {
  EVENT_NOT_INITIALIZED = 'EVENT_NOT_INITIALIZED',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type StartEventResult = StartEventNotEnoughStreamsConnectedProblem | StartEventProblem | StartEventSuccess;

export type StartEventSuccess = {
  __typename?: 'StartEventSuccess';
  results: Array<StreamingOperationResult>;
};

export type StartLiveTestProblem = {
  __typename?: 'StartLiveTestProblem';
  /** Code indicating the problem */
  code: StartLiveTestProblemCode;
};

export enum StartLiveTestProblemCode {
  EVENT_NOT_INITIALIZED = 'EVENT_NOT_INITIALIZED',
  NO_STREAM_CONNECTED_ON_PRIMARY = 'NO_STREAM_CONNECTED_ON_PRIMARY',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type StartLiveTestResult = StartLiveTestProblem | StartLiveTestSuccess;

export type StartLiveTestSuccess = {
  __typename?: 'StartLiveTestSuccess';
  webcast: Webcast;
};

export enum State {
  INITIALIZING = 'INITIALIZING',
  LIVE = 'LIVE',
  LIVE_TEST = 'LIVE_TEST',
  POSTLIVE = 'POSTLIVE',
  PRELIVE = 'PRELIVE',
}

export enum StateFilter {
  /** webcasts in state POSTLIVE */
  ENDED = 'ENDED',
  /** webcasts in state LIVE_TEST or LIVE */
  LIVE = 'LIVE',
  /** webcasts in state POSTLIVE and Vod enabled */
  ON_DEMAND = 'ON_DEMAND',
  /** webcasts in state INITIALIZING or PRELIVE */
  PLANNED = 'PLANNED',
}

export type StopEventProblem = {
  __typename?: 'StopEventProblem';
  /** Code indicating the problem */
  code: StopEventProblemCode;
};

export enum StopEventProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_LIVE_STATE = 'WEBCAST_NOT_IN_LIVE_STATE',
}

export type StopEventResult = StopEventProblem | StopEventSuccess;

export type StopEventSuccess = {
  __typename?: 'StopEventSuccess';
  results: Array<StreamingOperationResult>;
};

export type StopLiveTestProblem = {
  __typename?: 'StopLiveTestProblem';
  /** Code indicating the problem */
  code: StopLiveTestProblemCode;
};

export enum StopLiveTestProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_LIVE_TEST_STATE = 'WEBCAST_NOT_IN_LIVE_TEST_STATE',
}

export type StopLiveTestResult = StopLiveTestProblem | StopLiveTestSuccess;

export type StopLiveTestSuccess = {
  __typename?: 'StopLiveTestSuccess';
  webcast: Webcast;
};

export type Stream = {
  __typename?: 'Stream';
  /** Backup (ingested to "backupServer") stream state */
  backupStatus: StreamStatus;
  /** Configuration details needed to connect a video source to the live stream */
  ingest: Ingest;
  /** Other data used to identify live stream */
  metadata?: Maybe<StreamMetadata>;
  /** Configuration details needed to playback live stream */
  playback: Playback;
  /** Primary (ingested to "primaryServer") stream state */
  primaryStatus: StreamStatus;
};

export type StreamMetadata = {
  __typename?: 'StreamMetadata';
  language?: Maybe<Scalars['String']['output']>;
};

export type StreamMetadataInput = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type StreamRecording = {
  __typename?: 'StreamRecording';
  /** Download URL */
  downloadLink?: Maybe<Scalars['Url']['output']>;
  /** Metadata for the recording */
  metadata: RecordingMetadata;
  /** Status of recording processing */
  status: RecordingUploadStatus;
};

export enum StreamState {
  CONNECTED = 'CONNECTED',
  SERVER_NOT_AVAILABLE = 'SERVER_NOT_AVAILABLE',
  STARTED = 'STARTED',
  STARTING = 'STARTING',
  STOPPED = 'STOPPED',
  STOPPING = 'STOPPING',
}

export type StreamStatus = {
  __typename?: 'StreamStatus';
  recordingState: RecordingState;
  streamState: StreamState;
};

export type Streaming = {
  __typename?: 'Streaming';
  live?: Maybe<LiveStreaming>;
  recordings: Array<StreamRecording>;
  vod?: Maybe<VodStreaming>;
};

export type StreamingOperationResult = {
  __typename?: 'StreamingOperationResult';
  backup: StreamingOperationStatus;
  metadata?: Maybe<StreamMetadata>;
  primary: StreamingOperationStatus;
};

export enum StreamingOperationStatus {
  FAILED = 'FAILED',
  OK = 'OK',
  SKIPPED = 'SKIPPED',
}

export type SubtitleSettings = {
  __typename?: 'SubtitleSettings';
  aiLiveTranscriptGeneration: Scalars['Boolean']['output'];
  translationLanguages?: Maybe<Array<Scalars['String']['output']>>;
};

export type SubtitleSettingsInput = {
  aiLiveTranscriptGeneration?: Scalars['Boolean']['input'];
  translationLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum Theme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export type TokenProtectionPolicy = {
  __typename?: 'TokenProtectionPolicy';
  /** Indicates if policy is enabled */
  enabled: Scalars['Boolean']['output'];
};

export type UpdateAccessCodePolicyInput = {
  /**
   * Access code.
   * Must be between 6 and 30 characters long,
   * containing only letters (upper and lowercase),
   * digits, and special characters (_!#+=?@$&*-), with no spaces
   */
  accessCode?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if policy is enabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Webcast ID given policy will be associated with */
  webcastId: Scalars['ID']['input'];
};

/** Update access code policy has failed */
export type UpdateAccessCodePolicyProblem = {
  __typename?: 'UpdateAccessCodePolicyProblem';
  /** Code indicating the problem */
  code: UpdateAccessCodePolicyProblemCode;
};

export enum UpdateAccessCodePolicyProblemCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type UpdateAccessCodePolicyResult =
  | UpdateAccessCodePolicyProblem
  | UpdateAccessCodePolicySuccess
  | ValidationErrors;

/** Access code policy has been successfully updated */
export type UpdateAccessCodePolicySuccess = {
  __typename?: 'UpdateAccessCodePolicySuccess';
  /** Access code policy */
  accessCodePolicy: AccessCodePolicy;
};

export type UpdateAiSubtitlesFeatureInput = {
  _?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAppearanceSettingsFailure = {
  __typename?: 'UpdateAppearanceSettingsFailure';
  code?: Maybe<UpdateAppearanceSettingsFailureCode>;
};

export enum UpdateAppearanceSettingsFailureCode {
  INVALID_HEX_COLOR = 'INVALID_HEX_COLOR',
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
  UNSUPPORTED_FONT = 'UNSUPPORTED_FONT',
}

export type UpdateAppearanceSettingsInput = {
  appearanceColors?: InputMaybe<Array<InputMaybe<AppearanceColorsInput>>>;
  fonts?: InputMaybe<AppearanceFontsInput>;
  lsproId: Scalars['String']['input'];
  theme?: InputMaybe<Theme>;
};

export type UpdateAppearanceSettingsResult = UpdateAppearanceSettingsFailure | UpdateAppearanceSettingsSuccess;

export type UpdateAppearanceSettingsSuccess = {
  __typename?: 'UpdateAppearanceSettingsSuccess';
  appearanceSettings: AppearanceSettings;
};

export type UpdateBrowserBasedStudioFeatureInput = {
  _?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateComplianceFormFailure = {
  __typename?: 'UpdateComplianceFormFailure';
  code?: Maybe<UpdateComplianceFormFailureCode>;
};

export enum UpdateComplianceFormFailureCode {
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
}

export type UpdateComplianceFormInput = {
  complianceForm?: InputMaybe<ComplianceFormInput>;
  includeDefaultComplianceForm?: InputMaybe<Scalars['Boolean']['input']>;
  lsproId: Scalars['String']['input'];
};

export type UpdateComplianceFormResult = UpdateComplianceFormFailure | UpdateComplianceFormSuccess;

export type UpdateComplianceFormSuccess = {
  __typename?: 'UpdateComplianceFormSuccess';
  complianceForm: ComplianceForm;
  includeDefaultComplianceForm: Scalars['Boolean']['output'];
};

export type UpdateEmailItemsInput = {
  /** Action enum (ADD or REMOVE). */
  action: ItemListAction;
  /**
   * List of emails that should either be added or removed.
   * Email templates with wildcard symbol (*) are supported.
   * Items should follow valid email format. Otherwise UpdateEmailWhitelistPolicyEmailValidationFailure will be returned.
   * Items are transformed to lower case and duplicates are removed.
   */
  items: Array<Scalars['String']['input']>;
};

export type UpdateEmailRegistrationPolicyFailure = {
  __typename?: 'UpdateEmailRegistrationPolicyFailure';
  code: UpdateEmailRegistrationPolicyFailureCode;
};

export enum UpdateEmailRegistrationPolicyFailureCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type UpdateEmailRegistrationPolicyInput = {
  /** Indicates if policy is enabled */
  enabled: Scalars['Boolean']['input'];
  /** If true, requires users to fill in first and last names */
  requireNameFields: Scalars['Boolean']['input'];
  /** Webcast ID this policy applies for */
  webcastId: Scalars['ID']['input'];
};

export type UpdateEmailRegistrationPolicyResult =
  | UpdateEmailRegistrationPolicyFailure
  | UpdateEmailRegistrationPolicySuccess;

export type UpdateEmailRegistrationPolicySuccess = {
  __typename?: 'UpdateEmailRegistrationPolicySuccess';
  policy: EmailRegistrationPolicy;
};

export type UpdateEmailWhitelistPolicyEmailValidationFailure = {
  __typename?: 'UpdateEmailWhitelistPolicyEmailValidationFailure';
  invalidEmails: Array<Scalars['String']['output']>;
};

export enum UpdateEmailWhitelistPolicyFailureCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type UpdateEmailWhitelistPolicyGenericFailure = {
  __typename?: 'UpdateEmailWhitelistPolicyGenericFailure';
  code: UpdateEmailWhitelistPolicyFailureCode;
};

export type UpdateEmailWhitelistPolicyInput = {
  /**
   * List of modifications that will be applied to current email list.
   * Set to null value to keep the current value.
   */
  emails?: InputMaybe<Array<UpdateEmailItemsInput>>;
  /**
   * Is policy enabled.
   * Set to null value to keep the current value.
   */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If true, requires users to fill in first and last names.
   * Set to null value to keep the current value.
   */
  requireNameFields?: InputMaybe<Scalars['Boolean']['input']>;
  /** Webcast ID this policy applies for */
  webcastId: Scalars['ID']['input'];
};

export type UpdateEmailWhitelistPolicyResult =
  | UpdateEmailWhitelistPolicyEmailValidationFailure
  | UpdateEmailWhitelistPolicyGenericFailure
  | UpdateEmailWhitelistPolicySuccess;

export type UpdateEmailWhitelistPolicySuccess = {
  __typename?: 'UpdateEmailWhitelistPolicySuccess';
  policy: EmailWhitelistPolicy;
};

export type UpdateEventPermissionInput = {
  /**
   * List of modifications that will be applied to collaborators list.
   * Set to null value to keep the current value.
   */
  collaborators?: InputMaybe<Array<UpdateUserIdItemsInput>>;
  /**
   * List of modifications that will be applied to owners list.
   * Set to null value to keep the current value.
   */
  owners?: InputMaybe<Array<UpdateUserIdItemsInput>>;
  /**
   * event type
   * Set to null value to keep the current value.
   */
  type?: InputMaybe<EventPermissionType>;
  /** Webcast ID this EventPermission applies for */
  webcastId: Scalars['ID']['input'];
};

export type UpdateEventPermissionProblem = {
  __typename?: 'UpdateEventPermissionProblem';
  /** Code indicating the problem */
  code: UpdateEventPermissionProblemCode;
};

export enum UpdateEventPermissionProblemCode {
  AT_LEAST_ONE_OWNER_REQUIRED = 'AT_LEAST_ONE_OWNER_REQUIRED',
  LSPRO_NOT_FOUND = 'LSPRO_NOT_FOUND',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
}

export type UpdateEventPermissionResult =
  | UpdateEventPermissionProblem
  | UpdateEventPermissionSuccess
  | UpdateEventPermissionValidationFailure;

export type UpdateEventPermissionSuccess = {
  __typename?: 'UpdateEventPermissionSuccess';
  /** Event permission */
  eventPermission: EventPermission;
};

export type UpdateEventPermissionValidationFailure = {
  __typename?: 'UpdateEventPermissionValidationFailure';
  invalidCollaborators: Array<Scalars['String']['output']>;
  invalidOwners: Array<Scalars['String']['output']>;
};

export type UpdateLiveStreamProFeatureInput = {
  /**  or  */
  aiSubtitlesFeature?: InputMaybe<UpdateAiSubtitlesFeatureInput>;
  /**  or  */
  browserBasedStudioFeature?: InputMaybe<UpdateBrowserBasedStudioFeatureInput>;
  /** Indicates whether the feature is enabled */
  enabled: Scalars['Boolean']['input'];
  /** Indicates the feature type */
  featureType: LiveStreamProFeatureType;
  /**
   * One and only one of the following fields should be set depending on featureType.
   * Other values will be discarded.
   * The error is returned if the corresponding field is not set.
   *
   * todo: Consider using @oneOf GraphQL directive once it's officially supported
   *       (https://github.com/graphql/graphql-spec/pull/825)
   */
  liveStreamingHoursFeature?: InputMaybe<UpdateLiveStreamingHoursFeatureInput>;
};

export type UpdateLiveStreamProInput = {
  features?: InputMaybe<Array<UpdateLiveStreamProFeatureInput>>;
  lsproId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLiveStreamingHoursFeatureInput = {
  quotaType: QuotaType;
  value: Scalars['Int']['input'];
};

/** Update of the Referrer Protection has failed */
export type UpdateReferrerProtectionPolicyFailure = {
  __typename?: 'UpdateReferrerProtectionPolicyFailure';
  /** Code indicating the problem */
  code: UpdateReferrerProtectionPolicyFailureCode;
};

export enum UpdateReferrerProtectionPolicyFailureCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type UpdateReferrerProtectionPolicyInput = {
  /** List of referrer domains allowed to view the webcast */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Flag for enabling/disabling Referrer Protection */
  enabled: Scalars['Boolean']['input'];
  /** Webcast ID Referrer Protection will be enabled for */
  webcastId: Scalars['ID']['input'];
};

/** Referrer Protection has been successfully updated */
export type UpdateReferrerProtectionPolicySuccess = {
  __typename?: 'UpdateReferrerProtectionPolicySuccess';
  /** Referrer protection configuration */
  referrerProtectionPolicy: ReferrerProtectionPolicy;
};

export type UpdateReferrerProtectionResult =
  | UpdateReferrerProtectionPolicyFailure
  | UpdateReferrerProtectionPolicySuccess;

export type UpdateSingleSignOnEmployeeIdItemsInput = {
  /** Action enum (ADD or REMOVE). */
  action: ItemListAction;
  /**
   * List of employee ids that should either be added or removed.
   * Items are stored case sensitive and duplicates are removed.
   */
  items: Array<Scalars['String']['input']>;
};

export type UpdateSingleSignOnGroupItemsInput = {
  /** Action enum (ADD or REMOVE). */
  action: ItemListAction;
  /**
   * List of groups that should either be added or removed.
   * Items are stored case sensitive and duplicates are removed.
   */
  items: Array<Scalars['String']['input']>;
};

export enum UpdateSingleSignOnPolicyFailureCode {
  IDP_LIST_EMPTY = 'IDP_LIST_EMPTY',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type UpdateSingleSignOnPolicyGenericFailure = {
  __typename?: 'UpdateSingleSignOnPolicyGenericFailure';
  code: UpdateSingleSignOnPolicyFailureCode;
};

export type UpdateSingleSignOnPolicyInput = {
  /**
   * List of modifications that will be applied to current email list.
   * Set to null value to keep the current value.
   */
  emails?: InputMaybe<Array<UpdateEmailItemsInput>>;
  /**
   * List of modifications that will be applied to current employee id list.
   * Set to null value to keep the current value.
   */
  employeeIds?: InputMaybe<Array<UpdateSingleSignOnEmployeeIdItemsInput>>;
  /**
   * Is policy enabled.
   * Set to null value to keep the current value.
   */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * List of modifications that will be applied to current group list.
   * Set to null value to keep the current value.
   */
  groups?: InputMaybe<Array<UpdateSingleSignOnGroupItemsInput>>;
  /**
   * List of IDPs for single sign-on
   * Set to null value to keep the current value.
   */
  idps?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Are restrictions (i.e. emails, employeeIds, groups) enabled
   * Set to null value to keep the current value.
   */
  restrictionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Webcast ID this policy applies for */
  webcastId: Scalars['ID']['input'];
};

export type UpdateSingleSignOnPolicyResult =
  | UpdateSingleSignOnPolicyGenericFailure
  | UpdateSingleSignOnPolicySuccess
  | UpdateSingleSignOnPolicyValidationFailure;

export type UpdateSingleSignOnPolicySuccess = {
  __typename?: 'UpdateSingleSignOnPolicySuccess';
  policy: SingleSignOnPolicy;
};

export type UpdateSingleSignOnPolicyValidationFailure = {
  __typename?: 'UpdateSingleSignOnPolicyValidationFailure';
  invalidEmails: Array<Scalars['String']['output']>;
};

export type UpdateTokenProtectionFailure = {
  __typename?: 'UpdateTokenProtectionFailure';
  code: UpdateTokenProtectionFailureCode;
};

export enum UpdateTokenProtectionFailureCode {
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type UpdateTokenProtectionPolicyInput = {
  /** Indicates if policy is enabled */
  enabled: Scalars['Boolean']['input'];
  /** Webcast ID this policy applies for */
  webcastId: Scalars['ID']['input'];
};

export type UpdateTokenProtectionResult = UpdateTokenProtectionFailure | UpdateTokenProtectionSuccess;

export type UpdateTokenProtectionSuccess = {
  __typename?: 'UpdateTokenProtectionSuccess';
  policy: TokenProtectionPolicy;
};

export type UpdateUserIdItemsInput = {
  /** Action enum (ADD or REMOVE). */
  action: ItemListAction;
  /** List of user ids that should either be added or removed. */
  items: Array<Scalars['String']['input']>;
};

export type UpdateVodInput = {
  language: Scalars['String']['input'];
  video: VideoReferenceInput;
  webcastId: Scalars['ID']['input'];
};

export type UpdateVodProblem = {
  __typename?: 'UpdateVodProblem';
  code?: Maybe<UpdateVodProblemCode>;
};

export enum UpdateVodProblemCode {
  LANGUAGE_NOT_CONFIGURED = 'LANGUAGE_NOT_CONFIGURED',
  LANGUAGE_NOT_SUPPORTED = 'LANGUAGE_NOT_SUPPORTED',
  WEBCAST_NOT_FOUND = 'WEBCAST_NOT_FOUND',
  WEBCAST_NOT_IN_POSTLIVE_STATE = 'WEBCAST_NOT_IN_POSTLIVE_STATE',
}

export type UpdateVodResult = UpdateVodProblem | UpdateVodSuccess;

export type UpdateVodSuccess = {
  __typename?: 'UpdateVodSuccess';
  reference: VideoReference;
};

export type UpdateWebcastInput = {
  /** Webcast media content for languages */
  contents?: InputMaybe<Array<WebcastContentInput>>;
  /** Planned duration of the webcast event in minutes */
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  /** E-Cdn settings */
  ecdnSettings?: InputMaybe<ECdnSettingsInput>;
  /** Webcast ID */
  id: Scalars['ID']['input'];
  /** Ingest input */
  ingest?: InputMaybe<IngestInput>;
  /** Language input to modify configured languages */
  languages?: InputMaybe<Array<LanguageConfigurationInput>>;
  /** Planned start date and time (ISO 8601) */
  plannedStartAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Recording settings */
  recordingSettings?: InputMaybe<RecordingSettingsInput>;
  /** Subtitles settings */
  subtitleSettings?: InputMaybe<SubtitleSettingsInput>;
};

/** Webcast update failed */
export type UpdateWebcastProblem = {
  __typename?: 'UpdateWebcastProblem';
  /** Code indicating the problem */
  code: UpdateWebcastProblemCode;
};

export enum UpdateWebcastProblemCode {
  AI_LIVE_TRANSCRIPT_SRT_NOT_SUPPORTED = 'AI_LIVE_TRANSCRIPT_SRT_NOT_SUPPORTED',
  AI_SUBTITLES_LSPRO_FEATURE_NOT_ENABLED = 'AI_SUBTITLES_LSPRO_FEATURE_NOT_ENABLED',
  CONTENT_LANGUAGE_NOT_EXISTENT = 'CONTENT_LANGUAGE_NOT_EXISTENT',
  INCORRECT_NUMBER_OF_PIDS = 'INCORRECT_NUMBER_OF_PIDS',
  INVALID_ECDN_SETTINGS = 'INVALID_ECDN_SETTINGS',
  INVALID_PRIMARY_LANGUAGE_CONFIGURATION_PROVIDED = 'INVALID_PRIMARY_LANGUAGE_CONFIGURATION_PROVIDED',
  LANGUAGE_LINKED_WITH_PRESENTATIONS = 'LANGUAGE_LINKED_WITH_PRESENTATIONS',
  LANGUAGE_NOT_SUPPORTED = 'LANGUAGE_NOT_SUPPORTED',
  MORE_THAN_ONE_INGEST_FIELD_PROVIDED = 'MORE_THAN_ONE_INGEST_FIELD_PROVIDED',
  MULTI_LANGUAGE_SRT_NOT_SUPPORTED = 'MULTI_LANGUAGE_SRT_NOT_SUPPORTED',
  NO_ACTIVE_ECDN_SETTINGS = 'NO_ACTIVE_ECDN_SETTINGS',
  PLANNED_START_SHOULD_BE_IN_FUTURE = 'PLANNED_START_SHOULD_BE_IN_FUTURE',
  PRIMARY_LANGUAGE_NOT_PROVIDED = 'PRIMARY_LANGUAGE_NOT_PROVIDED',
  TOO_MANY_PRIMARY_LANGUAGES_PROVIDED = 'TOO_MANY_PRIMARY_LANGUAGES_PROVIDED',
  WEBCAST_NOT_IN_PRELIVE_STATE = 'WEBCAST_NOT_IN_PRELIVE_STATE',
}

export type UpdateWebcastResult = UpdateWebcastProblem | UpdateWebcastSuccess | ValidationErrors;

/** Webcast has been successfully updated */
export type UpdateWebcastSuccess = {
  __typename?: 'UpdateWebcastSuccess';
  webcast: Webcast;
};

export type UploadLink = {
  __typename?: 'UploadLink';
  /**
   * Security token that needs to passed in HTTP header when uploading the presentation:
   *
   * Authorization: token <TOKEN>
   */
  token: Scalars['String']['output'];
  /** Upload URL */
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  roles: Array<Role>;
  status: UserStatus;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED',
  INVITED = 'INVITED',
}

export type ValidationError = {
  __typename?: 'ValidationError';
  code: ValidationErrorCode;
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum ValidationErrorCode {
  PATTERN = 'Pattern',
  POSITIVE = 'Positive',
  POSITIVEORZERO = 'PositiveOrZero',
  RANGE = 'Range',
  SIZE = 'Size',
  UNKNOWN = 'Unknown',
}

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type Video = {
  __typename?: 'Video';
  channelId: Scalars['ID']['output'];
  createdDate: Scalars['DateTime']['output'];
  duration: Scalars['Int']['output'];
  hasSecurityPolicy?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  thumbnail?: Maybe<Scalars['Url']['output']>;
  title: Scalars['String']['output'];
};

/**
 * GraphQL Cursor Connections Specification:
 *
 * https://relay.dev/graphql/connections.htm
 */
export type VideoConnection = {
  __typename?: 'VideoConnection';
  edges: Array<VideoEdge>;
  pageInfo: PageInfo;
};

export type VideoEdge = {
  __typename?: 'VideoEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Video;
};

export type VideoInput = {
  reference?: InputMaybe<VideoReferenceInput>;
};

export type VideoManager = {
  __typename?: 'VideoManager';
  channels?: Maybe<Array<Channel>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VideoManagerChannel = {
  __typename?: 'VideoManagerChannel';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type VideoManagerChannelInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type VideoManagerLink = {
  __typename?: 'VideoManagerLink';
  channels?: Maybe<Array<VideoManagerChannel>>;
  enabled: Scalars['Boolean']['output'];
  videoManagerId?: Maybe<Scalars['ID']['output']>;
};

export type VideoManagerLinkInput = {
  channels?: InputMaybe<Array<VideoManagerChannelInput>>;
  enabled: Scalars['Boolean']['input'];
  videoManagerId?: InputMaybe<Scalars['ID']['input']>;
};

export type VideoReference = {
  __typename?: 'VideoReference';
  channelId: Scalars['ID']['output'];
  videoId: Scalars['ID']['output'];
};

export type VideoReferenceInput = {
  channelId: Scalars['ID']['input'];
  videoId: Scalars['ID']['input'];
};

export type VideoSearchProblem = {
  __typename?: 'VideoSearchProblem';
  code: VideoSearchProblemCode;
};

export enum VideoSearchProblemCode {
  VMPRO_LINK_MISSING = 'VMPRO_LINK_MISSING',
}

export type VideoSearchResult = ValidationErrors | VideoConnection | VideoSearchProblem;

export type VodStream = {
  __typename?: 'VodStream';
  /** Vod stream metadata */
  metadata?: Maybe<StreamMetadata>;
  /** Parameters for selecting the VOD video */
  reference: VideoReference;
};

export type VodStreaming = {
  __typename?: 'VodStreaming';
  enabled: Scalars['Boolean']['output'];
  streams?: Maybe<Array<VodStream>>;
};

export type Webcast = {
  __typename?: 'Webcast';
  /** Account (customer) ID */
  accountId: Scalars['String']['output'];
  /** Additional languages */
  additionalLanguages: Array<Scalars['String']['output']>;
  /** Webcast analytics */
  analytics?: Maybe<Analytics>;
  /** Chat details connected with the webcast */
  chat?: Maybe<Chat>;
  /** Webcast media content for all languages */
  contents: Array<WebcastContent>;
  /** Cover image url */
  coverImageUrl?: Maybe<Scalars['String']['output']>;
  /** Webcast creation date and time (ISO 8601) */
  createdDate: Scalars['DateTime']['output'];
  /** Planned duration of the webcast event in minutes */
  durationInMinutes: Scalars['Int']['output'];
  /** E-Cdn config */
  ecdnSettings?: Maybe<ECdnSettings>;
  /** Webcast event permission */
  eventPermission: EventPermission;
  /** Webcast ID */
  id: Scalars['ID']['output'];
  /** LiveStream Pro id */
  lsproId: Scalars['String']['output'];
  /** If a webcast was copied, id of the original */
  originalWebcastId?: Maybe<Scalars['ID']['output']>;
  /** Planned start date and time (ISO 8601) */
  plannedStartAt: Scalars['DateTime']['output'];
  /** Presentations associated with given webcast */
  presentations: Array<Presentation>;
  /** Primary language */
  primaryLanguage: Scalars['String']['output'];
  /** Recording settings */
  recordingSettings?: Maybe<RecordingSettings>;
  /** Security policies */
  securityPolicies: Array<SecurityPolicy>;
  /** Sharing link */
  sharingLink?: Maybe<SharingLink>;
  /**
   * Sharing url
   * @deprecated Use sharingLink instead
   */
  sharingUrl?: Maybe<Scalars['String']['output']>;
  /** State */
  state?: Maybe<State>;
  /** Streaming details connected with the webcast */
  streaming?: Maybe<Streaming>;
  /** Subtitles settings */
  subtitleSettings?: Maybe<SubtitleSettings>;
};

/**
 * GraphQL Cursor Connections Specification:
 *
 * https://relay.dev/graphql/connections.htm
 */
export type WebcastConnection = {
  __typename?: 'WebcastConnection';
  edges: Array<WebcastEdge>;
  pageInfo: PageInfo;
};

export type WebcastContent = {
  __typename?: 'WebcastContent';
  language: Scalars['String']['output'];
  live: WebcastLiveContent;
  postLive: WebcastPostLiveContent;
  preLive: WebcastPreLiveContent;
  title?: Maybe<Scalars['String']['output']>;
};

export type WebcastContentInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  live?: InputMaybe<WebcastLiveContentInput>;
  postLive?: InputMaybe<WebcastPostLiveContentInput>;
  preLive?: InputMaybe<WebcastPreLiveContentInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WebcastEdge = {
  __typename?: 'WebcastEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Webcast;
};

export type WebcastFilters = {
  states?: InputMaybe<Array<StateFilter>>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WebcastLiveContent = {
  __typename?: 'WebcastLiveContent';
  coverImage?: Maybe<Asset>;
  description?: Maybe<Scalars['String']['output']>;
};

export type WebcastLiveContentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
};

export enum WebcastOrder {
  PLANNED_AT_ASC = 'PLANNED_AT_ASC',
  PLANNED_AT_DESC = 'PLANNED_AT_DESC',
  STATE_ASC = 'STATE_ASC',
  STATE_DESC = 'STATE_DESC',
  /** By default list is sorted by planned start date (newest to oldest) and shows events in LIVE and LIVE_TEST first */
  STATE_THEN_PLANNED_AT_DESC = 'STATE_THEN_PLANNED_AT_DESC',
}

export type WebcastPostLiveContent = {
  __typename?: 'WebcastPostLiveContent';
  coverImage?: Maybe<Asset>;
  description?: Maybe<Scalars['String']['output']>;
  video?: Maybe<VideoReference>;
};

export type WebcastPostLiveContentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<VideoInput>;
};

export type WebcastPreLiveContent = {
  __typename?: 'WebcastPreLiveContent';
  coverImage?: Maybe<Asset>;
  description?: Maybe<Scalars['String']['output']>;
  video?: Maybe<VideoReference>;
};

export type WebcastPreLiveContentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<VideoInput>;
};

export type GetWebcastStateQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastStateQuery = {
  __typename?: 'Query';
  webcast: { __typename?: 'Webcast'; id: string; state?: State | null | undefined };
};

export type GetVideoManagersQueryVariables = Exact<{ [key: string]: never }>;

export type GetVideoManagersQuery = {
  __typename?: 'Query';
  videoManagers:
    | { __typename?: 'GetVideoManagersProblem' }
    | {
        __typename?: 'GetVideoManagersSuccess';
        videoManagers: Array<{ __typename?: 'VideoManager'; id: string; name: string }>;
      };
};

export type ChannelsFragmentFragment = {
  __typename?: 'VideoManager';
  channels?:
    | Array<{
        __typename?: 'Channel';
        id: string;
        name: string;
        parentId?: string | null | undefined;
        children: Array<string | null | undefined>;
      }>
    | null
    | undefined;
};

export type GetVideoManagerQueryVariables = Exact<{
  videoManagerId: Scalars['ID']['input'];
}>;

export type GetVideoManagerQuery = {
  __typename?: 'Query';
  videoManager:
    | { __typename?: 'GetVideoManagerProblem'; code: GetVideoManagerProblemCode }
    | {
        __typename?: 'VideoManager';
        id: string;
        name: string;
        channels?:
          | Array<{
              __typename?: 'Channel';
              id: string;
              name: string;
              parentId?: string | null | undefined;
              children: Array<string | null | undefined>;
            }>
          | null
          | undefined;
      };
};

export type VideoFragmentFragment = {
  __typename?: 'Video';
  id: string;
  channelId: string;
  title: string;
  duration: number;
  createdDate: string;
  thumbnail?: string | null | undefined;
};

export type GetVideosQueryVariables = Exact<{
  videoManagerId: Scalars['ID']['input'];
  channelId: Scalars['ID']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetVideosQuery = {
  __typename?: 'Query';
  videos:
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | {
        __typename?: 'VideoConnection';
        pageInfo: {
          __typename?: 'PageInfo';
          hasPreviousPage: boolean;
          hasNextPage: boolean;
          startCursor?: string | null | undefined;
          endCursor?: string | null | undefined;
        };
        edges: Array<{
          __typename?: 'VideoEdge';
          cursor?: string | null | undefined;
          node: {
            __typename?: 'Video';
            id: string;
            channelId: string;
            title: string;
            duration: number;
            createdDate: string;
            thumbnail?: string | null | undefined;
          };
        }>;
      }
    | { __typename?: 'VideoSearchProblem'; code: VideoSearchProblemCode };
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser?:
    | {
        __typename?: 'CurrentUser';
        id: string;
        accounts: Array<{
          __typename?: 'CurrentUserAccount';
          userRoles: Array<Role>;
          liveStreamPro: { __typename?: 'LiveStreamPro'; id: string; name: string };
        }>;
      }
    | null
    | undefined;
};

export type GetFeaturesQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
}>;

export type GetFeaturesQuery = {
  __typename?: 'Query';
  lsproSettings?:
    | {
        __typename?: 'LiveStreamProSettings';
        features: Array<
          | { __typename?: 'AiSubtitlesFeature'; enabled: boolean }
          | { __typename?: 'BrowserBasedStudioFeature'; enabled: boolean }
          | { __typename?: 'LiveStreamingHoursFeature'; enabled: boolean; value: number; quotaType: QuotaType }
        >;
      }
    | null
    | undefined;
};

export type GetPrimaryTitleQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetPrimaryTitleQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    primaryLanguage: string;
    contents: Array<{ __typename?: 'WebcastContent'; language: string; title?: string | null | undefined }>;
  };
};

export type GetLsproBrandingSettingsQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
}>;

export type GetLsproBrandingSettingsQuery = {
  __typename?: 'Query';
  configuration: {
    __typename?: 'Configuration';
    supportedFonts: Array<string>;
    appearanceColors: Array<{
      __typename?: 'AppearanceColors';
      theme: Theme;
      primaryColor: string;
      backgroundColor: string;
      cardColor: string;
      textFontColor: string;
      headingFontColor: string;
    }>;
  };
  lsproSettings?:
    | {
        __typename?: 'LiveStreamProSettings';
        appearance: {
          __typename?: 'AppearanceSettings';
          theme: Theme;
          appearanceColors: Array<{
            __typename?: 'AppearanceColors';
            backgroundColor: string;
            cardColor: string;
            headingFontColor: string;
            primaryColor: string;
            textFontColor: string;
            theme: Theme;
          }>;
          fonts: { __typename?: 'AppearanceFonts'; headingFont: string; textFont: string };
          assets: Array<{
            __typename?: 'AppearanceAsset';
            type: AppearanceAssetType;
            downloadLink?: string | null | undefined;
            uploadLink: { __typename?: 'UploadLink'; url: string; token: string };
          }>;
        };
      }
    | null
    | undefined;
};

export type CreateAppearanceAssetMutationVariables = Exact<{
  input: CreateAppearanceAssetInput;
}>;

export type CreateAppearanceAssetMutation = {
  __typename?: 'Mutation';
  createAppearanceAsset:
    | { __typename?: 'CreateAppearanceAssetFailure'; code: CreateAppearanceAssetFailureCode }
    | {
        __typename?: 'CreateAppearanceAssetSuccess';
        asset: {
          __typename?: 'AppearanceAsset';
          type: AppearanceAssetType;
          downloadLink?: string | null | undefined;
          uploadLink: { __typename?: 'UploadLink'; url: string; token: string };
        };
      };
};

export type DeleteAppearanceAssetMutationVariables = Exact<{
  input: DeleteAppearanceAssetInput;
}>;

export type DeleteAppearanceAssetMutation = {
  __typename?: 'Mutation';
  deleteAppearanceAsset:
    | { __typename?: 'DeleteAppearanceAssetFailure'; code?: DeleteAppearanceAssetFailureCode | null | undefined }
    | { __typename?: 'DeleteAppearanceAssetSuccess'; lsproId: string };
};

export type UpdateLsproThemeAndColorMutationVariables = Exact<{
  input: UpdateAppearanceSettingsInput;
}>;

export type UpdateLsproThemeAndColorMutation = {
  __typename?: 'Mutation';
  updateAppearanceSettings:
    | { __typename?: 'UpdateAppearanceSettingsFailure'; code?: UpdateAppearanceSettingsFailureCode | null | undefined }
    | {
        __typename?: 'UpdateAppearanceSettingsSuccess';
        appearanceSettings: {
          __typename?: 'AppearanceSettings';
          theme: Theme;
          appearanceColors: Array<{
            __typename?: 'AppearanceColors';
            backgroundColor: string;
            cardColor: string;
            headingFontColor: string;
            primaryColor: string;
            textFontColor: string;
            theme: Theme;
          }>;
        };
      };
};

export type UpdateLsproFontsMutationVariables = Exact<{
  input: UpdateAppearanceSettingsInput;
}>;

export type UpdateLsproFontsMutation = {
  __typename?: 'Mutation';
  updateAppearanceSettings:
    | { __typename?: 'UpdateAppearanceSettingsFailure'; code?: UpdateAppearanceSettingsFailureCode | null | undefined }
    | {
        __typename?: 'UpdateAppearanceSettingsSuccess';
        appearanceSettings: {
          __typename?: 'AppearanceSettings';
          fonts: { __typename?: 'AppearanceFonts'; headingFont: string; textFont: string };
        };
      };
};

export type GetLsproComplianceSettingsQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
}>;

export type GetLsproComplianceSettingsQuery = {
  __typename?: 'Query';
  lsproSettings?:
    | {
        __typename?: 'LiveStreamProSettings';
        includeDefaultComplianceForm: boolean;
        complianceForm: { __typename?: 'ComplianceForm'; text: string };
      }
    | null
    | undefined;
};

export type UpdateLsproComplianceSettingsMutationVariables = Exact<{
  input: UpdateComplianceFormInput;
}>;

export type UpdateLsproComplianceSettingsMutation = {
  __typename?: 'Mutation';
  updateLiveStreamProComplianceForm:
    | { __typename?: 'UpdateComplianceFormFailure'; code?: UpdateComplianceFormFailureCode | null | undefined }
    | {
        __typename?: 'UpdateComplianceFormSuccess';
        includeDefaultComplianceForm: boolean;
        complianceForm: { __typename?: 'ComplianceForm'; text: string };
      };
};

export type StreamingUsageReportQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;

export type StreamingUsageReportQuery = {
  __typename?: 'Query';
  streamingUsageReport:
    | { __typename?: 'GetStreamingUsageReportProblem'; code: GetStreamingUsageReportProblemCode }
    | { __typename?: 'GetStreamingUsageReportSuccess'; downloadUrl: string };
};

export type StreamingUsageQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
}>;

export type StreamingUsageQuery = {
  __typename?: 'Query';
  streamingUsage:
    | {
        __typename?: 'GetMonthlyStreamingUsageResult';
        month: number;
        usedMinutes: number;
        year: number;
        quota: { __typename?: 'Quota'; type: QuotaType; value: number };
      }
    | { __typename?: 'GetStreamingUsageProblem'; code?: GetStreamingUsageProblemCode | null | undefined };
};

export type GetLsproSecuritySettingsQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
}>;

export type GetLsproSecuritySettingsQuery = {
  __typename?: 'Query';
  lsproSettings?:
    | {
        __typename?: 'LiveStreamProSettings';
        securitySettings: { __typename?: 'SecuritySettings'; tokenGenerationSecret: string };
      }
    | null
    | undefined;
};

export type SetUserRolesMutationVariables = Exact<{
  input: SetUserRolesInput;
}>;

export type SetUserRolesMutation = {
  __typename?: 'Mutation';
  setUserRoles: {
    __typename?: 'SetUserRolesSuccess';
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      status: UserStatus;
      roles: Array<Role>;
      invitedAt?: string | null | undefined;
    };
  };
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteUser:
    | { __typename?: 'InviteUserProblem'; code: InviteUserProblemCode }
    | {
        __typename?: 'InviteUserSuccess';
        user: {
          __typename?: 'User';
          id: string;
          email: string;
          status: UserStatus;
          roles: Array<Role>;
          invitedAt?: string | null | undefined;
        };
      };
};

export type RemoveUserMutationVariables = Exact<{
  input: RemoveUserInput;
}>;

export type RemoveUserMutation = {
  __typename?: 'Mutation';
  removeUser: { __typename?: 'RemoveUserSuccess'; id: string };
};

export type GetUsersQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users:
    | { __typename?: 'GetUsersProblem'; code?: GetUsersProblemCode | null | undefined }
    | {
        __typename?: 'GetUsersSuccess';
        users: Array<{
          __typename?: 'User';
          id: string;
          email: string;
          status: UserStatus;
          roles: Array<Role>;
          invitedAt?: string | null | undefined;
        }>;
      };
};

export type GetWebcastEcdnSettingsQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastEcdnSettingsQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    ecdnSettings?: { __typename?: 'ECdnSettings'; isActive: boolean; type: ECdnType } | null | undefined;
  };
};

export type UpdateWebcastEcdnMutationVariables = Exact<{
  input: UpdateWebcastInput;
}>;

export type UpdateWebcastEcdnMutation = {
  __typename?: 'Mutation';
  updateWebcast:
    | { __typename?: 'UpdateWebcastProblem'; code: UpdateWebcastProblemCode }
    | {
        __typename?: 'UpdateWebcastSuccess';
        webcast: {
          __typename?: 'Webcast';
          id: string;
          ecdnSettings?: { __typename?: 'ECdnSettings'; isActive: boolean; type: ECdnType } | null | undefined;
        };
      }
    | { __typename?: 'ValidationErrors' };
};

export type GetWebcastSecurity_ReferrerProtectionPolicyFragment = {
  __typename?: 'ReferrerProtectionPolicy';
  enabled: boolean;
  domains?: Array<string> | null | undefined;
};

export type GetWebcastSecurity_TokenProtectionPolicyFragment = {
  __typename?: 'TokenProtectionPolicy';
  enabled: boolean;
};

export type GetWebcastEmbeddingQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastEmbeddingQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    securityPolicies: Array<
      | { __typename?: 'AccessCodePolicy' }
      | { __typename?: 'EmailRegistrationPolicy' }
      | { __typename?: 'EmailWhitelistPolicy' }
      | { __typename?: 'ReferrerProtectionPolicy'; enabled: boolean; domains?: Array<string> | null | undefined }
      | { __typename?: 'SingleSignOnPolicy' }
      | { __typename?: 'TokenProtectionPolicy'; enabled: boolean }
    >;
  };
};

export type UpdateReferrerProtectionPolicyMutationVariables = Exact<{
  input: UpdateReferrerProtectionPolicyInput;
}>;

export type UpdateReferrerProtectionPolicyMutation = {
  __typename?: 'Mutation';
  updateReferrerProtectionPolicy:
    | { __typename?: 'UpdateReferrerProtectionPolicyFailure'; code: UpdateReferrerProtectionPolicyFailureCode }
    | {
        __typename?: 'UpdateReferrerProtectionPolicySuccess';
        referrerProtectionPolicy: {
          __typename?: 'ReferrerProtectionPolicy';
          enabled: boolean;
          domains?: Array<string> | null | undefined;
        };
      };
};

export type UpdateTokenProtectionPolicyMutationVariables = Exact<{
  input: UpdateTokenProtectionPolicyInput;
}>;

export type UpdateTokenProtectionPolicyMutation = {
  __typename?: 'Mutation';
  updateTokenProtectionPolicy:
    | { __typename?: 'UpdateTokenProtectionFailure'; code: UpdateTokenProtectionFailureCode }
    | {
        __typename?: 'UpdateTokenProtectionSuccess';
        policy: { __typename?: 'TokenProtectionPolicy'; enabled: boolean };
      };
};

export type GetWebcastDescriptionQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastDescriptionQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    primaryLanguage: string;
    additionalLanguages: Array<string>;
    contents: Array<{
      __typename?: 'WebcastContent';
      language: string;
      preLive: { __typename?: 'WebcastPreLiveContent'; description?: string | null | undefined };
      live: { __typename?: 'WebcastLiveContent'; description?: string | null | undefined };
      postLive: { __typename?: 'WebcastPostLiveContent'; description?: string | null | undefined };
    }>;
  };
};

export type UpdateWebcastDescriptionMutationVariables = Exact<{
  input: UpdateWebcastInput;
}>;

export type UpdateWebcastDescriptionMutation = {
  __typename?: 'Mutation';
  updateWebcast:
    | { __typename?: 'UpdateWebcastProblem'; code: UpdateWebcastProblemCode }
    | {
        __typename?: 'UpdateWebcastSuccess';
        webcast: {
          __typename?: 'Webcast';
          id: string;
          contents: Array<{
            __typename?: 'WebcastContent';
            language: string;
            preLive: { __typename?: 'WebcastPreLiveContent'; description?: string | null | undefined };
            live: { __typename?: 'WebcastLiveContent'; description?: string | null | undefined };
            postLive: { __typename?: 'WebcastPostLiveContent'; description?: string | null | undefined };
          }>;
        };
      }
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      };
};

export type GetWebcastQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    primaryLanguage: string;
    plannedStartAt: string;
    durationInMinutes: number;
    state?: State | null | undefined;
    contents: Array<{ __typename?: 'WebcastContent'; title?: string | null | undefined; language: string }>;
  };
};

export type CreateWebcastMutationVariables = Exact<{
  input: CreateWebcastInput;
}>;

export type CreateWebcastMutation = {
  __typename?: 'Mutation';
  createWebcast:
    | { __typename?: 'CreateWebcastProblem'; code: CreateWebcastProblemCode }
    | {
        __typename?: 'CreateWebcastSuccess';
        webcast: {
          __typename?: 'Webcast';
          id: string;
          lsproId: string;
          primaryLanguage: string;
          plannedStartAt: string;
          durationInMinutes: number;
          contents: Array<{ __typename?: 'WebcastContent'; title?: string | null | undefined; language: string }>;
        };
      }
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      };
};

export type UpdateWebcastMutationVariables = Exact<{
  input: UpdateWebcastInput;
}>;

export type UpdateWebcastMutation = {
  __typename?: 'Mutation';
  updateWebcast:
    | { __typename?: 'UpdateWebcastProblem'; code: UpdateWebcastProblemCode }
    | {
        __typename?: 'UpdateWebcastSuccess';
        webcast: {
          __typename?: 'Webcast';
          id: string;
          lsproId: string;
          plannedStartAt: string;
          durationInMinutes: number;
          primaryLanguage: string;
          contents: Array<{ __typename?: 'WebcastContent'; title?: string | null | undefined; language: string }>;
        };
      }
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      };
};

export type UpdateWebCastsListQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
}>;

export type UpdateWebCastsListQuery = {
  __typename?: 'Query';
  webcasts: {
    __typename?: 'WebcastConnection';
    edges: Array<{
      __typename?: 'WebcastEdge';
      node: {
        __typename?: 'Webcast';
        id: string;
        lsproId: string;
        plannedStartAt: string;
        primaryLanguage: string;
        additionalLanguages: Array<string>;
        state?: State | null | undefined;
        coverImageUrl?: string | null | undefined;
        sharingLink?:
          | { __typename?: 'SharingLink'; sharingUrl: string; token?: string | null | undefined }
          | null
          | undefined;
        contents: Array<{ __typename?: 'WebcastContent'; language: string; title?: string | null | undefined }>;
        presentations: Array<{ __typename?: 'Presentation'; id: string; language: string }>;
        securityPolicies: Array<
          | { __typename: 'AccessCodePolicy'; accessCode: string; enabled: boolean }
          | { __typename: 'EmailRegistrationPolicy' }
          | { __typename: 'EmailWhitelistPolicy' }
          | { __typename: 'ReferrerProtectionPolicy' }
          | { __typename: 'SingleSignOnPolicy' }
          | { __typename: 'TokenProtectionPolicy' }
        >;
      };
    }>;
  };
};

export type GetWebcastPermissionsQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastPermissionsQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    eventPermission: {
      __typename?: 'EventPermission';
      type: EventPermissionType;
      owners: Array<{
        __typename?: 'User';
        email: string;
        firstName?: string | null | undefined;
        id: string;
        invitedAt?: string | null | undefined;
        lastName?: string | null | undefined;
        roles: Array<Role>;
        status: UserStatus;
      }>;
      collaborators: Array<{
        __typename?: 'User';
        email: string;
        firstName?: string | null | undefined;
        id: string;
        invitedAt?: string | null | undefined;
        lastName?: string | null | undefined;
        roles: Array<Role>;
        status: UserStatus;
      }>;
    };
  };
};

export type UpdateEventPermissionMutationVariables = Exact<{
  input: UpdateEventPermissionInput;
}>;

export type UpdateEventPermissionMutation = {
  __typename?: 'Mutation';
  updateEventPermission:
    | { __typename?: 'UpdateEventPermissionProblem'; code: UpdateEventPermissionProblemCode }
    | {
        __typename?: 'UpdateEventPermissionSuccess';
        eventPermission: { __typename?: 'EventPermission'; type: EventPermissionType };
      }
    | {
        __typename?: 'UpdateEventPermissionValidationFailure';
        invalidCollaborators: Array<string>;
        invalidOwners: Array<string>;
      };
};

export type ManageEventPermissionsMutationVariables = Exact<{
  input: UpdateEventPermissionInput;
}>;

export type ManageEventPermissionsMutation = {
  __typename?: 'Mutation';
  updateEventPermission:
    | { __typename?: 'UpdateEventPermissionProblem'; code: UpdateEventPermissionProblemCode }
    | {
        __typename?: 'UpdateEventPermissionSuccess';
        eventPermission: {
          __typename?: 'EventPermission';
          collaborators: Array<{ __typename?: 'User'; email: string; id: string }>;
          owners: Array<{ __typename?: 'User'; id: string; email: string }>;
        };
      }
    | {
        __typename?: 'UpdateEventPermissionValidationFailure';
        invalidCollaborators: Array<string>;
        invalidOwners: Array<string>;
      };
};

export type GetRecordingSettingsQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetRecordingSettingsQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    recordingSettings?:
      | {
          __typename?: 'RecordingSettings';
          enabled: boolean;
          videoManager?:
            | {
                __typename?: 'VideoManagerLink';
                enabled: boolean;
                videoManagerId?: string | null | undefined;
                channels?: Array<{ __typename?: 'VideoManagerChannel'; id: string; name: string }> | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateWebcastRecordSettingsMutationVariables = Exact<{
  input: UpdateWebcastInput;
}>;

export type UpdateWebcastRecordSettingsMutation = {
  __typename?: 'Mutation';
  updateWebcast:
    | { __typename?: 'UpdateWebcastProblem'; code: UpdateWebcastProblemCode }
    | {
        __typename?: 'UpdateWebcastSuccess';
        webcast: {
          __typename?: 'Webcast';
          id: string;
          recordingSettings?:
            | {
                __typename?: 'RecordingSettings';
                enabled: boolean;
                videoManager?:
                  | {
                      __typename?: 'VideoManagerLink';
                      enabled: boolean;
                      videoManagerId?: string | null | undefined;
                      channels?:
                        | Array<{ __typename?: 'VideoManagerChannel'; id: string; name: string }>
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        };
      }
    | { __typename?: 'ValidationErrors' };
};

export type GetWebcastSummaryManager_RecordingsFragment = {
  __typename?: 'StreamRecording';
  downloadLink?: string | null | undefined;
  status: RecordingUploadStatus;
  metadata: { __typename?: 'RecordingMetadata'; language?: string | null | undefined };
};

export type GetWebcastSummaryManagerQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastSummaryManagerQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    durationInMinutes: number;
    primaryLanguage: string;
    additionalLanguages: Array<string>;
    state?: State | null | undefined;
    contents: Array<{ __typename?: 'WebcastContent'; language: string; title?: string | null | undefined }>;
    streaming?:
      | {
          __typename?: 'Streaming';
          recordings: Array<{
            __typename?: 'StreamRecording';
            downloadLink?: string | null | undefined;
            status: RecordingUploadStatus;
            metadata: { __typename?: 'RecordingMetadata'; language?: string | null | undefined };
          }>;
          vod?:
            | {
                __typename?: 'VodStreaming';
                enabled: boolean;
                streams?:
                  | Array<{
                      __typename?: 'VodStream';
                      metadata?:
                        | { __typename?: 'StreamMetadata'; language?: string | null | undefined }
                        | null
                        | undefined;
                      reference: { __typename?: 'VideoReference'; channelId: string; videoId: string };
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    recordingSettings?:
      | {
          __typename?: 'RecordingSettings';
          enabled: boolean;
          videoManager?:
            | {
                __typename?: 'VideoManagerLink';
                videoManagerId?: string | null | undefined;
                channels?: Array<{ __typename?: 'VideoManagerChannel'; id: string; name: string }> | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    analytics?:
      | {
          __typename?: 'Analytics';
          peakActivePlays?: number | null | undefined;
          plays?: number | null | undefined;
          uniqueUsers?: number | null | undefined;
          webcastId: string;
        }
      | null
      | undefined;
    chat?: { __typename?: 'Chat'; type: ChatType } | null | undefined;
    securityPolicies: Array<
      | { __typename?: 'AccessCodePolicy'; enabled: boolean }
      | { __typename?: 'EmailRegistrationPolicy'; enabled: boolean }
      | { __typename?: 'EmailWhitelistPolicy'; enabled: boolean }
      | { __typename?: 'ReferrerProtectionPolicy'; enabled: boolean }
      | { __typename?: 'SingleSignOnPolicy'; enabled: boolean }
      | { __typename?: 'TokenProtectionPolicy'; enabled: boolean }
    >;
  };
  session:
    | { __typename?: 'GetSessionProblem' }
    | { __typename?: 'GetSessionSuccess'; startDate: string; endDate?: string | null | undefined };
};

export type GetWebcastReportQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
  reportType: ReportType;
}>;

export type GetWebcastReportQuery = {
  __typename?: 'Query';
  report?:
    | {
        __typename?: 'Report';
        downloadLink?: string | null | undefined;
        id?: string | null | undefined;
        status: ReportStatus;
        type: ReportType;
        webcastId: string;
        generationFailure?:
          | {
              __typename?: 'ReportGenerationFailure';
              code?: ReportFailureCode | null | undefined;
              reason?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GenerateReportMutationVariables = Exact<{
  input: GenerateReportInput;
}>;

export type GenerateReportMutation = {
  __typename?: 'Mutation';
  generateReport?:
    | { __typename?: 'GenerateReportProblem'; code?: GenerateReportProblemCode | null | undefined }
    | {
        __typename?: 'GenerateReportSuccess';
        report?:
          | {
              __typename?: 'Report';
              downloadLink?: string | null | undefined;
              id?: string | null | undefined;
              status: ReportStatus;
              type: ReportType;
              webcastId: string;
              generationFailure?:
                | {
                    __typename?: 'ReportGenerationFailure';
                    code?: ReportFailureCode | null | undefined;
                    reason?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateVodMutationVariables = Exact<{
  input: UpdateVodInput;
}>;

export type UpdateVodMutation = {
  __typename?: 'Mutation';
  updateVod:
    | { __typename?: 'UpdateVodProblem'; code?: UpdateVodProblemCode | null | undefined }
    | {
        __typename?: 'UpdateVodSuccess';
        reference: { __typename?: 'VideoReference'; videoId: string; channelId: string };
      };
};

export type EnableVodMutationVariables = Exact<{
  input: EnableVodInput;
}>;

export type EnableVodMutation = {
  __typename?: 'Mutation';
  enableVod:
    | { __typename?: 'EnableVodProblem'; code?: EnableVodProblemCode | null | undefined }
    | { __typename?: 'EnableVodSuccess' };
};

export type GetWebcastLanguagesQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastLanguagesQuery = {
  __typename?: 'Query';
  configuration: {
    __typename?: 'Configuration';
    supportedLanguages: Array<string>;
    supportedSubtitleLanguages: Array<string>;
  };
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    primaryLanguage: string;
    additionalLanguages: Array<string>;
    subtitleSettings?:
      | {
          __typename?: 'SubtitleSettings';
          aiLiveTranscriptGeneration: boolean;
          translationLanguages?: Array<string> | null | undefined;
        }
      | null
      | undefined;
    contents: Array<{ __typename?: 'WebcastContent'; language: string; title?: string | null | undefined }>;
    streaming?:
      | {
          __typename?: 'Streaming';
          live?: { __typename?: 'LiveStreaming'; ingestProtocol: IngestProtocol } | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateWebcastLanguagesMutationVariables = Exact<{
  input: UpdateWebcastInput;
}>;

export type UpdateWebcastLanguagesMutation = {
  __typename?: 'Mutation';
  updateWebcast:
    | { __typename?: 'UpdateWebcastProblem'; code: UpdateWebcastProblemCode }
    | {
        __typename?: 'UpdateWebcastSuccess';
        webcast: {
          __typename?: 'Webcast';
          id: string;
          primaryLanguage: string;
          additionalLanguages: Array<string>;
          subtitleSettings?:
            | {
                __typename?: 'SubtitleSettings';
                aiLiveTranscriptGeneration: boolean;
                translationLanguages?: Array<string> | null | undefined;
              }
            | null
            | undefined;
          contents: Array<{ __typename?: 'WebcastContent'; language: string; title?: string | null | undefined }>;
        };
      }
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      };
};

export type CoverImageFragment = {
  __typename?: 'Asset';
  assetId: string;
  downloadLink?: string | null | undefined;
  status: {
    __typename?: 'AssetUploadStatus';
    code: AssetUploadStatusCode;
    error?: AssetUploadErrorCode | null | undefined;
  };
};

export type VideoFragment = { __typename?: 'VideoReference'; videoId: string; channelId: string };

export type UpdateWebcastCoversMutationVariables = Exact<{
  input: UpdateWebcastInput;
}>;

export type UpdateWebcastCoversMutation = {
  __typename?: 'Mutation';
  updateWebcast:
    | { __typename?: 'UpdateWebcastProblem'; code: UpdateWebcastProblemCode }
    | { __typename?: 'UpdateWebcastSuccess' }
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      };
};

export type CreateWebcastCoverImageMutationVariables = Exact<{
  webcastId: Scalars['ID']['input'];
  eventStage: EventStage;
  language: Scalars['String']['input'];
}>;

export type CreateWebcastCoverImageMutation = {
  __typename?: 'Mutation';
  createWebcastCoverImage:
    | { __typename?: 'AssetCreateResultFailure'; code: AssetCreateResultFailureCode }
    | {
        __typename?: 'AssetCreateResultSuccess';
        assetId: string;
        uploadLink: { __typename?: 'UploadLink'; url: string; token: string };
      };
};

export type DeleteWebcastCoverImageMutationVariables = Exact<{
  webcastId: Scalars['ID']['input'];
  eventStage: EventStage;
  language: Scalars['String']['input'];
}>;

export type DeleteWebcastCoverImageMutation = {
  __typename?: 'Mutation';
  deleteWebcastCoverImage: { __typename?: 'AssetDeleteResult'; assetId?: string | null | undefined };
};

export type GetWebcastMediaQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastMediaQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    primaryLanguage: string;
    additionalLanguages: Array<string>;
    contents: Array<{
      __typename?: 'WebcastContent';
      language: string;
      preLive: {
        __typename?: 'WebcastPreLiveContent';
        coverImage?:
          | {
              __typename?: 'Asset';
              assetId: string;
              downloadLink?: string | null | undefined;
              status: {
                __typename?: 'AssetUploadStatus';
                code: AssetUploadStatusCode;
                error?: AssetUploadErrorCode | null | undefined;
              };
            }
          | null
          | undefined;
        video?: { __typename?: 'VideoReference'; videoId: string; channelId: string } | null | undefined;
      };
      postLive: {
        __typename?: 'WebcastPostLiveContent';
        coverImage?:
          | {
              __typename?: 'Asset';
              assetId: string;
              downloadLink?: string | null | undefined;
              status: {
                __typename?: 'AssetUploadStatus';
                code: AssetUploadStatusCode;
                error?: AssetUploadErrorCode | null | undefined;
              };
            }
          | null
          | undefined;
        video?: { __typename?: 'VideoReference'; videoId: string; channelId: string } | null | undefined;
      };
    }>;
  };
};

export type GetWebcastPresentationsQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastPresentationsQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    primaryLanguage: string;
    additionalLanguages: Array<string>;
    presentations: Array<{
      __typename?: 'Presentation';
      id: string;
      groupId: string;
      language: string;
      downloadLink?: string | null | undefined;
      sourceFileName: string;
      uploadLink: { __typename?: 'UploadLink'; url: string; token: string };
      status: {
        __typename?: 'PresentationStatus';
        uploadStatus: { __typename?: 'PresentationUploadStatus'; progress: ProgressStatus };
        slidesExtractionStatus: {
          __typename?: 'PresentationSlidesExtractionStatus';
          progress: ProgressStatus;
          totalSlides?: number | null | undefined;
          processedSlides?: number | null | undefined;
        };
      };
      slides?:
        | Array<{ __typename?: 'PresentationSlide'; index: number; downloadUrl: string; thumbnailDownloadUrl: string }>
        | null
        | undefined;
    }>;
  };
};

export type GetChatTypeQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetChatTypeQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    chat?: { __typename?: 'Chat'; type: ChatType } | null | undefined;
  };
};

export type CreateChatMutationVariables = Exact<{
  input: CreateChatInput;
}>;

export type CreateChatMutation = {
  __typename?: 'Mutation';
  createChat: { __typename?: 'CreateChatProblem'; code: CreateChatProblemCode } | { __typename?: 'CreateChatSuccess' };
};

export type DeleteChatMutationVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type DeleteChatMutation = {
  __typename?: 'Mutation';
  deleteChat: { __typename?: 'DeleteChatProblem'; code: DeleteChatProblemCode } | { __typename?: 'DeleteChatSuccess' };
};

export type ChangeChatTypeMutationVariables = Exact<{
  input: ChangeChatTypeInput;
}>;

export type ChangeChatTypeMutation = {
  __typename?: 'Mutation';
  changeChatType:
    | { __typename?: 'ChangeChatTypeProblem'; code: ChangeChatTypeProblemCode }
    | { __typename?: 'ChangeChatTypeSuccess' };
};

export type UpdateWebcastProtocolMutationVariables = Exact<{
  input: UpdateWebcastInput;
}>;

export type UpdateWebcastProtocolMutation = {
  __typename?: 'Mutation';
  updateWebcast:
    | { __typename?: 'UpdateWebcastProblem'; code: UpdateWebcastProblemCode }
    | {
        __typename?: 'UpdateWebcastSuccess';
        webcast: {
          __typename?: 'Webcast';
          id: string;
          streaming?:
            | {
                __typename?: 'Streaming';
                live?:
                  | {
                      __typename?: 'LiveStreaming';
                      ingestProtocol: IngestProtocol;
                      parameters:
                        | { __typename?: 'RtmpLiveStreamingParameters'; authenticationEnabled: boolean }
                        | { __typename?: 'SrtLiveStreamingParameters' };
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        };
      }
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      };
};

export type GetWebcastProtocolQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastProtocolQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    additionalLanguages: Array<string>;
    subtitleSettings?: { __typename?: 'SubtitleSettings'; aiLiveTranscriptGeneration: boolean } | null | undefined;
    streaming?:
      | {
          __typename?: 'Streaming';
          live?:
            | {
                __typename?: 'LiveStreaming';
                ingestProtocol: IngestProtocol;
                parameters:
                  | { __typename?: 'RtmpLiveStreamingParameters'; authenticationEnabled: boolean }
                  | { __typename?: 'SrtLiveStreamingParameters' };
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    ecdnSettings?: { __typename?: 'ECdnSettings'; isActive: boolean; type: ECdnType } | null | undefined;
  };
};

export type GetWebcastSetupSummaryQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastSetupSummaryQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    plannedStartAt: string;
    durationInMinutes: number;
    state?: State | null | undefined;
    primaryLanguage: string;
    additionalLanguages: Array<string>;
    sharingLink?:
      | { __typename?: 'SharingLink'; sharingUrl: string; token?: string | null | undefined }
      | null
      | undefined;
    securityPolicies: Array<
      | { __typename?: 'AccessCodePolicy'; accessCode: string; enabled: boolean }
      | { __typename?: 'EmailRegistrationPolicy'; enabled: boolean }
      | { __typename?: 'EmailWhitelistPolicy'; enabled: boolean }
      | { __typename?: 'ReferrerProtectionPolicy'; enabled: boolean; domains?: Array<string> | null | undefined }
      | { __typename?: 'SingleSignOnPolicy'; enabled: boolean }
      | { __typename?: 'TokenProtectionPolicy'; enabled: boolean }
    >;
    chat?: { __typename?: 'Chat'; type: ChatType } | null | undefined;
    streaming?:
      | {
          __typename?: 'Streaming';
          live?:
            | {
                __typename?: 'LiveStreaming';
                ingestProtocol: IngestProtocol;
                parameters:
                  | { __typename?: 'RtmpLiveStreamingParameters'; authenticationEnabled: boolean }
                  | { __typename?: 'SrtLiveStreamingParameters' };
                streams: Array<{
                  __typename?: 'Stream';
                  metadata?: { __typename?: 'StreamMetadata'; language?: string | null | undefined } | null | undefined;
                  ingest: {
                    __typename?: 'Ingest';
                    primaryServer: string;
                    backupServer: string;
                    streamName: string;
                    username: string;
                    password: string;
                  };
                }>;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    ecdnSettings?: { __typename?: 'ECdnSettings'; isActive: boolean; type: ECdnType } | null | undefined;
  };
};

export type InitializeEventMutationVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type InitializeEventMutation = {
  __typename?: 'Mutation';
  initializeEvent:
    | { __typename?: 'InitializeEventProblem'; code: InitializeEventProblemCode }
    | { __typename?: 'InitializeEventSuccess' };
};

export type GetWebcastSecurityQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastSecurityQuery = {
  __typename?: 'Query';
  identityProviders?: Array<{ __typename?: 'IdentityProvider'; alias: string; name: string }> | null | undefined;
  webcast: {
    __typename?: 'Webcast';
    id: string;
    state?: State | null | undefined;
    securityPolicies: Array<
      | { __typename?: 'AccessCodePolicy'; enabled: boolean; accessCode: string }
      | { __typename?: 'EmailRegistrationPolicy'; enabled: boolean; requireNameFields: boolean }
      | { __typename?: 'EmailWhitelistPolicy'; enabled: boolean; emails: Array<string>; requireNameFields: boolean }
      | { __typename?: 'ReferrerProtectionPolicy' }
      | {
          __typename?: 'SingleSignOnPolicy';
          enabled: boolean;
          idps?: Array<string> | null | undefined;
          restrictions?:
            | {
                __typename?: 'SingleSignOnRestrictions';
                enabled: boolean;
                emails: Array<string>;
                employeeIds: Array<string>;
                groups: Array<string>;
              }
            | null
            | undefined;
        }
      | { __typename?: 'TokenProtectionPolicy' }
    >;
  };
};

export type UpdateAccessCodePolicyMutationVariables = Exact<{
  input: UpdateAccessCodePolicyInput;
}>;

export type UpdateAccessCodePolicyMutation = {
  __typename?: 'Mutation';
  updateAccessCodePolicy:
    | { __typename?: 'UpdateAccessCodePolicyProblem'; code: UpdateAccessCodePolicyProblemCode }
    | {
        __typename?: 'UpdateAccessCodePolicySuccess';
        accessCodePolicy: { __typename?: 'AccessCodePolicy'; accessCode: string; enabled: boolean };
      }
    | {
        __typename?: 'ValidationErrors';
        errors?:
          | Array<
              | { __typename?: 'ValidationError'; field: string; code: ValidationErrorCode; message: string }
              | null
              | undefined
            >
          | null
          | undefined;
      };
};

export type UpdateSingleSignOnPolicyMutationVariables = Exact<{
  input: UpdateSingleSignOnPolicyInput;
}>;

export type UpdateSingleSignOnPolicyMutation = {
  __typename?: 'Mutation';
  updateSingleSignOnPolicy:
    | { __typename?: 'UpdateSingleSignOnPolicyGenericFailure'; code: UpdateSingleSignOnPolicyFailureCode }
    | {
        __typename?: 'UpdateSingleSignOnPolicySuccess';
        policy: {
          __typename?: 'SingleSignOnPolicy';
          enabled: boolean;
          idps?: Array<string> | null | undefined;
          restrictions?:
            | {
                __typename?: 'SingleSignOnRestrictions';
                enabled: boolean;
                emails: Array<string>;
                employeeIds: Array<string>;
                groups: Array<string>;
              }
            | null
            | undefined;
        };
      }
    | { __typename?: 'UpdateSingleSignOnPolicyValidationFailure'; invalidEmails: Array<string> };
};

export type UpdateEmailRegistrationPolicyMutationVariables = Exact<{
  input: UpdateEmailRegistrationPolicyInput;
}>;

export type UpdateEmailRegistrationPolicyMutation = {
  __typename?: 'Mutation';
  updateEmailRegistrationPolicy:
    | { __typename?: 'UpdateEmailRegistrationPolicyFailure'; code: UpdateEmailRegistrationPolicyFailureCode }
    | {
        __typename?: 'UpdateEmailRegistrationPolicySuccess';
        policy: { __typename?: 'EmailRegistrationPolicy'; enabled: boolean; requireNameFields: boolean };
      };
};

export type UpdateEmailWhitelistPolicyMutationVariables = Exact<{
  input: UpdateEmailWhitelistPolicyInput;
}>;

export type UpdateEmailWhitelistPolicyMutation = {
  __typename?: 'Mutation';
  updateEmailWhitelistPolicy:
    | { __typename?: 'UpdateEmailWhitelistPolicyEmailValidationFailure'; invalidEmails: Array<string> }
    | { __typename?: 'UpdateEmailWhitelistPolicyGenericFailure'; code: UpdateEmailWhitelistPolicyFailureCode }
    | {
        __typename?: 'UpdateEmailWhitelistPolicySuccess';
        policy: {
          __typename?: 'EmailWhitelistPolicy';
          enabled: boolean;
          emails: Array<string>;
          requireNameFields: boolean;
        };
      };
};

export type GetWebcastInformationQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastInformationQuery = {
  __typename?: 'Query';
  webcast: {
    __typename?: 'Webcast';
    id: string;
    ecdnSettings?: { __typename?: 'ECdnSettings'; isActive: boolean; type: ECdnType } | null | undefined;
  };
};

export type GetWebcastStateForDuplicationQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
}>;

export type GetWebcastStateForDuplicationQuery = {
  __typename?: 'Query';
  webcast: { __typename?: 'Webcast'; id: string; state?: State | null | undefined };
};

export type DuplicateWebcastMutationVariables = Exact<{
  input: DuplicateWebcastInput;
}>;

export type DuplicateWebcastMutation = {
  __typename?: 'Mutation';
  duplicateWebcast:
    | { __typename?: 'DuplicateWebcastProblem'; code: DuplicateWebcastProblemCode }
    | {
        __typename?: 'DuplicateWebcastSuccess';
        webcast: { __typename?: 'Webcast'; id: string; originalWebcastId?: string | null | undefined };
      }
    | { __typename?: 'ValidationErrors' };
};

export type GetWebcasts_WebcastFragment = {
  __typename?: 'Webcast';
  id: string;
  plannedStartAt: string;
  primaryLanguage: string;
  additionalLanguages: Array<string>;
  state?: State | null | undefined;
  coverImageUrl?: string | null | undefined;
  sharingLink?:
    | { __typename?: 'SharingLink'; sharingUrl: string; token?: string | null | undefined }
    | null
    | undefined;
  contents: Array<{ __typename?: 'WebcastContent'; language: string; title?: string | null | undefined }>;
  presentations: Array<{ __typename?: 'Presentation'; id: string; language: string }>;
  chat?: { __typename?: 'Chat'; applicationId: string } | null | undefined;
  streaming?:
    | { __typename?: 'Streaming'; vod?: { __typename?: 'VodStreaming'; enabled: boolean } | null | undefined }
    | null
    | undefined;
};

export type GetWebcastsQueryVariables = Exact<{
  lsproId: Scalars['ID']['input'];
  orderBy?: InputMaybe<WebcastOrder>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<WebcastFilters>;
}>;

export type GetWebcastsQuery = {
  __typename?: 'Query';
  webcasts: {
    __typename?: 'WebcastConnection';
    edges: Array<{
      __typename?: 'WebcastEdge';
      node: {
        __typename?: 'Webcast';
        id: string;
        plannedStartAt: string;
        primaryLanguage: string;
        additionalLanguages: Array<string>;
        state?: State | null | undefined;
        coverImageUrl?: string | null | undefined;
        sharingLink?:
          | { __typename?: 'SharingLink'; sharingUrl: string; token?: string | null | undefined }
          | null
          | undefined;
        contents: Array<{ __typename?: 'WebcastContent'; language: string; title?: string | null | undefined }>;
        presentations: Array<{ __typename?: 'Presentation'; id: string; language: string }>;
        chat?: { __typename?: 'Chat'; applicationId: string } | null | undefined;
        streaming?:
          | { __typename?: 'Streaming'; vod?: { __typename?: 'VodStreaming'; enabled: boolean } | null | undefined }
          | null
          | undefined;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null | undefined; hasNextPage: boolean };
  };
};

export type DeleteWebcastEventMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteWebcastEventMutation = {
  __typename?: 'Mutation';
  deleteWebcast:
    | { __typename?: 'DeleteWebcastProblem'; code: DeleteWebcastProblemCode }
    | { __typename?: 'DeleteWebcastSuccess'; webcast: { __typename?: 'Webcast'; id: string } };
};

export type CreatePresentationMutationVariables = Exact<{
  createPresentationInput: CreatePresentationInput;
  groupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreatePresentationMutation = {
  __typename?: 'Mutation';
  createPresentation:
    | { __typename?: 'CreatePresentationProblem'; code: CreatePresentationProblemCode }
    | {
        __typename?: 'CreatePresentationSuccess';
        presentation: {
          __typename?: 'Presentation';
          id: string;
          groupId: string;
          uploadLink: { __typename?: 'UploadLink'; url: string; token: string };
        };
      }
    | { __typename?: 'ValidationErrors' };
};

export type DeletePresentationMutationVariables = Exact<{
  deletePresentationInput: DeletePresentationInput;
}>;

export type DeletePresentationMutation = {
  __typename?: 'Mutation';
  deletePresentation:
    | { __typename?: 'DeletePresentationProblem'; code: DeletePresentationProblemCode }
    | {
        __typename?: 'DeletePresentationSuccess';
        presentation: { __typename?: 'Presentation'; id: string; groupId: string };
      };
};

export type GetIsVmproLinkedQueryVariables = Exact<{ [key: string]: never }>;

export type GetIsVmproLinkedQuery = {
  __typename?: 'Query';
  currentUser?: { __typename?: 'CurrentUser'; isVmproLinked: boolean } | null | undefined;
};

export type LinkVmproMutationVariables = Exact<{
  input: LinkVmproInput;
}>;

export type LinkVmproMutation = {
  __typename?: 'Mutation';
  linkVmpro:
    | { __typename?: 'LinkVmproProblem'; code: LinkVmproProblemCode }
    | { __typename?: 'LinkVmproSuccess'; authorizationCode: string; redirectUri: string };
};

export const ChannelsFragmentFragmentDoc = gql`
  fragment ChannelsFragment on VideoManager {
    channels {
      id
      name
      parentId
      children
    }
  }
`;
export const VideoFragmentFragmentDoc = gql`
  fragment VideoFragment on Video {
    id
    channelId
    title
    duration
    createdDate
    thumbnail
  }
`;
export const GetWebcastSecurity_ReferrerProtectionPolicyFragmentDoc = gql`
  fragment GetWebcastSecurity_ReferrerProtectionPolicy on ReferrerProtectionPolicy {
    enabled
    domains
  }
`;
export const GetWebcastSecurity_TokenProtectionPolicyFragmentDoc = gql`
  fragment GetWebcastSecurity_TokenProtectionPolicy on TokenProtectionPolicy {
    enabled
  }
`;
export const GetWebcastSummaryManager_RecordingsFragmentDoc = gql`
  fragment GetWebcastSummaryManager_Recordings on StreamRecording {
    downloadLink
    status
    metadata {
      language
    }
  }
`;
export const CoverImageFragmentDoc = gql`
  fragment CoverImage on Asset {
    assetId
    downloadLink
    status {
      code
      error
    }
  }
`;
export const VideoFragmentDoc = gql`
  fragment Video on VideoReference {
    videoId
    channelId
  }
`;
export const GetWebcasts_WebcastFragmentDoc = gql`
  fragment GetWebcasts_Webcast on Webcast {
    id
    sharingLink {
      sharingUrl
      token
    }
    plannedStartAt
    primaryLanguage
    additionalLanguages
    state
    coverImageUrl
    contents {
      language
      title
    }
    presentations {
      id
      language
    }
    chat {
      applicationId
    }
    streaming {
      vod {
        enabled
      }
    }
  }
`;
export const GetWebcastStateDocument = gql`
  query GetWebcastState($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
    }
  }
`;

/**
 * __useGetWebcastStateQuery__
 *
 * To run a query within a React component, call `useGetWebcastStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastStateQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastStateQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastStateQuery, GetWebcastStateQueryVariables> &
    ({ variables: GetWebcastStateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastStateQuery, GetWebcastStateQueryVariables>(GetWebcastStateDocument, options);
}
export function useGetWebcastStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastStateQuery, GetWebcastStateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastStateQuery, GetWebcastStateQueryVariables>(GetWebcastStateDocument, options);
}
export function useGetWebcastStateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWebcastStateQuery, GetWebcastStateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastStateQuery, GetWebcastStateQueryVariables>(GetWebcastStateDocument, options);
}
export type GetWebcastStateQueryHookResult = ReturnType<typeof useGetWebcastStateQuery>;
export type GetWebcastStateLazyQueryHookResult = ReturnType<typeof useGetWebcastStateLazyQuery>;
export type GetWebcastStateSuspenseQueryHookResult = ReturnType<typeof useGetWebcastStateSuspenseQuery>;
export type GetWebcastStateQueryResult = Apollo.QueryResult<GetWebcastStateQuery, GetWebcastStateQueryVariables>;
export const GetVideoManagersDocument = gql`
  query GetVideoManagers {
    videoManagers {
      ... on GetVideoManagersSuccess {
        videoManagers {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetVideoManagersQuery__
 *
 * To run a query within a React component, call `useGetVideoManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVideoManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVideoManagersQuery, GetVideoManagersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVideoManagersQuery, GetVideoManagersQueryVariables>(GetVideoManagersDocument, options);
}
export function useGetVideoManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVideoManagersQuery, GetVideoManagersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVideoManagersQuery, GetVideoManagersQueryVariables>(GetVideoManagersDocument, options);
}
export function useGetVideoManagersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetVideoManagersQuery, GetVideoManagersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVideoManagersQuery, GetVideoManagersQueryVariables>(
    GetVideoManagersDocument,
    options
  );
}
export type GetVideoManagersQueryHookResult = ReturnType<typeof useGetVideoManagersQuery>;
export type GetVideoManagersLazyQueryHookResult = ReturnType<typeof useGetVideoManagersLazyQuery>;
export type GetVideoManagersSuspenseQueryHookResult = ReturnType<typeof useGetVideoManagersSuspenseQuery>;
export type GetVideoManagersQueryResult = Apollo.QueryResult<GetVideoManagersQuery, GetVideoManagersQueryVariables>;
export const GetVideoManagerDocument = gql`
  query GetVideoManager($videoManagerId: ID!) {
    videoManager(videoManagerId: $videoManagerId) {
      ... on VideoManager {
        id
        name
        ...ChannelsFragment
      }
      ... on GetVideoManagerProblem {
        code
      }
    }
  }
  ${ChannelsFragmentFragmentDoc}
`;

/**
 * __useGetVideoManagerQuery__
 *
 * To run a query within a React component, call `useGetVideoManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoManagerQuery({
 *   variables: {
 *      videoManagerId: // value for 'videoManagerId'
 *   },
 * });
 */
export function useGetVideoManagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetVideoManagerQuery, GetVideoManagerQueryVariables> &
    ({ variables: GetVideoManagerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVideoManagerQuery, GetVideoManagerQueryVariables>(GetVideoManagerDocument, options);
}
export function useGetVideoManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVideoManagerQuery, GetVideoManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVideoManagerQuery, GetVideoManagerQueryVariables>(GetVideoManagerDocument, options);
}
export function useGetVideoManagerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVideoManagerQuery, GetVideoManagerQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVideoManagerQuery, GetVideoManagerQueryVariables>(GetVideoManagerDocument, options);
}
export type GetVideoManagerQueryHookResult = ReturnType<typeof useGetVideoManagerQuery>;
export type GetVideoManagerLazyQueryHookResult = ReturnType<typeof useGetVideoManagerLazyQuery>;
export type GetVideoManagerSuspenseQueryHookResult = ReturnType<typeof useGetVideoManagerSuspenseQuery>;
export type GetVideoManagerQueryResult = Apollo.QueryResult<GetVideoManagerQuery, GetVideoManagerQueryVariables>;
export const GetVideosDocument = gql`
  query GetVideos($videoManagerId: ID!, $channelId: ID!, $searchTerm: String) {
    videos(videoManagerId: $videoManagerId, channelId: $channelId, searchTerm: $searchTerm) {
      ... on VideoConnection {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            ...VideoFragment
          }
        }
      }
      ... on VideoSearchProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
  ${VideoFragmentFragmentDoc}
`;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *      videoManagerId: // value for 'videoManagerId'
 *      channelId: // value for 'channelId'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetVideosQuery(
  baseOptions: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables> &
    ({ variables: GetVideosQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
}
export function useGetVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
}
export function useGetVideosSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
}
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<typeof useGetVideosLazyQuery>;
export type GetVideosSuspenseQueryHookResult = ReturnType<typeof useGetVideosSuspenseQuery>;
export type GetVideosQueryResult = Apollo.QueryResult<GetVideosQuery, GetVideosQueryVariables>;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      id
      accounts {
        liveStreamPro {
          id
          name
        }
        userRoles
      }
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GetFeaturesDocument = gql`
  query GetFeatures($lsproId: ID!) {
    lsproSettings(lsproId: $lsproId) {
      features {
        ... on AiSubtitlesFeature {
          enabled
        }
        ... on BrowserBasedStudioFeature {
          enabled
        }
        ... on LiveStreamingHoursFeature {
          enabled
          value
          quotaType
        }
      }
    }
  }
`;

/**
 * __useGetFeaturesQuery__
 *
 * To run a query within a React component, call `useGetFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *   },
 * });
 */
export function useGetFeaturesQuery(
  baseOptions: Apollo.QueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables> &
    ({ variables: GetFeaturesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
}
export function useGetFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
}
export function useGetFeaturesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
}
export type GetFeaturesQueryHookResult = ReturnType<typeof useGetFeaturesQuery>;
export type GetFeaturesLazyQueryHookResult = ReturnType<typeof useGetFeaturesLazyQuery>;
export type GetFeaturesSuspenseQueryHookResult = ReturnType<typeof useGetFeaturesSuspenseQuery>;
export type GetFeaturesQueryResult = Apollo.QueryResult<GetFeaturesQuery, GetFeaturesQueryVariables>;
export const GetPrimaryTitleDocument = gql`
  query GetPrimaryTitle($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      primaryLanguage
      contents {
        language
        title
      }
    }
  }
`;

/**
 * __useGetPrimaryTitleQuery__
 *
 * To run a query within a React component, call `useGetPrimaryTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrimaryTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrimaryTitleQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetPrimaryTitleQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrimaryTitleQuery, GetPrimaryTitleQueryVariables> &
    ({ variables: GetPrimaryTitleQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrimaryTitleQuery, GetPrimaryTitleQueryVariables>(GetPrimaryTitleDocument, options);
}
export function useGetPrimaryTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrimaryTitleQuery, GetPrimaryTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrimaryTitleQuery, GetPrimaryTitleQueryVariables>(GetPrimaryTitleDocument, options);
}
export function useGetPrimaryTitleSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPrimaryTitleQuery, GetPrimaryTitleQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPrimaryTitleQuery, GetPrimaryTitleQueryVariables>(GetPrimaryTitleDocument, options);
}
export type GetPrimaryTitleQueryHookResult = ReturnType<typeof useGetPrimaryTitleQuery>;
export type GetPrimaryTitleLazyQueryHookResult = ReturnType<typeof useGetPrimaryTitleLazyQuery>;
export type GetPrimaryTitleSuspenseQueryHookResult = ReturnType<typeof useGetPrimaryTitleSuspenseQuery>;
export type GetPrimaryTitleQueryResult = Apollo.QueryResult<GetPrimaryTitleQuery, GetPrimaryTitleQueryVariables>;
export const GetLsproBrandingSettingsDocument = gql`
  query GetLsproBrandingSettings($lsproId: ID!) {
    configuration {
      supportedFonts
      appearanceColors {
        theme
        primaryColor
        backgroundColor
        cardColor
        textFontColor
        headingFontColor
      }
    }
    lsproSettings(lsproId: $lsproId) {
      appearance {
        theme
        appearanceColors {
          backgroundColor
          cardColor
          headingFontColor
          primaryColor
          textFontColor
          theme
        }
        fonts {
          headingFont
          textFont
        }
        assets {
          type
          downloadLink
          uploadLink {
            url
            token
          }
        }
      }
    }
  }
`;

/**
 * __useGetLsproBrandingSettingsQuery__
 *
 * To run a query within a React component, call `useGetLsproBrandingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLsproBrandingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLsproBrandingSettingsQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *   },
 * });
 */
export function useGetLsproBrandingSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetLsproBrandingSettingsQuery, GetLsproBrandingSettingsQueryVariables> &
    ({ variables: GetLsproBrandingSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLsproBrandingSettingsQuery, GetLsproBrandingSettingsQueryVariables>(
    GetLsproBrandingSettingsDocument,
    options
  );
}
export function useGetLsproBrandingSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLsproBrandingSettingsQuery, GetLsproBrandingSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLsproBrandingSettingsQuery, GetLsproBrandingSettingsQueryVariables>(
    GetLsproBrandingSettingsDocument,
    options
  );
}
export function useGetLsproBrandingSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLsproBrandingSettingsQuery, GetLsproBrandingSettingsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLsproBrandingSettingsQuery, GetLsproBrandingSettingsQueryVariables>(
    GetLsproBrandingSettingsDocument,
    options
  );
}
export type GetLsproBrandingSettingsQueryHookResult = ReturnType<typeof useGetLsproBrandingSettingsQuery>;
export type GetLsproBrandingSettingsLazyQueryHookResult = ReturnType<typeof useGetLsproBrandingSettingsLazyQuery>;
export type GetLsproBrandingSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetLsproBrandingSettingsSuspenseQuery
>;
export type GetLsproBrandingSettingsQueryResult = Apollo.QueryResult<
  GetLsproBrandingSettingsQuery,
  GetLsproBrandingSettingsQueryVariables
>;
export const CreateAppearanceAssetDocument = gql`
  mutation CreateAppearanceAsset($input: CreateAppearanceAssetInput!) {
    createAppearanceAsset(input: $input) {
      ... on CreateAppearanceAssetSuccess {
        asset {
          type
          downloadLink
          uploadLink {
            url
            token
          }
        }
      }
      ... on CreateAppearanceAssetFailure {
        code
      }
    }
  }
`;
export type CreateAppearanceAssetMutationFn = Apollo.MutationFunction<
  CreateAppearanceAssetMutation,
  CreateAppearanceAssetMutationVariables
>;

/**
 * __useCreateAppearanceAssetMutation__
 *
 * To run a mutation, you first call `useCreateAppearanceAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppearanceAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppearanceAssetMutation, { data, loading, error }] = useCreateAppearanceAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppearanceAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAppearanceAssetMutation, CreateAppearanceAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAppearanceAssetMutation, CreateAppearanceAssetMutationVariables>(
    CreateAppearanceAssetDocument,
    options
  );
}
export type CreateAppearanceAssetMutationHookResult = ReturnType<typeof useCreateAppearanceAssetMutation>;
export type CreateAppearanceAssetMutationResult = Apollo.MutationResult<CreateAppearanceAssetMutation>;
export type CreateAppearanceAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAppearanceAssetMutation,
  CreateAppearanceAssetMutationVariables
>;
export const DeleteAppearanceAssetDocument = gql`
  mutation DeleteAppearanceAsset($input: DeleteAppearanceAssetInput!) {
    deleteAppearanceAsset(input: $input) {
      ... on DeleteAppearanceAssetSuccess {
        lsproId
      }
      ... on DeleteAppearanceAssetFailure {
        code
      }
    }
  }
`;
export type DeleteAppearanceAssetMutationFn = Apollo.MutationFunction<
  DeleteAppearanceAssetMutation,
  DeleteAppearanceAssetMutationVariables
>;

/**
 * __useDeleteAppearanceAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAppearanceAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppearanceAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppearanceAssetMutation, { data, loading, error }] = useDeleteAppearanceAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppearanceAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAppearanceAssetMutation, DeleteAppearanceAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppearanceAssetMutation, DeleteAppearanceAssetMutationVariables>(
    DeleteAppearanceAssetDocument,
    options
  );
}
export type DeleteAppearanceAssetMutationHookResult = ReturnType<typeof useDeleteAppearanceAssetMutation>;
export type DeleteAppearanceAssetMutationResult = Apollo.MutationResult<DeleteAppearanceAssetMutation>;
export type DeleteAppearanceAssetMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppearanceAssetMutation,
  DeleteAppearanceAssetMutationVariables
>;
export const UpdateLsproThemeAndColorDocument = gql`
  mutation UpdateLsproThemeAndColor($input: UpdateAppearanceSettingsInput!) {
    updateAppearanceSettings(input: $input) {
      ... on UpdateAppearanceSettingsSuccess {
        appearanceSettings {
          theme
          appearanceColors {
            backgroundColor
            cardColor
            headingFontColor
            primaryColor
            textFontColor
            theme
          }
        }
      }
      ... on UpdateAppearanceSettingsFailure {
        code
      }
    }
  }
`;
export type UpdateLsproThemeAndColorMutationFn = Apollo.MutationFunction<
  UpdateLsproThemeAndColorMutation,
  UpdateLsproThemeAndColorMutationVariables
>;

/**
 * __useUpdateLsproThemeAndColorMutation__
 *
 * To run a mutation, you first call `useUpdateLsproThemeAndColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLsproThemeAndColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLsproThemeAndColorMutation, { data, loading, error }] = useUpdateLsproThemeAndColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLsproThemeAndColorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLsproThemeAndColorMutation, UpdateLsproThemeAndColorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLsproThemeAndColorMutation, UpdateLsproThemeAndColorMutationVariables>(
    UpdateLsproThemeAndColorDocument,
    options
  );
}
export type UpdateLsproThemeAndColorMutationHookResult = ReturnType<typeof useUpdateLsproThemeAndColorMutation>;
export type UpdateLsproThemeAndColorMutationResult = Apollo.MutationResult<UpdateLsproThemeAndColorMutation>;
export type UpdateLsproThemeAndColorMutationOptions = Apollo.BaseMutationOptions<
  UpdateLsproThemeAndColorMutation,
  UpdateLsproThemeAndColorMutationVariables
>;
export const UpdateLsproFontsDocument = gql`
  mutation UpdateLsproFonts($input: UpdateAppearanceSettingsInput!) {
    updateAppearanceSettings(input: $input) {
      ... on UpdateAppearanceSettingsSuccess {
        appearanceSettings {
          fonts {
            headingFont
            textFont
          }
        }
      }
      ... on UpdateAppearanceSettingsFailure {
        code
      }
    }
  }
`;
export type UpdateLsproFontsMutationFn = Apollo.MutationFunction<
  UpdateLsproFontsMutation,
  UpdateLsproFontsMutationVariables
>;

/**
 * __useUpdateLsproFontsMutation__
 *
 * To run a mutation, you first call `useUpdateLsproFontsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLsproFontsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLsproFontsMutation, { data, loading, error }] = useUpdateLsproFontsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLsproFontsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLsproFontsMutation, UpdateLsproFontsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLsproFontsMutation, UpdateLsproFontsMutationVariables>(
    UpdateLsproFontsDocument,
    options
  );
}
export type UpdateLsproFontsMutationHookResult = ReturnType<typeof useUpdateLsproFontsMutation>;
export type UpdateLsproFontsMutationResult = Apollo.MutationResult<UpdateLsproFontsMutation>;
export type UpdateLsproFontsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLsproFontsMutation,
  UpdateLsproFontsMutationVariables
>;
export const GetLsproComplianceSettingsDocument = gql`
  query GetLsproComplianceSettings($lsproId: ID!) {
    lsproSettings(lsproId: $lsproId) {
      includeDefaultComplianceForm
      complianceForm {
        text
      }
    }
  }
`;

/**
 * __useGetLsproComplianceSettingsQuery__
 *
 * To run a query within a React component, call `useGetLsproComplianceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLsproComplianceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLsproComplianceSettingsQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *   },
 * });
 */
export function useGetLsproComplianceSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetLsproComplianceSettingsQuery, GetLsproComplianceSettingsQueryVariables> &
    ({ variables: GetLsproComplianceSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLsproComplianceSettingsQuery, GetLsproComplianceSettingsQueryVariables>(
    GetLsproComplianceSettingsDocument,
    options
  );
}
export function useGetLsproComplianceSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLsproComplianceSettingsQuery, GetLsproComplianceSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLsproComplianceSettingsQuery, GetLsproComplianceSettingsQueryVariables>(
    GetLsproComplianceSettingsDocument,
    options
  );
}
export function useGetLsproComplianceSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLsproComplianceSettingsQuery, GetLsproComplianceSettingsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLsproComplianceSettingsQuery, GetLsproComplianceSettingsQueryVariables>(
    GetLsproComplianceSettingsDocument,
    options
  );
}
export type GetLsproComplianceSettingsQueryHookResult = ReturnType<typeof useGetLsproComplianceSettingsQuery>;
export type GetLsproComplianceSettingsLazyQueryHookResult = ReturnType<typeof useGetLsproComplianceSettingsLazyQuery>;
export type GetLsproComplianceSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetLsproComplianceSettingsSuspenseQuery
>;
export type GetLsproComplianceSettingsQueryResult = Apollo.QueryResult<
  GetLsproComplianceSettingsQuery,
  GetLsproComplianceSettingsQueryVariables
>;
export const UpdateLsproComplianceSettingsDocument = gql`
  mutation UpdateLsproComplianceSettings($input: UpdateComplianceFormInput!) {
    updateLiveStreamProComplianceForm(input: $input) {
      ... on UpdateComplianceFormSuccess {
        complianceForm {
          text
        }
        includeDefaultComplianceForm
      }
      ... on UpdateComplianceFormFailure {
        code
      }
    }
  }
`;
export type UpdateLsproComplianceSettingsMutationFn = Apollo.MutationFunction<
  UpdateLsproComplianceSettingsMutation,
  UpdateLsproComplianceSettingsMutationVariables
>;

/**
 * __useUpdateLsproComplianceSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateLsproComplianceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLsproComplianceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLsproComplianceSettingsMutation, { data, loading, error }] = useUpdateLsproComplianceSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLsproComplianceSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLsproComplianceSettingsMutation,
    UpdateLsproComplianceSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLsproComplianceSettingsMutation, UpdateLsproComplianceSettingsMutationVariables>(
    UpdateLsproComplianceSettingsDocument,
    options
  );
}
export type UpdateLsproComplianceSettingsMutationHookResult = ReturnType<
  typeof useUpdateLsproComplianceSettingsMutation
>;
export type UpdateLsproComplianceSettingsMutationResult = Apollo.MutationResult<UpdateLsproComplianceSettingsMutation>;
export type UpdateLsproComplianceSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateLsproComplianceSettingsMutation,
  UpdateLsproComplianceSettingsMutationVariables
>;
export const StreamingUsageReportDocument = gql`
  query StreamingUsageReport($lsproId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    streamingUsageReport(lsproId: $lsproId, startDate: $startDate, endDate: $endDate) {
      ... on GetStreamingUsageReportSuccess {
        downloadUrl
      }
      ... on GetStreamingUsageReportProblem {
        code
      }
    }
  }
`;

/**
 * __useStreamingUsageReportQuery__
 *
 * To run a query within a React component, call `useStreamingUsageReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamingUsageReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamingUsageReportQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useStreamingUsageReportQuery(
  baseOptions: Apollo.QueryHookOptions<StreamingUsageReportQuery, StreamingUsageReportQueryVariables> &
    ({ variables: StreamingUsageReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StreamingUsageReportQuery, StreamingUsageReportQueryVariables>(
    StreamingUsageReportDocument,
    options
  );
}
export function useStreamingUsageReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StreamingUsageReportQuery, StreamingUsageReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StreamingUsageReportQuery, StreamingUsageReportQueryVariables>(
    StreamingUsageReportDocument,
    options
  );
}
export function useStreamingUsageReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<StreamingUsageReportQuery, StreamingUsageReportQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StreamingUsageReportQuery, StreamingUsageReportQueryVariables>(
    StreamingUsageReportDocument,
    options
  );
}
export type StreamingUsageReportQueryHookResult = ReturnType<typeof useStreamingUsageReportQuery>;
export type StreamingUsageReportLazyQueryHookResult = ReturnType<typeof useStreamingUsageReportLazyQuery>;
export type StreamingUsageReportSuspenseQueryHookResult = ReturnType<typeof useStreamingUsageReportSuspenseQuery>;
export type StreamingUsageReportQueryResult = Apollo.QueryResult<
  StreamingUsageReportQuery,
  StreamingUsageReportQueryVariables
>;
export const StreamingUsageDocument = gql`
  query StreamingUsage($lsproId: ID!) {
    streamingUsage(lsproId: $lsproId) {
      ... on GetMonthlyStreamingUsageResult {
        month
        quota {
          type
          value
        }
        usedMinutes
        year
      }
      ... on GetStreamingUsageProblem {
        code
      }
    }
  }
`;

/**
 * __useStreamingUsageQuery__
 *
 * To run a query within a React component, call `useStreamingUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamingUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamingUsageQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *   },
 * });
 */
export function useStreamingUsageQuery(
  baseOptions: Apollo.QueryHookOptions<StreamingUsageQuery, StreamingUsageQueryVariables> &
    ({ variables: StreamingUsageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StreamingUsageQuery, StreamingUsageQueryVariables>(StreamingUsageDocument, options);
}
export function useStreamingUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StreamingUsageQuery, StreamingUsageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StreamingUsageQuery, StreamingUsageQueryVariables>(StreamingUsageDocument, options);
}
export function useStreamingUsageSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StreamingUsageQuery, StreamingUsageQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<StreamingUsageQuery, StreamingUsageQueryVariables>(StreamingUsageDocument, options);
}
export type StreamingUsageQueryHookResult = ReturnType<typeof useStreamingUsageQuery>;
export type StreamingUsageLazyQueryHookResult = ReturnType<typeof useStreamingUsageLazyQuery>;
export type StreamingUsageSuspenseQueryHookResult = ReturnType<typeof useStreamingUsageSuspenseQuery>;
export type StreamingUsageQueryResult = Apollo.QueryResult<StreamingUsageQuery, StreamingUsageQueryVariables>;
export const GetLsproSecuritySettingsDocument = gql`
  query GetLsproSecuritySettings($lsproId: ID!) {
    lsproSettings(lsproId: $lsproId) {
      securitySettings {
        tokenGenerationSecret
      }
    }
  }
`;

/**
 * __useGetLsproSecuritySettingsQuery__
 *
 * To run a query within a React component, call `useGetLsproSecuritySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLsproSecuritySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLsproSecuritySettingsQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *   },
 * });
 */
export function useGetLsproSecuritySettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetLsproSecuritySettingsQuery, GetLsproSecuritySettingsQueryVariables> &
    ({ variables: GetLsproSecuritySettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLsproSecuritySettingsQuery, GetLsproSecuritySettingsQueryVariables>(
    GetLsproSecuritySettingsDocument,
    options
  );
}
export function useGetLsproSecuritySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLsproSecuritySettingsQuery, GetLsproSecuritySettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLsproSecuritySettingsQuery, GetLsproSecuritySettingsQueryVariables>(
    GetLsproSecuritySettingsDocument,
    options
  );
}
export function useGetLsproSecuritySettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLsproSecuritySettingsQuery, GetLsproSecuritySettingsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLsproSecuritySettingsQuery, GetLsproSecuritySettingsQueryVariables>(
    GetLsproSecuritySettingsDocument,
    options
  );
}
export type GetLsproSecuritySettingsQueryHookResult = ReturnType<typeof useGetLsproSecuritySettingsQuery>;
export type GetLsproSecuritySettingsLazyQueryHookResult = ReturnType<typeof useGetLsproSecuritySettingsLazyQuery>;
export type GetLsproSecuritySettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetLsproSecuritySettingsSuspenseQuery
>;
export type GetLsproSecuritySettingsQueryResult = Apollo.QueryResult<
  GetLsproSecuritySettingsQuery,
  GetLsproSecuritySettingsQueryVariables
>;
export const SetUserRolesDocument = gql`
  mutation SetUserRoles($input: SetUserRolesInput!) {
    setUserRoles(input: $input) {
      ... on SetUserRolesSuccess {
        user {
          id
          email
          status
          roles
          invitedAt
        }
      }
    }
  }
`;
export type SetUserRolesMutationFn = Apollo.MutationFunction<SetUserRolesMutation, SetUserRolesMutationVariables>;

/**
 * __useSetUserRolesMutation__
 *
 * To run a mutation, you first call `useSetUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserRolesMutation, { data, loading, error }] = useSetUserRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<SetUserRolesMutation, SetUserRolesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUserRolesMutation, SetUserRolesMutationVariables>(SetUserRolesDocument, options);
}
export type SetUserRolesMutationHookResult = ReturnType<typeof useSetUserRolesMutation>;
export type SetUserRolesMutationResult = Apollo.MutationResult<SetUserRolesMutation>;
export type SetUserRolesMutationOptions = Apollo.BaseMutationOptions<
  SetUserRolesMutation,
  SetUserRolesMutationVariables
>;
export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      ... on InviteUserSuccess {
        user {
          id
          email
          status
          roles
          invitedAt
        }
      }
      ... on InviteUserProblem {
        code
      }
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const RemoveUserDocument = gql`
  mutation RemoveUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      ... on RemoveUserSuccess {
        id
      }
    }
  }
`;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const GetUsersDocument = gql`
  query GetUsers($lsproId: ID!) {
    users(lsproId: $lsproId) {
      ... on GetUsersSuccess {
        users {
          id
          email
          status
          roles
          invitedAt
        }
      }
      ... on GetUsersProblem {
        code
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables> &
    ({ variables: GetUsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetWebcastEcdnSettingsDocument = gql`
  query GetWebcastEcdnSettings($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
      ecdnSettings {
        isActive
        type
      }
    }
  }
`;

/**
 * __useGetWebcastEcdnSettingsQuery__
 *
 * To run a query within a React component, call `useGetWebcastEcdnSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastEcdnSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastEcdnSettingsQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastEcdnSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastEcdnSettingsQuery, GetWebcastEcdnSettingsQueryVariables> &
    ({ variables: GetWebcastEcdnSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastEcdnSettingsQuery, GetWebcastEcdnSettingsQueryVariables>(
    GetWebcastEcdnSettingsDocument,
    options
  );
}
export function useGetWebcastEcdnSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastEcdnSettingsQuery, GetWebcastEcdnSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastEcdnSettingsQuery, GetWebcastEcdnSettingsQueryVariables>(
    GetWebcastEcdnSettingsDocument,
    options
  );
}
export function useGetWebcastEcdnSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastEcdnSettingsQuery, GetWebcastEcdnSettingsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastEcdnSettingsQuery, GetWebcastEcdnSettingsQueryVariables>(
    GetWebcastEcdnSettingsDocument,
    options
  );
}
export type GetWebcastEcdnSettingsQueryHookResult = ReturnType<typeof useGetWebcastEcdnSettingsQuery>;
export type GetWebcastEcdnSettingsLazyQueryHookResult = ReturnType<typeof useGetWebcastEcdnSettingsLazyQuery>;
export type GetWebcastEcdnSettingsSuspenseQueryHookResult = ReturnType<typeof useGetWebcastEcdnSettingsSuspenseQuery>;
export type GetWebcastEcdnSettingsQueryResult = Apollo.QueryResult<
  GetWebcastEcdnSettingsQuery,
  GetWebcastEcdnSettingsQueryVariables
>;
export const UpdateWebcastEcdnDocument = gql`
  mutation UpdateWebcastEcdn($input: UpdateWebcastInput!) {
    updateWebcast(input: $input) {
      ... on UpdateWebcastSuccess {
        webcast {
          id
          ecdnSettings {
            isActive
            type
          }
        }
      }
      ... on UpdateWebcastProblem {
        code
      }
    }
  }
`;
export type UpdateWebcastEcdnMutationFn = Apollo.MutationFunction<
  UpdateWebcastEcdnMutation,
  UpdateWebcastEcdnMutationVariables
>;

/**
 * __useUpdateWebcastEcdnMutation__
 *
 * To run a mutation, you first call `useUpdateWebcastEcdnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebcastEcdnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebcastEcdnMutation, { data, loading, error }] = useUpdateWebcastEcdnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebcastEcdnMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWebcastEcdnMutation, UpdateWebcastEcdnMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebcastEcdnMutation, UpdateWebcastEcdnMutationVariables>(
    UpdateWebcastEcdnDocument,
    options
  );
}
export type UpdateWebcastEcdnMutationHookResult = ReturnType<typeof useUpdateWebcastEcdnMutation>;
export type UpdateWebcastEcdnMutationResult = Apollo.MutationResult<UpdateWebcastEcdnMutation>;
export type UpdateWebcastEcdnMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebcastEcdnMutation,
  UpdateWebcastEcdnMutationVariables
>;
export const GetWebcastEmbeddingDocument = gql`
  query GetWebcastEmbedding($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
      securityPolicies {
        ... on ReferrerProtectionPolicy {
          ...GetWebcastSecurity_ReferrerProtectionPolicy
        }
        ... on TokenProtectionPolicy {
          ...GetWebcastSecurity_TokenProtectionPolicy
        }
      }
    }
  }
  ${GetWebcastSecurity_ReferrerProtectionPolicyFragmentDoc}
  ${GetWebcastSecurity_TokenProtectionPolicyFragmentDoc}
`;

/**
 * __useGetWebcastEmbeddingQuery__
 *
 * To run a query within a React component, call `useGetWebcastEmbeddingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastEmbeddingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastEmbeddingQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastEmbeddingQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastEmbeddingQuery, GetWebcastEmbeddingQueryVariables> &
    ({ variables: GetWebcastEmbeddingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastEmbeddingQuery, GetWebcastEmbeddingQueryVariables>(
    GetWebcastEmbeddingDocument,
    options
  );
}
export function useGetWebcastEmbeddingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastEmbeddingQuery, GetWebcastEmbeddingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastEmbeddingQuery, GetWebcastEmbeddingQueryVariables>(
    GetWebcastEmbeddingDocument,
    options
  );
}
export function useGetWebcastEmbeddingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastEmbeddingQuery, GetWebcastEmbeddingQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastEmbeddingQuery, GetWebcastEmbeddingQueryVariables>(
    GetWebcastEmbeddingDocument,
    options
  );
}
export type GetWebcastEmbeddingQueryHookResult = ReturnType<typeof useGetWebcastEmbeddingQuery>;
export type GetWebcastEmbeddingLazyQueryHookResult = ReturnType<typeof useGetWebcastEmbeddingLazyQuery>;
export type GetWebcastEmbeddingSuspenseQueryHookResult = ReturnType<typeof useGetWebcastEmbeddingSuspenseQuery>;
export type GetWebcastEmbeddingQueryResult = Apollo.QueryResult<
  GetWebcastEmbeddingQuery,
  GetWebcastEmbeddingQueryVariables
>;
export const UpdateReferrerProtectionPolicyDocument = gql`
  mutation UpdateReferrerProtectionPolicy($input: UpdateReferrerProtectionPolicyInput!) {
    updateReferrerProtectionPolicy(input: $input) {
      ... on UpdateReferrerProtectionPolicySuccess {
        referrerProtectionPolicy {
          ...GetWebcastSecurity_ReferrerProtectionPolicy
        }
      }
      ... on UpdateReferrerProtectionPolicyFailure {
        code
      }
    }
  }
  ${GetWebcastSecurity_ReferrerProtectionPolicyFragmentDoc}
`;
export type UpdateReferrerProtectionPolicyMutationFn = Apollo.MutationFunction<
  UpdateReferrerProtectionPolicyMutation,
  UpdateReferrerProtectionPolicyMutationVariables
>;

/**
 * __useUpdateReferrerProtectionPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateReferrerProtectionPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferrerProtectionPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferrerProtectionPolicyMutation, { data, loading, error }] = useUpdateReferrerProtectionPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferrerProtectionPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReferrerProtectionPolicyMutation,
    UpdateReferrerProtectionPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReferrerProtectionPolicyMutation, UpdateReferrerProtectionPolicyMutationVariables>(
    UpdateReferrerProtectionPolicyDocument,
    options
  );
}
export type UpdateReferrerProtectionPolicyMutationHookResult = ReturnType<
  typeof useUpdateReferrerProtectionPolicyMutation
>;
export type UpdateReferrerProtectionPolicyMutationResult =
  Apollo.MutationResult<UpdateReferrerProtectionPolicyMutation>;
export type UpdateReferrerProtectionPolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdateReferrerProtectionPolicyMutation,
  UpdateReferrerProtectionPolicyMutationVariables
>;
export const UpdateTokenProtectionPolicyDocument = gql`
  mutation UpdateTokenProtectionPolicy($input: UpdateTokenProtectionPolicyInput!) {
    updateTokenProtectionPolicy(input: $input) {
      ... on UpdateTokenProtectionSuccess {
        policy {
          ...GetWebcastSecurity_TokenProtectionPolicy
        }
      }
      ... on UpdateTokenProtectionFailure {
        code
      }
    }
  }
  ${GetWebcastSecurity_TokenProtectionPolicyFragmentDoc}
`;
export type UpdateTokenProtectionPolicyMutationFn = Apollo.MutationFunction<
  UpdateTokenProtectionPolicyMutation,
  UpdateTokenProtectionPolicyMutationVariables
>;

/**
 * __useUpdateTokenProtectionPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateTokenProtectionPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTokenProtectionPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTokenProtectionPolicyMutation, { data, loading, error }] = useUpdateTokenProtectionPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTokenProtectionPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTokenProtectionPolicyMutation,
    UpdateTokenProtectionPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTokenProtectionPolicyMutation, UpdateTokenProtectionPolicyMutationVariables>(
    UpdateTokenProtectionPolicyDocument,
    options
  );
}
export type UpdateTokenProtectionPolicyMutationHookResult = ReturnType<typeof useUpdateTokenProtectionPolicyMutation>;
export type UpdateTokenProtectionPolicyMutationResult = Apollo.MutationResult<UpdateTokenProtectionPolicyMutation>;
export type UpdateTokenProtectionPolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdateTokenProtectionPolicyMutation,
  UpdateTokenProtectionPolicyMutationVariables
>;
export const GetWebcastDescriptionDocument = gql`
  query GetWebcastDescription($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      primaryLanguage
      additionalLanguages
      contents {
        language
        preLive {
          description
        }
        live {
          description
        }
        postLive {
          description
        }
      }
    }
  }
`;

/**
 * __useGetWebcastDescriptionQuery__
 *
 * To run a query within a React component, call `useGetWebcastDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastDescriptionQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastDescriptionQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastDescriptionQuery, GetWebcastDescriptionQueryVariables> &
    ({ variables: GetWebcastDescriptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastDescriptionQuery, GetWebcastDescriptionQueryVariables>(
    GetWebcastDescriptionDocument,
    options
  );
}
export function useGetWebcastDescriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastDescriptionQuery, GetWebcastDescriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastDescriptionQuery, GetWebcastDescriptionQueryVariables>(
    GetWebcastDescriptionDocument,
    options
  );
}
export function useGetWebcastDescriptionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastDescriptionQuery, GetWebcastDescriptionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastDescriptionQuery, GetWebcastDescriptionQueryVariables>(
    GetWebcastDescriptionDocument,
    options
  );
}
export type GetWebcastDescriptionQueryHookResult = ReturnType<typeof useGetWebcastDescriptionQuery>;
export type GetWebcastDescriptionLazyQueryHookResult = ReturnType<typeof useGetWebcastDescriptionLazyQuery>;
export type GetWebcastDescriptionSuspenseQueryHookResult = ReturnType<typeof useGetWebcastDescriptionSuspenseQuery>;
export type GetWebcastDescriptionQueryResult = Apollo.QueryResult<
  GetWebcastDescriptionQuery,
  GetWebcastDescriptionQueryVariables
>;
export const UpdateWebcastDescriptionDocument = gql`
  mutation UpdateWebcastDescription($input: UpdateWebcastInput!) {
    updateWebcast(input: $input) {
      ... on UpdateWebcastSuccess {
        webcast {
          id
          contents {
            language
            preLive {
              description
            }
            live {
              description
            }
            postLive {
              description
            }
          }
        }
      }
      ... on UpdateWebcastProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
`;
export type UpdateWebcastDescriptionMutationFn = Apollo.MutationFunction<
  UpdateWebcastDescriptionMutation,
  UpdateWebcastDescriptionMutationVariables
>;

/**
 * __useUpdateWebcastDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateWebcastDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebcastDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebcastDescriptionMutation, { data, loading, error }] = useUpdateWebcastDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebcastDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWebcastDescriptionMutation, UpdateWebcastDescriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebcastDescriptionMutation, UpdateWebcastDescriptionMutationVariables>(
    UpdateWebcastDescriptionDocument,
    options
  );
}
export type UpdateWebcastDescriptionMutationHookResult = ReturnType<typeof useUpdateWebcastDescriptionMutation>;
export type UpdateWebcastDescriptionMutationResult = Apollo.MutationResult<UpdateWebcastDescriptionMutation>;
export type UpdateWebcastDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebcastDescriptionMutation,
  UpdateWebcastDescriptionMutationVariables
>;
export const GetWebcastDocument = gql`
  query GetWebcast($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      primaryLanguage
      plannedStartAt
      durationInMinutes
      state
      contents {
        title
        language
      }
    }
  }
`;

/**
 * __useGetWebcastQuery__
 *
 * To run a query within a React component, call `useGetWebcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastQuery, GetWebcastQueryVariables> &
    ({ variables: GetWebcastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastQuery, GetWebcastQueryVariables>(GetWebcastDocument, options);
}
export function useGetWebcastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastQuery, GetWebcastQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastQuery, GetWebcastQueryVariables>(GetWebcastDocument, options);
}
export function useGetWebcastSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWebcastQuery, GetWebcastQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastQuery, GetWebcastQueryVariables>(GetWebcastDocument, options);
}
export type GetWebcastQueryHookResult = ReturnType<typeof useGetWebcastQuery>;
export type GetWebcastLazyQueryHookResult = ReturnType<typeof useGetWebcastLazyQuery>;
export type GetWebcastSuspenseQueryHookResult = ReturnType<typeof useGetWebcastSuspenseQuery>;
export type GetWebcastQueryResult = Apollo.QueryResult<GetWebcastQuery, GetWebcastQueryVariables>;
export const CreateWebcastDocument = gql`
  mutation CreateWebcast($input: CreateWebcastInput!) {
    createWebcast(input: $input) {
      ... on CreateWebcastSuccess {
        webcast {
          id
          lsproId
          primaryLanguage
          plannedStartAt
          durationInMinutes
          contents {
            title
            language
          }
        }
      }
      ... on CreateWebcastProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
`;
export type CreateWebcastMutationFn = Apollo.MutationFunction<CreateWebcastMutation, CreateWebcastMutationVariables>;

/**
 * __useCreateWebcastMutation__
 *
 * To run a mutation, you first call `useCreateWebcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebcastMutation, { data, loading, error }] = useCreateWebcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebcastMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWebcastMutation, CreateWebcastMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWebcastMutation, CreateWebcastMutationVariables>(CreateWebcastDocument, options);
}
export type CreateWebcastMutationHookResult = ReturnType<typeof useCreateWebcastMutation>;
export type CreateWebcastMutationResult = Apollo.MutationResult<CreateWebcastMutation>;
export type CreateWebcastMutationOptions = Apollo.BaseMutationOptions<
  CreateWebcastMutation,
  CreateWebcastMutationVariables
>;
export const UpdateWebcastDocument = gql`
  mutation UpdateWebcast($input: UpdateWebcastInput!) {
    updateWebcast(input: $input) {
      ... on UpdateWebcastSuccess {
        webcast {
          id
          lsproId
          plannedStartAt
          durationInMinutes
          primaryLanguage
          contents {
            title
            language
          }
        }
      }
      ... on UpdateWebcastProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
`;
export type UpdateWebcastMutationFn = Apollo.MutationFunction<UpdateWebcastMutation, UpdateWebcastMutationVariables>;

/**
 * __useUpdateWebcastMutation__
 *
 * To run a mutation, you first call `useUpdateWebcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebcastMutation, { data, loading, error }] = useUpdateWebcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebcastMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWebcastMutation, UpdateWebcastMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebcastMutation, UpdateWebcastMutationVariables>(UpdateWebcastDocument, options);
}
export type UpdateWebcastMutationHookResult = ReturnType<typeof useUpdateWebcastMutation>;
export type UpdateWebcastMutationResult = Apollo.MutationResult<UpdateWebcastMutation>;
export type UpdateWebcastMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebcastMutation,
  UpdateWebcastMutationVariables
>;
export const UpdateWebCastsListDocument = gql`
  query UpdateWebCastsList($lsproId: ID!) {
    webcasts(lsproId: $lsproId, first: 100) {
      edges {
        node {
          id
          lsproId
          sharingLink {
            sharingUrl
            token
          }
          plannedStartAt
          primaryLanguage
          additionalLanguages
          contents {
            language
            title
          }
          state
          coverImageUrl
          presentations {
            id
            language
          }
          securityPolicies {
            __typename
            ... on AccessCodePolicy {
              accessCode
              enabled
            }
          }
        }
      }
    }
  }
`;

/**
 * __useUpdateWebCastsListQuery__
 *
 * To run a query within a React component, call `useUpdateWebCastsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebCastsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateWebCastsListQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *   },
 * });
 */
export function useUpdateWebCastsListQuery(
  baseOptions: Apollo.QueryHookOptions<UpdateWebCastsListQuery, UpdateWebCastsListQueryVariables> &
    ({ variables: UpdateWebCastsListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpdateWebCastsListQuery, UpdateWebCastsListQueryVariables>(
    UpdateWebCastsListDocument,
    options
  );
}
export function useUpdateWebCastsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UpdateWebCastsListQuery, UpdateWebCastsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpdateWebCastsListQuery, UpdateWebCastsListQueryVariables>(
    UpdateWebCastsListDocument,
    options
  );
}
export function useUpdateWebCastsListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UpdateWebCastsListQuery, UpdateWebCastsListQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UpdateWebCastsListQuery, UpdateWebCastsListQueryVariables>(
    UpdateWebCastsListDocument,
    options
  );
}
export type UpdateWebCastsListQueryHookResult = ReturnType<typeof useUpdateWebCastsListQuery>;
export type UpdateWebCastsListLazyQueryHookResult = ReturnType<typeof useUpdateWebCastsListLazyQuery>;
export type UpdateWebCastsListSuspenseQueryHookResult = ReturnType<typeof useUpdateWebCastsListSuspenseQuery>;
export type UpdateWebCastsListQueryResult = Apollo.QueryResult<
  UpdateWebCastsListQuery,
  UpdateWebCastsListQueryVariables
>;
export const GetWebcastPermissionsDocument = gql`
  query GetWebcastPermissions($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      eventPermission {
        type
        owners {
          email
          firstName
          id
          invitedAt
          lastName
          roles
          status
        }
        collaborators {
          email
          firstName
          id
          invitedAt
          lastName
          roles
          status
        }
      }
    }
  }
`;

/**
 * __useGetWebcastPermissionsQuery__
 *
 * To run a query within a React component, call `useGetWebcastPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastPermissionsQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastPermissionsQuery, GetWebcastPermissionsQueryVariables> &
    ({ variables: GetWebcastPermissionsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastPermissionsQuery, GetWebcastPermissionsQueryVariables>(
    GetWebcastPermissionsDocument,
    options
  );
}
export function useGetWebcastPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastPermissionsQuery, GetWebcastPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastPermissionsQuery, GetWebcastPermissionsQueryVariables>(
    GetWebcastPermissionsDocument,
    options
  );
}
export function useGetWebcastPermissionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastPermissionsQuery, GetWebcastPermissionsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastPermissionsQuery, GetWebcastPermissionsQueryVariables>(
    GetWebcastPermissionsDocument,
    options
  );
}
export type GetWebcastPermissionsQueryHookResult = ReturnType<typeof useGetWebcastPermissionsQuery>;
export type GetWebcastPermissionsLazyQueryHookResult = ReturnType<typeof useGetWebcastPermissionsLazyQuery>;
export type GetWebcastPermissionsSuspenseQueryHookResult = ReturnType<typeof useGetWebcastPermissionsSuspenseQuery>;
export type GetWebcastPermissionsQueryResult = Apollo.QueryResult<
  GetWebcastPermissionsQuery,
  GetWebcastPermissionsQueryVariables
>;
export const UpdateEventPermissionDocument = gql`
  mutation UpdateEventPermission($input: UpdateEventPermissionInput!) {
    updateEventPermission(input: $input) {
      ... on UpdateEventPermissionSuccess {
        eventPermission {
          type
        }
      }
      ... on UpdateEventPermissionProblem {
        code
      }
      ... on UpdateEventPermissionValidationFailure {
        invalidCollaborators
        invalidOwners
      }
    }
  }
`;
export type UpdateEventPermissionMutationFn = Apollo.MutationFunction<
  UpdateEventPermissionMutation,
  UpdateEventPermissionMutationVariables
>;

/**
 * __useUpdateEventPermissionMutation__
 *
 * To run a mutation, you first call `useUpdateEventPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventPermissionMutation, { data, loading, error }] = useUpdateEventPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventPermissionMutation, UpdateEventPermissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventPermissionMutation, UpdateEventPermissionMutationVariables>(
    UpdateEventPermissionDocument,
    options
  );
}
export type UpdateEventPermissionMutationHookResult = ReturnType<typeof useUpdateEventPermissionMutation>;
export type UpdateEventPermissionMutationResult = Apollo.MutationResult<UpdateEventPermissionMutation>;
export type UpdateEventPermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventPermissionMutation,
  UpdateEventPermissionMutationVariables
>;
export const ManageEventPermissionsDocument = gql`
  mutation ManageEventPermissions($input: UpdateEventPermissionInput!) {
    updateEventPermission(input: $input) {
      ... on UpdateEventPermissionSuccess {
        eventPermission {
          collaborators {
            email
            id
          }
          owners {
            id
            email
          }
        }
      }
      ... on UpdateEventPermissionProblem {
        code
      }
      ... on UpdateEventPermissionValidationFailure {
        invalidCollaborators
        invalidOwners
      }
    }
  }
`;
export type ManageEventPermissionsMutationFn = Apollo.MutationFunction<
  ManageEventPermissionsMutation,
  ManageEventPermissionsMutationVariables
>;

/**
 * __useManageEventPermissionsMutation__
 *
 * To run a mutation, you first call `useManageEventPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageEventPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageEventPermissionsMutation, { data, loading, error }] = useManageEventPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageEventPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<ManageEventPermissionsMutation, ManageEventPermissionsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManageEventPermissionsMutation, ManageEventPermissionsMutationVariables>(
    ManageEventPermissionsDocument,
    options
  );
}
export type ManageEventPermissionsMutationHookResult = ReturnType<typeof useManageEventPermissionsMutation>;
export type ManageEventPermissionsMutationResult = Apollo.MutationResult<ManageEventPermissionsMutation>;
export type ManageEventPermissionsMutationOptions = Apollo.BaseMutationOptions<
  ManageEventPermissionsMutation,
  ManageEventPermissionsMutationVariables
>;
export const GetRecordingSettingsDocument = gql`
  query GetRecordingSettings($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
      recordingSettings {
        enabled
        videoManager {
          enabled
          videoManagerId
          channels {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetRecordingSettingsQuery__
 *
 * To run a query within a React component, call `useGetRecordingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingSettingsQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetRecordingSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecordingSettingsQuery, GetRecordingSettingsQueryVariables> &
    ({ variables: GetRecordingSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecordingSettingsQuery, GetRecordingSettingsQueryVariables>(
    GetRecordingSettingsDocument,
    options
  );
}
export function useGetRecordingSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecordingSettingsQuery, GetRecordingSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecordingSettingsQuery, GetRecordingSettingsQueryVariables>(
    GetRecordingSettingsDocument,
    options
  );
}
export function useGetRecordingSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRecordingSettingsQuery, GetRecordingSettingsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRecordingSettingsQuery, GetRecordingSettingsQueryVariables>(
    GetRecordingSettingsDocument,
    options
  );
}
export type GetRecordingSettingsQueryHookResult = ReturnType<typeof useGetRecordingSettingsQuery>;
export type GetRecordingSettingsLazyQueryHookResult = ReturnType<typeof useGetRecordingSettingsLazyQuery>;
export type GetRecordingSettingsSuspenseQueryHookResult = ReturnType<typeof useGetRecordingSettingsSuspenseQuery>;
export type GetRecordingSettingsQueryResult = Apollo.QueryResult<
  GetRecordingSettingsQuery,
  GetRecordingSettingsQueryVariables
>;
export const UpdateWebcastRecordSettingsDocument = gql`
  mutation UpdateWebcastRecordSettings($input: UpdateWebcastInput!) {
    updateWebcast(input: $input) {
      ... on UpdateWebcastSuccess {
        webcast {
          id
          recordingSettings {
            enabled
            videoManager {
              enabled
              videoManagerId
              channels {
                id
                name
              }
            }
          }
        }
      }
      ... on UpdateWebcastProblem {
        code
      }
    }
  }
`;
export type UpdateWebcastRecordSettingsMutationFn = Apollo.MutationFunction<
  UpdateWebcastRecordSettingsMutation,
  UpdateWebcastRecordSettingsMutationVariables
>;

/**
 * __useUpdateWebcastRecordSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWebcastRecordSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebcastRecordSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebcastRecordSettingsMutation, { data, loading, error }] = useUpdateWebcastRecordSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebcastRecordSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWebcastRecordSettingsMutation,
    UpdateWebcastRecordSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebcastRecordSettingsMutation, UpdateWebcastRecordSettingsMutationVariables>(
    UpdateWebcastRecordSettingsDocument,
    options
  );
}
export type UpdateWebcastRecordSettingsMutationHookResult = ReturnType<typeof useUpdateWebcastRecordSettingsMutation>;
export type UpdateWebcastRecordSettingsMutationResult = Apollo.MutationResult<UpdateWebcastRecordSettingsMutation>;
export type UpdateWebcastRecordSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebcastRecordSettingsMutation,
  UpdateWebcastRecordSettingsMutationVariables
>;
export const GetWebcastSummaryManagerDocument = gql`
  query GetWebcastSummaryManager($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      durationInMinutes
      primaryLanguage
      additionalLanguages
      state
      contents {
        language
        title
      }
      streaming {
        recordings {
          ...GetWebcastSummaryManager_Recordings
        }
        vod {
          enabled
          streams {
            metadata {
              language
            }
            reference {
              channelId
              videoId
            }
          }
        }
      }
      recordingSettings {
        enabled
        videoManager {
          channels {
            id
            name
          }
          videoManagerId
        }
      }
      analytics {
        peakActivePlays
        plays
        uniqueUsers
        webcastId
      }
      chat {
        type
      }
      securityPolicies {
        ... on AccessCodePolicy {
          enabled
        }
        ... on ReferrerProtectionPolicy {
          enabled
        }
        ... on SingleSignOnPolicy {
          enabled
        }
        ... on EmailRegistrationPolicy {
          enabled
        }
        ... on EmailWhitelistPolicy {
          enabled
        }
        ... on TokenProtectionPolicy {
          enabled
        }
      }
    }
    session(webcastId: $webcastId) {
      ... on GetSessionSuccess {
        startDate
        endDate
      }
    }
  }
  ${GetWebcastSummaryManager_RecordingsFragmentDoc}
`;

/**
 * __useGetWebcastSummaryManagerQuery__
 *
 * To run a query within a React component, call `useGetWebcastSummaryManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastSummaryManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastSummaryManagerQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastSummaryManagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastSummaryManagerQuery, GetWebcastSummaryManagerQueryVariables> &
    ({ variables: GetWebcastSummaryManagerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastSummaryManagerQuery, GetWebcastSummaryManagerQueryVariables>(
    GetWebcastSummaryManagerDocument,
    options
  );
}
export function useGetWebcastSummaryManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastSummaryManagerQuery, GetWebcastSummaryManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastSummaryManagerQuery, GetWebcastSummaryManagerQueryVariables>(
    GetWebcastSummaryManagerDocument,
    options
  );
}
export function useGetWebcastSummaryManagerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastSummaryManagerQuery, GetWebcastSummaryManagerQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastSummaryManagerQuery, GetWebcastSummaryManagerQueryVariables>(
    GetWebcastSummaryManagerDocument,
    options
  );
}
export type GetWebcastSummaryManagerQueryHookResult = ReturnType<typeof useGetWebcastSummaryManagerQuery>;
export type GetWebcastSummaryManagerLazyQueryHookResult = ReturnType<typeof useGetWebcastSummaryManagerLazyQuery>;
export type GetWebcastSummaryManagerSuspenseQueryHookResult = ReturnType<
  typeof useGetWebcastSummaryManagerSuspenseQuery
>;
export type GetWebcastSummaryManagerQueryResult = Apollo.QueryResult<
  GetWebcastSummaryManagerQuery,
  GetWebcastSummaryManagerQueryVariables
>;
export const GetWebcastReportDocument = gql`
  query GetWebcastReport($webcastId: ID!, $reportType: ReportType!) {
    report(webcastId: $webcastId, reportType: $reportType) {
      downloadLink
      generationFailure {
        code
        reason
      }
      id
      status
      type
      webcastId
    }
  }
`;

/**
 * __useGetWebcastReportQuery__
 *
 * To run a query within a React component, call `useGetWebcastReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastReportQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *      reportType: // value for 'reportType'
 *   },
 * });
 */
export function useGetWebcastReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastReportQuery, GetWebcastReportQueryVariables> &
    ({ variables: GetWebcastReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastReportQuery, GetWebcastReportQueryVariables>(GetWebcastReportDocument, options);
}
export function useGetWebcastReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastReportQuery, GetWebcastReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastReportQuery, GetWebcastReportQueryVariables>(GetWebcastReportDocument, options);
}
export function useGetWebcastReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastReportQuery, GetWebcastReportQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastReportQuery, GetWebcastReportQueryVariables>(
    GetWebcastReportDocument,
    options
  );
}
export type GetWebcastReportQueryHookResult = ReturnType<typeof useGetWebcastReportQuery>;
export type GetWebcastReportLazyQueryHookResult = ReturnType<typeof useGetWebcastReportLazyQuery>;
export type GetWebcastReportSuspenseQueryHookResult = ReturnType<typeof useGetWebcastReportSuspenseQuery>;
export type GetWebcastReportQueryResult = Apollo.QueryResult<GetWebcastReportQuery, GetWebcastReportQueryVariables>;
export const GenerateReportDocument = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      ... on GenerateReportSuccess {
        report {
          downloadLink
          generationFailure {
            code
            reason
          }
          id
          status
          type
          webcastId
        }
      }
      ... on GenerateReportProblem {
        code
      }
    }
  }
`;
export type GenerateReportMutationFn = Apollo.MutationFunction<GenerateReportMutation, GenerateReportMutationVariables>;

/**
 * __useGenerateReportMutation__
 *
 * To run a mutation, you first call `useGenerateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateReportMutation, { data, loading, error }] = useGenerateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateReportMutation, GenerateReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateReportMutation, GenerateReportMutationVariables>(GenerateReportDocument, options);
}
export type GenerateReportMutationHookResult = ReturnType<typeof useGenerateReportMutation>;
export type GenerateReportMutationResult = Apollo.MutationResult<GenerateReportMutation>;
export type GenerateReportMutationOptions = Apollo.BaseMutationOptions<
  GenerateReportMutation,
  GenerateReportMutationVariables
>;
export const UpdateVodDocument = gql`
  mutation UpdateVod($input: UpdateVodInput!) {
    updateVod(input: $input) {
      ... on UpdateVodSuccess {
        reference {
          videoId
          channelId
        }
      }
      ... on UpdateVodProblem {
        code
      }
    }
  }
`;
export type UpdateVodMutationFn = Apollo.MutationFunction<UpdateVodMutation, UpdateVodMutationVariables>;

/**
 * __useUpdateVodMutation__
 *
 * To run a mutation, you first call `useUpdateVodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVodMutation, { data, loading, error }] = useUpdateVodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVodMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVodMutation, UpdateVodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVodMutation, UpdateVodMutationVariables>(UpdateVodDocument, options);
}
export type UpdateVodMutationHookResult = ReturnType<typeof useUpdateVodMutation>;
export type UpdateVodMutationResult = Apollo.MutationResult<UpdateVodMutation>;
export type UpdateVodMutationOptions = Apollo.BaseMutationOptions<UpdateVodMutation, UpdateVodMutationVariables>;
export const EnableVodDocument = gql`
  mutation EnableVod($input: EnableVodInput!) {
    enableVod(input: $input) {
      ... on EnableVodProblem {
        code
      }
    }
  }
`;
export type EnableVodMutationFn = Apollo.MutationFunction<EnableVodMutation, EnableVodMutationVariables>;

/**
 * __useEnableVodMutation__
 *
 * To run a mutation, you first call `useEnableVodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableVodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableVodMutation, { data, loading, error }] = useEnableVodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableVodMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableVodMutation, EnableVodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableVodMutation, EnableVodMutationVariables>(EnableVodDocument, options);
}
export type EnableVodMutationHookResult = ReturnType<typeof useEnableVodMutation>;
export type EnableVodMutationResult = Apollo.MutationResult<EnableVodMutation>;
export type EnableVodMutationOptions = Apollo.BaseMutationOptions<EnableVodMutation, EnableVodMutationVariables>;
export const GetWebcastLanguagesDocument = gql`
  query GetWebcastLanguages($webcastId: ID!) {
    configuration {
      supportedLanguages
      supportedSubtitleLanguages
    }
    webcast(webcastId: $webcastId) {
      id
      state
      primaryLanguage
      additionalLanguages
      subtitleSettings {
        aiLiveTranscriptGeneration
        translationLanguages
      }
      contents {
        language
        title
      }
      streaming {
        live {
          ingestProtocol
        }
      }
    }
  }
`;

/**
 * __useGetWebcastLanguagesQuery__
 *
 * To run a query within a React component, call `useGetWebcastLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastLanguagesQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastLanguagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastLanguagesQuery, GetWebcastLanguagesQueryVariables> &
    ({ variables: GetWebcastLanguagesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastLanguagesQuery, GetWebcastLanguagesQueryVariables>(
    GetWebcastLanguagesDocument,
    options
  );
}
export function useGetWebcastLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastLanguagesQuery, GetWebcastLanguagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastLanguagesQuery, GetWebcastLanguagesQueryVariables>(
    GetWebcastLanguagesDocument,
    options
  );
}
export function useGetWebcastLanguagesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastLanguagesQuery, GetWebcastLanguagesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastLanguagesQuery, GetWebcastLanguagesQueryVariables>(
    GetWebcastLanguagesDocument,
    options
  );
}
export type GetWebcastLanguagesQueryHookResult = ReturnType<typeof useGetWebcastLanguagesQuery>;
export type GetWebcastLanguagesLazyQueryHookResult = ReturnType<typeof useGetWebcastLanguagesLazyQuery>;
export type GetWebcastLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetWebcastLanguagesSuspenseQuery>;
export type GetWebcastLanguagesQueryResult = Apollo.QueryResult<
  GetWebcastLanguagesQuery,
  GetWebcastLanguagesQueryVariables
>;
export const UpdateWebcastLanguagesDocument = gql`
  mutation UpdateWebcastLanguages($input: UpdateWebcastInput!) {
    updateWebcast(input: $input) {
      ... on UpdateWebcastSuccess {
        webcast {
          id
          primaryLanguage
          additionalLanguages
          subtitleSettings {
            aiLiveTranscriptGeneration
            translationLanguages
          }
          contents {
            language
            title
          }
        }
      }
      ... on UpdateWebcastProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
`;
export type UpdateWebcastLanguagesMutationFn = Apollo.MutationFunction<
  UpdateWebcastLanguagesMutation,
  UpdateWebcastLanguagesMutationVariables
>;

/**
 * __useUpdateWebcastLanguagesMutation__
 *
 * To run a mutation, you first call `useUpdateWebcastLanguagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebcastLanguagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebcastLanguagesMutation, { data, loading, error }] = useUpdateWebcastLanguagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebcastLanguagesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWebcastLanguagesMutation, UpdateWebcastLanguagesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebcastLanguagesMutation, UpdateWebcastLanguagesMutationVariables>(
    UpdateWebcastLanguagesDocument,
    options
  );
}
export type UpdateWebcastLanguagesMutationHookResult = ReturnType<typeof useUpdateWebcastLanguagesMutation>;
export type UpdateWebcastLanguagesMutationResult = Apollo.MutationResult<UpdateWebcastLanguagesMutation>;
export type UpdateWebcastLanguagesMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebcastLanguagesMutation,
  UpdateWebcastLanguagesMutationVariables
>;
export const UpdateWebcastCoversDocument = gql`
  mutation UpdateWebcastCovers($input: UpdateWebcastInput!) {
    updateWebcast(input: $input) {
      ... on UpdateWebcastProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
`;
export type UpdateWebcastCoversMutationFn = Apollo.MutationFunction<
  UpdateWebcastCoversMutation,
  UpdateWebcastCoversMutationVariables
>;

/**
 * __useUpdateWebcastCoversMutation__
 *
 * To run a mutation, you first call `useUpdateWebcastCoversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebcastCoversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebcastCoversMutation, { data, loading, error }] = useUpdateWebcastCoversMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebcastCoversMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWebcastCoversMutation, UpdateWebcastCoversMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebcastCoversMutation, UpdateWebcastCoversMutationVariables>(
    UpdateWebcastCoversDocument,
    options
  );
}
export type UpdateWebcastCoversMutationHookResult = ReturnType<typeof useUpdateWebcastCoversMutation>;
export type UpdateWebcastCoversMutationResult = Apollo.MutationResult<UpdateWebcastCoversMutation>;
export type UpdateWebcastCoversMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebcastCoversMutation,
  UpdateWebcastCoversMutationVariables
>;
export const CreateWebcastCoverImageDocument = gql`
  mutation CreateWebcastCoverImage($webcastId: ID!, $eventStage: EventStage!, $language: String!) {
    createWebcastCoverImage(webcastId: $webcastId, eventStage: $eventStage, language: $language) {
      ... on AssetCreateResultSuccess {
        assetId
        uploadLink {
          url
          token
        }
      }
      ... on AssetCreateResultFailure {
        code
      }
    }
  }
`;
export type CreateWebcastCoverImageMutationFn = Apollo.MutationFunction<
  CreateWebcastCoverImageMutation,
  CreateWebcastCoverImageMutationVariables
>;

/**
 * __useCreateWebcastCoverImageMutation__
 *
 * To run a mutation, you first call `useCreateWebcastCoverImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebcastCoverImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebcastCoverImageMutation, { data, loading, error }] = useCreateWebcastCoverImageMutation({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *      eventStage: // value for 'eventStage'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateWebcastCoverImageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWebcastCoverImageMutation, CreateWebcastCoverImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWebcastCoverImageMutation, CreateWebcastCoverImageMutationVariables>(
    CreateWebcastCoverImageDocument,
    options
  );
}
export type CreateWebcastCoverImageMutationHookResult = ReturnType<typeof useCreateWebcastCoverImageMutation>;
export type CreateWebcastCoverImageMutationResult = Apollo.MutationResult<CreateWebcastCoverImageMutation>;
export type CreateWebcastCoverImageMutationOptions = Apollo.BaseMutationOptions<
  CreateWebcastCoverImageMutation,
  CreateWebcastCoverImageMutationVariables
>;
export const DeleteWebcastCoverImageDocument = gql`
  mutation DeleteWebcastCoverImage($webcastId: ID!, $eventStage: EventStage!, $language: String!) {
    deleteWebcastCoverImage(webcastId: $webcastId, eventStage: $eventStage, language: $language) {
      assetId
    }
  }
`;
export type DeleteWebcastCoverImageMutationFn = Apollo.MutationFunction<
  DeleteWebcastCoverImageMutation,
  DeleteWebcastCoverImageMutationVariables
>;

/**
 * __useDeleteWebcastCoverImageMutation__
 *
 * To run a mutation, you first call `useDeleteWebcastCoverImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebcastCoverImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebcastCoverImageMutation, { data, loading, error }] = useDeleteWebcastCoverImageMutation({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *      eventStage: // value for 'eventStage'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useDeleteWebcastCoverImageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWebcastCoverImageMutation, DeleteWebcastCoverImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWebcastCoverImageMutation, DeleteWebcastCoverImageMutationVariables>(
    DeleteWebcastCoverImageDocument,
    options
  );
}
export type DeleteWebcastCoverImageMutationHookResult = ReturnType<typeof useDeleteWebcastCoverImageMutation>;
export type DeleteWebcastCoverImageMutationResult = Apollo.MutationResult<DeleteWebcastCoverImageMutation>;
export type DeleteWebcastCoverImageMutationOptions = Apollo.BaseMutationOptions<
  DeleteWebcastCoverImageMutation,
  DeleteWebcastCoverImageMutationVariables
>;
export const GetWebcastMediaDocument = gql`
  query GetWebcastMedia($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      primaryLanguage
      additionalLanguages
      contents {
        language
        preLive {
          coverImage {
            ...CoverImage
          }
          video {
            ...Video
          }
        }
        postLive {
          coverImage {
            ...CoverImage
          }
          video {
            ...Video
          }
        }
      }
    }
  }
  ${CoverImageFragmentDoc}
  ${VideoFragmentDoc}
`;

/**
 * __useGetWebcastMediaQuery__
 *
 * To run a query within a React component, call `useGetWebcastMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastMediaQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastMediaQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastMediaQuery, GetWebcastMediaQueryVariables> &
    ({ variables: GetWebcastMediaQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastMediaQuery, GetWebcastMediaQueryVariables>(GetWebcastMediaDocument, options);
}
export function useGetWebcastMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastMediaQuery, GetWebcastMediaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastMediaQuery, GetWebcastMediaQueryVariables>(GetWebcastMediaDocument, options);
}
export function useGetWebcastMediaSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWebcastMediaQuery, GetWebcastMediaQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastMediaQuery, GetWebcastMediaQueryVariables>(GetWebcastMediaDocument, options);
}
export type GetWebcastMediaQueryHookResult = ReturnType<typeof useGetWebcastMediaQuery>;
export type GetWebcastMediaLazyQueryHookResult = ReturnType<typeof useGetWebcastMediaLazyQuery>;
export type GetWebcastMediaSuspenseQueryHookResult = ReturnType<typeof useGetWebcastMediaSuspenseQuery>;
export type GetWebcastMediaQueryResult = Apollo.QueryResult<GetWebcastMediaQuery, GetWebcastMediaQueryVariables>;
export const GetWebcastPresentationsDocument = gql`
  query GetWebcastPresentations($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
      primaryLanguage
      additionalLanguages
      presentations {
        id
        groupId
        language
        downloadLink
        sourceFileName
        uploadLink {
          url
          token
        }
        status {
          uploadStatus {
            progress
          }
          slidesExtractionStatus {
            progress
            totalSlides
            processedSlides
          }
        }
        slides {
          index
          downloadUrl
          thumbnailDownloadUrl
        }
      }
    }
  }
`;

/**
 * __useGetWebcastPresentationsQuery__
 *
 * To run a query within a React component, call `useGetWebcastPresentationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastPresentationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastPresentationsQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastPresentationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastPresentationsQuery, GetWebcastPresentationsQueryVariables> &
    ({ variables: GetWebcastPresentationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastPresentationsQuery, GetWebcastPresentationsQueryVariables>(
    GetWebcastPresentationsDocument,
    options
  );
}
export function useGetWebcastPresentationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastPresentationsQuery, GetWebcastPresentationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastPresentationsQuery, GetWebcastPresentationsQueryVariables>(
    GetWebcastPresentationsDocument,
    options
  );
}
export function useGetWebcastPresentationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastPresentationsQuery, GetWebcastPresentationsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastPresentationsQuery, GetWebcastPresentationsQueryVariables>(
    GetWebcastPresentationsDocument,
    options
  );
}
export type GetWebcastPresentationsQueryHookResult = ReturnType<typeof useGetWebcastPresentationsQuery>;
export type GetWebcastPresentationsLazyQueryHookResult = ReturnType<typeof useGetWebcastPresentationsLazyQuery>;
export type GetWebcastPresentationsSuspenseQueryHookResult = ReturnType<typeof useGetWebcastPresentationsSuspenseQuery>;
export type GetWebcastPresentationsQueryResult = Apollo.QueryResult<
  GetWebcastPresentationsQuery,
  GetWebcastPresentationsQueryVariables
>;
export const GetChatTypeDocument = gql`
  query GetChatType($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
      chat {
        type
      }
    }
  }
`;

/**
 * __useGetChatTypeQuery__
 *
 * To run a query within a React component, call `useGetChatTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatTypeQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetChatTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatTypeQuery, GetChatTypeQueryVariables> &
    ({ variables: GetChatTypeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatTypeQuery, GetChatTypeQueryVariables>(GetChatTypeDocument, options);
}
export function useGetChatTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatTypeQuery, GetChatTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatTypeQuery, GetChatTypeQueryVariables>(GetChatTypeDocument, options);
}
export function useGetChatTypeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatTypeQuery, GetChatTypeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatTypeQuery, GetChatTypeQueryVariables>(GetChatTypeDocument, options);
}
export type GetChatTypeQueryHookResult = ReturnType<typeof useGetChatTypeQuery>;
export type GetChatTypeLazyQueryHookResult = ReturnType<typeof useGetChatTypeLazyQuery>;
export type GetChatTypeSuspenseQueryHookResult = ReturnType<typeof useGetChatTypeSuspenseQuery>;
export type GetChatTypeQueryResult = Apollo.QueryResult<GetChatTypeQuery, GetChatTypeQueryVariables>;
export const CreateChatDocument = gql`
  mutation CreateChat($input: CreateChatInput!) {
    createChat(input: $input) {
      ... on CreateChatProblem {
        code
      }
    }
  }
`;
export type CreateChatMutationFn = Apollo.MutationFunction<CreateChatMutation, CreateChatMutationVariables>;

/**
 * __useCreateChatMutation__
 *
 * To run a mutation, you first call `useCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMutation, { data, loading, error }] = useCreateChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChatMutation, CreateChatMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChatMutation, CreateChatMutationVariables>(CreateChatDocument, options);
}
export type CreateChatMutationHookResult = ReturnType<typeof useCreateChatMutation>;
export type CreateChatMutationResult = Apollo.MutationResult<CreateChatMutation>;
export type CreateChatMutationOptions = Apollo.BaseMutationOptions<CreateChatMutation, CreateChatMutationVariables>;
export const DeleteChatDocument = gql`
  mutation DeleteChat($webcastId: ID!) {
    deleteChat(webcastId: $webcastId) {
      ... on DeleteChatProblem {
        code
      }
    }
  }
`;
export type DeleteChatMutationFn = Apollo.MutationFunction<DeleteChatMutation, DeleteChatMutationVariables>;

/**
 * __useDeleteChatMutation__
 *
 * To run a mutation, you first call `useDeleteChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatMutation, { data, loading, error }] = useDeleteChatMutation({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useDeleteChatMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteChatMutation, DeleteChatMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteChatMutation, DeleteChatMutationVariables>(DeleteChatDocument, options);
}
export type DeleteChatMutationHookResult = ReturnType<typeof useDeleteChatMutation>;
export type DeleteChatMutationResult = Apollo.MutationResult<DeleteChatMutation>;
export type DeleteChatMutationOptions = Apollo.BaseMutationOptions<DeleteChatMutation, DeleteChatMutationVariables>;
export const ChangeChatTypeDocument = gql`
  mutation ChangeChatType($input: ChangeChatTypeInput!) {
    changeChatType(input: $input) {
      ... on ChangeChatTypeProblem {
        code
      }
    }
  }
`;
export type ChangeChatTypeMutationFn = Apollo.MutationFunction<ChangeChatTypeMutation, ChangeChatTypeMutationVariables>;

/**
 * __useChangeChatTypeMutation__
 *
 * To run a mutation, you first call `useChangeChatTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeChatTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeChatTypeMutation, { data, loading, error }] = useChangeChatTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeChatTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeChatTypeMutation, ChangeChatTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeChatTypeMutation, ChangeChatTypeMutationVariables>(ChangeChatTypeDocument, options);
}
export type ChangeChatTypeMutationHookResult = ReturnType<typeof useChangeChatTypeMutation>;
export type ChangeChatTypeMutationResult = Apollo.MutationResult<ChangeChatTypeMutation>;
export type ChangeChatTypeMutationOptions = Apollo.BaseMutationOptions<
  ChangeChatTypeMutation,
  ChangeChatTypeMutationVariables
>;
export const UpdateWebcastProtocolDocument = gql`
  mutation UpdateWebcastProtocol($input: UpdateWebcastInput!) {
    updateWebcast(input: $input) {
      ... on UpdateWebcastSuccess {
        webcast {
          id
          streaming {
            live {
              ingestProtocol
              parameters {
                ... on RtmpLiveStreamingParameters {
                  authenticationEnabled
                }
              }
            }
          }
        }
      }
      ... on UpdateWebcastProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
`;
export type UpdateWebcastProtocolMutationFn = Apollo.MutationFunction<
  UpdateWebcastProtocolMutation,
  UpdateWebcastProtocolMutationVariables
>;

/**
 * __useUpdateWebcastProtocolMutation__
 *
 * To run a mutation, you first call `useUpdateWebcastProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebcastProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebcastProtocolMutation, { data, loading, error }] = useUpdateWebcastProtocolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebcastProtocolMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWebcastProtocolMutation, UpdateWebcastProtocolMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebcastProtocolMutation, UpdateWebcastProtocolMutationVariables>(
    UpdateWebcastProtocolDocument,
    options
  );
}
export type UpdateWebcastProtocolMutationHookResult = ReturnType<typeof useUpdateWebcastProtocolMutation>;
export type UpdateWebcastProtocolMutationResult = Apollo.MutationResult<UpdateWebcastProtocolMutation>;
export type UpdateWebcastProtocolMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebcastProtocolMutation,
  UpdateWebcastProtocolMutationVariables
>;
export const GetWebcastProtocolDocument = gql`
  query GetWebcastProtocol($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
      additionalLanguages
      subtitleSettings {
        aiLiveTranscriptGeneration
      }
      streaming {
        live {
          ingestProtocol
          parameters {
            ... on RtmpLiveStreamingParameters {
              authenticationEnabled
            }
          }
        }
      }
      ecdnSettings {
        isActive
        type
      }
    }
  }
`;

/**
 * __useGetWebcastProtocolQuery__
 *
 * To run a query within a React component, call `useGetWebcastProtocolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastProtocolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastProtocolQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastProtocolQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastProtocolQuery, GetWebcastProtocolQueryVariables> &
    ({ variables: GetWebcastProtocolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastProtocolQuery, GetWebcastProtocolQueryVariables>(
    GetWebcastProtocolDocument,
    options
  );
}
export function useGetWebcastProtocolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastProtocolQuery, GetWebcastProtocolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastProtocolQuery, GetWebcastProtocolQueryVariables>(
    GetWebcastProtocolDocument,
    options
  );
}
export function useGetWebcastProtocolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastProtocolQuery, GetWebcastProtocolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastProtocolQuery, GetWebcastProtocolQueryVariables>(
    GetWebcastProtocolDocument,
    options
  );
}
export type GetWebcastProtocolQueryHookResult = ReturnType<typeof useGetWebcastProtocolQuery>;
export type GetWebcastProtocolLazyQueryHookResult = ReturnType<typeof useGetWebcastProtocolLazyQuery>;
export type GetWebcastProtocolSuspenseQueryHookResult = ReturnType<typeof useGetWebcastProtocolSuspenseQuery>;
export type GetWebcastProtocolQueryResult = Apollo.QueryResult<
  GetWebcastProtocolQuery,
  GetWebcastProtocolQueryVariables
>;
export const GetWebcastSetupSummaryDocument = gql`
  query GetWebcastSetupSummary($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      plannedStartAt
      durationInMinutes
      state
      sharingLink {
        sharingUrl
        token
      }
      securityPolicies {
        ... on AccessCodePolicy {
          accessCode
          enabled
        }
        ... on ReferrerProtectionPolicy {
          enabled
          domains
        }
        ... on SingleSignOnPolicy {
          enabled
        }
        ... on EmailRegistrationPolicy {
          enabled
        }
        ... on EmailWhitelistPolicy {
          enabled
        }
        ... on TokenProtectionPolicy {
          enabled
        }
      }
      primaryLanguage
      additionalLanguages
      chat {
        type
      }
      streaming {
        live {
          ingestProtocol
          parameters {
            ... on RtmpLiveStreamingParameters {
              authenticationEnabled
            }
          }
          streams {
            metadata {
              language
            }
            ingest {
              primaryServer
              backupServer
              streamName
              username
              password
            }
          }
        }
      }
      ecdnSettings {
        isActive
        type
      }
    }
  }
`;

/**
 * __useGetWebcastSetupSummaryQuery__
 *
 * To run a query within a React component, call `useGetWebcastSetupSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastSetupSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastSetupSummaryQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastSetupSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastSetupSummaryQuery, GetWebcastSetupSummaryQueryVariables> &
    ({ variables: GetWebcastSetupSummaryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastSetupSummaryQuery, GetWebcastSetupSummaryQueryVariables>(
    GetWebcastSetupSummaryDocument,
    options
  );
}
export function useGetWebcastSetupSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastSetupSummaryQuery, GetWebcastSetupSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastSetupSummaryQuery, GetWebcastSetupSummaryQueryVariables>(
    GetWebcastSetupSummaryDocument,
    options
  );
}
export function useGetWebcastSetupSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastSetupSummaryQuery, GetWebcastSetupSummaryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastSetupSummaryQuery, GetWebcastSetupSummaryQueryVariables>(
    GetWebcastSetupSummaryDocument,
    options
  );
}
export type GetWebcastSetupSummaryQueryHookResult = ReturnType<typeof useGetWebcastSetupSummaryQuery>;
export type GetWebcastSetupSummaryLazyQueryHookResult = ReturnType<typeof useGetWebcastSetupSummaryLazyQuery>;
export type GetWebcastSetupSummarySuspenseQueryHookResult = ReturnType<typeof useGetWebcastSetupSummarySuspenseQuery>;
export type GetWebcastSetupSummaryQueryResult = Apollo.QueryResult<
  GetWebcastSetupSummaryQuery,
  GetWebcastSetupSummaryQueryVariables
>;
export const InitializeEventDocument = gql`
  mutation InitializeEvent($webcastId: ID!) {
    initializeEvent(webcastId: $webcastId) {
      ... on InitializeEventProblem {
        code
      }
    }
  }
`;
export type InitializeEventMutationFn = Apollo.MutationFunction<
  InitializeEventMutation,
  InitializeEventMutationVariables
>;

/**
 * __useInitializeEventMutation__
 *
 * To run a mutation, you first call `useInitializeEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeEventMutation, { data, loading, error }] = useInitializeEventMutation({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useInitializeEventMutation(
  baseOptions?: Apollo.MutationHookOptions<InitializeEventMutation, InitializeEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitializeEventMutation, InitializeEventMutationVariables>(
    InitializeEventDocument,
    options
  );
}
export type InitializeEventMutationHookResult = ReturnType<typeof useInitializeEventMutation>;
export type InitializeEventMutationResult = Apollo.MutationResult<InitializeEventMutation>;
export type InitializeEventMutationOptions = Apollo.BaseMutationOptions<
  InitializeEventMutation,
  InitializeEventMutationVariables
>;
export const GetWebcastSecurityDocument = gql`
  query GetWebcastSecurity($lsproId: ID!, $webcastId: ID!) {
    identityProviders(lsproId: $lsproId) {
      alias
      name
    }
    webcast(webcastId: $webcastId) {
      id
      state
      securityPolicies {
        ... on AccessCodePolicy {
          enabled
          accessCode
        }
        ... on SingleSignOnPolicy {
          enabled
          idps
          restrictions {
            enabled
            emails
            employeeIds
            groups
          }
        }
        ... on EmailRegistrationPolicy {
          enabled
          requireNameFields
        }
        ... on EmailWhitelistPolicy {
          enabled
          emails
          requireNameFields
        }
      }
    }
  }
`;

/**
 * __useGetWebcastSecurityQuery__
 *
 * To run a query within a React component, call `useGetWebcastSecurityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastSecurityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastSecurityQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastSecurityQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastSecurityQuery, GetWebcastSecurityQueryVariables> &
    ({ variables: GetWebcastSecurityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastSecurityQuery, GetWebcastSecurityQueryVariables>(
    GetWebcastSecurityDocument,
    options
  );
}
export function useGetWebcastSecurityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastSecurityQuery, GetWebcastSecurityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastSecurityQuery, GetWebcastSecurityQueryVariables>(
    GetWebcastSecurityDocument,
    options
  );
}
export function useGetWebcastSecuritySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastSecurityQuery, GetWebcastSecurityQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastSecurityQuery, GetWebcastSecurityQueryVariables>(
    GetWebcastSecurityDocument,
    options
  );
}
export type GetWebcastSecurityQueryHookResult = ReturnType<typeof useGetWebcastSecurityQuery>;
export type GetWebcastSecurityLazyQueryHookResult = ReturnType<typeof useGetWebcastSecurityLazyQuery>;
export type GetWebcastSecuritySuspenseQueryHookResult = ReturnType<typeof useGetWebcastSecuritySuspenseQuery>;
export type GetWebcastSecurityQueryResult = Apollo.QueryResult<
  GetWebcastSecurityQuery,
  GetWebcastSecurityQueryVariables
>;
export const UpdateAccessCodePolicyDocument = gql`
  mutation UpdateAccessCodePolicy($input: UpdateAccessCodePolicyInput!) {
    updateAccessCodePolicy(input: $input) {
      ... on UpdateAccessCodePolicySuccess {
        accessCodePolicy {
          accessCode
          enabled
        }
      }
      ... on UpdateAccessCodePolicyProblem {
        code
      }
      ... on ValidationErrors {
        errors {
          field
          code
          message
        }
      }
    }
  }
`;
export type UpdateAccessCodePolicyMutationFn = Apollo.MutationFunction<
  UpdateAccessCodePolicyMutation,
  UpdateAccessCodePolicyMutationVariables
>;

/**
 * __useUpdateAccessCodePolicyMutation__
 *
 * To run a mutation, you first call `useUpdateAccessCodePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessCodePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessCodePolicyMutation, { data, loading, error }] = useUpdateAccessCodePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessCodePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccessCodePolicyMutation, UpdateAccessCodePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccessCodePolicyMutation, UpdateAccessCodePolicyMutationVariables>(
    UpdateAccessCodePolicyDocument,
    options
  );
}
export type UpdateAccessCodePolicyMutationHookResult = ReturnType<typeof useUpdateAccessCodePolicyMutation>;
export type UpdateAccessCodePolicyMutationResult = Apollo.MutationResult<UpdateAccessCodePolicyMutation>;
export type UpdateAccessCodePolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccessCodePolicyMutation,
  UpdateAccessCodePolicyMutationVariables
>;
export const UpdateSingleSignOnPolicyDocument = gql`
  mutation UpdateSingleSignOnPolicy($input: UpdateSingleSignOnPolicyInput!) {
    updateSingleSignOnPolicy(input: $input) {
      ... on UpdateSingleSignOnPolicySuccess {
        policy {
          enabled
          idps
          restrictions {
            enabled
            emails
            employeeIds
            groups
          }
        }
      }
      ... on UpdateSingleSignOnPolicyValidationFailure {
        invalidEmails
      }
      ... on UpdateSingleSignOnPolicyGenericFailure {
        code
      }
    }
  }
`;
export type UpdateSingleSignOnPolicyMutationFn = Apollo.MutationFunction<
  UpdateSingleSignOnPolicyMutation,
  UpdateSingleSignOnPolicyMutationVariables
>;

/**
 * __useUpdateSingleSignOnPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateSingleSignOnPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSingleSignOnPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSingleSignOnPolicyMutation, { data, loading, error }] = useUpdateSingleSignOnPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSingleSignOnPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSingleSignOnPolicyMutation, UpdateSingleSignOnPolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSingleSignOnPolicyMutation, UpdateSingleSignOnPolicyMutationVariables>(
    UpdateSingleSignOnPolicyDocument,
    options
  );
}
export type UpdateSingleSignOnPolicyMutationHookResult = ReturnType<typeof useUpdateSingleSignOnPolicyMutation>;
export type UpdateSingleSignOnPolicyMutationResult = Apollo.MutationResult<UpdateSingleSignOnPolicyMutation>;
export type UpdateSingleSignOnPolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdateSingleSignOnPolicyMutation,
  UpdateSingleSignOnPolicyMutationVariables
>;
export const UpdateEmailRegistrationPolicyDocument = gql`
  mutation UpdateEmailRegistrationPolicy($input: UpdateEmailRegistrationPolicyInput!) {
    updateEmailRegistrationPolicy(input: $input) {
      ... on UpdateEmailRegistrationPolicySuccess {
        policy {
          enabled
          requireNameFields
        }
      }
      ... on UpdateEmailRegistrationPolicyFailure {
        code
      }
    }
  }
`;
export type UpdateEmailRegistrationPolicyMutationFn = Apollo.MutationFunction<
  UpdateEmailRegistrationPolicyMutation,
  UpdateEmailRegistrationPolicyMutationVariables
>;

/**
 * __useUpdateEmailRegistrationPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateEmailRegistrationPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailRegistrationPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailRegistrationPolicyMutation, { data, loading, error }] = useUpdateEmailRegistrationPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailRegistrationPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailRegistrationPolicyMutation,
    UpdateEmailRegistrationPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEmailRegistrationPolicyMutation, UpdateEmailRegistrationPolicyMutationVariables>(
    UpdateEmailRegistrationPolicyDocument,
    options
  );
}
export type UpdateEmailRegistrationPolicyMutationHookResult = ReturnType<
  typeof useUpdateEmailRegistrationPolicyMutation
>;
export type UpdateEmailRegistrationPolicyMutationResult = Apollo.MutationResult<UpdateEmailRegistrationPolicyMutation>;
export type UpdateEmailRegistrationPolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailRegistrationPolicyMutation,
  UpdateEmailRegistrationPolicyMutationVariables
>;
export const UpdateEmailWhitelistPolicyDocument = gql`
  mutation UpdateEmailWhitelistPolicy($input: UpdateEmailWhitelistPolicyInput!) {
    updateEmailWhitelistPolicy(input: $input) {
      ... on UpdateEmailWhitelistPolicySuccess {
        policy {
          enabled
          emails
          requireNameFields
        }
      }
      ... on UpdateEmailWhitelistPolicyGenericFailure {
        code
      }
      ... on UpdateEmailWhitelistPolicyEmailValidationFailure {
        invalidEmails
      }
    }
  }
`;
export type UpdateEmailWhitelistPolicyMutationFn = Apollo.MutationFunction<
  UpdateEmailWhitelistPolicyMutation,
  UpdateEmailWhitelistPolicyMutationVariables
>;

/**
 * __useUpdateEmailWhitelistPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateEmailWhitelistPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailWhitelistPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailWhitelistPolicyMutation, { data, loading, error }] = useUpdateEmailWhitelistPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailWhitelistPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailWhitelistPolicyMutation,
    UpdateEmailWhitelistPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEmailWhitelistPolicyMutation, UpdateEmailWhitelistPolicyMutationVariables>(
    UpdateEmailWhitelistPolicyDocument,
    options
  );
}
export type UpdateEmailWhitelistPolicyMutationHookResult = ReturnType<typeof useUpdateEmailWhitelistPolicyMutation>;
export type UpdateEmailWhitelistPolicyMutationResult = Apollo.MutationResult<UpdateEmailWhitelistPolicyMutation>;
export type UpdateEmailWhitelistPolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailWhitelistPolicyMutation,
  UpdateEmailWhitelistPolicyMutationVariables
>;
export const GetWebcastInformationDocument = gql`
  query GetWebcastInformation($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      ecdnSettings {
        isActive
        type
      }
    }
  }
`;

/**
 * __useGetWebcastInformationQuery__
 *
 * To run a query within a React component, call `useGetWebcastInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastInformationQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastInformationQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastInformationQuery, GetWebcastInformationQueryVariables> &
    ({ variables: GetWebcastInformationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastInformationQuery, GetWebcastInformationQueryVariables>(
    GetWebcastInformationDocument,
    options
  );
}
export function useGetWebcastInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastInformationQuery, GetWebcastInformationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastInformationQuery, GetWebcastInformationQueryVariables>(
    GetWebcastInformationDocument,
    options
  );
}
export function useGetWebcastInformationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastInformationQuery, GetWebcastInformationQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastInformationQuery, GetWebcastInformationQueryVariables>(
    GetWebcastInformationDocument,
    options
  );
}
export type GetWebcastInformationQueryHookResult = ReturnType<typeof useGetWebcastInformationQuery>;
export type GetWebcastInformationLazyQueryHookResult = ReturnType<typeof useGetWebcastInformationLazyQuery>;
export type GetWebcastInformationSuspenseQueryHookResult = ReturnType<typeof useGetWebcastInformationSuspenseQuery>;
export type GetWebcastInformationQueryResult = Apollo.QueryResult<
  GetWebcastInformationQuery,
  GetWebcastInformationQueryVariables
>;
export const GetWebcastStateForDuplicationDocument = gql`
  query GetWebcastStateForDuplication($webcastId: ID!) {
    webcast(webcastId: $webcastId) {
      id
      state
    }
  }
`;

/**
 * __useGetWebcastStateForDuplicationQuery__
 *
 * To run a query within a React component, call `useGetWebcastStateForDuplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastStateForDuplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastStateForDuplicationQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *   },
 * });
 */
export function useGetWebcastStateForDuplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWebcastStateForDuplicationQuery,
    GetWebcastStateForDuplicationQueryVariables
  > &
    ({ variables: GetWebcastStateForDuplicationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastStateForDuplicationQuery, GetWebcastStateForDuplicationQueryVariables>(
    GetWebcastStateForDuplicationDocument,
    options
  );
}
export function useGetWebcastStateForDuplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWebcastStateForDuplicationQuery,
    GetWebcastStateForDuplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastStateForDuplicationQuery, GetWebcastStateForDuplicationQueryVariables>(
    GetWebcastStateForDuplicationDocument,
    options
  );
}
export function useGetWebcastStateForDuplicationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWebcastStateForDuplicationQuery, GetWebcastStateForDuplicationQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastStateForDuplicationQuery, GetWebcastStateForDuplicationQueryVariables>(
    GetWebcastStateForDuplicationDocument,
    options
  );
}
export type GetWebcastStateForDuplicationQueryHookResult = ReturnType<typeof useGetWebcastStateForDuplicationQuery>;
export type GetWebcastStateForDuplicationLazyQueryHookResult = ReturnType<
  typeof useGetWebcastStateForDuplicationLazyQuery
>;
export type GetWebcastStateForDuplicationSuspenseQueryHookResult = ReturnType<
  typeof useGetWebcastStateForDuplicationSuspenseQuery
>;
export type GetWebcastStateForDuplicationQueryResult = Apollo.QueryResult<
  GetWebcastStateForDuplicationQuery,
  GetWebcastStateForDuplicationQueryVariables
>;
export const DuplicateWebcastDocument = gql`
  mutation DuplicateWebcast($input: DuplicateWebcastInput!) {
    duplicateWebcast(input: $input) {
      ... on DuplicateWebcastSuccess {
        webcast {
          id
          originalWebcastId
        }
      }
      ... on DuplicateWebcastProblem {
        code
      }
    }
  }
`;
export type DuplicateWebcastMutationFn = Apollo.MutationFunction<
  DuplicateWebcastMutation,
  DuplicateWebcastMutationVariables
>;

/**
 * __useDuplicateWebcastMutation__
 *
 * To run a mutation, you first call `useDuplicateWebcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateWebcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateWebcastMutation, { data, loading, error }] = useDuplicateWebcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateWebcastMutation(
  baseOptions?: Apollo.MutationHookOptions<DuplicateWebcastMutation, DuplicateWebcastMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DuplicateWebcastMutation, DuplicateWebcastMutationVariables>(
    DuplicateWebcastDocument,
    options
  );
}
export type DuplicateWebcastMutationHookResult = ReturnType<typeof useDuplicateWebcastMutation>;
export type DuplicateWebcastMutationResult = Apollo.MutationResult<DuplicateWebcastMutation>;
export type DuplicateWebcastMutationOptions = Apollo.BaseMutationOptions<
  DuplicateWebcastMutation,
  DuplicateWebcastMutationVariables
>;
export const GetWebcastsDocument = gql`
  query GetWebcasts(
    $lsproId: ID!
    $orderBy: WebcastOrder
    $after: String
    $first: Int
    $searchTerm: String
    $filterBy: WebcastFilters
  ) {
    webcasts(
      lsproId: $lsproId
      orderBy: $orderBy
      after: $after
      first: $first
      searchTerm: $searchTerm
      filterBy: $filterBy
    ) {
      edges {
        node {
          ...GetWebcasts_Webcast
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${GetWebcasts_WebcastFragmentDoc}
`;

/**
 * __useGetWebcastsQuery__
 *
 * To run a query within a React component, call `useGetWebcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastsQuery({
 *   variables: {
 *      lsproId: // value for 'lsproId'
 *      orderBy: // value for 'orderBy'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      searchTerm: // value for 'searchTerm'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGetWebcastsQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastsQuery, GetWebcastsQueryVariables> &
    ({ variables: GetWebcastsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastsQuery, GetWebcastsQueryVariables>(GetWebcastsDocument, options);
}
export function useGetWebcastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastsQuery, GetWebcastsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastsQuery, GetWebcastsQueryVariables>(GetWebcastsDocument, options);
}
export function useGetWebcastsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWebcastsQuery, GetWebcastsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastsQuery, GetWebcastsQueryVariables>(GetWebcastsDocument, options);
}
export type GetWebcastsQueryHookResult = ReturnType<typeof useGetWebcastsQuery>;
export type GetWebcastsLazyQueryHookResult = ReturnType<typeof useGetWebcastsLazyQuery>;
export type GetWebcastsSuspenseQueryHookResult = ReturnType<typeof useGetWebcastsSuspenseQuery>;
export type GetWebcastsQueryResult = Apollo.QueryResult<GetWebcastsQuery, GetWebcastsQueryVariables>;
export const DeleteWebcastEventDocument = gql`
  mutation DeleteWebcastEvent($id: ID!) {
    deleteWebcast(id: $id) {
      ... on DeleteWebcastSuccess {
        webcast {
          id
        }
      }
      ... on DeleteWebcastProblem {
        code
      }
    }
  }
`;
export type DeleteWebcastEventMutationFn = Apollo.MutationFunction<
  DeleteWebcastEventMutation,
  DeleteWebcastEventMutationVariables
>;

/**
 * __useDeleteWebcastEventMutation__
 *
 * To run a mutation, you first call `useDeleteWebcastEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebcastEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebcastEventMutation, { data, loading, error }] = useDeleteWebcastEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWebcastEventMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWebcastEventMutation, DeleteWebcastEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWebcastEventMutation, DeleteWebcastEventMutationVariables>(
    DeleteWebcastEventDocument,
    options
  );
}
export type DeleteWebcastEventMutationHookResult = ReturnType<typeof useDeleteWebcastEventMutation>;
export type DeleteWebcastEventMutationResult = Apollo.MutationResult<DeleteWebcastEventMutation>;
export type DeleteWebcastEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteWebcastEventMutation,
  DeleteWebcastEventMutationVariables
>;
export const CreatePresentationDocument = gql`
  mutation CreatePresentation($createPresentationInput: CreatePresentationInput!, $groupId: ID) {
    createPresentation(input: $createPresentationInput, groupId: $groupId) {
      ... on CreatePresentationSuccess {
        presentation {
          id
          groupId
          uploadLink {
            url
            token
          }
        }
      }
      ... on CreatePresentationProblem {
        code
      }
    }
  }
`;
export type CreatePresentationMutationFn = Apollo.MutationFunction<
  CreatePresentationMutation,
  CreatePresentationMutationVariables
>;

/**
 * __useCreatePresentationMutation__
 *
 * To run a mutation, you first call `useCreatePresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresentationMutation, { data, loading, error }] = useCreatePresentationMutation({
 *   variables: {
 *      createPresentationInput: // value for 'createPresentationInput'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useCreatePresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePresentationMutation, CreatePresentationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePresentationMutation, CreatePresentationMutationVariables>(
    CreatePresentationDocument,
    options
  );
}
export type CreatePresentationMutationHookResult = ReturnType<typeof useCreatePresentationMutation>;
export type CreatePresentationMutationResult = Apollo.MutationResult<CreatePresentationMutation>;
export type CreatePresentationMutationOptions = Apollo.BaseMutationOptions<
  CreatePresentationMutation,
  CreatePresentationMutationVariables
>;
export const DeletePresentationDocument = gql`
  mutation DeletePresentation($deletePresentationInput: DeletePresentationInput!) {
    deletePresentation(input: $deletePresentationInput) {
      ... on DeletePresentationSuccess {
        presentation {
          id
          groupId
        }
      }
      ... on DeletePresentationProblem {
        code
      }
    }
  }
`;
export type DeletePresentationMutationFn = Apollo.MutationFunction<
  DeletePresentationMutation,
  DeletePresentationMutationVariables
>;

/**
 * __useDeletePresentationMutation__
 *
 * To run a mutation, you first call `useDeletePresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresentationMutation, { data, loading, error }] = useDeletePresentationMutation({
 *   variables: {
 *      deletePresentationInput: // value for 'deletePresentationInput'
 *   },
 * });
 */
export function useDeletePresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePresentationMutation, DeletePresentationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePresentationMutation, DeletePresentationMutationVariables>(
    DeletePresentationDocument,
    options
  );
}
export type DeletePresentationMutationHookResult = ReturnType<typeof useDeletePresentationMutation>;
export type DeletePresentationMutationResult = Apollo.MutationResult<DeletePresentationMutation>;
export type DeletePresentationMutationOptions = Apollo.BaseMutationOptions<
  DeletePresentationMutation,
  DeletePresentationMutationVariables
>;
export const GetIsVmproLinkedDocument = gql`
  query GetIsVmproLinked {
    currentUser {
      isVmproLinked
    }
  }
`;

/**
 * __useGetIsVmproLinkedQuery__
 *
 * To run a query within a React component, call `useGetIsVmproLinkedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsVmproLinkedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsVmproLinkedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsVmproLinkedQuery(
  baseOptions?: Apollo.QueryHookOptions<GetIsVmproLinkedQuery, GetIsVmproLinkedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsVmproLinkedQuery, GetIsVmproLinkedQueryVariables>(GetIsVmproLinkedDocument, options);
}
export function useGetIsVmproLinkedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIsVmproLinkedQuery, GetIsVmproLinkedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIsVmproLinkedQuery, GetIsVmproLinkedQueryVariables>(GetIsVmproLinkedDocument, options);
}
export function useGetIsVmproLinkedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetIsVmproLinkedQuery, GetIsVmproLinkedQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetIsVmproLinkedQuery, GetIsVmproLinkedQueryVariables>(
    GetIsVmproLinkedDocument,
    options
  );
}
export type GetIsVmproLinkedQueryHookResult = ReturnType<typeof useGetIsVmproLinkedQuery>;
export type GetIsVmproLinkedLazyQueryHookResult = ReturnType<typeof useGetIsVmproLinkedLazyQuery>;
export type GetIsVmproLinkedSuspenseQueryHookResult = ReturnType<typeof useGetIsVmproLinkedSuspenseQuery>;
export type GetIsVmproLinkedQueryResult = Apollo.QueryResult<GetIsVmproLinkedQuery, GetIsVmproLinkedQueryVariables>;
export const LinkVmproDocument = gql`
  mutation LinkVmpro($input: LinkVmproInput!) {
    linkVmpro(input: $input) {
      ... on LinkVmproSuccess {
        authorizationCode
        redirectUri
      }
      ... on LinkVmproProblem {
        code
      }
    }
  }
`;
export type LinkVmproMutationFn = Apollo.MutationFunction<LinkVmproMutation, LinkVmproMutationVariables>;

/**
 * __useLinkVmproMutation__
 *
 * To run a mutation, you first call `useLinkVmproMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkVmproMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkVmproMutation, { data, loading, error }] = useLinkVmproMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkVmproMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkVmproMutation, LinkVmproMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkVmproMutation, LinkVmproMutationVariables>(LinkVmproDocument, options);
}
export type LinkVmproMutationHookResult = ReturnType<typeof useLinkVmproMutation>;
export type LinkVmproMutationResult = Apollo.MutationResult<LinkVmproMutation>;
export type LinkVmproMutationOptions = Apollo.BaseMutationOptions<LinkVmproMutation, LinkVmproMutationVariables>;
