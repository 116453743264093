import { AnimationEventHandler } from 'react';

type IconProps = { onAnimationEnd?: AnimationEventHandler | undefined };

export const StepForwardIcon = ({ onAnimationEnd }: IconProps) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="#fff"
    aria-hidden="true"
    role="img"
    onAnimationEnd={onAnimationEnd}
    data-testid="step-forward-icon"
  >
    <path d="M9 9v10H7v-8H5V9h4zm8 2v6a2 2 0 01-2 2h-2a2 2 0 01-2-2v-6a2 2 0 012-2h2a2 2 0 012 2zm-4 0v6h2v-6h-2zM10.5 0c2.65 0 5.05 1 6.9 2.6L20 0v7h-7l2.62-2.62A7.95 7.95 0 002.9 8L.53 7.22A10.51 10.51 0 0110.5 0z" />
  </svg>
);
