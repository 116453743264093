import { IconButton, Tooltip, TrashIcon } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import styles from './event-cover.module.css';

type Props = {
  imageUrl: string;
  icon?: React.ReactNode;
  label?: string;
  disabled?: boolean;
  testIdPrefix?: string;
  onDelete: () => void;
};

export function EventCover({ imageUrl, icon, label, disabled, testIdPrefix, onDelete }: Props) {
  const { t } = useTranslation();

  return (
    <div
      data-testid={`${testIdPrefix}cover`}
      className={styles.eventCover}
      style={{ backgroundImage: `url('${imageUrl}')` }}
    >
      <footer className={styles.footer}>
        <span data-testid={`${testIdPrefix}cover-label`}>
          {icon}
          {label}
        </span>

        <Tooltip label={t('manager.webcastSetup.media.deleteCoverImage')}>
          <IconButton
            data-testid={`${testIdPrefix}cover-delete-button`}
            rounded
            type="button"
            disabled={disabled}
            onClick={onDelete}
            aria-label={t('manager.webcastSetup.media.deleteCoverImage')}
          >
            <TrashIcon />
          </IconButton>
        </Tooltip>
      </footer>
    </div>
  );
}
