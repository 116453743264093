import type { MutableRefObject, CSSProperties } from 'react';
import classNames from 'classnames';

import { clamp } from '../../utils/clamp';
import { getFormattedTime } from '../../utils/time';
import { useCursorPosition } from '../../hooks/use-cursor-position';
import { useDynamicSize } from '../../hooks/use-dynamic-size';
import styles from './timeline-preview.module.css';
import type { Chapter, Storyboard } from '../timeline/timeline.types';

type Props = {
  timelineRef: MutableRefObject<HTMLElement | null>;
  totalTime?: number;
  storyboard?: Storyboard;
  chapter?: Chapter;
  hideThumbnail?: boolean;
  style?: CSSProperties;
};

export function TimelinePreview({ timelineRef, totalTime, storyboard, chapter, hideThumbnail, style }: Props) {
  const { width: timelineWidth } = useDynamicSize(timelineRef);
  const [{ x: hoverPosition }, active] = useCursorPosition(timelineRef, false, false);

  if ((!storyboard?.sources?.length && !chapter?.title) || !timelineRef.current || !totalTime) return null;

  const showThumbnail = !hideThumbnail && (storyboard?.sources?.length || chapter?.thumbnail);
  const wrapperWidth = showThumbnail ? 157 : 85;
  const wrapperPosition = clamp(0, hoverPosition - wrapperWidth / 2, timelineWidth - wrapperWidth);
  const wrapperStyle = { left: wrapperPosition, width: wrapperWidth };
  const hoveredTime = (hoverPosition / timelineWidth) * totalTime;

  let imageStyle = {},
    width,
    height;
  if (storyboard) {
    const { sources, interval, spritesPerRow } = storyboard;
    width = storyboard.width;
    height = storyboard.height;
    const timeInterval = hoveredTime / interval / 1000;

    const x = Math.floor(timeInterval % spritesPerRow);
    const y = Math.floor(timeInterval / spritesPerRow);

    imageStyle = {
      width,
      height,
      backgroundImage: `url(${sources[0]})`,
      backgroundPosition: `-${x * width}px -${y * height}px`,
      transform: wrapperWidth > width ? `scale(${wrapperWidth / width})` : undefined,
    };
  } else if (chapter) {
    width = wrapperWidth;
    height = (wrapperWidth / 16) * 9;
    imageStyle = {
      backgroundImage: `url(${chapter?.thumbnail})`,
      backgroundSize: 'cover',
    };
  }

  return (
    <div
      data-testid="timeline-preview"
      className={classNames(styles.wrapper, { [styles.active]: active })}
      style={{ ...wrapperStyle, ...style }}
    >
      {showThumbnail && (
        <div
          className={styles.thumbnailWrapper}
          style={{
            width: '100%',
            aspectRatio: `${width} / ${height}`,
          }}
        >
          <div className={styles.thumbnail} style={imageStyle} />
        </div>
      )}
      <div className={styles.content}>
        {chapter?.title && <div className={styles.chapterTitle}>{chapter.title}</div>}
        <div className={styles.hoveredTime}>{getFormattedTime(hoveredTime)}</div>
      </div>
    </div>
  );
}
