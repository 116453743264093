import { t } from 'i18next';

import { validDomain, validEmail } from '../../../../utils';

export function validateEmailInput(value: string, values: string[] = []) {
  const _value = value.trim().toLowerCase();
  const _values = values.map((value) => value.trim().toLowerCase());

  if (_value.includes('*')) {
    if (!_value.startsWith('*@')) {
      return t('manager.webcastSetup.viewerAccess.whitelistModal.errorMessages.email.wildcardNotAtTheBeginning');
    }

    if (!validDomain(_value)) {
      return t('manager.webcastSetup.viewerAccess.whitelistModal.errorMessages.email.invalidEmail');
    }
  }

  if (!_value.includes('*@') && !validEmail(_value)) {
    return t('manager.webcastSetup.viewerAccess.whitelistModal.errorMessages.email.invalidEmail');
  }

  if (_values.includes(_value)) {
    return t('manager.webcastSetup.viewerAccess.whitelistModal.errorMessages.email.duplicatedEmail');
  }
}
