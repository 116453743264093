import type { Descendant } from 'slate';

import { Heading, Paragraph, SaveButton, SelectionInputLayout, Toggle } from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ComplianceLinkEditor } from './compliance-link-editor';
import { parseContent } from '../../../../components/rich-text-editor';
import {
  useGetLsproComplianceSettingsQuery,
  useUpdateLsproComplianceSettingsMutation,
} from '../../../../generated/graphql-manager';
import { useCurrentUser } from '../../../hooks/current-user';
import managerStyles from '../../../manager.module.css';

export function CompliancePage() {
  const { lsproId } = useCurrentUser();
  const { t } = useTranslation();
  const [complianceFormContent, setComplianceFormContent] = useState<Descendant[]>();
  const [editorRefreshKey, setEditorRefreshKey] = useState(Date.now());

  const { data, loading, refetch } = useGetLsproComplianceSettingsQuery({
    variables: { lsproId },
    onCompleted(data) {
      setEditorRefreshKey(Date.now());
      setComplianceFormContent(parseContent(data?.lsproSettings?.complianceForm?.text));
    },
  });

  const areImprintLinksEnabled = Boolean(data?.lsproSettings?.includeDefaultComplianceForm);

  const [updateLsproComplianceSettings, { loading: updateLsproComplianceSettingsLoading }] =
    useUpdateLsproComplianceSettingsMutation();

  const handleEnableImprintLinks = async (value: boolean) => {
    await updateLsproComplianceSettings({
      variables: {
        input: {
          lsproId,
          includeDefaultComplianceForm: value,
        },
      },
      optimisticResponse: {
        updateLiveStreamProComplianceForm: {
          __typename: 'UpdateComplianceFormSuccess',
          complianceForm: {
            text: data?.lsproSettings?.complianceForm?.text || '',
          },
          includeDefaultComplianceForm: value,
        },
      },
      update: (cache, { data }) => {
        if (data?.updateLiveStreamProComplianceForm.__typename !== 'UpdateComplianceFormSuccess') return;

        cache.modify({
          fields: {
            lsproSettings: (lsproSettings) => ({
              ...lsproSettings,
              includeDefaultComplianceForm: value,
            }),
          },
        });
      },
    });
  };

  const saveLinkChanges = async () => {
    await updateLsproComplianceSettings({
      variables: {
        input: {
          lsproId,
          complianceForm: {
            text: JSON.stringify(complianceFormContent),
          },
        },
      },
      onCompleted: () => {
        refetch();
      },
    });
  };

  return (
    <main className={managerStyles.main} data-testid="compliance-page">
      <Heading className={managerStyles.grayText} data-testid="compliance-heading">
        {t('manager.channelSettings.compliance.heading')}
      </Heading>

      <Paragraph data-testid="compliance-paragraph">{t('manager.channelSettings.compliance.paragraph')}</Paragraph>

      <SelectionInputLayout
        hintMessage={t('manager.channelSettings.compliance.movingimageLinksHint')}
        checked={areImprintLinksEnabled}
        inputElement={
          <Toggle
            data-testid="enable-imprint-links-toggle"
            checked={areImprintLinksEnabled}
            onChange={() => handleEnableImprintLinks(!areImprintLinksEnabled)}
          >
            {t('manager.channelSettings.compliance.movingimageLinksInfo')}
          </Toggle>
        }
      />

      <ComplianceLinkEditor
        refreshKey={editorRefreshKey}
        content={complianceFormContent}
        disabled={loading || updateLsproComplianceSettingsLoading}
        onChange={setComplianceFormContent}
      />

      <div className={managerStyles.buttons}>
        <SaveButton
          data-testid="compliance-submit-button"
          onClick={saveLinkChanges}
          saving={updateLsproComplianceSettingsLoading}
          disabled={updateLsproComplianceSettingsLoading}
        />
      </div>
    </main>
  );
}
