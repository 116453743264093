// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline_wrapper__1uawa {
  --thumb-size: 18px;

  display: flex;
  align-items: center;
  height: var(--thumb-size);
  position: relative;

  flex: none;
}

.timeline_indicators__t1NV5 {
  position: absolute;

  height: 4px;
  width: 100%;

  background: var(--sliderBackgroundColor);
  overflow: hidden;

  transition: transform 0.2s;
}
.timeline_wrapper__1uawa:hover .timeline_indicatorsHover__BskoG {
  transform: scaleY(2);
}

@media (prefers-reduced-motion) {
  .timeline_indicators__t1NV5 {
    transition: none;
  }
}

.timeline_indicators__t1NV5 div {
  position: absolute;
  background: white;
  opacity: 0.2;

  height: 100%;
}

.timeline_indicators__t1NV5 div:nth-child(2) {
  opacity: 0.6;
}

.timeline_indicators__t1NV5 div:nth-child(3) {
  opacity: 1;
  background: var(--primaryColor);
}

.timeline_input__1gA\\+2 {
  width: 100%;
  position: relative;
  background: none;
  cursor: pointer;
}

.timeline_indicators__t1NV5 div {
  /* transition: width 0.2s linear; */
}
`, "",{"version":3,"sources":["webpack://./src/components/timeline/timeline.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;;EAElB,UAAU;AACZ;;AAEA;EACE,kBAAkB;;EAElB,WAAW;EACX,WAAW;;EAEX,wCAAwC;EACxC,gBAAgB;;EAEhB,0BAA0B;AAC5B;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;;EAEZ,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".wrapper {\n  --thumb-size: 18px;\n\n  display: flex;\n  align-items: center;\n  height: var(--thumb-size);\n  position: relative;\n\n  flex: none;\n}\n\n.indicators {\n  position: absolute;\n\n  height: 4px;\n  width: 100%;\n\n  background: var(--sliderBackgroundColor);\n  overflow: hidden;\n\n  transition: transform 0.2s;\n}\n.wrapper:hover .indicatorsHover {\n  transform: scaleY(2);\n}\n\n@media (prefers-reduced-motion) {\n  .indicators {\n    transition: none;\n  }\n}\n\n.indicators div {\n  position: absolute;\n  background: white;\n  opacity: 0.2;\n\n  height: 100%;\n}\n\n.indicators div:nth-child(2) {\n  opacity: 0.6;\n}\n\n.indicators div:nth-child(3) {\n  opacity: 1;\n  background: var(--primaryColor);\n}\n\n.input {\n  width: 100%;\n  position: relative;\n  background: none;\n  cursor: pointer;\n}\n\n.indicators div {\n  /* transition: width 0.2s linear; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `timeline_wrapper__1uawa`,
	"indicators": `timeline_indicators__t1NV5`,
	"indicatorsHover": `timeline_indicatorsHover__BskoG`,
	"input": `timeline_input__1gA+2`
};
export default ___CSS_LOADER_EXPORT___;
