import type { HTMLAttributes, MouseEvent } from 'react';

import { IconButton, MenuIcon, Tooltip, classNames, useOnClickOutside } from '@movingimage-evp/mi-ui-component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './menu-more.module.css';

export type MenuPosition = 'left' | 'center' | 'right' | 'top-right' | 'top-left';

type Props = {
  menuPosition?: MenuPosition;
} & HTMLAttributes<HTMLDivElement>;

export function MenuMore({ menuPosition = 'center', children }: Props) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useOnClickOutside(wrapperRef, () => setIsMenuOpen(false));

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
  };

  return (
    <div ref={wrapperRef} className={styles.wrapper} onClick={handleClick}>
      <Tooltip label={t('manager.components.menuMore.label')} hidden={isMenuOpen}>
        <IconButton
          rounded
          data-testid="more-menu-button"
          aria-label={t('manager.components.menuMore.label')}
          className={styles.menuButton}
          onClick={() => setIsMenuOpen((state) => !state)}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>

      {isMenuOpen && (
        <div className={classNames(styles.menu, styles[menuPosition])} data-testid="more-menu">
          {children}
        </div>
      )}
    </div>
  );
}
